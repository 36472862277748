import { Injectable, OnDestroy } from '@angular/core'

@Injectable({
  providedIn: 'root',
})
export class DomObserverService implements OnDestroy {
  private observer: MutationObserver

  constructor() {}

  observeElementById(
    elementId: string,
    callback: (element: HTMLElement) => void
  ): void {
    this.observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'childList') {
          const element = document.getElementById(elementId)
          if (element) {
            callback(element as HTMLElement)
          }
        }
      })
    })

    this.observer.observe(document.body, {
      childList: true,
      subtree: true,
    })
  }

  ngOnDestroy(): void {
    if (this.observer) {
      this.observer.disconnect()
    }
  }
}
