export const SUCCESS_CREATE_SURVEY_TITLE = '¡Encuesta creada! 📝'
export const SUCCESS_CREATE_SURVEY_MESSAGE =
  '¡Ahora puedes empezar a configurarla!'

export const SUCCESS_CLONE_SURVEY_TITLE = '¡Encuesta clonada! 📝'
export const SUCCESS_CLONE_SURVEY_MESSAGE =
  '¡Ahora puedes empezar a configurarla!'

export const SUCCESS_ARCHIVED_SURVEY_TITLE = '¡Encuesta archivada! 📦'
export const SUCCESS_ARCHIVED_SURVEY_MESSAGE =
  '¡Tu encuesta ha sido archivada exitosamente!'

export const SUCCESS_DELETED_SURVEY_TITLE = '¡Encuesta eliminada! 🪦'
export const SUCCESS_DELETED_SURVEY_MESSAGE =
  '¡Tu encuesta ha sido eliminada exitosamente!'

export const SUCCESS_ACTIVE_SURVEY_TITLE = '¡Encuesta activada! 🟢'
export const SUCCESS_ACTIVE_SURVEY_MESSAGE =
  '¡Tu encuesta ha sido activada exitosamente!'

export const SUCCESS_DESACTIVE_SURVEY_TITLE = '¡Encuesta deshabilitada! 🟠'
export const SUCCESS_DESACTIVE_SURVEY_MESSAGE =
  '¡Tu encuesta ha sido deshabilitada!'

export const SUCCESS_COPY_SURVEY_TITLE = '¡Link Encuesta copiado! 📋'
export const SUCCESS_COPY_SURVEY_MESSAGE =
  '¡El link de la encuesta ha sido copiado exitosamente!'

export const ERROR_CREATE_SURVEY_LIMIT_TITLE =
  'Has llegado al límite de encuesta'
export const ERROR_CREATE_SURVEY_LIMIT_MESSAGE =
  'Mejora tu plan para poder generar más encuestas'

export const ERROR_CREATE_SURVEY_EXIST_TITLE = 'El nombre ya existe 🤔'
export const ERROR_CREATE_SURVEY_EXIST_MESSAGE =
  'Cambia el nombre de tu encuesta'
export const ERROR_CREATE_CATEGORY_MESSAGE = 'No se pudo crear la categoría'

export const SUCCESS_UPDATE_SURVEY_TITLE = '¡Se realizaron los cambios! 🪅'
export const SUCCESS_UPDATE_SURVEY_MESSAGE =
  'Encuesta actualizada correctamente'

export const ERROR_LIST_SURVEY_TITLE = 'No pudimos obtener lista de encuestas'
export const ERROR_LIST_SURVEY_MESSAGE = 'Favor intentelo más tarde'

export const INFO_EXIST_SURVEY_TITLE = 'La encuesta ya existe'
export const INFO_EXIST_SURVEY_MESSAGE = 'El nombre de la encuesta ya existe'

export const INFO_LIMIT_QUESTIONS_TITLE = 'Limite Preguntas'
export const INFO_LIMIT_QUESTIONS_MESSAGE =
  'No se permite ingresar más de 20 preguntas'

export const TYPE_LIST = 'lIST'
export const TYPE_CARD = 'CARD'
