import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { IResponse } from './../../../models/response/IResponse'
import { SessionService } from './../../../services/session/session.service'
import {
  ERROR_SERVICE_MESSAGE,
  ERROR_SERVICE_TITLE,
} from './../../../utils/constants/generic-messages'
import {
  ERROR_VALID_ACTUAL_PASS_MESSAGE,
  ERROR_VALID_REPEAT_PASS_MESSAGE,
  SUCCESS_UPDATE_PASS_MESSAGE,
  SUCCESS_UPDATE_PASS_TITLE,
} from './../../../utils/constants/session-messages'

@Component({
  selector: 'app-update-pass-profile',
  templateUrl: './update-pass-profile.component.html',
  styleUrls: ['./update-pass-profile.component.scss'],
})
export class UpdatePassProfileComponent implements OnInit {
  public loadingTokenValidation: boolean
  public isLoading: boolean
  public isValidRequest: boolean
  public validateForm!: FormGroup
  public email: string
  public password: string = ''
  public hasLowerCase: boolean = false
  public hasUpperCase: boolean = false
  public hasNumber: boolean = false
  public validLength: boolean = false
  public passwordConditions = []

  constructor(
    public router: Router,
    private _session: SessionService,
    private notification: NzNotificationService,
    private fb: FormBuilder
  ) {
    this.loadingTokenValidation = false
    this.isValidRequest = true
    this.isLoading = false
  }

  ngOnInit(): void {
    this.passwordConditions = []
    this.validateForm = this.fb.group({
      actPassword: [null, [Validators.required]],
      newPassword: [
        null,
        [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(18),
          this.validatePassword.bind(this),
        ],
      ],
      repeatNewPassword: [
        null,
        [Validators.required, this.confirmationValidator],
      ],
    })
  }

  validatePassword(control: FormControl): void {
    if (!control.value) {
      this.hasLowerCase = false
      this.hasUpperCase = false
      this.hasNumber = false
      this.validLength = false
      return null
    }

    if (control.value) {
      const { value } = control
      this.hasLowerCase = /[a-z]/.test(value)
      this.hasUpperCase = /[A-Z]/.test(value)
      this.hasNumber = /\d/.test(value)
      this.validLength = value.length >= 6 && value.length <= 18

      this.validateForm.get('newPassword')?.valueChanges.subscribe(() => {
        this.passwordConditions = [
          {
            condition: 'Al menos una minúscula',
            isValid: this.hasLowerCase,
          },
          {
            condition: 'Al menos una mayúscula',
            isValid: this.hasUpperCase,
          },
          { condition: 'Al menos un número', isValid: this.hasNumber },
          {
            condition: 'Largo entre 6 a 18 caracteres',
            isValid: this.validLength,
          },
        ]
      })
    }
  }

  public submitForm(): void {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty()
      this.validateForm.controls[i].updateValueAndValidity()
    }

    if (this.validateForm.valid) {
      const request = {
        password: this.validateForm.get('actPassword')?.value,
        newPassword: this.validateForm.get('newPassword')?.value,
        newPasswordRepeat: this.validateForm.get('repeatNewPassword')?.value,
      }

      this.isLoading = true
      this.validateForm.disable()
      this._session.updatePasswordProfile(request).subscribe(
        (response: IResponse) => {
          const { code } = response.status
          if (code === 200 || code === 201) {
            this.notification.create(
              'success',
              SUCCESS_UPDATE_PASS_MESSAGE,
              SUCCESS_UPDATE_PASS_TITLE
            )
          }
          this.resetForm()
        },
        (error) => {
          switch (error.status) {
            case 400:
              this.notification.create(
                'warning',
                ERROR_VALID_REPEAT_PASS_MESSAGE,
                null
              )
              break
            case 401:
              this.notification.create(
                'warning',
                ERROR_VALID_ACTUAL_PASS_MESSAGE,
                null
              )
              break
            default:
              this.notification.create(
                'error',
                ERROR_SERVICE_TITLE,
                ERROR_SERVICE_MESSAGE
              )
              break
          }
          this.resetForm()
        }
      )
    }
  }

  private resetForm = () => {
    this.isLoading = false
    this.validateForm.reset()
    this.validateForm.enable()
    this.passwordConditions = []
  }

  public confirmationValidator = (
    control: FormControl
  ): { [s: string]: boolean } => {
    if (!control.value) {
      return { required: true }
    } else if (control.value !== this.validateForm.controls.newPassword.value) {
      return { confirm: true, error: true }
    }
    return {}
  }
}
