<section
  *ngIf="!loadingTokenValidation"
  id="login"
  class="login"
  style="background-image: url('./assets/images/bg/bg-login-05.jpg') !important"
>
  <div class="login__background">
    <div class="row align-items-center h-100">
      <div
        class="col-lg-5 col-md-12 align-self-center animated fadeIn d-none d-lg-block"
      >
        <img
          src="./assets/images/logos/light-large-logo.png"
          class="img-fluid d-block mx-auto"
          alt=""
        />
      </div>
      <div
        class="col-lg-7 col-md-12 align-self-center animated fadeIn form-col"
      >
        <div class="container">
          <div class="card p-xs-2 p-sm-5 mx-auto">
            <div class="card-body">
              <img
                src="./assets/images/logos/dark-large-logo.png"
                class="img-fluid mr-auto logo-form d-none mb-2"
                alt=""
              />
              <h1 class="text-dark">Debes activar tu cuenta</h1>
              <p class="text-dark mb-5">
                Tu cuenta asociada al
                <span class="text-info">{{ email }}</span> no está activada,
                favor revisa tu casilla de correo electrónico, sino podemos
                reeviarte el correo.
              </p>

              <div class="d-flex">
                <button
                  [disabled]="loadingSendEmail"
                  type="button"
                  (click)="sendEmailActivatedAccount()"
                  class="btn btn-primary ml-auto"
                >
                  <i *ngIf="loadingSendEmail" nz-icon [nzType]="'loading'"></i>
                  <span *ngIf="!loadingSendEmail">Reenviame el correo</span>
                </button>
              </div>
              <div class="mt-3">
                <small class="text-muted mt-xs-2">
                  Protegido por reCAPTCHA y sujeto a
                  <a
                    class=""
                    href="https://policies.google.com/privacy?hl=es"
                    aria-label="Política de privacidad de Google"
                    rel="noopener"
                    target="_blank"
                    >Política de privacidad</a
                  >
                  y
                  <a
                    class=""
                    href="https://policies.google.com/terms?hl=es"
                    aria-label="Condiciones del servicio de Google"
                    rel="noopener"
                    target="_blank"
                    >Condiciones del servicio</a
                  >
                  de Google.
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<app-loader
  *ngIf="loadingTokenValidation"
  bg="dark"
  label="Validando cuenta"
></app-loader>
