import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Select, Store } from '@ngxs/store'
import { Observable } from 'rxjs'
import { first } from 'rxjs/operators'
import { IResponse } from 'src/app/models/response/IResponse'
import { IUser } from 'src/app/models/response/IUser'
import { UserService } from 'src/app/services/service.index'
import { TokenService } from 'src/app/services/session/token.service'
import { IUserTutorial } from './../../models/response/IUser'
import { UpdateLoadingUser, UpdateUser } from './user.actions'
import { UserState } from './user.state'

@Injectable({ providedIn: 'root' })
export class UserFacade {
  @Select(UserState.getLoadingUser) isLoadingUser$: Observable<boolean>
  @Select(UserState.getUser) user$: Observable<IUser>

  constructor(
    private store: Store,
    private router: Router,
    private _user: UserService,
    private _token: TokenService
  ) {}

  public obtainUserFetch(): void {
    this.store.dispatch(new UpdateLoadingUser(true))

    this.validateUserStorage()

    this._user.getUser().subscribe(
      (response: IResponse) => {
        let imagenPerfil = response.data.imagenPerfil
          ? response.data.imagenPerfil
          : '../../../assets/images/profile/profile-hw.jpg'
        let user: IUser
        switch (response.status.code) {
          case 200:
            user = { ...response.data, imagenPerfil }
            this.store.dispatch(new UpdateUser(user))
            this.store.dispatch(new UpdateLoadingUser(false))
            return true
          default:
            return false
        }
      },
      (error: Error) => {
        this.store.dispatch(new UpdateLoadingUser(false))
        const userStorage = localStorage.getItem('user')
        if (!userStorage) {
          this._token.deleteTokenAndLogout()
          this.router.navigate([`/login`])
        }
      }
    )
  }

  public userTutorialFetch(payload: IUserTutorial): void {
    this._user.updateUserTutorial(payload).subscribe(
      async (response: IResponse) => {
        switch (response.status.code) {
          case 200:
          case 201:
            const user: IUser = await this.user$.pipe(first()).toPromise()
            this.store.dispatch(
              new UpdateUser({
                ...user,
                tutorial: {
                  ...user.tutorial,
                  surveyTutorialCompleted: true,
                },
              })
            )
            return true
          default:
            return false
        }
      },
      (error: Error) => {
        return false
      }
    )
  }

  private validateUserStorage() {
    const userStorage = localStorage.getItem('user')

    if (userStorage) {
      this.store.dispatch(new UpdateUser(JSON.parse(userStorage)))
      this.store.dispatch(new UpdateLoadingUser(false))
    }
  }
}
