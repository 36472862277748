export const SUCCESS_LOGIN_TITLE = 'Bienvenid@ '
export const SUCCESS_LOGIN_MESSAGE = 'Nos alegra verte denuevo :)'
export const ERROR_LOGIN_TITLE = 'Oops! No se pudo iniciar sesión'
export const ERROR_LOGIN_MESSAGE = 'Usuario o contraseña incorrecta'

export const SUCCESS_REGISTER_TITLE = 'Su cuenta ha sido creada'
export const SUCCESS_REGISTER_MESSAGE =
  'Nos alegra que formes parte de esta experiencia :)'
export const USER_EXIST_REGISTER_TITLE = 'Este email ya está registrado'
export const USER_EXIST_REGISTER_MESSAGE =
  'Ingresa a login para iniciar sesión, o intenta recuperar tu contraseña'
export const CLIENT_EXIST_REGISTER_TITLE =
  ' como empresa ya se encuetra registrada'
export const CLIENT_EXIST_REGISTER_MESSAGE =
  'Si no puedes continuar con el registro, cambia la empresa o contáctate con el administrador'

export const SUCCESS_RECOVERY_TITLE = 'Enviado!'
export const SUCCESS_RECOVERY_MESSAGE = 'Revise su buzón. Enviamos un correo a '
export const ERROR_RECOVERY_TITLE =
  'No hemos encontrado este email en nuestros registros'
export const SUCCESS_UPDATE_PASS_MESSAGE = 'Su contraseña a sido restablecida'
export const SUCCESS_UPDATE_PASS_TITLE =
  'Ahora puedes ingresar con tu nueva contraseña'

export const ERROR_VALID_ACTUAL_PASS_MESSAGE =
  'Su contraseña actual es incorrecta'

export const ERROR_VALID_REPEAT_PASS_MESSAGE =
  'La nueva contraseña no puede ser igual a la actual'

export const ERROR_TOKEN_UPDATE_PASS_MESSAGE =
  'Lo sentimos, esta solicitud de restablecimiento de contraseña ha caducado. Por favor, solicita un nuevo enlace de restablecimiento.'
export const ERROR_TOKEN_UPDATE_PASS_TITLE =
  '🔐 Error en el restablecimiento de la contraseña'

export const SUCCESS_VALIDATION_ACCOUNT_TITLE = 'Tú cuenta fue Activada!'
export const SUCCESS_VALIDATION_ACCOUNT_MESSAGE =
  'Ahora puedes ingresar con tus credenciales'

export const TOKEN_EXPIRED_TITLE = 'Enlace de activación no válido'
export const TOKEN_EXPIRED_MESSAGE =
  'Lo sentimos, el enlace para activar tu cuenta en Empati ya no es válido.'

export const ERROR_VALIDATION_ACCOUNT_TITLE =
  'Hubo un problema al realizar la solicitud'
export const ERROR_VALIDATION_ACCOUNT_MESSAGE =
  'El token de acceso ya no es válido o ha expirado'
