<div
  *ngIf="(questionFacade.isErrorQuestionList$ | async) === false"
  class="container-question-list"
>
  <div
    *ngIf="(questionFacade.isLoadingQuestionList$ | async) === false"
    class="header-table mb-4 row"
  >
    <div *ngIf="questionList.length > 0" class="col px-0 animated-fade-in">
      <div class="search-wrapper mt-3">
        <i nz-icon nzType="search" nzTheme="outline"></i>
        <input
          type="search"
          placeholder="Busca la pregunta aquí..."
          [(ngModel)]="searchElement"
        />
      </div>
    </div>
    <div class="col px-0">
      <button
        *ngIf="(questionFacade.isChangesSavingOrder$ | async) === false"
        type="submit"
        [ngClass]="questionList.length > 0 ? 'ml-auto' : 'mx-auto'"
        class="btn btn-primary d-block mt-3 animated-fade-in"
        [disabled]="buttonDisabled"
        (click)="showModal()"
      >
        <span class="mr-2"> Agregar Pregunta </span>
        <i nz-icon nzType="plus-circle" nzTheme="fill"></i>
      </button>

      <nz-alert
        *ngIf="(questionFacade.isChangesSavingOrder$ | async) === true"
        class="mt-3 animated-fade-in"
        nzType="info"
        [nzDescription]="template"
      ></nz-alert>
      <ng-template #template>
        <p>
          <nz-badge nzStatus="processing"></nz-badge> Haz actualizado el orden
          de las preguntas. <b>¿Quieres guardar los cambios?</b>
        </p>

        <button
          type="button"
          class="btn btn-primary btn-sm float-right"
          [disabled]="buttonDisabled"
          (click)="saveOrderQuestions()"
        >
          <span class=""> Guardar </span>
        </button>
        <button
          type="button"
          class="btn btn-secondary btn-sm float-right mr-2"
          [disabled]="buttonDisabled"
          (click)="discardChangesOrderQuestions()"
        >
          <span class=""> Descartar </span>
        </button>
      </ng-template>
    </div>
  </div>

  <app-table-question-list
    *ngIf="type === TABLE_TYPE"
    [surveyId]="surveyId"
  ></app-table-question-list>

  <app-view-question-list *ngIf="type === VIEW_TYPE" [surveyId]="surveyId">
  </app-view-question-list>

  <nz-modal
    [nzFooter]="null"
    [(nzVisible)]="isVisible"
    nzTitle="Crear Pregunta"
    (nzOnCancel)="handleCancel()"
  >
    <ng-container *nzModalContent>
      <app-question-form
        *ngIf="isVisible"
        (handleCancel)="handleCancel()"
      ></app-question-form>
    </ng-container>
  </nz-modal>
</div>

<nz-result
  *ngIf="(questionFacade.isErrorQuestionList$ | async) === true"
  nzStatus="warning"
  nzTitle="Hay algunos problemas con la solicitud de preguntas."
>
  <div nz-result-extra>
    <button
      class="btn btn-primary btn-sm"
      (click)="questionFacade.obtainQuestionListFetch(surveyId)"
    >
      Reintentarlo
    </button>
  </div>
</nz-result>
