import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core'
import { Title } from '@angular/platform-browser'
import { Router } from '@angular/router'
import { Select, Store } from '@ngxs/store'
import { Observable } from 'rxjs-compat'
import { IResponse } from 'src/app/models/response/IResponse'
import { IUser } from 'src/app/models/response/IUser'
import { SessionService } from 'src/app/services/service.index'
import { TokenService } from 'src/app/services/session/token.service'
import { UpdateLoadingLogout } from 'src/app/store/session/session.actions'
import { UserState } from 'src/app/store/user/user.state'
import { environment } from './../../../environments/environment'
import { SessionState } from './../../store/session/session.state'
import { UserFacade } from './../../store/user/user.facade'
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnChanges {
  public isBetaProduction = environment.production

  public iconMenu: string
  public titlePage: string
  @Select(UserState.getUser) user$: Observable<IUser>
  @Select(SessionState.getLoadingLogout) loadingLogout$: Observable<boolean>
  @Output() changeShowExpandSidebar = new EventEmitter<File>()
  @Input() showExpandSidebar: boolean
  @Input() showSidebarMobile: boolean

  constructor(
    public userFacade: UserFacade,
    private _token: TokenService,
    private _session: SessionService,
    private router: Router,
    private store: Store,
    private _titleRouting: Title
  ) {
    this.iconMenu = 'menu-fold'
    this.titlePage = _titleRouting.getTitle()
    this.getUser()
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { currentValue } = changes.showExpandSidebar
    currentValue
      ? (this.iconMenu = 'menu-fold')
      : (this.iconMenu = 'menu-unfold')

    this.router.events.subscribe((title: any) => {
      if (title) {
        this.titlePage = this._titleRouting.getTitle()
      }
    })
  }

  private getUser(): void {
    this.userFacade.obtainUserFetch()
  }

  public logoutSession(): void {
    this.store.dispatch(new UpdateLoadingLogout(true))
    this._session.logoutSession().subscribe(
      (response: IResponse) => {
        this.deleteSession()
      },
      (error) => {
        this.deleteSession()
      }
    )
  }

  private deleteSession() {
    this._token.deleteTokenAndLogout()
    this.router.navigate([`/login`])
    this.store.dispatch(new UpdateLoadingLogout(false))
  }
}
