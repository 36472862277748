<div class="main-survey animated-fade-in row">
  <div class="col-12">
    <app-card-info-survey></app-card-info-survey>
  </div>
  <div class="col-12">
    <div class="card animated-fade-in" id="survey-list">
      <div class="row mx-1">
        <div class="col-12">
          <app-header-section-survey
            [(isModeSurveyArchived)]="isModeSurveyArchived"
            [(isVisibleFormWithIA)]="isVisibleFormWithIA"
            [(surveyTypeFilter)]="surveyTypeFilter"
          ></app-header-section-survey>
        </div>
        <!-- <div
            *ngIf="typeStatusSurvey === 1 && isModeSurveyArchived === false"
            class="mb-3 w-100 align-self-center"
            [ngClass]="
              typeViewSurvey === 'list'
                ? 'col-12'
                : 'col-xs-12 col-sm-6 col-lg-4 col-xxl-3'
            "
          >
            <app-card-survey
              id="create-survey"
              class="w-100"
              [viewNewSurveyCard]="true"
              [typeViewSurvey]="typeViewSurvey"
              (click)="isVisibleForm = true"
              [(isModeSurveyArchived)]="isModeSurveyArchived"
            ></app-card-survey>
          </div> -->

        <nz-skeleton
          *ngIf="(surveyFacade.errorService$ | async).errorSurveyList === false"
          [nzLoading]="(surveyFacade.isLoadingSurveyList$ | async) === true"
          [nzActive]="true"
          [nzAvatar]="true"
          [nzParagraph]="{ rows: 5 }"
          [nzRound]="true"
          class="mx-3"
        >
          <div class="row">
            <div
              *ngFor="
                let survey of typeStatusSurvey === 1
                  ? (surveyFacade.surveyList$
                    | async
                    | favoriteFilter: surveyTypeFilter === 'FAVORITE')
                  : (surveyFacade.surveyListArchived$ | async)
              "
              class="mb-3 w-100"
              [ngClass]="
                typeViewSurvey === 'list'
                  ? 'col-12'
                  : 'col-xs-12 col-sm-6 col-lg-4 col-xxl-3'
              "
            >
              <app-card-survey
                class="w-100"
                [survey]="survey"
                [typeStatusSurvey]="typeStatusSurvey"
                [typeViewSurvey]="typeViewSurvey"
                [(isModeSurveyArchived)]="isModeSurveyArchived"
              ></app-card-survey>
            </div>
            <div
              class="info-bg-empty col-12"
              *ngIf="
                ((surveyFacade.surveyList$ | async) === null ||
                  (
                    surveyFacade.surveyList$
                    | async
                    | favoriteFilter: surveyTypeFilter === 'FAVORITE'
                  ).length === 0) &&
                (surveyFacade.isLoadingSurveyList$ | async) === false
              "
            >
              <div class="text-justified align-items-center">
                <h5 class="text-dark text-center">
                  Al parecer aún no tienes encuestas
                  {{ surveyTypeFilter === 'FAVORITE' ? 'destacadas' : '' }}
                  {{ surveyTypeFilter === 'ARCHIVED' ? 'archivadas' : '' }}
                </h5>
                <span
                  *ngIf="surveyTypeFilter === 'ALL'"
                  class="d-block text-dark text-center"
                >
                  Para comenzar, crea la primera.
                </span>
                <img
                  class="icon-not-found mx-auto my-2"
                  [src]="IMG_NOT_FOUND"
                  alt="Not found"
                />
              </div>
            </div>
          </div>
        </nz-skeleton>
      </div>
    </div>
    <div
      *ngIf="(surveyFacade.errorService$ | async).errorSurveyList === true"
      class="card"
    >
      <nz-result
        nzStatus="warning"
        nzTitle="Hay algunos problemas con para obtener las encuestas."
      >
        <div nz-result-extra>
          <button
            class="btn btn-primary btn-sm"
            (click)="
              surveyFacade.getSurveyList(); surveyFacade.getSurveyCategories()
            "
          >
            Reintentarlo
          </button>
        </div>
      </nz-result>
    </div>
  </div>
</div>

<!-- Modal create survey -->
<nz-modal
  [nzFooter]="null"
  [(nzVisible)]="isVisibleForm"
  (nzOnCancel)="handleCancel()"
  (nzOnOk)="handleOk()"
  [nzMaskClosable]="false"
  style="width: 900px"
>
  <ng-container *nzModalContent>
    <app-survey-form
      *ngIf="isVisibleForm"
      (handleCancel)="handleCancel()"
      (handleModal)="handleModal()"
      [editSurvey]="false"
    ></app-survey-form>
  </ng-container>
</nz-modal>
