<div class="win98popup shadow">
  <div class="bar">
    <p>Paint</p>
    <button class="shadow">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="8px"
        height="7px"
        viewBox="0 0 8 7"
        fill-rule="evenodd"
        stroke-linejoin="round"
        stroke-miterlimit="2"
      >
        <path
          d="M1 6V5h1V4h1V3h2v1h1v1h1v1h1v1H6V6H5V5H3v1H2v1H0V6h1zm0-4V1H0V0h2v1h1v1h2V1h1V0h2v1H7v1H6v1H2V2H1z"
        />
      </svg>
    </button>
  </div>
  <section>
    <p>Save changes to untitled?</p>
  </section>
  <div class="buttons">
    <button class="shadow">
      <img
        src="https://raw.githubusercontent.com/mbledkowski/win98popup/master/outline.svg"
        alt=""
        width="66.9"
        height="15"
      />
      <p><u>Y</u>es</p>
    </button>
    <button class="shadow">
      <img
        src="https://raw.githubusercontent.com/mbledkowski/win98popup/master/outline.svg"
        alt=""
        width="66.9"
        height="15"
      />
      <p><u>N</u>o</p>
    </button>
    <button class="shadow">
      <img
        src="https://raw.githubusercontent.com/mbledkowski/win98popup/master/outline.svg"
        alt=""
        width="66.9"
        height="15"
      />
      <p>Cancel</p>
    </button>
  </div>
</div>
