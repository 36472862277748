<div class="card-blog" *ngIf="blog">
  <div class="card">
    <div class="card-content">
      <div class="card-top">
        <p class="card-title truncate-1-line">{{ blog?.title }}</p>
      </div>
      <div class="card-top">
        <span class="mt-2 truncate-3-lines">{{ blog?.description }}</span>
      </div>
      <div class="card-bottom" (click)="redirectToExternalPage(blog.url)">
        <p>Continuar leyendo</p>
        <svg
          width="32"
          viewBox="0 -960 960 960"
          height="32"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M226-160q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19ZM226-414q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19ZM226-668q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Z"
          ></path>
        </svg>
      </div>
    </div>
  </div>
</div>
