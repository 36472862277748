import { UserStateModel } from './../../store/user/user.state'
import { IUser } from 'src/app/models/response/IUser'
import { Component, OnInit } from '@angular/core'
import { UserService } from 'src/app/services/service.index'
import { Select, Store } from '@ngxs/store'
import { UserState } from 'src/app/store/user/user.state'
import { Observable } from 'rxjs-compat'

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  @Select(UserState.getUser) user$: Observable<IUser>
  public loading: boolean

  constructor(public _user: UserService, private store: Store) {
    this.loading = true
  }

  ngOnInit(): void {}
}
