import { optionsChartDefault } from './../../utils/properties/hightchartOptions'
import { Component, OnInit } from '@angular/core'

import * as Highcharts from 'highcharts'

declare let require: any
const More = require('highcharts/highcharts-more')
More(Highcharts)

import Histogram from 'highcharts/modules/histogram-bellcurve'
Histogram(Highcharts)

import highcharts3D from 'highcharts/highcharts-3d'
highcharts3D(Highcharts)

import Cylinder from 'highcharts/modules/cylinder'
Cylinder(Highcharts)

const Exporting = require('highcharts/modules/exporting')
Exporting(Highcharts)

const ExportData = require('highcharts/modules/export-data')
ExportData(Highcharts)

const Accessibility = require('highcharts/modules/accessibility')
Accessibility(Highcharts)

const Wordcloud = require('highcharts/modules/wordcloud')
Wordcloud(Highcharts)

const text =
    'Agradecer el Trabajo y profesionalismo de la empresa Humanware, junto a ellos logramos los objetivos planteados en la solución de los problemas de servicios',
  lines = text.replace(/[():'?0-9]+/g, '').split(/[,\. ]+/g),
  data = lines.reduce((arr, word) => {
    let obj = Highcharts.find(arr, (obj) => obj.name === word)
    if (obj) {
      obj.weight += 1
    } else {
      obj = {
        name: word,
        weight: 1,
      }
      arr.push(obj)
    }
    return arr
  }, [])

@Component({
  selector: 'app-digital-book',
  templateUrl: './digital-book.component.html',
})
export class DigitalBookComponent implements OnInit {
  public tabSelected: string = 'my-observations'
  Highcharts: typeof Highcharts = Highcharts
  chartOptionsWords: Highcharts.Options

  constructor() {
    this.getChartOptionsWords()
  }

  ngOnInit(): void {}

  private getChartOptionsWords(): void {
    this.chartOptionsWords = {
      lang: optionsChartDefault.lang,
      chart: {
        type: 'wordcloud',
        renderTo: 'container',
      },
      credits: {
        enabled: false,
      },
      accessibility: {
        screenReaderSection: {
          beforeChartFormat:
            '<h5>{chartTitle}</h5>' +
            '<div>{chartSubtitle}</div>' +
            '<div>{chartLongdesc}</div>' +
            '<div>{viewTableButton}</div>',
        },
      },
      series: [
        {
          type: 'wordcloud',
          data,
          name: 'Occurrences',
        },
      ],
      title: {
        text: 'Palabras claves de las observaciones de los usuarios',
      },
      subtitle: {
        text: 'Ocurrencias del último mes',
      },
      tooltip: {
        headerFormat:
          '<span style="font-size: 16px"><b>{point.key}</b></span><br>',
      },
    }
  }
}
