import { Component } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { IEmailRecovery } from 'src/app/models/request/IEmailRecovery'
import { IResponse } from 'src/app/models/response/IResponse'
import { SessionService } from 'src/app/services/service.index'
import { TokenService } from 'src/app/services/session/token.service'
import {
  ERROR_SERVICE_MESSAGE,
  ERROR_SERVICE_TITLE,
} from 'src/app/utils/constants/generic-messages'
import {
  SUCCESS_RECOVERY_MESSAGE,
  SUCCESS_RECOVERY_TITLE,
  TOKEN_EXPIRED_MESSAGE,
  TOKEN_EXPIRED_TITLE,
} from 'src/app/utils/constants/session-messages'
import { environment } from 'src/environments/environment'
import { ITokenActivateAccount } from './../../../models/request/ITokenActivateAccount'
import {
  ERROR_VALIDATION_ACCOUNT_MESSAGE,
  ERROR_VALIDATION_ACCOUNT_TITLE,
  SUCCESS_VALIDATION_ACCOUNT_MESSAGE,
  SUCCESS_VALIDATION_ACCOUNT_TITLE,
} from './../../../utils/constants/session-messages'
import { encryptKeyStorage } from './../../../utils/converts/encrypt'

const ENV = environment.env ? 'PROD' : 'QA'

@Component({
  selector: 'app-activate-account',
  templateUrl: './activate-account.component.html',
  styleUrls: ['../session.scss'],
})
export class ActivateAccountComponent {
  public loadingTokenValidation: boolean
  public loadingSendEmail: boolean
  public email: string

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private _token: TokenService,
    private _session: SessionService,
    private notification: NzNotificationService
  ) {
    const token = this.route.snapshot.paramMap.get('tokenId')
    const email = this.route.snapshot.paramMap.get('email')

    if (token && email) {
      this.loadingTokenValidation = true
      this.email = email
      const request: ITokenActivateAccount = { key: token, email }
      this.validateTokenActivatedAccountRequest(request)
    } else if (email) {
      this.email = email
    }
  }

  public validateTokenActivatedAccountRequest(
    request: ITokenActivateAccount
  ): void {
    this._token.validateTokenToActivateAccount(request).subscribe(
      (response: IResponse) => {
        switch (response.status.code) {
          case 200:
          case 201:
            this.loadingTokenValidation = false
            this.notification.create(
              'success',
              SUCCESS_VALIDATION_ACCOUNT_TITLE,
              SUCCESS_VALIDATION_ACCOUNT_MESSAGE
            )
            this.router.navigate(['/login'])
            break
          default:
            this.loadingTokenValidation = false
            this.notification.create(
              'error',
              ERROR_VALIDATION_ACCOUNT_TITLE,
              ERROR_VALIDATION_ACCOUNT_MESSAGE
            )
            this.router.navigate(['/login'])
            break
        }
        if (environment.env === 'dev') {
          // Paso 1: Cifrar las claves
          const encryptedKey1 = encryptKeyStorage('domain')
          const encryptedKey2 = encryptKeyStorage('api_domain')

          // Paso 2: Guardar los valores que no quieres eliminar
          const keep1 = localStorage.getItem(encryptedKey1)
          const keep2 = localStorage.getItem(encryptedKey2)

          // Paso 3: Limpiar el localStorage
          localStorage.clear()

          // Paso 4: Restaurar las claves cifradas y sus valores
          localStorage.setItem(encryptedKey1, keep1)
          localStorage.setItem(encryptedKey2, keep2)
          return
        }
        localStorage.clear()
      },
      (error) => {
        if (error.status === 400) {
          this.loadingTokenValidation = false
          this.notification.create(
            'warning',
            TOKEN_EXPIRED_TITLE,
            TOKEN_EXPIRED_MESSAGE
          )
          this.router.navigate(['/login'])
          return
        }
        this.notification.create(
          'error',
          ERROR_SERVICE_TITLE,
          ERROR_SERVICE_MESSAGE
        )
        this.loadingTokenValidation = false
        this.router.navigate(['/login'])
      }
    )
  }

  public sendEmailActivatedAccount(): void {
    this.loadingSendEmail = true
    const request: IEmailRecovery = {
      email: this.email,
    }

    this._session.sendMailActivationAccount(request).subscribe(
      (response: IResponse) => {
        switch (response.status.code) {
          case 200:
          case 201:
            this.notification.create(
              'success',
              SUCCESS_RECOVERY_TITLE,
              `${SUCCESS_RECOVERY_MESSAGE} ${request.email}`
            )
            break
          default:
            this.notification.create(
              'error',
              ERROR_SERVICE_TITLE,
              ERROR_SERVICE_MESSAGE
            )
            break
        }

        this.loadingSendEmail = false
      },
      (error: Error) => {
        this.notification.create(
          'error',
          ERROR_SERVICE_TITLE,
          ERROR_SERVICE_MESSAGE
        )
        this.loadingSendEmail = false
      }
    )
  }
}
