<a class="my-payments-table__options">
  <img
    class="option-icon"
    src="{{ ICON_OPTION }}"
    nz-dropdown
    nzTrigger="click"
    [nzDropdownMenu]="options"
    nz-icon
    nzType="down"
    alt=""
  />
</a>

<nz-dropdown-menu #options="nzDropdownMenu" class="options-questions">
  <ul nz-menu>
    <li
      *ngIf="question.isActive"
      nz-menu-item
      (click)="editQuestionModal(question)"
    >
      Editar
    </li>
    <li nz-menu-item (click)="changeStatus(question)">
      {{ question.isActive ? 'Deshabilitar' : 'Habilitar' }}
    </li>
    <li
      nz-menu-item
      nz-popconfirm
      [nzCondition]="question.hasActivity"
      (click)="
        question.hasActivity
          ? (isVisibleModalDelete = true)
          : (isVisibleModalDelete = false)
      "
      nzPopconfirmTitle="¿Estás seguro de quitar esta pregunta?"
      nzCancelText="Cancelar"
      nzPopconfirmPlacement="top"
      (nzOnConfirm)="removeQuestion(question)"
      [nzPopconfirmPlacement]="confirmPlacement"
      (keypress)="(null)"
    >
      Quitar
    </li>
  </ul>
</nz-dropdown-menu>

<nz-modal
  [nzFooter]="null"
  [(nzVisible)]="isVisibleModalEdit"
  nzTitle="Editar Pregunta"
  (nzOnCancel)="handleCancel()"
>
  <ng-container *nzModalContent>
    <app-question-form
      *ngIf="isVisibleModalEdit"
      (handleCancel)="handleCancel()"
      [editQuestion]="true"
    ></app-question-form>
  </ng-container>
</nz-modal>

<nz-modal
  [nzFooter]="null"
  [(nzVisible)]="isVisibleModalDelete"
  nzTitle="Quitar Pregunta"
  (nzOnCancel)="handleCancel()"
>
  <ng-container *nzModalContent>
    <div *ngIf="isVisibleModalDelete" class="container">
      <nz-alert
        *ngIf="question.hasActivity"
        class="mb-3"
        nzType="warning"
        [nzMessage]="questionNoEditTitle"
        [nzDescription]="questionNoEditText"
        nzShowIcon
      ></nz-alert>

      <button
        (click)="handleCancel()"
        type="button"
        class="btn btn-primary d-block ml-auto"
      >
        <span>Continuar</span>
      </button>
    </div>
  </ng-container>
</nz-modal>
