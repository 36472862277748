<div
  *ngIf="!viewNewSurveyCard && typeViewSurvey === 'card'"
  [ngClass]="
    'card-survey ' +
    (+survey.isActive === 0 || +survey.isArchived === 1 ? 'card-disabled' : '')
  "
>
  <div *ngIf="viewDetailSurvey">
    <a class="card-survey__options">
      <img
        class="option-icon ml-auto"
        src="{{ ICON_OPTION }}"
        nz-dropdown
        nzTrigger="click"
        [nzDropdownMenu]="menu"
        nz-icon
        nzType="down"
        alt=""
      />
    </a>
    <em
      *ngIf="+survey?.isArchived !== 1"
      #iconElement
      class="card-survey__favorite"
      nz-icon
      [nzType]="'star'"
      [nzTheme]="survey.isFavorite ? 'twotone' : 'outline'"
      [nzTwotoneColor]="survey.isFavorite ? '#FFD700' : ''"
      (click)="toggleFavorite(survey)"
      onKeyPress=""
      onKeyDown=""
      onKeyUp=""
    >
    </em>
  </div>
  <nz-spin
    [nzSpinning]="isLoadingToggleSurvey"
    [nzTip]="'Validando encuesta...'"
  >
    <div
      class="container-summary-survey"
      (click)="viewDetail(survey.surveyId)"
      (keypress)="(null)"
    >
      <div class="status-survey text-right">
        <nz-tag
          [nzColor]="+survey?.isArchived === 1 ? '#f50' : '#95a5a6'"
          *ngIf="+survey.isActive === 0 || +survey?.isArchived === 1"
          class="m-0 mt-1"
          >{{
            +survey?.isArchived === 1 ? 'Archivada' : 'Deshabilitada'
          }}</nz-tag
        >
        <nz-tag [nzColor]="'#2ecc71'" *ngIf="+survey.isActive === 1"
          >Activa</nz-tag
        >
      </div>
      <div class="card-survey__header">
        <div class="status-survey">
          <nz-tag *ngIf="survey.category" class="" [nzColor]="'purple'">{{
            survey.category
          }}</nz-tag>
        </div>
        <div class="d-block">
          <h4 class="pt-2">{{ survey.name | shortText: 0:70 }}</h4>
          <label for="surveyDescription"
            >{{ survey.description | shortText: 0:85 }}
          </label>
        </div>
      </div>
      <hr />
      <div *ngIf="viewInfoSurvey" class="card-survey__info">
        <!-- Questions -->
        <div class="row">
          <div class="col-9">
            <img class="info-icon" src="{{ ICON_INFO }}" alt="" />
            <span>Preguntas</span>
          </div>
          <div class="col-3">
            <span class="text-value"> {{ survey.activeQuestions }} </span>
          </div>
        </div>
        <!-- Responses -->
        <div class="row">
          <div class="col-9">
            <img class="info-icon" src="{{ ICON_INFO }}" alt="" />
            <span>Respuestas</span>
          </div>
          <div class="col-3">
            <span class="text-value">
              {{ survey.responsesUsed }}
            </span>
          </div>
        </div>
        <!-- Comments -->
        <div class="row">
          <div class="col-9">
            <img class="info-icon" src="{{ ICON_INFO }}" alt="" />
            <span>Libro comentarios</span>
          </div>
          <div class="col-3">
            <span class="text-value">
              {{ survey.commentsReceived }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </nz-spin>
  <div *ngIf="viewRecentActivity" class="card-survey__activity"></div>
</div>

<div
  *ngIf="viewNewSurveyCard && typeViewSurvey === 'card'"
  class="card-new-survey"
>
  <div class="d-block">
    <div class="d-block mb-4">Nueva encuesta</div>
    <div class="btn-add-survey m-auto">
      <i nz-icon [nzType]="'plus'" nzTheme="outline"></i>
    </div>
  </div>
</div>
<div
  *ngIf="!viewNewSurveyCard && typeViewSurvey === 'list'"
  [ngClass]="
    'card-survey-list ' +
    (+survey.isActive === 0 || +survey.isArchived === 1 ? 'card-disabled' : '')
  "
>
  <div *ngIf="viewDetailSurvey">
    <a class="card-survey-list__options float-right">
      <img
        class="option-icon ml-auto"
        src="{{ ICON_OPTION }}"
        nz-dropdown
        nzTrigger="click"
        [nzDropdownMenu]="menu"
        nz-icon
        nzType="down"
        alt=""
      />
    </a>
  </div>
  <nz-spin
    [nzSpinning]="isLoadingToggleSurvey"
    [nzTip]="'Validando encuesta...'"
  >
    <div
      class="container-summary-survey row"
      (click)="viewDetail(survey.surveyId)"
      onKeyPress=""
      onKeyDown=""
      onKeyUp=""
    >
      <div class="col-6">
        <div class="card-survey-list__header">
          <h4>{{ survey.name }}</h4>
          <label for="surveyDescription">{{ survey.description }} </label>
          <nz-badge
            nzColor="#95a5a6"
            *ngIf="+survey.isActive === 0"
            class="status-survey-list"
            nzStatus="processing"
            [nzText]="'Deshabilitada'"
          ></nz-badge>
          <nz-badge
            nzColor="#95a5a6"
            *ngIf="+survey.isArchived === 1"
            class="status-survey-list"
            nzStatus="processing"
            [nzText]="'Archivada'"
          ></nz-badge>
        </div>
      </div>
      <div class="col-6">
        <div *ngIf="viewInfoSurvey" class="card-survey-list__info">
          <!-- Questions -->
          <div class="row">
            <div class="col-9">
              <span
                class="info-icon"
                nz-icon
                nzType="question-circle"
                nzTheme="outline"
              ></span>
              <span>Preguntas</span>
            </div>
            <div class="col-3">
              <span class="text-value">
                {{ survey.activeQuestions }}
              </span>
            </div>
          </div>
          <!-- Responses -->
          <div class="row">
            <div class="col-9">
              <span
                class="info-icon"
                nz-icon
                nzType="message"
                nzTheme="outline"
              ></span>
              <span>Respuestas</span>
            </div>
            <div class="col-3">
              <span class="text-value">
                {{ survey.responsesUsed }}
              </span>
            </div>
          </div>
          <!-- Comments -->
          <div class="row">
            <div class="col-9">
              <span
                class="info-icon"
                nz-icon
                nzType="comment"
                nzTheme="outline"
              ></span>
              <span>Libro comentarios</span>
            </div>
            <div class="col-3">
              <span class="text-value">
                {{ survey.commentsReceived }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nz-spin>
  <div *ngIf="viewRecentActivity" class="card-survey-list__activity"></div>
</div>
<nz-dropdown-menu #menu="nzDropdownMenu">
  <div *ngIf="survey">
    <ul *ngIf="!isModeSurveyArchived" nz-menu>
      <li
        nz-menu-item
        (click)="toggleFavorite(survey)"
        (keypress)="$event.stopPropagation()"
      >
        <em class="mr-2" nz-icon nzType="star" nzTheme="outline"></em>
        Destacar
      </li>
      <li
        nz-menu-item
        (click)="viewDetail(survey.surveyId)"
        (keypress)="$event.stopPropagation()"
      >
        <em class="mr-2" nz-icon nzType="file-search" nzTheme="outline"></em>
        Ver detalle
      </li>

      <li nz-submenu nzTrigger="click">
        <span title (keypress)="$event.stopPropagation()">
          <em class="mr-2" nz-icon nzType="tag" nzTheme="outline"></em>
          Agregar categoría
        </span>
        <ul
          (click)="$event.stopPropagation()"
          (keypress)="$event.stopPropagation()"
          class="category-menu"
        >
          <li class="m-2 text-center">
            <nz-tag
              *ngFor="let category of listOfCategories"
              class="cursor"
              (click)="assignCategory(survey.surveyId, category.categoryId)"
              (keypress)="$event.stopPropagation()"
              [nzColor]="
                category.categoryName === survey.category ? 'purple' : 'gray'
              "
              >{{ category.categoryName }}</nz-tag
            >
          </li>
          <li *ngIf="listOfCategories?.length < 1" class="m-2 text-center">
            <nz-tag [nzColor]="'black'">No hay categorias disponibles</nz-tag>
          </li>

          <hr class="m-0 p-0" />

          <li class="p-2 d-flex align-items-center" nz-menu-item>
            <input
              type="text"
              nz-input
              placeholder="Nueva categoría"
              maxlength="25"
              #inputElement
              class="form-control mr-2"
              (click)="$event.stopPropagation()"
            />
            <a
              class="add-item d-flex align-items-center"
              (click)="
                addTag(inputElement.value);
                $event.stopPropagation();
                inputElement.value = ''
              "
            >
              <i nz-icon nzType="plus" class="mr-1"></i>
              Añadir
            </a>
          </li>
        </ul>
      </li>

      <li
        nz-menu-item
        (click)="viewSurveyCloneModal(survey.surveyId)"
        (keypress)="$event.stopPropagation()"
      >
        <em class="mr-2" nz-icon nzType="copy" nzTheme="outline"></em> Clonar
        Encuesta
      </li>
      <li nz-menu-item *ngIf="false">
        <em class="mr-2" nz-icon nzType="bar-chart" nzTheme="outline"></em> Ver
        Resultados
      </li>
      <li nz-submenu [nzTitle]="titleTemplate">
        <ng-template #titleTemplate>
          <em class="mr-2" nz-icon nzType="share-alt" nzTheme="outline"></em>
          Compartir
        </ng-template>
        <ul>
          <li nz-menu-item (click)="displayQR()" (keypress)="(null)">
            <em class="mr-2" nz-icon nzType="qrcode" nzTheme="outline"></em> Ver
            QR
          </li>
          <nz-modal
            [nzFooter]="null"
            [(nzVisible)]="isVisibleQR"
            [nzTitle]="'QR: ' + survey?.name"
            (nzOnCancel)="handleCancel()"
            (nzOnOk)="handleOk()"
          >
            <ng-container *nzModalContent>
              <nz-spin [nzSpinning]="loadingQR" [nzTip]="'Generando QR...'">
                <div style="height: 450px">
                  <div *ngIf="!loadingQR" class="animated-fade-in">
                    <img
                      class="d-block mx-auto"
                      width="400px"
                      height="400px"
                      [src]="displayImageQR"
                      alt="qr"
                    />
                    <button
                      type="submit"
                      class="btn btn-primary d-block ml-auto my-2"
                      (click)="downloadImage()"
                      [disabled]="loadingDownloadQR"
                    >
                      <span
                        *ngIf="loadingDownloadQR"
                        class="d-flex align-items-center"
                      >
                        <span class="mr-2"> Descargando </span>
                        <i nz-icon [nzType]="'loading'"></i>
                      </span>
                      <span
                        *ngIf="!loadingDownloadQR"
                        class="d-flex align-items-center"
                      >
                        <span class="mr-2"> Descargar QR </span>
                        <i nz-icon nzType="down-circle" nzTheme="outline"></i
                      ></span>
                    </button>
                  </div>
                </div>
              </nz-spin>
            </ng-container>
          </nz-modal>
          <li
            nz-menu-item
            (click)="copyLink()"
            (keypress)="$event.stopPropagation()"
          >
            <em class="mr-2" nz-icon nzType="link" nzTheme="outline"></em>
            Copiar Link
          </li>
        </ul>
      </li>
      <li
        *ngIf="+survey.isActive === 0"
        nz-menu-item
        (click)="toggleSurvey(survey.surveyId, +survey.isActive)"
        (keypress)="$event.stopPropagation()"
      >
        <em class="mr-2" nz-icon nzType="check-circle" nzTheme="twotone"></em
        >Habilitar
      </li>
      <li
        *ngIf="+survey.isActive !== 0"
        nz-menu-item
        (click)="toggleSurvey(survey.surveyId, +survey.isActive)"
        (keypress)="$event.stopPropagation()"
      >
        <em class="mr-2" nz-icon nzType="stop" nzTheme="outline"></em
        >Deshabilitar
      </li>
      <li
        class="mr-2"
        nz-menu-item
        (click)="toggleArchiveSurvey(survey.surveyId, 1)"
        (keypress)="$event.stopPropagation()"
      >
        <em class="mr-2" nz-icon nzType="inbox" nzTheme="outline"></em> Archivar
      </li>
    </ul>

    <ul *ngIf="isModeSurveyArchived" nz-menu>
      <li
        nz-menu-item
        (click)="toggleArchiveSurvey(survey.surveyId, 0)"
        (keypress)="(null)"
      >
        <em nz-icon class="mr-2" nzType="undo" nzTheme="outline"></em>
        Restaurar
      </li>
      <li
        nz-menu-item
        nz-popconfirm
        nzPopconfirmTitle="¿Estás seguro de eliminar esta encuesta?"
        nzCancelText="Cancelar"
        nzPopconfirmPlacement="top"
        (nzOnConfirm)="deleteSurvey(survey.surveyId)"
        (keypress)="(null)"
      >
        <em nz-icon class="mr-2" nzType="delete" nzTheme="outline"></em>
        Eliminar
      </li>
    </ul>
  </div>
</nz-dropdown-menu>

<nz-modal
  [nzFooter]="null"
  [(nzVisible)]="isVisibleSurveyCloneModal"
  (nzOnCancel)="isVisibleSurveyCloneModal = false"
  (nzOnOk)="cloneSurvey(survey)"
  nzTitle="Clonar Encuesta"
  nzCentered
>
  <ng-container *nzModalContent>
    <nz-spin
      [nzSpinning]="isLoadingCloneSurvey"
      [nzTip]="'Obteniendo datos encuesta...'"
    >
      <div class="row">
        <div class="col-12">
          <app-survey-form
            [survey]="survey"
            [form]="form"
            [isCloneSurvey]="true"
            [editSurvey]="true"
            [isGenerateAction]="false"
            (handleCancel)="isVisibleSurveyCloneModal = false"
            (formChange)="handleFormChange($event)"
          >
          </app-survey-form>
        </div>

        <div class="col-12 table-clone">
          <nz-table
            #editRowTable
            nzBordered
            [nzData]="questions"
            [nzShowPagination]="false"
            [nzScroll]="{ y: '250px' }"
            nzSize="small"
          >
            <thead>
              <tr>
                <th nzWidth="40%" id="question">Pregunta</th>
                <th nzWidth="30%" id="type">Tipo</th>
                <th nzWidth="15%" id="required">Requerida</th>
                <th nzWidth="15%" id="comment">Comentarios Habilitados</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of editRowTable.data" class="editable-row">
                <td>
                  <div class="editable-cell">
                    <b class="mr-2">{{ data.orden }}.</b> {{ data.title }}
                  </div>
                </td>
                <td>
                  {{ data.type }}
                </td>
                <td>
                  <nz-switch
                    [(ngModel)]="data.isMandatory"
                    [nzCheckedChildren]="'Sí'"
                    [nzUnCheckedChildren]="'No'"
                    disabled="true"
                  ></nz-switch>
                </td>
                <td>
                  <!-- Checkbox para comentarios habilitados -->
                  <nz-switch
                    [(ngModel)]="data.commentAvailable"
                    [nzCheckedChildren]="'Sí'"
                    [nzUnCheckedChildren]="'No'"
                    disabled="true"
                  ></nz-switch>
                </td>
              </tr>
            </tbody>
          </nz-table>
        </div>

        <div class="col-12">
          <div class="text-center mt-4">
            <a
              *ngIf="!isLoadingCloneSurvey"
              (click)="isVisibleSurveyCloneModal = false"
              class="text-secondary"
            >
              <span>Cancelar</span>
            </a>
            <button
              [disabled]="isLoadingCloneSurvey"
              type="button"
              (click)="cloneSurvey(survey)"
              class="btn btn-sm btn-primary btn-rounded ml-3"
            >
              <span
                *ngIf="isLoadingCloneSurvey"
                class="d-flex align-items-center"
              >
                <span class="mr-2"> Guardando </span>
                <i nz-icon [nzType]="'loading'"></i>
              </span>
              <span
                *ngIf="!isLoadingCloneSurvey"
                class="d-flex align-items-center"
              >
                <span class="mr-2">
                  {{
                    !isLoadingCloneSurvey
                      ? 'Clonar Encuesta'
                      : 'Clonando Encuesta...'
                  }}
                </span>
                <i nz-icon nzType="copy" nzTheme="fill"></i
              ></span>
            </button>
          </div>
        </div>
      </div>
    </nz-spin>
  </ng-container>
</nz-modal>
