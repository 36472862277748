import { State, Action, StateContext, Selector } from '@ngxs/store'
import { Injectable } from '@angular/core'
import {
  UpdateGeneralInfo,
  UpdateIsLoadingGeneralInfo,
  UpdateIsLoadingWeekResponses,
  UpdateTendencyWeekReport,
  UpdateWeekResponsesReport,
  UpdateisLoadingTendencyWeekResponses,
} from './dashboard.actions'

export interface IGeneralInfo {
  totalSurveys: number
  totalInteractions: number
  totalAvailableText: string
  namePlan: string
}

export interface IWeekResponsesReport {
  categories: Array<string>
  dataSeries: Array<number>
}

export interface ITendecyWeekResponsesReport {
  categories: Array<string>
  dataSeriesPrev: Array<number>
  dataSeriesCurrent: Array<number>
}

export interface ILoadingDashboard {
  isLoadingGeneralInfo: boolean
  isLoadingWeekResponses: boolean
  isLoadingTendencyWeekResponses: boolean
}

export interface DashboardStateModel {
  generalInfo: IGeneralInfo
  weekResponsesReport: IWeekResponsesReport | any
  tendencyWeekReport: ITendecyWeekResponsesReport | any
  loading: ILoadingDashboard
}

@State<DashboardStateModel>({
  name: 'dashboard',
  defaults: {
    generalInfo: null,
    weekResponsesReport: null,
    tendencyWeekReport: null,
    loading: {
      isLoadingGeneralInfo: true,
      isLoadingWeekResponses: true,
      isLoadingTendencyWeekResponses: true,
    },
  },
})
@Injectable()
export class DashboardState {
  @Selector()
  public static getGeneralInfo(state: DashboardStateModel): IGeneralInfo {
    return state.generalInfo
  }

  @Selector()
  public static getWeekResponsesReport(
    state: DashboardStateModel
  ): IWeekResponsesReport {
    return state.weekResponsesReport
  }

  @Selector()
  public static getTendencyWeeksReport(
    state: DashboardStateModel
  ): ITendecyWeekResponsesReport {
    return state.tendencyWeekReport
  }
  @Selector()
  public static getIsLoadingGeneralInfo(state: DashboardStateModel): boolean {
    return state.loading.isLoadingGeneralInfo
  }

  @Selector()
  public static getIsLoadingWeekResponses(state: DashboardStateModel): boolean {
    return state.loading.isLoadingWeekResponses
  }

  @Selector()
  public static getIsLoadingTendency(state: DashboardStateModel): boolean {
    return state.loading.isLoadingTendencyWeekResponses
  }

  @Action(UpdateGeneralInfo)
  updateGeneralInfo(
    { getState, setState }: StateContext<DashboardStateModel>,
    { payload }: UpdateGeneralInfo
  ): void {
    const state = getState()
    setState({
      ...state,
      generalInfo: payload,
    })
  }

  @Action(UpdateWeekResponsesReport)
  updateWeekResponsesReport(
    { getState, setState }: StateContext<DashboardStateModel>,
    { payload }: UpdateWeekResponsesReport
  ): void {
    const state = getState()
    setState({
      ...state,
      weekResponsesReport: payload,
    })
  }

  @Action(UpdateTendencyWeekReport)
  updateTendencyWeekReport(
    { getState, setState }: StateContext<DashboardStateModel>,
    { payload }: UpdateTendencyWeekReport
  ): void {
    const state = getState()
    setState({
      ...state,
      tendencyWeekReport: payload,
    })
  }

  @Action(UpdateIsLoadingGeneralInfo)
  updateIsLoadingGeneralInfo(
    { getState, setState }: StateContext<DashboardStateModel>,
    { payload }: UpdateIsLoadingGeneralInfo
  ): void {
    const state = getState()

    setState({
      ...state,
      loading: { ...state.loading, isLoadingGeneralInfo: payload },
    })
  }

  @Action(UpdateIsLoadingWeekResponses)
  updateIsLoadingWeekResponses(
    { getState, setState }: StateContext<DashboardStateModel>,
    { payload }: UpdateIsLoadingWeekResponses
  ): void {
    const state = getState()

    setState({
      ...state,
      loading: { ...state.loading, isLoadingWeekResponses: payload },
    })
  }

  @Action(UpdateisLoadingTendencyWeekResponses)
  updateisLoadingTendencyWeekResponses(
    { getState, setState }: StateContext<DashboardStateModel>,
    { payload }: UpdateisLoadingTendencyWeekResponses
  ): void {
    const state = getState()

    setState({
      ...state,
      loading: { ...state.loading, isLoadingTendencyWeekResponses: payload },
    })
  }
}
