<div *ngIf="false" class="card-info-survey animated-fade-in">
  <nz-skeleton
    [nzLoading]="isLoadingCardInfo"
    [nzActive]="true"
    [nzAvatar]="true"
    [nzParagraph]="{ rows: 4 }"
    [nzRound]="true"
  >
    <div *ngIf="!isLoadingCardInfo" class="row animated-fade-in">
      <!-- survey data -->
      <div class="col-xs-12 col-sm-6 col-xl-8">
        <div class="card-image-data">
          <div class="row m-0 h-100">
            <div class="col-xs-12 col-xl-6 d-none d-xl-block bg">
              <img class="logo-company" alt="" src="{{ LOGO_COMPANY }}" />
            </div>
            <div class="col-xs-12 col-xl-6 info-survey">
              <div class="info-bg">
                <div
                  class="text-center my-3"
                  *ngIf="
                    (surveyFacade.loadingAboutSurvey$ | async) &&
                    (surveyFacade.aboutSurvey$ | async)
                  "
                >
                  <h5>Sobre tus encuestas...</h5>
                  <nz-carousel
                    nzEffect="scrollx"
                    [nzAutoPlay]="true"
                    [nzAutoPlaySpeed]="5000"
                  >
                    <div
                      nz-carousel-content
                      class="py-0 pt-1"
                      *ngFor="
                        let item of surveyFacade.aboutSurvey$ | async;
                        index as i
                      "
                    >
                      <span class="response-text font-weight-normal">
                        {{ item.question | shortText: 0:50 }}
                      </span>
                      <span class="response-text">{{ item.response }}</span>
                      <span class="survey-text font-weight-normal">{{
                        item.survey
                      }}</span>
                    </div>
                  </nz-carousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- survey info description -->
      <div class="col-xs-12 col-sm-6 col-xl-4">
        <div class="row info-plan h-100">
          <div class="col-8 align-self-center">
            <!-- surveys -->
            <div class="progress">
              <nz-progress
                [nzPercent]="
                  (planFacade.currentPlan$ | async).percentSurveysUsed
                "
                nzSize="small"
                [nzShowInfo]="false"
                nzStrokeColor="#40189D"
              ></nz-progress>
              <span>Encuestas</span>
              <span class="float-right font-weight-bold"
                >{{ (planFacade.currentPlan$ | async).numberSurveysUsed }}/{{
                  (planFacade.currentPlan$ | async).numberPlanSurveys
                }}</span
              >
            </div>
            <!-- responses -->
            <div class="progress">
              <nz-progress
                [nzPercent]="
                  (planFacade.currentPlan$ | async).percentResponsesUsed
                "
                nzSize="small"
                [nzShowInfo]="false"
                nzStrokeColor="#40189D"
              ></nz-progress>
              <span>Respuestas</span>
              <span class="float-right font-weight-bold"
                >{{ (planFacade.currentPlan$ | async).numberResponsesUsed }}/{{
                  (planFacade.currentPlan$ | async).numberPlanResponses
                }}</span
              >
            </div>
          </div>
          <div class="col-4 detail align-self-center">
            <div class="info-plan__suscription">
              <h5>Plan</h5>
              <h5 class="text-plan">
                {{ (planFacade.currentPlan$ | async).typePlan }}
              </h5>
            </div>
          </div>
          <div class="col-12 align-self-end">
            <button
              class="btn btn-primary float-right"
              [routerLink]="routerPlan"
            >
              <span class="mr-2"> Mejorar Plan </span>
              <i nz-icon nzType="rocket" nzTheme="outline"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </nz-skeleton>
</div>

<div class="row">
  <div class="col-12 col-lg-12 col-xl-8 d-none d-lg-block">
    <nz-ribbon nzText="Durante el último mes" nzColor="#a27ec8">
      <div class="primary-custom-card1 card">
        <div class="card-body p-0">
          <div class="row">
            <div class="col-xl-5 col-lg-6 col-md-12 col-sm-12">
              <div class="prime-card">
                <img
                  id="survey-welcome"
                  class="img-fluid"
                  src="../../../assets/images/bg/hi-survey.png"
                  alt=""
                />
              </div>
            </div>
            <div
              id="survey-list-info"
              class="col-xl-7 col-lg-6 col-md-12 col-sm-12 mt-3 mt-lg-0"
            >
              <nz-spin
                [nzSpinning]="surveyFacade.loadingAboutSurvey$ | async"
                [nzTip]="'Obteniendo información...'"
                style="min-height: 185px"
              >
                <div
                  *ngIf="
                    (surveyFacade.aboutSurvey$ | async) !== null &&
                    (surveyFacade.aboutSurvey$ | async).length > 0
                  "
                  class="text-justified align-items-center"
                >
                  <h4
                    class="text-dark text-center font-weight-semibold mb-3 mt-2"
                  >
                    <span class="text-primary">Lo que dicen</span> tus encuestas
                  </h4>
                  <nz-carousel
                    nzEffect="scrollx"
                    [nzAutoPlay]="true"
                    [nzAutoPlaySpeed]="5000"
                  >
                    <div
                      nz-carousel-content
                      *ngFor="
                        let item of surveyFacade.aboutSurvey$ | async;
                        index as i
                      "
                    >
                      <span
                        class="text-center d-block"
                        [innerHTML]="item.question | shortText: 0:125"
                      >
                      </span>
                      <span
                        class="font-weight-semibold tx-12 text-center d-block mt-2"
                        [innerHTML]="item.response"
                      >
                      </span>
                      <strong class="d-block text-center mt-2">{{
                        item.survey
                      }}</strong>
                    </div>
                  </nz-carousel>
                  <div class="d-block text-center icons">
                    <i
                      nz-icon
                      class="mr-3 cursor-pointer"
                      nzType="arrow-left"
                      nzTheme="outline"
                      (click)="prevSlide()"
                    ></i>
                    <i
                      nz-icon
                      class="cursor-pointer"
                      nzType="arrow-right"
                      nzTheme="outline"
                      (click)="nextSlide()"
                    ></i>
                  </div>
                </div>
                <div
                  *ngIf="
                    ((surveyFacade.aboutSurvey$ | async) === null ||
                      (surveyFacade.aboutSurvey$ | async).length === 0) &&
                    (surveyFacade.loadingAboutSurvey$ | async) === false
                  "
                  class="text-justified align-items-center"
                >
                  <h4
                    class="text-dark text-center font-weight-semibold mb-3 mt-2"
                  >
                    <span class="text-primary">¡Bienvenido!</span> Al parecer
                    aún no tienes
                    {{
                      (surveyFacade.surveyListInfo$ | async).totalSurveys > 0
                        ? 'interacciones'
                        : 'encuestas'
                    }}
                  </h4>
                  <img
                    class="icon-not-found mx-auto my-2"
                    [src]="IMG_NOT_FOUND"
                    alt=""
                  />
                  <span class="d-block text-dark text-center">
                    Para comenzar,
                    {{
                      (surveyFacade.surveyListInfo$ | async).totalSurveys > 0
                        ? 'crea preguntas a tus encuestas, comparte'
                        : 'crea tu primera encuesta'
                    }}
                    <br />
                    y luego podrás ver las estadísticas aquí.
                  </span>
                </div>
              </nz-spin>
            </div>
          </div>
        </div>
      </div>
    </nz-ribbon>
  </div>
  <div class="col-12 col-lg-12 col-xl-4 mb-4 d-none d-xl-block" id="info-plan">
    <app-info-plan-liquid></app-info-plan-liquid>
  </div>
</div>

<nz-modal
  [(nzVisible)]="isVisibleModal"
  (nzOnCancel)="isVisibleModal = false"
  nzCentered
>
  <ng-container *nzModalContent>
    <div class="modal-content row">
      <div class="col-12 col-md-4 d-none d-md-block m-0 p-0">
        <img class="img-rocket" [src]="IMG_ROCKET" alt="" />
      </div>
      <div class="col-12 col-md-8">
        <p class="modal-text text-primary font-weight-bold">
          ¡Has alcanzado el límite de respuestas de tu plan
          {{ (planFacade.currentPlan$ | async).name }}!
        </p>
        <span
          ><strong>Actualiza ahora</strong> para disfrutar de más respuestas,
          personalización y soporte prioritario.</span
        >
      </div>
    </div>
  </ng-container>
  <div *nzModalFooter class="d-block mx-auto">
    <!-- <button
      type="button"
      class="btn btn-secondary shadow btn mr-2"
      (click)="isVisibleModal = false"
    >
      Cancelar
    </button> -->
    <button
      *ngIf="!isBetaProduction"
      type="button"
      class="btn btn-primary shadow btn d-block mx-auto"
      [routerLink]="'/app/plans-prices'"
    >
      Actualiza tu plan
      <i class="ml-2" nz-icon nzType="rocket" nzTheme="outline"></i>
    </button>
  </div>
</nz-modal>
