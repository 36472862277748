import { Component } from '@angular/core'
import * as Highcharts from 'highcharts'
import { IQuestion } from 'src/app/models/response/IQuestion'
import { ISurvey } from './../../models/response/ISurvey'
import { QuestionFacade } from './../../store/question/question.facade'
import { SurveyFacade } from './../../store/survey/survey.facade'
import { optionsChartDefault } from './../../utils/properties/hightchartOptions'

@Component({
  selector: 'app-nps-indicator-info',
  templateUrl: './nps-indicator-info.component.html',
  styleUrls: ['./nps-indicator-info.component.scss'],
})
export class NpsIndicatorInfoComponent {
  Highcharts: typeof Highcharts = Highcharts
  chartStyle = { display: 'block', height: '100%', width: '100%' }
  public npsResponsesIndicators: any = {
    language: optionsChartDefault.lang,
    chart: {
      type: 'gauge',
    },
    title: {
      text: '',
    },
    pane: {
      startAngle: -90,
      endAngle: 89.9,
      background: null,
      center: ['50%', '75%'],
      size: '110%',
    },
    yAxis: {
      min: 0,
      max: 100,
      tickPosition: 'inside',
      tickLength: 15,
      tickWidth: 1,
      tickInterval: 10,
      labels: {
        step: 2,
        rotation: 'auto',
        distance: 20,
        style: {
          fontSize: '12px',
        },
      },
      plotBands: [
        {
          from: 0,
          to: 60,
          color: '#E8195D', // red
          innerRadius: '60%',
          outerRadius: '100%',
        },
        {
          from: 60,
          to: 80,
          color: '#FCC218', // yellow
          innerRadius: '60%',
          outerRadius: '100%',
        },
        {
          from: 80,
          to: 100,
          color: '#00A0AE', // green
          innerRadius: '60%',
          outerRadius: '100%',
        },
      ],
    },
    series: [
      {
        name: 'NPS',
        data: [75], // replace with actual NPS score
        tooltip: {
          valueSuffix: '%',
        },
        dataLabels: {
          formatter: function () {
            return (
              '<span style="font-size: 12px; opacity: 0.6">Total</span><br/>' +
              '<span style="font-size: 25px; color: black;">' +
              this.y +
              '</span>' +
              '</div>'
            )
          },
          useHTML: true,
          style: {
            color: 'black', // Change the color to dark
            textOutline: 'none',
          },
          borderWidth: 0,
          padding: 15,
          zIndex: 5,
        },
        dial: {
          radius: '80%',
          backgroundColor: 'gray',
          baseWidth: 12,
          baseLength: '0%',
          rearLength: '0%',
        },
        pivot: {
          backgroundColor: 'gray',
          radius: 6,
        },
      },
    ],
    credits: {
      enabled: false,
    },
    plotOptions: {
      gauge: {
        dial: {
          backgroundColor: '#707B7F',
        },
        pivot: {
          backgroundColor: 'gold',
        },
      },
    },
  }

  public npsOptions = [
    { score: 0, icon: 'frown', color: '#E8195D', responses: 10 },
    { score: 1, icon: 'frown', color: '#E8195D', responses: 15 },
    { score: 2, icon: 'frown', color: '#E8195D', responses: 12 },
    { score: 3, icon: 'frown', color: '#E8195D', responses: 17 },
    { score: 4, icon: 'frown', color: '#E8195D', responses: 13 },
    { score: 5, icon: 'frown', color: '#E8195D', responses: 19 },
    { score: 6, icon: 'frown', color: '#E8195D', responses: 18 },
    { score: 7, icon: 'meh', color: '#FCC218', responses: 25 },
    { score: 8, icon: 'meh', color: '#FCC218', responses: 30 },
    { score: 9, icon: 'smile', color: '#00A0AE', responses: 35 },
    { score: 10, icon: 'smile', color: '#00A0AE', responses: 40 },
  ]

  public npsGroups = [
    {
      group: 'Detractores',
      scores: [0, 1, 2, 3, 4, 5, 6],
      color: '#E8195D',
      flexBasis: null,
    },
    {
      group: 'Pasivos',
      scores: [7, 8],
      color: '#FCC218',
      flexBasis: null,
    },
    {
      group: 'Promotores',
      scores: [9, 10],
      color: '#00A0AE',
      flexBasis: null,
    },
  ]

  public totalScores: number
  public surveyList: ISurvey[] = []
  public typeQuestionSelected: string = '0'
  public surveySelected: string = '0'
  public questionsSurveySelected: IQuestion[] = []
  public questionSelected: string = '0'
  public selectedOption: number = 0
  public isLoading = false

  constructor(
    public surveyFacade: SurveyFacade,
    public questionFacade: QuestionFacade
  ) {
    this.npsResponsesIndicators.chart = {
      ...this.npsResponsesIndicators.chart,
      reflow: true,
    }
    // Calcula el número total de puntuaciones
    this.totalScores = this.npsGroups.reduce(
      (total, group) => total + group.scores.length,
      0
    )

    // Calcula el ancho inicial de cada bloque en base al porcentaje de puntuaciones que contiene
    this.npsGroups.forEach((group: any) => {
      group.flexBasis = `${(group.scores.length / this.totalScores) * 100}%`
    })

    this.surveyFacade.surveyList$.subscribe((surveyList: ISurvey[]) => {
      if (surveyList.length > 0) {
        this.surveyList = surveyList.filter(
          (survey: ISurvey) => survey.responsesUsed > 0
        )
      }
    })
  }

  public getQuestionSurvey(surveyId: string): void {
    this.isLoading = true
    // this.questionFacade
    //   .obtainQuestionListFetch(surveyId, false, false)
    //   .subscribe((questions: IQuestion[]) => {
    //     if (questions.length > 0) {
    //       this.questionsSurveySelected = questions.filter(
    //         (question: IQuestion) =>
    //           question.typeId === this.typeQuestionSelected
    //       )
    //     }

    //     this.isLoading = false
    //   })
  }

  public getIndicators(questionId: string): void {}
}
