import {
  TOKEN_EXPIRED_MESSAGE,
  TOKEN_EXPIRED_TITLE,
} from './../../../utils/constants/session-messages'
import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { ITokenUpdatePassword } from 'src/app/models/request/ITokenUpdatePassword'
import { IUpdatePassword } from 'src/app/models/request/IUpdatePassword'
import { IResponse } from 'src/app/models/response/IResponse'
import { SessionService } from 'src/app/services/service.index'
import { TokenService } from 'src/app/services/session/token.service'
import {
  ERROR_SERVICE_MESSAGE,
  ERROR_SERVICE_TITLE,
} from 'src/app/utils/constants/generic-messages'
import {
  ERROR_TOKEN_UPDATE_PASS_MESSAGE,
  ERROR_TOKEN_UPDATE_PASS_TITLE,
  SUCCESS_UPDATE_PASS_MESSAGE,
  SUCCESS_UPDATE_PASS_TITLE,
} from 'src/app/utils/constants/session-messages'

@Component({
  selector: 'app-update-pass',
  templateUrl: './update-pass.component.html',
  styleUrls: ['../session.scss'],
})
export class UpdatePassComponent implements OnInit {
  public loadingTokenValidation: boolean
  public loading: boolean
  public isValidRequest: boolean
  public validateForm!: FormGroup
  public email: string

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private _session: SessionService,
    private _token: TokenService,
    private notification: NzNotificationService,
    private fb: FormBuilder
  ) {
    this.loadingTokenValidation = false
    this.loading = false
    this.isValidRequest = true

    const token = this.route.snapshot.paramMap.get('tokenId')
    const email = this.route.snapshot.paramMap.get('email')

    if (token) {
      this.loadingTokenValidation = true
      const request: ITokenUpdatePassword = { key: token, email }
      this.validateTokenRequest(request)
    } else {
      this.notification.create(
        'info',
        ERROR_TOKEN_UPDATE_PASS_TITLE,
        ERROR_TOKEN_UPDATE_PASS_MESSAGE
      )
      this.router.navigate([`/login`])
    }
  }

  ngOnInit(): void {
    this.validateForm = this.fb.group({
      password: [
        null,
        [
          Validators.required,
          Validators.pattern('((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{6,18})'),
        ],
      ],
      repeatPassword: [null, [Validators.required, this.confirmationValidator]],
    })
  }

  private validateTokenRequest(request: ITokenUpdatePassword): void {
    this._token.validateTokenToUpdatePass(request).subscribe(
      (response: IResponse) => {
        switch (response.status.code) {
          case 200:
          case 201:
            this.isValidRequest = true
            break
          default:
            this.isValidRequest = false
            this.router.navigate([`/login`])
            break
        }
        this.email = request.email
        this.loadingTokenValidation = false
      },
      (error) => {
        this.router.navigate([`/login`])
        this.loadingTokenValidation = false
        if (error.status === 401) {
          this.notification.create(
            'warning',
            ERROR_TOKEN_UPDATE_PASS_TITLE,
            ERROR_TOKEN_UPDATE_PASS_MESSAGE
          )
          return
        }
        this.notification.create(
          'error',
          ERROR_SERVICE_TITLE,
          ERROR_SERVICE_MESSAGE
        )
      }
    )
  }

  public submitForm(): void {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty()
      this.validateForm.controls[i].updateValueAndValidity()
    }

    if (this.validateForm.valid) {
      const request: IUpdatePassword = {
        password: this.validateForm.get('password')?.value,
        password_confirmation: this.validateForm.get('repeatPassword')?.value,
        email: this.email,
      }

      this.loading = true
      this._session.updatePassword(request).subscribe(
        (response: IResponse) => {
          switch (response.status.code) {
            case 200:
            case 201:
              this.notification.create(
                'success',
                SUCCESS_UPDATE_PASS_MESSAGE,
                SUCCESS_UPDATE_PASS_TITLE
              )
              this.router.navigate([`/login`])
              break
          }

          this.loading = false
        },
        (error: Error) => {
          console.error(error)
          this.notification.create(
            'error',
            ERROR_SERVICE_TITLE,
            ERROR_SERVICE_MESSAGE
          )
          this.loading = false
        }
      )
    }
  }

  private confirmationValidator = (
    control: FormControl
  ): { [s: string]: boolean } => {
    if (!control.value) {
      return { required: true }
    } else if (control.value !== this.validateForm.controls.password.value) {
      return { confirm: true, error: true }
    }
    return {}
  }
}
