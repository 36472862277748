<form nz-form [formGroup]="form" (ngSubmit)="saveForm()">
  <div class="row w-100">
    <!--Name input-->
    <div class="col-12 col-md-6 p-0">
      <app-input-field
        class="w-100 mb-2"
        [groupName]="form"
        controlName="nameBusiness"
        label="Empresa"
        errorMessage="Escribe nombre empresa"
        icon="crown"
        maxlength="250"
      ></app-input-field>
    </div>

    <!--Email input-->
    <div class="col-12 col-md-6">
      <app-input-field
        class="w-100 mb-2"
        [groupName]="form"
        controlName="secondaryEmail"
        label="Email"
        errorMessage="Escribe mail"
        secondaryErrorMessage="Escribe mail válido"
        icon="mail"
        maxlength="250"
      ></app-input-field>
    </div>

    <!--Nature company input-->
    <div class="col-12 col-md-6 p-0">
      <app-input-field
        class="w-100 mb-2"
        [groupName]="form"
        controlName="scopeBusiness"
        label="Giro"
        errorMessage="Escribe giro"
        icon="snippets"
        maxlength="250"
      ></app-input-field>
    </div>

    <!--RUT input-->
    <div class="col-12 col-md-6">
      <app-input-field
        class="w-100 mb-2"
        [groupName]="form"
        controlName="rut"
        label="Rut / Número registro fiscal"
        errorMessage="Ingresa RUT o número de registro fiscal"
        icon="heat-map"
        maxlength="50"
      ></app-input-field>
    </div>

    <div class="d-block ml-lg-auto mt-4">
      <button type="submit" class="btn btn-primary ml-2">
        <span class="mr-2">Continuar</span>
        <span nz-icon nzType="right" nzTheme="outline"></span>
      </button>
    </div>
  </div>
</form>
<div id="comercial_information"></div>
