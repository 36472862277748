<div class="card px-0 card-content align-items-start">
  <h1 class="card-title my-0 mx-3">Tu Próximo Plan</h1>
  <hr class="w-100" />
  <div class="container plan-items-buy">
    <div class="row">
      <div class="col-12 col-xl-8 my-1">
        {{ (planFacade.selectedPlan$ | async).name }} <br />
        <small>(Facturación mensual)</small>
      </div>
      <div class="col-12 col-xl-4 my-1 font-weight-bold text-right">
        ${{ (planFacade.selectedPlan$ | async)?.price | numberFormat }} CLP
      </div>
      <div class="col-12 col-xl-8 my-1 mt-3 font-weight-bold">Incluye:</div>

      <div class="col-12 col-xl-8 my-1">
        Hasta
        {{ (planFacade.selectedPlan$ | async).numberPlanResponses }} respuestas
        al mes
      </div>
      <div class="col-12 col-xl-8 my-1">
        {{
          (planFacade.selectedPlan$ | async).numberPlanSurveys === -1
            ? 'Encuestas ilimitadas'
            : 'Puedes crear hasta ' +
              (planFacade.selectedPlan$ | async).numberPlanSurveys +
              ' encuestas'
        }}
      </div>
      <div class="col-12 col-xl-8 my-1">
        Acceso para
        {{ (planFacade.selectedPlan$ | async).numberUsers }} usuarios
      </div>
      <div class="col-8 col-xl-8 my-4 discount-text">Descuento</div>
      <div
        class="col-4 col-xl-4 my-4 font-weight-bold discount-text text-right"
      >
        ${{ (planFacade.selectedPlan$ | async)?.discount | numberFormat }} CLP
      </div>
    </div>
  </div>
  <hr class="w-100" />
  <div class="container mx-0">
    <div class="row">
      <div class="col-12 col-xl-7 my-0 text-center text-xl-left">
        <span class="font-weight-bold total-text">TOTAL</span>
        <small class="d-block">(incluyendo impuestos)</small>
      </div>
      <div
        class="col-12 col-xl-5 my-1 font-weight-bold total-text text-center text-xl-right mt-3 mt-xl-0"
      >
        ${{
          (planFacade.selectedPlan$ | async)?.totalPriceDiscount | numberFormat
        }}
        CLP
      </div>
    </div>
  </div>
  <hr class="w-100" />
  <div class="container px-3 condition-text">
    <ul class="p-0 my-2">
      Se te cobrará un total de ${{
        (planFacade.selectedPlan$ | async)?.totalPriceDiscount | numberFormat
      }}
      CLP en concepto de cargo prorrogado para el periodo de facturación actual.
    </ul>
    <ul class="p-0 my-2">
      Ten en cuenta que tu plan se facturará una única vez y no se renovará
      automáticamente al finalizar el mes contratado. La vigencia de tu plan
      expirará el día
      {{
        (planFacade.selectedPlan$ | async).finishDate
      }}.
    </ul>
    <!-- <ul class="p-0 my-2">
      Puedes cancelar tu suscripción en cualquier momento antes del 01 de Junio
      de 2022, o si has utilizado menos del 5% de las respuestas asignadas a tu
      plan actual.
    </ul> -->
  </div>
</div>
