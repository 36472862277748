import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs-compat'
import { IResponse } from 'src/app/models/response/IResponse'
import { environment } from 'src/environments/environment'
import { map } from 'rxjs/operators'

@Injectable({
  providedIn: 'root',
})
export class ResponsesService {
  protected api: string

  constructor(public http: HttpClient) {
    this.api = environment.api
  }

  public getLastResponses(page: number, perPage = 5): Observable<IResponse> {
    const uri = `${this.api}/client/responses?page=${page}&per_page=${perPage}`
    return this.http.get(uri).pipe(map((data: IResponse) => data))
  }

  public getResponsesClient(): Observable<IResponse> {
    const uri = `${this.api}/client/responses`
    return this.http.get(uri).pipe(map((data: IResponse) => data))
  }
}
