import { ComponentsModule } from './../../components/components.module'
import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { NgZorroAntdModule } from 'src/app/ng-zorro-antd.module'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'

import { QuestionComponent } from './question.component'
import { QuestionFormComponent } from './question-form/question-form.component'
import { QuestionListComponent } from './question-list/question-list.component'
import { TableQuestionListComponent } from './table-question-list/table-question-list.component'
import { ViewQuestionListComponent } from './view-question-list/view-question-list.component'
import { QuestionOptionsComponent } from './question-options/question-options.component'
@NgModule({
  declarations: [
    QuestionComponent,
    QuestionFormComponent,
    QuestionListComponent,
    TableQuestionListComponent,
    ViewQuestionListComponent,
    QuestionOptionsComponent,
  ],
  imports: [
    CommonModule,
    NgZorroAntdModule,
    FormsModule,
    ReactiveFormsModule,
    ComponentsModule,
  ],
  exports: [
    QuestionFormComponent,
    QuestionListComponent,
    ViewQuestionListComponent,
  ],
  providers: [],
})
export class QuestionModule {}
