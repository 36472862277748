import { Component } from '@angular/core'

@Component({
  selector: 'app-breadcrumbs-report',
  templateUrl: './breadcrumbs-report.component.html',
  styleUrls: ['./breadcrumbs-report.component.scss'],
})
export class BreadcrumbsReportComponent {
  onBreadcrumbClick(event: Event): void {
    event.preventDefault()
  }
}
