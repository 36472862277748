<div class="container-fluid animated-fade-in" *ngIf="!loadingUser">
  <h4>Vista general de la cuenta</h4>
  <div *ngIf="true" class="general-info">
    <div class="row title-section">
      <span class="col-1 my-auto">GENERAL</span>
      <span class="col-11">
        <hr />
      </span>
    </div>

    <div class="row personal-info">
      <div class="col-4">
        <label for="nombre">Nombre</label>
      </div>
      <div class="col-8">
        <span>{{ (user$ | async).nombreUsuario }}</span>
      </div>

      <div class="col-4">
        <label for="email">Email</label>
      </div>
      <div class="col-8">
        <span>{{ (user$ | async).email }}</span>
      </div>

      <div class="col-4">
        <label for="telefono">Ocupación</label>
      </div>
      <div class="col-8">
        <span>{{ (user$ | async).cargo ? (user$ | async).cargo : '-' }}</span>
      </div>

      <div class="col-4">
        <label for="telefono">Teléfono</label>
      </div>
      <div class="col-8">
        <span>{{
          (user$ | async).telefono ? (user$ | async).telefono : '-'
        }}</span>
      </div>
    </div>

    <div
      *ngIf="(user$ | async).tipoUsuario === 'empresa'"
      class="row title-section animated-fade-in"
    >
      <span class="col-1 my-auto">EMPRESA</span>
      <span class="col-11">
        <hr />
      </span>
    </div>

    <div
      *ngIf="(user$ | async).tipoUsuario === 'empresa'"
      class="business-info animated-fade-in"
    >
      <nz-skeleton
        [nzLoading]="loadingCompany"
        [nzActive]="true"
        [nzAvatar]="true"
        [nzParagraph]="{ rows: 5 }"
        [nzRound]="true"
      >
        <div *ngIf="!loadingCompany" class="row">
          <div class="col-4">
            <label for="nombre">Nombre Empresa/Organización</label>
          </div>
          <div class="col-8">
            <span>{{ company.nombreCliente }}</span>
          </div>

          <div class="col-4">
            <label for="rubro">Rubro</label>
          </div>
          <div class="col-8">
            <span>{{ company.rubro ? company.rubro : '-' }}</span>
          </div>

          <div class="col-4">
            <label for="sitioWeb">Sitio web</label>
          </div>
          <div class="col-8">
            <span>{{ company.sitioWeb ? company.sitioWeb : '-' }}</span>
          </div>

          <div class="col-4">
            <label for="sitioWeb">Teléfono</label>
          </div>
          <div class="col-8">
            <span>{{ company.telefono ? company.telefono : '-' }}</span>
          </div>

          <!-- TODO: Pendiente por card  -->
          <div class="col-4">
            <label for="sitioWeb">Encargado</label>
          </div>
          <div class="col-8">
            <!-- <span>{{
              company.encargado ? company.encargado.nombreUsuario : '-'
            }}</span> -->
          </div>
        </div>
      </nz-skeleton>
    </div>

    <div class="row title-section">
      <span class="col-2 my-auto">TU PLAN</span>
      <span class="col-10">
        <hr />
      </span>
    </div>

    <nz-skeleton
      [nzLoading]="(loadingPlan$ | async) === true"
      [nzActive]="true"
      [nzAvatar]="true"
      [nzParagraph]="{ rows: 5 }"
      [nzRound]="true"
    >
      <div *ngIf="(loadingPlan$ | async) === false" class="row plan-info">
        <div class="col-xs-12 col-sm-6">
          <app-current-card-plan
            [infoPlan]="plan$ | async"
          ></app-current-card-plan>
        </div>
        <div class="col-xs-12 col-sm-6 mt-4">
          <!-- surveys -->
          <div class="progress">
            <nz-progress
              [nzPercent]="(plan$ | async).percentSurveysUsed"
              nzSize="small"
              [nzShowInfo]="false"
              nzStrokeColor="#40189D"
            ></nz-progress>
            <span>Encuestas</span>
            <span class="float-right font-weight-bold"
              >{{ (plan$ | async).numberSurveysUsed }}/{{
                (plan$ | async).numberPlanSurveys === 0
                  ? '∞'
                  : (plan$ | async).numberPlanSurveys
              }}</span
            >
          </div>
          <!-- responses -->
          <div class="progress">
            <nz-progress
              [nzPercent]="(plan$ | async).percentResponsesUsed"
              nzSize="small"
              [nzShowInfo]="false"
              nzStrokeColor="#40189D"
            ></nz-progress>
            <span>Respuestas</span>
            <span class="float-right font-weight-bold">
              {{ (plan$ | async).numberResponsesUsed }}/{{
                (plan$ | async).numberPlanResponses
              }}
            </span>
          </div>

          <!-- <span class="date-info">
            Las respuestas se reiniciarán el
            {{ (plan$ | async).renewalPlanDate }}
          </span> -->
        </div>
      </div>
    </nz-skeleton>
  </div>
</div>
