<div class="mt-4"></div>
<app-card-info
  *ngIf="!editSurvey"
  class="mt-3"
  title="Crea tu encuesta"
  description="Prepárate para diseñar tu encuesta 🛠️. ¿Necesitas orientación? Nuestro Asistente IA te acompaña en cada paso. Si buscas un proceso más fluido y asistido, haz clic en Crear Encuesta con IA y experimenta cómo la inteligencia artificial transforma tus ideas en encuestas impactantes. 🧠✨"
  actionText="Crear Encuesta con IA"
  (actionClicked)="handleAction()"
></app-card-info>

<hr *ngIf="!editSurvey" class="my-4" />

<form
  nz-form
  [formGroup]="form"
  class="login-form survey-form"
  (ngSubmit)="submitForm()"
>
  <div class="row w-100">
    <div class="col-12 p-0">
      <app-input-field
        class="w-100"
        [groupName]="form"
        controlName="name"
        label="Nombre encuesta (*)"
        errorMessage="Ingresa nombre de encuesta"
        icon="audit"
      ></app-input-field>
    </div>
    <div class="col-12 col-md-6 p-0 pr-md-2">
      <app-select-field
        class="w-100"
        [groupName]="form"
        controlName="description"
        [label]="'Categoría de encuesta  (*)'"
        [options]="categories"
        placeholder="Seleccionar opción"
        errorMessage="Selecciona una categoría de encuesta"
      ></app-select-field>
    </div>
    <div class="col-12 col-md-6 p-0 pl-md-2">
      <app-select-field
        class="w-100"
        [groupName]="form"
        [controlName]="'surveyFormat'"
        [label]="'Formato de encuesta'"
        [options]="surveyFormat"
        placeholder="Seleccionar opción"
      ></app-select-field>
    </div>
  </div>

  <hr class="m-0 mt-3 p-0 w-100" />
  <h5 class="mr-auto my-3">Opciones de tu encuesta</h5>

  <div class="row w-100">
    <!-- <div class="col-12 col-lg-6 px-0" [hidden]="true">
      <nz-form-item>
        <span class="d-block mb-1"
          >¿Activar Libro Digital de Comentarios?
          <em
            class="ml-1"
            nz-icon
            nzType="info-circle"
            nzTheme="twotone"
            nz-tooltip
            nzTooltipTitle="Activa esta opción para habilitar un 'Libro Digital' al final de la encuesta. Los participantes podrán dejar reclamos, sugerencias o felicitaciones."
            nzTooltipColor="black"
          ></em>
        </span>
        <div class="w-100">
          <nz-form-control
            nzErrorTip="Indique si la encuesta incluye un libro digital."
          >
            <nz-switch formControlName="hasDigitalBook"></nz-switch>
          </nz-form-control>
        </div>
      </nz-form-item>
    </div> -->
    <div class="col-12 col-lg-6 px-0">
      <nz-form-item>
        <span class="d-block text-info mb-1"
          >¿Modo Tablet?
          <em
            class="ml-1"
            nz-icon
            nzType="info-circle"
            nzTheme="twotone"
            nz-tooltip
            nzTooltipTitle="El 'Modo Tablet' está diseñado para encuestas en dispositivos móviles, garantizando una navegación intuitiva y una visualización optimizada. Esta configuración esencial asegura que, si una encuesta no se completa, se reiniciará automáticamente tras un periodo de inactividad, preparándola para el siguiente usuario. Al finalizar, la encuesta se resetea inmediatamente, listo para una nueva respuesta. Ideal para kioscos o estaciones de auto-servicio donde cada interacción es única y seguida por otra."
            nzTooltipColor="black"
          ></em
        ></span>
        <div class="w-100">
          <nz-switch formControlName="isTabletModeSurvey"></nz-switch>
        </div>
      </nz-form-item>
    </div>

    <div class="col-12 col-lg-6 px-0">
      <nz-form-item>
        <span class="d-block text-info mb-1"
          >¿Modo Repetición Habilitado?
          <em
            class="ml-1"
            nz-icon
            nzType="info-circle"
            nzTheme="twotone"
            nz-tooltip
            nzTooltipTitle="Habilita esta opción para permitir que un mismo usuario responda la encuesta varias veces. Útil para recoger opiniones o feedbacks repetidos."
            nzTooltipColor="black"
          ></em
        ></span>
        <div class="w-100">
          <nz-form-control nzErrorTip=".">
            <nz-switch formControlName="isRepeatModeSurvey"></nz-switch>
          </nz-form-control>
        </div>
      </nz-form-item>
    </div>
    <div class="col-12 p-0">
      <nz-form-item>
        <span class="d-block text-info mb-1"
          >¿Redirección a página personaliza?
          <em
            class="ml-1"
            nz-icon
            nzType="info-circle"
            nzTheme="twotone"
            nz-tooltip
            nzTooltipTitle="Si se activa, los usuarios serán redirigidos a una URL específica al finalizar la encuesta. Útil para dirigir a los participantes a tu sitio web o a una página de agradecimiento."
            nzTooltipColor="black"
          ></em
        ></span>
        <div class="row m-0 p-0 w-100 mt-2">
          <div class="col-2 p-0">
            <nz-form-control
              nzErrorTip="Indique si después de completar la encuesta, el usuario será redirigido a una URL específica."
            >
              <nz-switch formControlName="isActiveRedirectUrl"></nz-switch>
            </nz-form-control>
          </div>
          <div class="col-10">
            <nz-form-control
              class="w-100"
              nzErrorTip="Ingrese la URL válida para la redirección."
            >
              <app-input-field
                [groupName]="form"
                [disabled]="!form.get('isActiveRedirectUrl').value"
                controlName="redirectUrl"
                label="Enlace personalizado"
                placeholder="https://www.empati.cl"
                icon="audit"
              ></app-input-field>
            </nz-form-control>
          </div>
        </div>
      </nz-form-item>
    </div>
  </div>

  <div *ngIf="isGenerateAction" class="d-block mt-5">
    <div class="d-flex justify-content-end align-items-center">
      <a
        *ngIf="!loading"
        (click)="this.handleCancel.emit()"
        class="text-secondary"
      >
        <span>Cancelar</span>
      </a>
      <button
        [disabled]="loading"
        type="submit"
        class="btn btn-sm btn-primary btn-rounded ml-3"
      >
        <span *ngIf="loading" class="d-flex align-items-center">
          <span class="mr-2"> Guardando </span>
          <i nz-icon [nzType]="'loading'"></i>
        </span>
        <span *ngIf="!loading" class="d-flex align-items-center">
          <span class="mr-2"> {{ nameButton }} </span>
          <i nz-icon nzType="save" nzTheme="fill"></i
        ></span>
      </button>
    </div>
  </div>
</form>
