export class UpdateIdJiraTicketCreated {
  static readonly type = '[JIRA] update ticket response'
  constructor(public payload: string) {}
}

export class UpdateLoadingJiraTicketCreated {
  static readonly type = '[JIRA] update loading create ticket'
  constructor(public payload: boolean) {}
}

export class CleanIdJiraTicketCreated {
  static readonly type = '[JIRA] clean state create ticket'
}
