import { IQuestion } from 'src/app/models/response/IQuestion'
import { IQuestionType } from './../../models/response/IQuestionType'

export class UpdateQuestion {
  static readonly type = '[QUESTION] update question'
  constructor(public payload: IQuestion) {}
}

export class UpdateLoadingQuestion {
  static readonly type = '[QUESTION] update loading question'
  constructor(public payload: boolean) {}
}

export class UpdateLoadingQuestionList {
  static readonly type = '[QUESTION] update loading question list'
  constructor(public payload: boolean) {}
}

export class UpdateQuestionList {
  static readonly type = '[QUESTION] update question list'
  constructor(public payload: IQuestion[]) {}
}

export class UpdateOriginalQuestionList {
  static readonly type = '[QUESTION] update original question list'
  constructor(public payload: IQuestion[]) {}
}

export class UpdateQuestionTypes {
  static readonly type = '[QUESTION] update question type list'
  constructor(public payload: IQuestionType[]) {}
}

export class UpdateIsLoadingCreateQuestion {
  static readonly type = '[QUESTION] update loading create question'
  constructor(public payload: boolean) {}
}

export class UpdateIsErrorQuestion {
  static readonly type = '[QUESTION] update is error question'
  constructor(public payload: boolean) {}
}

export class UpdateIsErrorQuestionList {
  static readonly type = '[QUESTION] update is error question list'
  constructor(public payload: boolean) {}
}

export class UpdateMessageQuestionEditLoading {
  static readonly type = '[QUESTION] update message question edit Loading'
  constructor(public payload: string) {}
}
