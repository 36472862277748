import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { environment } from 'src/environments/environment'
import { IAddCategoryRequest } from './../../models/request/IAddCategoryRequest'
import { ICategoryRequest } from './../../models/request/ICategoryRequest'
import { IEditCategoryRequest } from './../../models/request/IEditCategoryRequest'
import { IResponse } from './../../models/response/IResponse'

@Injectable({
  providedIn: 'root',
})
export class SurveyCategoryService {
  private api: string
  private prefix: string

  constructor(public http: HttpClient) {
    this.api = environment.api
    this.prefix = '/survey-category'
  }

  public addNewCategory(request: IAddCategoryRequest): Observable<IResponse> {
    const uri = `${this.api}${this.prefix}/add-new-category`
    return this.http
      .post<IResponse>(uri, request)
      .pipe(map((data: IResponse) => data))
  }

  public assignExistingCategory(
    request: ICategoryRequest
  ): Observable<IResponse> {
    const uri = `${this.api}${this.prefix}/assign-existing-category`
    return this.http
      .post<IResponse>(uri, request)
      .pipe(map((data: IResponse) => data))
  }

  public removeCategoryFromSurvey(
    request: ICategoryRequest
  ): Observable<IResponse> {
    const uri = `${this.api}${this.prefix}/remove-category`
    return this.http
      .request<IResponse>('delete', uri, { body: request })
      .pipe(map((data: IResponse) => data))
  }

  public editCategoryOfSurvey(
    request: IEditCategoryRequest
  ): Observable<IResponse> {
    const uri = `${this.api}${this.prefix}/edit-category`
    return this.http
      .post<IResponse>(uri, request)
      .pipe(map((data: IResponse) => data))
  }

  public listCategories(): Observable<IResponse> {
    const uri = `${this.api}${this.prefix}/list-categories`
    return this.http.get<IResponse>(uri).pipe(map((data: IResponse) => data))
  }
}
