<nz-spin
  [nzSpinning]="(dashboardFacade.isLoadingWeekResponses$ | async) === true"
>
  <div class="card detail-question-result my-4">
    <div class="row m-0 p-0">
      <div class="col-12 col-x2l-5 px-0 pr-x2l-3 mb-3 mb-x2l-0">
        <div class="item-summary">
          <h5 class="font-weight-bold mb-1">{{ title }}</h5>
          <span class="text-muted">{{ subtitle }}</span>
          <nz-spin
            [nzSpinning]="
              (dashboardFacade.isLoadingWeekResponses$ | async) === true
            "
            class="spin-container"
          >
            <div class="container mt-4">
              <div class="d-block mb-3 float-right">
                <nz-radio-group
                  [(ngModel)]="typeReport"
                  nzButtonStyle="solid"
                  (ngModelChange)="getWeekResponsesDataReport($event)"
                >
                  <label nz-radio-button nzValue="surveys" for="surveys"
                    >Encuestas Completadas</label
                  >
                  <label nz-radio-button nzValue="questions" for="questions"
                    >Preguntas Respondidas</label
                  >
                </nz-radio-group>
              </div>
              <highcharts-chart
                *ngIf="
                  (questionFacade.question$ | async) === null &&
                  (dashboardFacade.isLoadingWeekResponses$ | async) === false
                "
                [Highcharts]="Highcharts"
                [options]="dashboardFacade.weekResponsesReport$ | async"
                style="width: 100%; height: 405px; display: block"
              ></highcharts-chart>
            </div>
          </nz-spin>
        </div>
      </div>

      <div class="col-12 col-x2l-7 p-0">
        <div class="item-summary">
          <h5 class="mb-1">Indicadores Clave de Rendimiento de la Encuesta</h5>
          <span class="text-muted"
            >Un vistazo a la efectividad y participación de los usuarios</span
          >
          <div class="item-indicator mt-3">
            <span class="label">
              <nz-badge nzStatus="default"></nz-badge>Encuestas Completadas
            </span>

            <span class="value-score">{{
              summaryResult?.completedSurveys
            }}</span>
          </div>

          <div class="item-indicator">
            <span class="label">
              <nz-badge nzStatus="default"></nz-badge>Cantidad de Respuestas
            </span>
            <span class="value-score">{{ summaryResult?.totalResponses }}</span>
          </div>

          <div class="item-indicator">
            <span class="label">
              <nz-badge nzStatus="default"></nz-badge>
              Encuestas Abandonadas:</span
            >
            <span class="value-score">{{
              summaryResult?.abandonedSurveys
            }}</span>
          </div>

          <div class="item-indicator">
            <span class="label">
              <nz-badge nzStatus="default"></nz-badge>
              Tasa de Abandono:
              <em
                class="ml-2"
                nz-icon
                nzType="info-circle"
                nzTheme="twotone"
                nz-tooltip
                nzTooltipTitle="Tasa de Abandono: Indica el porcentaje de encuestas iniciadas pero no completadas. Una tasa alta puede señalar áreas de la encuesta que requieren mejoras para mantener el interés de los participantes."
                nzTooltipColor="black"
              ></em
            ></span>
            <span class="value">{{ summaryResult?.abandonRate }}%</span>
          </div>

          <div class="item-indicator">
            <span class="label">
              <nz-badge nzStatus="default"></nz-badge>
              Tiempo Promedio de Encuesta (hh/mm/ss):</span
            >
            <span class="value">
              <em
                class="mr-2"
                nz-icon
                nzType="clock-circle"
                nzTheme="twotone"
                nz-tooltip
                nzTooltipTitle="Tiempo Promedio de Respuesta: Este tiempo representa la duración media que los encuestados han dedicado a completar la encuesta. El formato de la duración es hh:mm:ss."
                nzTooltipColor="black"
              ></em>
              {{ summaryResult?.averageResponseTime }}
            </span>
          </div>

          <div class="mt-4">
            <h5 class="font-weight-bold mb-0">
              Análisis de la Tasa de Abandono de Encuestas
            </h5>
            <span class="text-muted"
              >Comparativa Visual de Encuestas Completadas vs. Abandonadas</span
            >
            <highcharts-chart
              [Highcharts]="Highcharts"
              [options]="donusChartOptionsAbandonedRate"
              style="width: 100%; height: 250px; display: block"
            ></highcharts-chart>
          </div>
        </div>
      </div>

      <div class="col-12 p-0 mt-3">
        <app-comments-insight
          [summaryResult]="summaryResult"
          [isLoadingSummary]="isLoadingSummary"
        >
        </app-comments-insight>
      </div>
    </div>
  </div>
</nz-spin>
