import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core'

@Component({
  selector: 'app-special-button',
  templateUrl: './special-button.component.html',
  styleUrls: ['./special-button.component.scss'],
})
export class SpecialButtonComponent implements AfterViewInit {
  @Input() disabled: boolean = false
  @Input() buttonText: string = 'Crear con IA'
  @Input() iconType: string | null = 'robot'
  @Output() buttonClicked = new EventEmitter<void>()
  @ViewChild('modal') modalChild

  constructor() {}

  ngAfterViewInit() {
    // Aquí puedes realizar acciones después de que se inicialicen las vistas
  }

  onButtonClick() {
    this.buttonClicked.emit()
    if (this.modalChild) {
      this.modalChild.open()
    }
  }
}
