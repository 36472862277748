<form nz-form [formGroup]="form">
  <div class="row">
    <div class="col-12 col-md-6">
      <app-input-field
        class="w-100 mb-3"
        [groupName]="form"
        controlName="name"
        label="Nombre empresa *"
        errorMessage="Ingresa nombre empresa/organización"
        icon="crown"
        [maxLength]="50"
      ></app-input-field>
    </div>
    <div class="col-12 col-md-6">
      <app-input-field
        class="w-100 mb-3"
        [groupName]="form"
        controlName="activity"
        label="Rubro"
        errorMessage="Ingresa rubro"
        icon="apartment"
        [maxLength]="50"
      ></app-input-field>
    </div>
    <div class="col-12 col-md-6">
      <app-input-field
        class="w-100 mb-3"
        [groupName]="form"
        controlName="webSite"
        label="Sitio web"
        errorMessage="Ingresa sitio web"
        icon="global"
        [maxLength]="40"
      ></app-input-field>
    </div>
  </div>

  <!-- TODO: PENDING FOR THE BACKEND ROLES
     <nz-form-item *ngIf="company?.encargado">
    <nz-form-label [nzSpan]="24">Encargado</nz-form-label>
    <div [nzSpan]="24">
      <span class="text-secondary"
        >{{ company.encargado?.nombreUsuario }}
      </span>
    </div>
  </nz-form-item> -->

  <nz-form-item class="button-create">
    <nz-form-control>
      <button
        [disabled]="loading"
        (click)="submitForm()"
        type="submit"
        class="btn btn-primary d-block ml-auto mt-3"
      >
        <i *ngIf="loading" nz-icon [nzType]="'loading'"></i>
        <span *ngIf="loading" class="ml-2">Guardando</span>
        <span *ngIf="!loading" class="d-flex align-items-center"
          >Actualizar
        </span>
      </button>
    </nz-form-control>
  </nz-form-item>
</form>
