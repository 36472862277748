import html2canvas from 'html2canvas'
import jspdf from 'jspdf'

export const getBase64 = (file: File): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })

export const downloadBase64File = (
  base64Data: string,
  filename: string
): void => {
  const blob: Blob = base64ToBlob(base64Data)
  const url: string = URL.createObjectURL(blob)

  const link: HTMLAnchorElement = document.createElement('a')
  link.href = url
  link.download = filename

  document.body.appendChild(link)
  link.click()

  document.body.removeChild(link)
  URL.revokeObjectURL(url)
}

function base64ToBlob(
  base64Data: string,
  contentType: string = 'application/octet-stream'
): Blob {
  const byteCharacters: string = atob(base64Data.split(',')[1])
  const byteArrays: Uint8Array[] = []

  for (let offset: number = 0; offset < byteCharacters.length; offset += 512) {
    const slice: string = byteCharacters.slice(offset, offset + 512)

    const byteNumbers: number[] = new Array(slice.length)
    for (let i: number = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray: Uint8Array = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }

  const blob: Blob = new Blob(byteArrays, { type: contentType })
  return blob
}

export const generatePDF = (idElement: string) => {
  const DATA = document.getElementById(idElement)

  if (!DATA) {
    throw new Error('Element not found')
  }

  const originalStyles = {
    width: DATA.style.width,
    overflow: DATA.style.overflow,
  }

  DATA.style.width = '768px'
  DATA.style.overflow = 'hidden'

  const canvasOptions = {
    scale: 7,
    useCORS: true,
    width: DATA.clientWidth,
    height: DATA.clientHeight,
    letterRendering: true,
    scrollX: 0,
    scrollY: 0,
  }

  return html2canvas(DATA, canvasOptions).then((canvas) => {
    DATA.style.width = originalStyles.width
    DATA.style.overflow = originalStyles.overflow

    const imgWidth = 190
    const pageHeight = 277
    const imgHeight = (canvas.height * imgWidth) / canvas.width
    const contentDataURL = canvas.toDataURL('image/png')

    let pdf = new jspdf('p', 'mm', 'a4', true)

    const marginLeft = 10
    const marginTop = 10

    pdf.addImage(
      contentDataURL,
      'PNG',
      marginLeft,
      marginTop,
      imgWidth,
      imgHeight
    )

    let heightLeft = imgHeight
    let position = imgHeight + marginTop
    while (heightLeft >= 0) {
      heightLeft -= pageHeight
      if (heightLeft > 0) {
        pdf.addPage()
        pdf.addImage(
          contentDataURL,
          'PNG',
          marginLeft,
          position - heightLeft,
          imgWidth,
          imgHeight
        )
      }
    }

    return pdf
  })
}

export const downloadPDF = async (idElement: string, docName: string) => {
  const pdf = await generatePDF(idElement)
  pdf.save(docName + '.pdf')
}

export const pdfToB64 = async (idElement: string, docName: string) => {
  const pdf = await generatePDF(idElement)
  return pdf.output('datauristring')
}

export const convertFileToBase64 = (
  file: File
): Promise<string | ArrayBuffer | null> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)

    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}
