<div class="row">
  <div class="col-12">
    <div class="card animated-fade-in">
      <div class="container-fluid">
        <h4>Usuarios</h4>
        <table class="general-table mt-5" cellspacing="0" summary="Mis Pagos">
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Perfil</th>
              <th>Fecha creación</th>
              <th>Fecha última actividad</th>
              <th>Email</th>
              <th>Estado</th>
              <th></th>
            </tr>
          </thead>
          <tbody cdkDropList>
            <tr *ngFor="let item of users">
              <td>
                <div class="d-flex align-items-center">
                  <img class="profile-user" [src]="item.imageProfile" alt="" />
                  <span class="mb-0">{{ item.userName }}</span>
                </div>
              </td>
              <td>{{ item.cargo }}</td>
              <td>
                {{ item.createDate | date: 'dd-MM-YYYY' }}
                {{ item.createDate | date: 'HH:mm a' }}
              </td>
              <td>
                {{ item.lastActivityDate | date: 'dd-MM-YYYY' }}
                {{ item.lastActivityDate | date: 'HH:mm a' }}
              </td>
              <td>{{ item.email }}</td>
              <td>
                <span class="badge badge-xl light badge-sm badge-success"
                  >Activo</span
                >
              </td>
              <td>
                <a class="general-table__options">
                  <img
                    class="option-icon"
                    src="{{ ICON_OPTION }}"
                    nz-dropdown
                    nzTrigger="click"
                    [nzDropdownMenu]="menu"
                    nz-icon
                    nzType="down"
                    alt=""
                  />
                </a>
                <nz-dropdown-menu #menu="nzDropdownMenu">
                  <ul nz-menu>
                    <li nz-menu-item *ngIf="item.status === 0">Activar</li>
                    <li nz-menu-item *ngIf="item.status === 1">Desactivar</li>
                    <li nz-menu-item>Editar</li>
                    <li nz-menu-item>Eliminar</li>
                  </ul>
                </nz-dropdown-menu>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
