import { optionsChartDefault } from './../../../utils/properties/hightchartOptions'
export const npsIndicators: any = {
  lang: optionsChartDefault.lang,
  chart: {
    type: 'pie',
    events: {
      render: function () {
        var chart = this,
          renderer = chart.renderer

        if (chart.customLabel) {
          chart.customLabel.destroy()
        }

        // Se calcula el valor de NPS
        const npsValue = chart.series[0].data[0].y - chart.series[0].data[2].y

        chart.customLabel = renderer
          .label(
            'NPS: ' + npsValue,
            null,
            null,
            null,
            null,
            null,
            true,
            false,
            null,
            true
          )
          .attr({
            zIndex: 5,
          })
          .css({
            color: '#4572A7',
            fontSize: '30px',
            position: 'absolute',
          })
          .add()
          .align(
            {
              align: 'center',
              verticalAlign: 'middle',
              x: chart.plotLeft + chart.plotWidth / 2,
              y: chart.plotTop + chart.plotHeight / 2,
            },
            null,
            'spacingBox'
          )
      },
    },
  },
  title: {
    text: 'Net Promoter Score',
  },
  tooltip: {
    pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
  },
  plotOptions: {
    pie: {
      innerSize: '50%',
      dataLabels: {
        enabled: false,
      },
      showInLegend: true,
    },
  },
  series: [
    {
      type: 'pie',
      name: 'NPS',
      data: [
        {
          name: 'Promoters',
          y: 45, // dummy data
          color: '#00A0AE', // color for Promoters
        },
        {
          name: 'Passives',
          y: 30, // dummy data
          color: '#FCC218', // color for Passives
        },
        {
          name: 'Detractors',
          y: 25, // dummy data
          color: '#E8195D', // color for Detractors
        },
      ],
    },
  ],
  credits: {
    enabled: false,
  },
}
