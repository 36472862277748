import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { NzUploadFile } from 'ng-zorro-antd/upload'
import { first } from 'rxjs/operators'
import { ISurvey } from 'src/app/models/response/ISurvey'
import { convertFileToBase64, getBase64 } from '../../../../utils/converts/file'
import { SurveyFacade } from './../../../../store/survey/survey.facade'

@Component({
  selector: 'app-option-survey',
  templateUrl: './option-survey.component.html',
  styleUrls: ['./option-survey.component.scss'],
})
export class OptionSurveyComponent implements OnInit {
  @Output() public isSettingsChangedChange = new EventEmitter<boolean>()
  @Output() public tabSelectedChange = new EventEmitter<string>()

  @Input() public isSettingsChanged = false
  @Input() public tabSelected: string

  public form: FormGroup
  public isLoading = false
  private survey: ISurvey
  public fileList: NzUploadFile[] = []
  previewImage: string | undefined = ''
  previewVisible = false

  constructor(
    private formBuilder: FormBuilder,
    private surveyFacade: SurveyFacade
  ) {}

  async ngOnInit(): Promise<void> {
    this.survey = await this.surveyFacade.survey$.pipe(first()).toPromise()
    const {
      hasDigitalBook,
      isRepeatModeSurvey,
      isTabletModeSurvey,
      isActiveRedirectUrl,
      redirectUrl,
    } = this.survey
    this.form = this.formBuilder.group({
      hasDigitalBook: [hasDigitalBook, Validators.required],
      isRepeatModeSurvey: [isRepeatModeSurvey, Validators.required],
      isTabletModeSurvey: [isTabletModeSurvey, Validators.required],
      isActiveRedirectUrl: [isActiveRedirectUrl, Validators.required],
      redirectUrl: [redirectUrl, [Validators.maxLength(50)]],
    })

    this.trackFormChanges()

    if (this.survey.logoUrl) {
      this.fileList = [
        {
          uid: '-1',
          name: 'image.png',
          status: 'done',
          url: this.survey.logoUrl,
        },
      ]
    }
  }

  private trackFormChanges(): void {
    this.form
      .get('isTabletModeSurvey')
      .valueChanges.subscribe((isTabletMode) => {
        if (isTabletMode) {
          this.form
            .get('isActiveRedirectUrl')
            .setValue(false, { emitEvent: false })
          this.form
            .get('isRepeatModeSurvey')
            .setValue(false, { emitEvent: false })
        }
      })

    this.form
      .get('isActiveRedirectUrl')
      .valueChanges.subscribe((isActiveRedirect) => {
        if (isActiveRedirect) {
          this.form
            .get('isTabletModeSurvey')
            .setValue(false, { emitEvent: false })
        }
      })

    this.form
      .get('isRepeatModeSurvey')
      .valueChanges.subscribe((isActiveRedirect) => {
        if (isActiveRedirect) {
          this.form
            .get('isTabletModeSurvey')
            .setValue(false, { emitEvent: false })
        }
      })
  }

  handlePreview = async (file: NzUploadFile): Promise<void> => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj!)
    }
    this.previewImage = file.url || file.preview
    this.previewVisible = true
  }

  public async onSubmit(): Promise<void> {
    if (this.form.valid) {
      let logoBase64 = null

      if (this.fileList[0]) {
        try {
          logoBase64 = await convertFileToBase64(this.fileList[0].originFileObj)
        } catch (error) {
          console.error('Error al convertir el archivo: ', error)
        }
      }

      const request = {
        ...this.survey,
        surveyId: this.survey.surveyId,
        ...this.form.value,
        logoUrl: logoBase64,
      }

      this.isLoading = true

      this.surveyFacade.updateSurveyFetch(request).subscribe(() => {
        this.isLoading = false
        this.resetConfigurationSurvey()
      })
    }
  }

  public resetConfigurationSurvey(): void {
    const mf = document.getElementById('survey-mf') as HTMLIFrameElement
    if (mf) {
      mf.contentWindow.postMessage(
        {
          type: 'RESET',
        },
        '*'
      )
    }
  }
}
