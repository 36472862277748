import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop'
import { Component, Input, OnInit } from '@angular/core'
import { NzMessageService } from 'ng-zorro-antd/message'
import { IQuestion } from 'src/app/models/response/IQuestion'
import { QuestionFacade } from './../../../store/question/question.facade'

@Component({
  selector: 'app-table-question-list',
  templateUrl: './table-question-list.component.html',
  styleUrls: ['./table-question-list.component.scss'],
  providers: [NzMessageService],
})
export class TableQuestionListComponent implements OnInit {
  @Input() public surveyId: string

  public questions: IQuestion[]
  public question: IQuestion
  public textPopConfirmDelete: string

  constructor(public questionFacade: QuestionFacade) {}

  ngOnInit(): void {
    this.questionFacade.questions$.subscribe((questions: IQuestion[]) => {
      if (questions) {
        this.questions = questions
      }
    })

    this.questionFacade.question$.subscribe((question: IQuestion) => {
      if (question) {
        this.question = question
      }
    })
  }

  public dragDropElement(event: CdkDragDrop<string[]>): void {
    const newOrder = moveItemInArray(
      this.questions,
      event.previousIndex,
      event.currentIndex
    )
  }

  public changeStatus(question: IQuestion): void {
    this.questionFacade.changeOptionsFetch(question, this.surveyId)
  }
}
