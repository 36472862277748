import { Component, OnDestroy } from '@angular/core'
import * as Highcharts from 'highcharts'
import { Subscription } from 'rxjs-compat'
import { first } from 'rxjs/operators'
import { IQuestion } from 'src/app/models/response/IQuestion'
import { ISummary } from '../../../models/request/ISummary'
import { ReportWeekType } from '../../../models/types/report.types'
import { chartOptionsDoughnutCSAT } from '../../../pages/survey/survey-report/mappersCharts/doughnut.mapper'
import { DashboardFacade } from '../../../store/dashboard/dashboard.facade'
import { QuestionFacade } from '../../../store/question/question.facade'
import { ReportFacade } from '../../../store/reports/report.facade'
import { SurveyFacade } from '../../../store/survey/survey.facade'
@Component({
  selector: 'app-csat-result',
  templateUrl: './csat-result.component.html',
  styleUrls: ['./csat-result.component.scss'],
})
export class CsatResultComponent implements OnDestroy {
  Highcharts: typeof Highcharts = Highcharts

  public donusChartOptions: any = chartOptionsDoughnutCSAT(
    [
      'Muy Insatisfecho',
      'Insatisfecho',
      'Neutral',
      'Satisfecho',
      'Muy Satisfecho',
    ],
    [0, 0, 0, 0, 0],
    0
  )

  public CSAT_SCALE_IMG = '../../../assets/images/graphics/csat-scale.png'

  public typeReportWeek: ReportWeekType = 'monthly'
  public surveySelectedId: string = '0'
  public isLoadingSummary = false
  public summaryResult
  public hasResults: boolean = false
  private subscription: Subscription
  private isGetSummay: boolean = false

  constructor(
    public questionFacade: QuestionFacade,
    private surveyFacade: SurveyFacade,
    public dashboardFacade: DashboardFacade,
    public reportFacade: ReportFacade
  ) {
    this.subscription = this.questionFacade.question$.subscribe(
      async (question) => {
        if (
          question &&
          question.questionId !== this.surveySelectedId &&
          question.typeId === 1 &&
          !this.isGetSummay
        ) {
          this.surveySelectedId = question.questionId
          await this.getSummary(question)
          this.isGetSummay = true
        }
      }
    )
  }

  ngOnDestroy(): void {
    console.log('[CsatResultComponent] destroy')
    this.subscription.unsubscribe()
  }

  public async getSummary(question: IQuestion): Promise<void> {
    try {
      const survey = await this.surveyFacade.survey$.pipe(first()).toPromise()

      const request: ISummary = {
        surveyId: survey.surveyId,
        questionTypeId: question.typeId.toString(),
        questionId: question.questionId.toString(),
        timeFrame: this.typeReportWeek,
      }

      this.isLoadingSummary = true

      const res = await this.reportFacade
        .getCsatSummaryFetch(request)
        .toPromise()

      if (res.status.code === 200) {
        this.summaryResult = res.data
        const { averageCsatScore, csatScore, totalResponses } =
          this.summaryResult
        this.hasResults = averageCsatScore && csatScore && totalResponses

        const {
          veryDissatisfied,
          dissatisfied,
          neutral,
          satisfied,
          verySatisfied,
        } = this.summaryResult.responsesCase

        this.donusChartOptions = chartOptionsDoughnutCSAT(
          [
            'Muy Insatisfecho',
            'Insatisfecho',
            'Neutral',
            'Satisfecho',
            'Muy Satisfecho',
          ],
          [veryDissatisfied, dissatisfied, neutral, satisfied, verySatisfied],
          this.summaryResult.csatScore
        )
      }

      this.isLoadingSummary = false
    } catch (error) {
      this.isLoadingSummary = false
      this.hasResults = false
    }
  }

  public async onRadioChange(event: any): Promise<void> {
    this.typeReportWeek = event
    const question = await this.questionFacade.question$
      .pipe(first())
      .toPromise()

    this.getSummary(question)
  }
}
