<div class="row mb-5">
  <div class="col align-self-end">
    <div class="float-right">
      <button
        nz-button
        [disabled]="loadingSurveys"
        nzSize="large"
        nzShape="circle"
        [nzType]="typeStatusSurvey === 1 ? 'primary' : 'dashed'"
        (click)="handleChangeType(1)"
        nz-tooltip
        nzTooltipTitle="Encuestas activas"
        nzTooltipColor="purple"
        nzTooltipPlacement="bottom"
      >
        <i nz-icon nzType="unordered-list" nzTheme="outline"></i>
      </button>
      <button
        class="ml-2"
        nz-button
        [disabled]="loadingSurveys"
        nzSize="large"
        nzShape="circle"
        [nzType]="typeStatusSurvey === 0 ? 'primary' : 'dashed'"
        (click)="handleChangeType(0)"
        nz-tooltip
        nzTooltipTitle="Papelera"
        nzTooltipColor="purple"
        nzTooltipPlacement="bottom"
      >
        <i nz-icon nzType="delete" nzSize="'large" nzTheme="outline"></i>
      </button>
    </div>
  </div>
</div>
