export const PERSONAL_INFORMATION_ID = 'personal_information'
export const PERSONAL_INFORMATION_NAME = 'Información Personal'

export const COMERCIAL_INFORMATION_ID = 'comercial_information'
export const COMERCIAL_INFORMATION_NAME = 'Información Comercial (Opcional)'

export const PAYMENT_METHOD_ID = 'payment_method'
export const PAYMENT_METHOD_NAME = 'Método de pago'

export const PAYMENT_STEP_KEY = '573P14n'
export const PAYMENT_COMERCIAL_KEY = 'C0m3R1p4ym37'
export const PAYMENT_PERSON_KEY = 'P3r501p4ym37'

export const PAYMENT_STEPS = [
  {
    id: PERSONAL_INFORMATION_ID,
    name: PERSONAL_INFORMATION_NAME,
    active: true,
    disabled: false,
    complete: false,
  },
  {
    id: COMERCIAL_INFORMATION_ID,
    name: COMERCIAL_INFORMATION_NAME,
    active: false,
    disabled: false,
    complete: false,
  },
  {
    id: PAYMENT_METHOD_ID,
    name: PAYMENT_METHOD_NAME,
    active: false,
    disabled: false,
    complete: false,
  },
]
