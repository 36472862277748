import { Component, OnInit } from '@angular/core'
import data from './users-data.json'

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
})
export class UserListComponent implements OnInit {
  public users: any[]
  public ICON_OPTION = './../assets/images/icons/icon_option_y.svg'

  constructor() {
    this.users = data
  }

  ngOnInit(): void {}
}
