import {
  ICompanyInformation,
  IMyPayments,
  IPersonalInformation,
  IStepPaymentForm,
} from './payment.state'
export class UpdateIsValidForm {
  static readonly type = '[Payment] update is valid form'
  constructor(public payload: boolean) {}
}

export class UpdateStepById {
  static readonly type = '[Payment] update step by id'
  constructor(public id: string, public name: string, public active: boolean) {}
}

export class UpdateSteps {
  static readonly type = '[Payment] update steps'
  constructor(public payload: IStepPaymentForm[]) {}
}

export class UpdatePersonalInformation {
  static readonly type = '[Payment] update personal information'
  constructor(public payload: IPersonalInformation) {}
}

export class UpdateCompanyInformation {
  static readonly type = '[Payment] update company information'
  constructor(public payload: ICompanyInformation) {}
}

export class UpdateMethodPayment {
  static readonly type = '[Payment] update payment type'
  constructor(public payload: string) {}
}

export class UpdateMyPayments {
  static readonly type = '[Payment] update my payments'
  constructor(public payload: IMyPayments) {}
}

export class UpdateIsLoadingPaymentForm {
  static readonly type = '[Payment] update loading payment form'
  constructor(public payload: boolean) {}
}

export class UpdateIsLoadingProcessPayment {
  static readonly type = '[Payment] update loading process payment'
  constructor(public payload: boolean) {}
}

export class UpdateIsLoadingMyPayments {
  static readonly type = '[Payment] update loading my payments'
  constructor(public payload: boolean) {}
}

export class UpdateIsLoadingDocument {
  static readonly type = '[Payment] update loading document'
  constructor(public payload: boolean) {}
}
