import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Title } from '@angular/platform-browser'
import { ActivatedRoute, Router } from '@angular/router'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { environment } from '../../../../environments/environment'
import { SessionService } from '../../../services/service.index'
import { ILogin } from './../../../models/request/ILogin'
import { IResponse } from './../../../models/response/IResponse'
import {
  ERROR_SERVICE_MESSAGE,
  ERROR_SERVICE_TITLE,
} from './../../../utils/constants/generic-messages'
import {
  ERROR_LOGIN_MESSAGE,
  ERROR_LOGIN_TITLE,
  SUCCESS_LOGIN_MESSAGE,
  SUCCESS_LOGIN_TITLE,
} from './../../../utils/constants/session-messages'

const ACCESS_TOKEN = environment.access_token

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['../session.scss'],
})
export class LoginComponent implements OnInit {
  public loading: boolean
  public validateForm!: FormGroup
  public accountNotActive: boolean
  public isBetaProduction = environment.production

  constructor(
    public router: Router,
    private titleService: Title,
    private route: ActivatedRoute,
    private _session: SessionService,
    private notification: NzNotificationService,
    private fb: FormBuilder
  ) {
    this.loading = false
    this.accountNotActive = false
    this.titleService.setTitle(this.route.snapshot.data.title)

    this.validateActiveSession()
  }

  ngOnInit(): void {
    this.validateForm = this.fb.group({
      userName: [null, [Validators.required, Validators.email]],
      password: [null, [Validators.required]],
      remember: [true],
    })
  }

  private validateActiveSession(): void {
    const token = localStorage.getItem(ACCESS_TOKEN)

    if (token) {
      this.router.navigate(['app/dashboard'])
    }
  }

  public submitForm(): void {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty()
      this.validateForm.controls[i].updateValueAndValidity()
    }

    if (this.validateForm.valid) {
      const request: ILogin = {
        email: this.validateForm.get('userName')?.value,
        password: this.validateForm.get('password')?.value,
      }

      this.loading = true
      this.validateForm.disable()

      this._session.logIn(request).subscribe(
        (response: IResponse) => {
          switch (response.status.code) {
            case 200:
              // TODO: Encrypt data
              localStorage.setItem(ACCESS_TOKEN, response.data.token)

              this.notification.create(
                'success',
                SUCCESS_LOGIN_TITLE + response.data.nombreUsuario,
                SUCCESS_LOGIN_MESSAGE
              )
              this.router.navigate([`/app/dashboard`])
              break
          }
          this.validateForm.enable()
          this.loading = false
        },
        (error) => {
          switch (error.status) {
            case 400:
              this.notification.create(
                'warning',
                ERROR_LOGIN_TITLE,
                ERROR_LOGIN_MESSAGE
              )
              break
            case 423:
              this.router.navigate([`/activate-account/${request.email}`])
              break
            case 500:
            default:
              this.notification.create(
                'error',
                ERROR_SERVICE_TITLE,
                ERROR_SERVICE_MESSAGE
              )
              break
          }

          this.validateForm.enable()
          this.loading = false
        }
      )
    }
  }

  private removeValidations(): void {
    Object.keys(this.validateForm.controls).forEach((controlName) => {
      const control = this.validateForm.controls[controlName]
      control.clearValidators()
      control.updateValueAndValidity()
    })
  }
}
