<div class="container">
  <div class="row">
    <div class="col-12 p-0">
      <nz-ribbon
        [nzText]="(questionFacade.question$ | async)?.type ?? '<'"
        nzColor="purple"
      >
        <div class="custom-dropdown">
          <div class="row">
            <div
              class="col-12 d-inline-flex"
              [ngClass]="
                questionSelectedId !== '0' ? ' col-12' : 'col-lg-8 col-x2l-6'
              "
            >
              <em nz-icon [nzType]="'message'" [nzTheme]="'twotone'"></em>
              <select
                class="custom-dropdown-select"
                name="options"
                [(ngModel)]="questionSelectedId"
                (ngModelChange)="getIndicators(questionSelectedId)"
                [disabled]="!surveySelected"
              >
                <ng-container *ngIf="!isLoading; else loading">
                  <option [value]="'0' || 0">Todas las preguntas</option>
                  <option
                    *ngFor="let question of questionsSurvey"
                    [value]="question.questionId"
                  >
                    {{ question.title }}
                  </option>
                </ng-container>
                <ng-template #loading>
                  <option value="" disabled>Cargando preguntas...</option>
                </ng-template>
              </select>
            </div>
            <div
              *ngIf="questionSelectedId === '0'"
              class="col-12 col-lg-4 col-x2l-6 mx-auto align-self-center"
            >
              <span class="d-block text-center">
                Selecciona una de las preguntas para ver sus resultados en la
                encuesta
              </span>
            </div>
          </div>
        </div>
      </nz-ribbon>
    </div>

    <div
      class="detail-question-options mt-2"
      *ngIf="questionSelectedId !== '0'"
    >
      <div class="row mx-auto align-self-center text-lg-center text-x2l-left">
        <div class="option-question-container col-12 col-md-4">
          <span>Pregunta Activa:</span>
          <nz-switch
            class="switch-control"
            [(ngModel)]="questionSelected.isActive"
            [nzControl]="true"
            (click)="changeOption(questionSelected, 'isActive')"
          ></nz-switch>
        </div>
        <div class="option-question-container col-12 col-md-4">
          <span>Pregunta Obligatoria:</span>
          <nz-switch
            class="switch-control"
            [(ngModel)]="questionSelected.isMandatory"
            [nzControl]="true"
            (click)="changeOption(questionSelected, 'isMandatory')"
          ></nz-switch>
        </div>
        <div
          class="option-question-container col-12 col-md-4"
          *ngIf="questionSelected.typeId === 1 || questionSelected.typeId === 2"
        >
          <span>Habilitar Comentarios:</span>
          <nz-switch
            class="switch-control"
            [(ngModel)]="questionSelected.commentAvailable"
            [nzControl]="true"
            (click)="changeOption(questionSelected, 'commentAvailable')"
          ></nz-switch>
        </div>
      </div>
    </div>

    <!-- CSAT -->
    <app-csat-result
      class="w-100"
      *ngIf="(questionFacade.question$ | async)?.typeId === 1"
      [hidden]="(questionFacade.question$ | async)?.typeId !== 1"
    ></app-csat-result>

    <!-- NPS -->
    <app-nps-result
      class="w-100"
      *ngIf="(questionFacade.question$ | async)?.typeId === 2"
      [hidden]="(questionFacade.question$ | async)?.typeId !== 2"
    ></app-nps-result>

    <!-- Multiple -->
    <app-option-result
      class="w-100"
      *ngIf="(questionFacade.question$ | async)?.typeId === 3"
      [hidden]="(questionFacade.question$ | async)?.typeId !== 3"
    ></app-option-result>

    <!-- Unica -->
    <app-option-result
      class="w-100"
      *ngIf="(questionFacade.question$ | async)?.typeId === 4"
      [hidden]="(questionFacade.question$ | async)?.typeId !== 4"
    ></app-option-result>

    <!-- Input Text -->
    <app-comments-result
      class="w-100 mt-3"
      *ngIf="(questionFacade.question$ | async)?.typeId === 5"
      [summaryResult]="null"
      [isLoadingSummary]="null"
    ></app-comments-result>

    <!-- Input Area -->
    <app-comments-result
      class="w-100 mt-3"
      *ngIf="(questionFacade.question$ | async)?.typeId === 6"
      [summaryResult]="null"
      [isLoadingSummary]="null"
    ></app-comments-result>

    <app-survey-result
      class="w-100"
      [hidden]="(questionFacade.question$ | async) !== null"
    ></app-survey-result>
  </div>
</div>
