<div class="bill-form-request__information-alert">
  <nz-alert
    nzType="info"
    nzMessage="Antes de solicitar factura revisa tus datos de facturación"
    nzDescription="Una vez solicites la factura esta estará disponible dentro de las próximos dos días hábiles. Te notificaremos cuando esta se encuentre disponble!"
    nzShowIcon
  ></nz-alert>
</div>
<h5>Datos de facturación</h5>
<form
  nz-form
  [formGroup]="form"
  nzLayout="vertical"
  class="login-form"
  (ngSubmit)="submitForm()"
>
  <nz-form-item>
    <nz-form-label nzRequired nzFor="note" [nzSpan]="24"
      >Nombre de Empresa</nz-form-label
    >
    <nz-form-control nzErrorTip="Ingresa nombre de empresa" [nzSpan]="24">
      <input
        type="text"
        nz-input
        formControlName="companyName"
        placeholder="Ingresa nombre de empresa"
        maxlength="70"
      />
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label nzRequired nzFor="note" [nzSpan]="24"
      >Email de facturación secundario</nz-form-label
    >
    <nz-form-control
      nzErrorTip="Ingresa Email de facturación secundario"
      [nzSpan]="24"
    >
      <input
        type="text"
        nz-input
        formControlName="billingEmail"
        placeholder="example@example.cl"
        maxlength="70"
      />
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label nzRequired nzFor="note" [nzSpan]="24">Giro</nz-form-label>
    <nz-form-control nzErrorTip="Ingresa el giro" [nzSpan]="24">
      <input
        type="text"
        nz-input
        formControlName="scopeBusiness"
        placeholder="Ingrese el giro"
        maxlength="70"
      />
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label nzRequired nzFor="note" [nzSpan]="24"
      >RUT o Número de registro fiscal</nz-form-label
    >
    <nz-form-control
      nzErrorTip="Ingresa el número de registro fiscal"
      [nzSpan]="24"
    >
      <input
        type="text"
        nz-input
        formControlName="taxRegisterNumber"
        placeholder="Ingrese el número de registro fiscal"
        maxlength="15"
      />
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label nzRequired nzFor="note" [nzSpan]="24"
      >Dirección</nz-form-label
    >
    <nz-form-control nzErrorTip="Ingresa la dirección" [nzSpan]="24">
      <input
        type="text"
        nz-input
        formControlName="address"
        placeholder="Ingrese su dirección"
        maxlength="70"
      />
    </nz-form-control>
  </nz-form-item>

  <nz-form-item class="button-create">
    <nz-form-control>
      <button
        [disabled]="(loadingJiraTicketRequest$ | async) === true"
        (click)="submitForm()"
        type="submit"
        class="btn btn-primary d-block ml-auto mt-3"
      >
        <i
          *ngIf="(loadingJiraTicketRequest$ | async) === true"
          nz-icon
          [nzType]="'loading'"
        ></i>
        <span *ngIf="(loadingJiraTicketRequest$ | async) === true" class="ml-2"
          >Guardando</span
        >
        <span
          *ngIf="(loadingJiraTicketRequest$ | async) === false"
          class="d-flex align-items-center"
          >{{ nameButton }}
        </span>
      </button>
    </nz-form-control>
  </nz-form-item>
</form>
