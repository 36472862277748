import { Component } from '@angular/core'
import { Router } from '@angular/router'
import { first } from 'rxjs/operators'
import { PaymentFacade } from '../../../../store/payment/payment.facade'
import { environment } from './../../../../../environments/environment'

@Component({
  selector: 'app-payment-failed',
  templateUrl: './payment-failed.component.html',
  styleUrls: ['./payment-failed.component.scss'],
})
export class PaymentFailedComponent {
  public errorImage: string = '../../../../../assets/images/icons/cancel.png'
  public routerLink: string

  constructor(private paymentFacade: PaymentFacade, private router: Router) {}

  async redirectTryPayment() {
    const planSelected = await this.paymentFacade.planSelected$
      .pipe(first())
      .toPromise()
    const planSelectedId =
      planSelected && Object.keys(planSelected).length !== 0
        ? planSelected.planId
        : localStorage.getItem(environment.plan_selected_id)

    if (planSelectedId) {
      this.routerLink = `/app/plans-prices/payment/${planSelectedId}/0`
    } else {
      this.routerLink = '/app/plans-prices'
    }
    this.router.navigate([this.routerLink])
  }
}
