import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { NgZorroAntdModule } from '../ng-zorro-antd.module'
import { ComponentsModule } from './../components/components.module'

import { PipesModule } from '../pipes/pipes.module'
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component'
import { ConfigSiteDevComponent } from './config-site-dev/config-site-dev.component'
import { FeatureFlagsComponent } from './config-site-dev/feature-flags/feature-flags.component'
import { PropertiesComponent } from './config-site-dev/properties/properties.component'
import { ConfigSurveyMfComponent } from './config-survey-mf/config-survey-mf.component'
import { HeaderComponent } from './header/header.component'
import { SidebarComponent } from './sidebar/sidebar.component'

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NgZorroAntdModule,
    ComponentsModule,
    ReactiveFormsModule,
    FormsModule,
    PipesModule,
  ],
  declarations: [
    HeaderComponent,
    SidebarComponent,
    BreadcrumbsComponent,
    ConfigSiteDevComponent,
    ConfigSurveyMfComponent,
    FeatureFlagsComponent,
    PropertiesComponent,
  ],
  exports: [
    HeaderComponent,
    SidebarComponent,
    BreadcrumbsComponent,
    ConfigSiteDevComponent,
    ConfigSurveyMfComponent,
  ],
})
export class SharedModule {}
