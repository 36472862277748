import { Component, EventEmitter, Input, Output } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { Router } from '@angular/router'
import { formatDistance } from 'date-fns'
import { es } from 'date-fns/locale'
import { SurveyService } from 'src/app/services/pages/survey.service'
import { IMassiveSurveyQuestionRequest } from './../../../../models/request/IMassiveSurveyQuestion'
import { celebrate } from './../../../../utils/animations/confetti'
@Component({
  selector: 'app-survey-form-massive',
  templateUrl: './survey-form-massive.component.html',
  styleUrls: ['./survey-form-massive.component.scss'],
})
export class SurveyFormMassiveComponent {
  public IMG_EMPATI_BOT = '../../../../../assets/images/profile/empati-ia.png'

  @Output() isSurveyGeneratedChange = new EventEmitter<boolean>()
  @Input() isSurveyGenerated: boolean
  @Input() surveyIAResult: IMassiveSurveyQuestionRequest

  isLoadingCreate: boolean = false
  isVisibleModal = false
  editForm: FormGroup
  editId: string | null = null
  likes = 0
  dislikes = 0
  time = formatDistance(new Date(), new Date(), { locale: es })

  constructor(private surveyService: SurveyService, private router: Router) {}

  generateAnotherSurveyIA(): void {
    this.isSurveyGeneratedChange.emit(false)
  }

  like(): void {
    this.likes = 1
    this.dislikes = 0
  }

  dislike(): void {
    this.likes = 0
    this.dislikes = 1
  }

  get questionsFormGroups() {
    return this.editForm.get('questions')['controls']
  }

  createSurveyIA(): void {
    this.isLoadingCreate = true
    this.surveyService.createMassiveSurvey(this.surveyIAResult).subscribe(
      (response) => {
        if (response.status.code === 201) {
          this.isVisibleModal = false
          this.isLoadingCreate = false
          celebrate()
          this.router.navigate([`/app/survey/${response.data.surveyId}`])
        }
      },
      (error) => {
        this.isVisibleModal = false
        this.isLoadingCreate = false
      }
    )
  }
}
