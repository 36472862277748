import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-csat-response-item',
  templateUrl: './csat-response-item.component.html',
  styleUrls: ['./csat-response-item.component.scss'],
})
export class CsatResponseItemComponent {
  @Input() csatScore: number | null = null
  @Input() csatType: number = 1

  public tootltipScore: string

  getCsatIcon(): string {
    this.tootltipScore = String(this.csatScore)
    if (this.csatType === 1) {
      switch (this.csatScore) {
        case 1:
          return 'frown'
        case 2:
          return 'meh'
        case 3:
          return 'meh'
        case 4:
          return 'smile'
        case 5:
          return 'smile'
        default:
          return ''
      }
    } else if (this.csatType === 2) {
      return 'star'
    } else {
      return ''
    }
  }

  getCsatColor(): string {
    if (this.csatType === 1) {
      const colors = ['#f44336', '#ff9800', '#ffc107', '#8bc34a', '#4caf50']
      return colors[this.csatScore - 1]
    } else {
      return ''
    }
  }
}
