import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core'
import * as Highcharts from 'highcharts'
import { first } from 'rxjs/operators'
import { SurveyFacade } from 'src/app/store/store.index'
import { ITrendingSummary } from './../../models/request/ITrendingSummary'
import { IResponse } from './../../models/response/IResponse'
import { ReportType, ReportWeekType } from './../../models/types/report.types'
import { chartOptionsDoughnutAbandonedRate } from './../../pages/survey/survey-report/mappersCharts/doughnut.mapper'
import { DashboardFacade } from './../../store/dashboard/dashboard.facade'
import { QuestionFacade } from './../../store/question/question.facade'
import { ReportFacade } from './../../store/reports/report.facade'

const weekDays = {
  Sunday: { es: 'Domingo', en: 'Sunday' },
  Monday: { es: 'Lunes', en: 'Monday' },
  Tuesday: { es: 'Martes', en: 'Tuesday' },
  Wednesday: { es: 'Miércoles', en: 'Wednesday' },
  Thursday: { es: 'Jueves', en: 'Thursday' },
  Friday: { es: 'Viernes', en: 'Friday' },
  Saturday: { es: 'Sábado', en: 'Saturday' },
}

const TITLE_SURVEY = 'Actividad Semanal de Encuestas Completadas'
const SUBTITLE_SURVEY =
  'Recuento total de encuestas completadas durante la última semana'

const TITLE_QUESTION = 'Actividad Semanal de Preguntas Respondidas'
const SUBTITLE_QUESTION =
  'Recuento total de preguntas respondidas durante la última semana'

@Component({
  selector: 'app-survey-result',
  templateUrl: './survey-result.component.html',
  styleUrls: ['./survey-result.component.scss'],
})
export class SurveyResultComponent implements OnInit, OnChanges {
  @Output() isSubmitFormChange = new EventEmitter<boolean>()
  @Input() isSubmitForm: boolean = true
  @Input() autoFetchData: boolean = true
  @Input() surveyId: string = null
  @Input() questionId: string = null
  @Input() startDate: string = null
  @Input() endDate: string = null

  Highcharts: typeof Highcharts = Highcharts

  public donusChartOptionsAbandonedRate
  public donusChartOptionsComments

  public typeReport: ReportType = 'surveys'
  public title: string
  public subtitle: string

  public typeReportWeek: ReportWeekType = 'monthly'
  public surveySelectedId: string = '0'
  public isLoadingSummary = true
  public summaryResult

  constructor(
    public questionFacade: QuestionFacade,
    private surveyFacade: SurveyFacade,
    public dashboardFacade: DashboardFacade,
    public reportFacade: ReportFacade
  ) {}

  ngOnInit(): void {
    if (this.autoFetchData) {
      this.getTrendingSummary()
      this.getWeekResponsesDataReport(this.typeReport)
      this.changeTitleChart()
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      (this.surveyId !== changes?.surveyId?.currentValue ||
        this.questionId !== changes?.questionId?.currentValue ||
        this.startDate !== changes?.startDate?.currentValue ||
        this.endDate !== changes?.endDate?.currentValue) &&
      this.isSubmitForm
    ) {
      this.getTrendingSummary()
      this.isSubmitFormChange.emit(false)
    }
  }

  private changeTitleChart(): void {
    this.title = this.typeReport === 'surveys' ? TITLE_SURVEY : TITLE_QUESTION
    this.subtitle =
      this.typeReport === 'surveys' ? SUBTITLE_SURVEY : SUBTITLE_QUESTION
  }

  public async getTrendingSummary(): Promise<void> {
    const survey = await this.surveyFacade.survey$.pipe(first()).toPromise()

    const surveyId = this.surveyId ?? survey.surveyId

    const request: ITrendingSummary = {
      surveyId,
      startDate: this.startDate,
      endDate: this.endDate,
    }

    this.isLoadingSummary = true

    this.reportFacade
      .geTrendingSummarySurveyFetch(request)
      .subscribe((res: IResponse) => {
        if (res.status.code === 200) {
          this.summaryResult = res.data

          const { completedSurveys, abandonedSurveys, abandonRate } =
            this.summaryResult

          this.donusChartOptionsAbandonedRate =
            chartOptionsDoughnutAbandonedRate(
              ['Encuestas Completadas', 'Encuestas Abandonadas'],
              [completedSurveys, abandonedSurveys],
              abandonRate
            )

          this.isLoadingSummary = false
        }
      })
  }

  public async getWeekResponsesDataReport(event: ReportType): Promise<void> {
    this.typeReport = event
    const survey = await this.surveyFacade.survey$.pipe(first()).toPromise()

    this.dashboardFacade.getWeekResponsesDataReportFetch(
      survey.surveyId,
      this.typeReport
    )
  }
}
