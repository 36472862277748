import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { AppRoutingModule } from './app-routing.module'
import { ComponentsModule } from './components/components.module'
import { AuthInterceptor } from './interceptors/auth.interceptor'
import { PagesModule } from './pages/pages.module'
import { QuestionModule } from './pages/question/question.module'
import { SessionModule } from './pages/session/session.module'
import { ServiceModule } from './services/service.module'
import { StoreModule } from './store/store.module'

import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin'
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin'
import { NgxsModule } from '@ngxs/store'
import { environment } from 'src/environments/environment'

import { registerLocaleData } from '@angular/common'
import localeEs from '@angular/common/locales/es'
import { NZ_I18N, es_ES } from 'ng-zorro-antd/i18n'
registerLocaleData(localeEs)

import { AppComponent } from './app.component'
import { SharedModule } from './shared/shared.module'
import { DashboardState } from './store/dashboard/dashboard.state'
import { PaymentState } from './store/payment/payment.state'
import { PlanState } from './store/plan/plan.state'
import { QuestionState } from './store/question/question.state'
import { ReportState } from './store/reports/report.state'
import { ResponsesState } from './store/responses/responses.state'
import { SessionState } from './store/session/session.state'
import { SurveyState } from './store/survey/survey.state'
import { UserState } from './store/user/user.state'

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    PagesModule,
    QuestionModule,
    ComponentsModule,
    SessionModule,
    ServiceModule,
    StoreModule,
    SharedModule,
    NgxsModule.forRoot(
      [
        SessionState,
        UserState,
        SurveyState,
        QuestionState,
        PlanState,
        PaymentState,
        ReportState,
        DashboardState,
        ResponsesState,
      ],
      {
        developmentMode: !environment.production,
      }
    ),
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: environment.production,
    }),
    NgxsLoggerPluginModule.forRoot({
      disabled: !environment.redux_logger,
    }),
  ],
  providers: [
    { provide: NZ_I18N, useValue: es_ES },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
