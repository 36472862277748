import { optionsChartDefault } from './../../../../utils/properties/hightchartOptions'

export const chartOptionsTendencyResponses = (
  categories: string[],
  dataSeriesCurrent: any[],
  dataSeriesPrev: any[]
) => {
  return {
    lang: optionsChartDefault.lang,
    chart: {
      type: 'areaspline',
      renderTo: 'container',
    },
    title: {
      text: 'Tendencia experiencia satisfacción del usuario',
    },
    subtitle: {
      align: 'center',
      text: 'Última semana',
    },
    legend: {
      layout: 'vertical',
      align: 'left',
      verticalAlign: 'top',
      x: 120,
      y: 70,
      floating: true,
      borderWidth: 1,
    },
    xAxis: {
      title: {
        text: 'Días de la semana',
      },
      categories: categories,
    },
    yAxis: {
      title: {
        text: 'Experiencia del usuario escala CSAT',
      },
    },
    tooltip: {
      shared: true,
      headerFormat: '<b>Comparación de la experiencia el {point.x}</b><br>',
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      areaspline: {
        fillOpacity: 0.5,
      },
    },
    reflow: true,
    width: null,
    series: [
      {
        name: 'Semana Actual',
        data: dataSeriesCurrent,
        animation: {
          duration: 2000,
          easing: 'easeOutBounce',
        },
      },
      {
        name: 'Semana Anterior',
        data: dataSeriesPrev,
        animation: {
          duration: 2000,
          easing: 'easeOutBounce',
        },
      },
    ],
  }
}
