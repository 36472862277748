<div class="card-info-delta">
  <div class="card-info-delta-title">
    <em
      nz-icon
      class="mr-1"
      [nzType]="'info-circle'"
      [nzTheme]="'twotone'"
    ></em>
    {{ title }}
  </div>
  <div *ngIf="actionText" class="card-info-delta-subtitle">
    {{ description | slice: 0:actionStartIndex }}
    <a
      *ngIf="actionText"
      href="#"
      (click)="$event.preventDefault(); onActionClick()"
    >
      {{ actionText }}
    </a>
    {{ description | slice: actionEndIndex:description.length }}
  </div>

  <div *ngIf="!actionText" class="card-info-delta-subtitle">
    {{ description }}
  </div>
</div>
