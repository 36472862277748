<div class="container-fluid animated-fade-in">
  <h4>Mis Pagos</h4>
  <nz-spin [nzSpinning]="paymentFacade.isLoadingMyPayments$ | async">
    <nz-table
      #dynamicTable
      class="table-basic animated-fade-in mt-5"
      cellspacing="0"
      summary="Mis Pagos"
      [nzData]="myPayments"
      [nzShowPagination]="false"
      [nzFrontPagination]="false"
    >
      <thead>
        <tr>
          <th>ID</th>
          <th>Fecha</th>
          <th>Monto</th>
          <th>Detalle</th>
          <th>Estado</th>
          <th></th>
        </tr>
      </thead>
      <tbody cdkDropList>
        <tr *ngFor="let item of myPayments">
          <td># {{ item.id }}</td>
          <td>
            {{ item.date | date: 'dd-MM-YYYY' }}
            {{ item.date | date: 'HH:mm a' }}
          </td>
          <td>$ {{ item.amount | number: '':'es-CL' }}</td>
          <td>{{ item.detail }}</td>
          <td>
            <nz-badge
              [nzStatus]="getTypeIconStatusPayment(item.statusPayment)"
              [nzText]="getStatusPayment(item.statusPayment)"
            ></nz-badge>
          </td>
          <td>
            <a class="general-table__options">
              <img
                class="option-icon"
                src="{{ ICON_OPTION }}"
                nz-dropdown
                nzTrigger="click"
                [nzDropdownMenu]="menu"
                nz-icon
                nzType="down"
                alt=""
              />
            </a>
            <nz-dropdown-menu #menu="nzDropdownMenu">
              <ul nz-menu *ngIf="item.statusPayment.id === '2'">
                <li
                  nz-menu-item
                  type="submit"
                  (click)="downloadVoucher(item.id)"
                >
                  Descargar Comprobante
                </li>
                <li
                  nz-menu-item
                  *ngIf="item.statusBilling.id === '1'"
                  (click)="showBillingFormModal()"
                >
                  Solicitar Factura
                </li>
                <li nz-menu-item *ngIf="item.statusBilling.id === '2'">
                  Factura solicitada
                </li>
                <li
                  nz-menu-item
                  *ngIf="item.statusBilling.id === '3'"
                  (click)="downloadBilling()"
                >
                  Descargar Factura
                </li>
              </ul>
              <ul nz-menu *ngIf="item.statusPayment.id !== '2'">
                <li nz-menu-item disabled="true">
                  No hay opciones disponibles
                </li>
              </ul>
            </nz-dropdown-menu>
          </td>
        </tr>
      </tbody>
    </nz-table>
    <div class="text-center mt-4 mb-3">
      <nz-pagination
        [(nzPageIndex)]="pageIndex"
        [(nzPageSize)]="pageSize"
        [nzTotal]="total"
        (nzPageIndexChange)="getMyPaymentsDataPerPage($event)"
      ></nz-pagination>
    </div>
  </nz-spin>
</div>

<nz-modal
  [nzFooter]="null"
  [(nzVisible)]="isVisibleBillingFormModal"
  (nzOnCancel)="handleBillingFormModalCancel()"
  (nzOnOk)="handleBillingFormModalOk()"
  [nzWidth]="1000"
  [nzBodyStyle]="{ padding: '56px' }"
  [nzWrapClassName]="
    this.isVisibleSuccesfulBillRequest
      ? 'modal-succesful-bill-Request modal-succesful-bill-close-button'
      : ''
  "
>
  <ng-container *nzModalContent>
    <div *ngIf="isVisibleSuccesfulBillRequest === false">
      <app-bill-form-request
        (sendBillRequest)="handleBillingFormModalOk()"
      ></app-bill-form-request>
    </div>

    <div
      *ngIf="
        isVisibleSuccesfulBillRequest === true &&
        (jiraFacade.idTicketCreated$ | async) !== ''
      "
    >
      <h5>Hemos enviado tu solicitud</h5>
      <p>
        Tu factura estará disponible dentro de los próximos dos días hábiles. Te
        notificaremos cuando esta se encuentre disponible
      </p>
      <p>Numero de solicitud: {{ jiraFacade.idTicketCreated$ | async }}</p>
      <div class="d-block mt-5">
        <div class="d-flex justify-content-end align-items-center">
          <button
            [disabled]="loading"
            class="btn btn-primary btn-rounded ml-3"
            (click)="handleBillingFormModalCancel()"
          >
            <span *ngIf="loading" class="d-flex align-items-center">
              <span class="mr-2">Guardando</span>
              <i nz-icon [nzType]="'loading'"></i>
            </span>
            <span *ngIf="!loading" class="d-flex align-items-center">
              <span class="mr-2">
                {{ nameButtonSuccessFulBillingRequestModal }}
              </span>
            </span>
          </button>
        </div>
      </div>
    </div>
  </ng-container>
</nz-modal>
