import { Component, OnInit } from '@angular/core'
import { PlanFacade } from './../../../store/plan/plan.facade'

@Component({
  selector: 'app-plan-types',
  templateUrl: './plan-types.component.html',
  styleUrls: ['./plan-types.component.scss'],
})
export class PlanTypesComponent implements OnInit {
  options = ['Mensual', 'Anual']
  optionSelected = 0
  typeSuscription = 'monthly'

  constructor(public planFacade: PlanFacade) {
    this.planFacade.obtainPlansDetailFetch()
  }

  ngOnInit(): void {}

  changeOption(option: string) {
    this.optionSelected = +option
    this.typeSuscription = this.optionSelected === 0 ? 'monthly' : 'anual'
  }
}
