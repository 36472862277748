import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'shortText',
})
export class ShortTextPipe implements PipeTransform {
  transform(value: string, start: number, end?: number): string {
    if (value == null) {
      return value
    }

    if (!this.supports(value)) {
      console.error('error pipe textShort')
    }

    const texto = value.split('')

    if (texto.length >= end) {
      return value.slice(start, end) + ' ...'
    } else {
      return value.slice(start, end)
    }
  }

  private supports(obj): boolean {
    return typeof obj === 'string' || Array.isArray(obj)
  }
}
