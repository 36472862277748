import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Store } from '@ngxs/store'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { ITokenUpdatePassword } from 'src/app/models/request/ITokenUpdatePassword'
import { IResponse } from 'src/app/models/response/IResponse'
import { IUser } from 'src/app/models/response/IUser'
import { environment } from 'src/environments/environment'
import { ITokenActivateAccount } from './../../models/request/ITokenActivateAccount'
import {
  UpdateLoadingSession,
  UpdateLoadingSessionPage,
} from './../../store/session/session.actions'
import { encryptKeyStorage } from './../../utils/converts/encrypt'

const ACCESS_TOKEN = environment.access_token

@Injectable({ providedIn: 'root' })
export class TokenService {
  protected api: string
  public user: IUser
  public loadingSession: boolean
  public loadingPageInfo: boolean

  constructor(
    private router: Router,
    public http: HttpClient,
    private store: Store
  ) {
    this.api = environment.api
    this.loadingSession = true
    this.loadingPageInfo = true
  }

  signOut(): void {
    if (environment.env !== 'dev') {
      localStorage.clear()

      return
    }
    // Paso 1: Cifrar las claves
    const encryptedKey1 = encryptKeyStorage('domain')
    const encryptedKey2 = encryptKeyStorage('api_domain')

    // Paso 2: Guardar los valores que no quieres eliminar
    const keep1 = localStorage.getItem(encryptedKey1)
    const keep2 = localStorage.getItem(encryptedKey2)

    // Paso 3: Limpiar el localStorage
    localStorage.clear()

    // Paso 4: Restaurar las claves cifradas y sus valores
    localStorage.setItem(encryptedKey1, keep1)
    localStorage.setItem(encryptedKey2, keep2)
  }

  public getToken(): string {
    return localStorage.getItem(ACCESS_TOKEN)
  }

  public saveToken(token: string): void {
    window.localStorage.removeItem(ACCESS_TOKEN)
    window.localStorage.setItem(ACCESS_TOKEN, token)
  }
  public deleteTokenAndLogout(): void {
    window.localStorage.removeItem(ACCESS_TOKEN)
    window.localStorage.removeItem('573P14n')
    window.localStorage.removeItem('user')
    window.localStorage.removeItem('1dUs34')
    window.localStorage.removeItem('1dCl13')
    this.router.navigate([`/login`])
    this.loadingSession = false
    this.loadingPageInfo = false
  }

  public async isAuthenticated(): Promise<boolean> {
    if (environment.env === 'dev') {
      this.store.dispatch(new UpdateLoadingSession(false))
      this.store.dispatch(new UpdateLoadingSessionPage(false))
      return true
    }
    const token: string = localStorage.getItem(ACCESS_TOKEN)
    if (!token) this.deleteTokenAndLogout()

    this.store.dispatch(new UpdateLoadingSessionPage(true))

    this.fetchIsAuthenticated().subscribe(
      (response: IResponse) => {
        if (response.status.code === 200) {
          this.store.dispatch(new UpdateLoadingSession(false))
          this.store.dispatch(new UpdateLoadingSessionPage(false))
          return true
        }
        this.deleteTokenAndLogout()
        return false
      },
      (error) => {
        this.deleteTokenAndLogout()
        return false
      }
    )

    return false
  }

  public validateTokenToUpdatePass(
    request: ITokenUpdatePassword
  ): Observable<IResponse> {
    const uri = `${this.api}/v1/public/mail/validate-recovery-account`
    return this.http.post(uri, request).pipe(map((data: IResponse) => data))
  }

  public validateTokenToActivateAccount(
    request: ITokenActivateAccount
  ): Observable<IResponse> {
    const uri = `${this.api}/v1/public/validate-account`
    return this.http.post(uri, request).pipe(map((data: IResponse) => data))
  }

  private fetchIsAuthenticated(): Observable<IResponse> {
    const uri = `${this.api}/user/validate`
    return this.http.get(uri).pipe(map((data: IResponse) => data))
  }
}
