import { Component, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal'
import { NzTabsCanDeactivateFn } from 'ng-zorro-antd/tabs'
import { Observable } from 'rxjs-compat'
import { first } from 'rxjs/operators'
import { QuestionFacade } from 'src/app/store/question/question.facade'
import { ReportFacade } from 'src/app/store/reports/report.facade'
import { SurveyFacade } from '../../../store/survey/survey.facade'
import { DomObserverService } from './../../../services/observer/dom-observer.service'
import { DesignSurveyComponent } from './design-survey/design-survey.component'
import { OptionSurveyComponent } from './option-survey/option-survey.component'

interface IOptions {
  label: string
  value: number
}

const OPTIONS = [
  { label: 'Vista Previa', value: 0, icon: 'layout' },
  { label: 'Resultados', value: 1, icon: 'pie-chart' },
]
@Component({
  selector: 'app-survey-detail',
  templateUrl: './survey-detail.component.html',
  styleUrls: ['./survey-detail.component.scss'],
})
export class SurveyDetailComponent implements OnInit {
  @ViewChild(DesignSurveyComponent) desingComponent: DesignSurveyComponent
  @ViewChild(OptionSurveyComponent) optionComponent: OptionSurveyComponent

  public confirmModal?: NzModalRef

  public surveyId: string
  public viewSurveyType: number
  public tabSelected: string
  public dispositiveType: string
  public options: IOptions[]
  public isSettingsChanged = false
  public isDesignSettingsChanged = false
  public titleTabInfo: string
  public descriptionTabInfo: string

  constructor(
    public activateRoute: ActivatedRoute,
    public surveyFacade: SurveyFacade,
    public questionFacade: QuestionFacade,
    private reportFacade: ReportFacade,
    private modal: NzModalService,
    private domObserver: DomObserverService
  ) {
    this.surveyId = this.activateRoute.snapshot.params.surveyId
    this.viewSurveyType = 0
    this.tabSelected = 'questions'
    this.dispositiveType = 'desktop'
    this.options = OPTIONS

    this.getTitleAndDescriptionTabInfo()
    this.surveyFacade.sendSurveyMfConfig()
  }

  ngOnInit(): void {
    this.domObserver.observeElementById(
      'survey-mf',
      async (element: HTMLIFrameElement) => {
        const question = await this.questionFacade.question$
          .pipe(first())
          .toPromise()
        if (element && element.contentWindow) {
          element.contentWindow.postMessage(
            { question, type: 'QUESTION', isVisibleEditForm: false },
            '*'
          )
        }
      }
    )
  }

  public async onViewSurveyTypeChange(
    newViewSurveyType: number
  ): Promise<void> {
    if (newViewSurveyType === 1) {
      this.questionFacade.updateQuestion(null)
    }
  }

  updateViewSurveyType(value: number): void {
    setTimeout(() => {
      this.viewSurveyType = value
    })
  }

  onTabSelectedChange(newTabSelected: string): void {
    if (newTabSelected !== 'questions') {
      this.viewSurveyType = 0
    }

    this.tabSelected = newTabSelected
    this.getTitleAndDescriptionTabInfo()
  }

  public canDeactivate: NzTabsCanDeactivateFn = (fromIndex: number) => {
    const confirmMessage = fromIndex === 1 ? 'el diseño' : 'las opciones'
    const confirmContent = `Has realizado cambios en ${confirmMessage} de tu encuesta. Si sales ahora, todos los cambios se perderán. ¿Estás seguro de que quieres continuar?`

    if (fromIndex === 1 && this.isDesignSettingsChanged) {
      return new Observable((observer) => {
        this.confirmModal = this.modal.confirm({
          nzTitle: '¿Deseas salir sin guardar?',
          nzContent: confirmContent,
          nzOnOk: () => {
            this.desingComponent.resetConfigurationSurvey()

            this.isDesignSettingsChanged = false
            observer.next(true)
            observer.complete()
          },
          nzOnCancel: () => {
            observer.next(false)
            observer.complete()
          },
        })
      })
    }
    if (fromIndex === 2 && this.isSettingsChanged) {
      return new Observable((observer) => {
        this.confirmModal = this.modal.confirm({
          nzTitle: '¿Deseas salir sin guardar?',
          nzContent: confirmContent,
          nzOnOk: () => {
            this.optionComponent.resetConfigurationSurvey()

            this.isDesignSettingsChanged = false
            observer.next(true)
            observer.complete()
          },
          nzOnCancel: () => {
            observer.next(false)
            observer.complete()
          },
        })
      })
    }
    return true
  }

  private getTitleAndDescriptionTabInfo(): void {
    switch (this.tabSelected) {
      case 'questions':
        this.titleTabInfo = 'Ordena tus preguntas'
        this.descriptionTabInfo =
          'Arrastra y suelta las preguntas para reorganizarlas en el orden que prefieras. ¡Es fácil y rápido! Una vez que estés satisfecho con el orden, asegúrate de guardar tus cambios.'
        break
      case 'design':
        this.titleTabInfo = 'Personaliza el diseño'
        this.descriptionTabInfo = `Ofrece una experiencia visual atractiva con formatos que van desde 'Una pregunta a la vez' hasta 'Clásico', y fondos personalizables. Y no olvides cargar tu logo, lo que según estudios incrementa el reconocimiento de marca en un 80%. Así, cada encuesta se convierte en una extensión de tu imagen corporativa.`
        break
      case 'options':
        this.titleTabInfo = 'Configura las Opciones Avanzadas'
        this.descriptionTabInfo = `Activa el 'Libro Digital de Comentarios' para obtener feedback al concluir las encuestas y usa el 'Modo Repetición' para encuestas frecuentes. Configura redirecciones tras participar y el 'Modo Tablet' para reinicios automáticos, manteniendo encuestas listas para el siguiente usuario.`
        break
      default:
        break
    }
  }
}
