<div class="nps-box" [ngClass]="getNpsClass()">
  <div class="nps-score">
    <span
      nz-tooltip
      [nzTooltipTrigger]="'hover'"
      [nzTooltipPlacement]="'top'"
      [nzTooltipTitle]="tootltipScore"
      >{{ npsScore }}
    </span>
  </div>
</div>
