import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { IEmailRecovery } from 'src/app/models/request/IEmailRecovery'
import { ILogin } from 'src/app/models/request/ILogin'
import { IRegister } from 'src/app/models/request/IRegister'
import { IUpdatePassword } from 'src/app/models/request/IUpdatePassword'
import { IUpdatePasswordProfile } from 'src/app/models/request/IUpdatePasswordProfile'
import { environment } from 'src/environments/environment'
import { IResponse } from './../../models/response/IResponse'

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  protected api: string

  constructor(public http: HttpClient) {
    this.api = environment.api
  }

  public logIn(request: ILogin): Observable<IResponse> {
    const uri = `${this.api}/v1/public/login`
    return this.http.post(uri, request).pipe(map((data: IResponse) => data))
  }

  public register(request: IRegister): Observable<IResponse> {
    const uri = `${this.api}/v1/public/register`
    return this.http.post(uri, request).pipe(map((data: IResponse) => data))
  }

  public sendEmailRecovery(request: IEmailRecovery): Observable<IResponse> {
    const uri = `${this.api}/v1/public/mail/pass-recovery`
    return this.http.post(uri, request).pipe(map((data: IResponse) => data))
  }

  public updatePassword(request: IUpdatePassword): Observable<IResponse> {
    const uri = `${this.api}/v1/public/update-pass`
    return this.http.post(uri, request).pipe(map((data: IResponse) => data))
  }

  public updatePasswordProfile(
    request: IUpdatePasswordProfile
  ): Observable<IResponse> {
    const uri = `${this.api}/user/password`
    return this.http.put(uri, request).pipe(map((data: IResponse) => data))
  }

  public logoutSession(): Observable<IResponse> {
    const uri = `${this.api}/logout`
    return this.http.get(uri).pipe(map((data: IResponse) => data))
  }

  public sendMailActivationAccount(
    request: IEmailRecovery
  ): Observable<IResponse> {
    const uri = `${this.api}/v1/public/mail/validate-account`
    return this.http.post(uri, request).pipe(map((data: IResponse) => data))
  }
}
