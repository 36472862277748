<div class="plugin animated-fade-in-fast" *ngIf="!hasCookie && showFeedback">
  <button
    class="view-btn active-view m-0 mr-3"
    nz-tooltip
    [nzTooltipTitle]="!isExpanded ? 'Encuesta de Satisfacción' : ''"
    nzTooltipColor="black"
    nzTooltipPlacement="bottom"
    (click)="toggle()"
  >
    <span *ngIf="!response"> {{ isExpanded ? '🤔' : '🙂' }} </span>
    <span *ngIf="response"> {{ !wasSent ? '🤔' : '😄' }} </span>
  </button>
</div>
<div
  *ngIf="isExpanded && !wasSent && !isLoading"
  class="floating-box animated-fade-in-fast"
>
  <ng-container
    [ngSwitch]="this.surveyQuestions[this.currentQuestionIndex].typeId"
  >
    <ng-container *ngIf="shouldRenderComponent; else elseBlock">
      <app-nps-mobile
        *ngSwitchCase="2"
        class="animated-fade-in"
        [question]="surveyQuestions[currentQuestionIndex]"
        [(comment)]="comment"
        [(response)]="response"
        (handleShow)="isExpanded = false"
        (handleSubmit)="handleSubmit()"
        (responseChange)="handleChange($event)"
      ></app-nps-mobile>

      <app-csat-mobile
        *ngSwitchCase="1"
        class="animated-fade-in"
        [question]="surveyQuestions[currentQuestionIndex]"
        [(comment)]="comment"
        [(response)]="response"
        (handleShow)="isExpanded = false"
        (handleSubmit)="handleSubmit()"
        (responseChange)="handleChange($event)"
      ></app-csat-mobile>
    </ng-container>
    <ng-template #elseBlock></ng-template>

    <div *ngSwitchCase="1" class="row justify-content-between animated-fade-in">
      <div class="col-6">
        <em>Muy insatisfecho</em>
      </div>
      <div class="col-6 text-end">
        <em>Muy satisfecho</em>
      </div>
    </div>
    <div *ngSwitchCase="2" class="row justify-content-between animated-fade-in">
      <div class="col-6">
        <em>Probablemente no</em>
      </div>
      <div class="col-6 text-end">
        <em>Seguramente sí</em>
      </div>
    </div>
  </ng-container>

  <div *ngIf="showComment" class="mt-3 animated-fade-in">
    <textarea
      class="form-control animated-fade-in"
      [(ngModel)]="comment"
      rows="2"
      maxlength="200"
      [placeholder]="'Te gustaría decirnos algo más'"
      nz-input
    ></textarea>
  </div>

  <div class="plugin-mobile mx-auto" *ngIf="showFeedback">
    <div class="d-block mt-3">
      <div class="d-flex justify-content-end align-items-center">
        <button
          class="btn btn-sm btn-primary btn-rounded ml-3"
          nz-button
          *ngIf="currentQuestionIndex > 0"
          (click)="previousQuestion()"
        >
          Anterior
        </button>
        <button
          class="btn btn-sm btn-primary btn-rounded ml-3"
          nz-button
          *ngIf="currentQuestionIndex < surveyQuestions.length - 1"
          (click)="nextQuestion()"
        >
          Siguiente
        </button>
        <button
          class="btn btn-sm btn-primary btn-rounded ml-3"
          nz-button
          [nzLoading]="loadingSendFeed"
          *ngIf="currentQuestionIndex >= surveyQuestions.length - 1"
          (click)="sendFeedBack()"
        >
          Enviar
        </button>
      </div>
    </div>
  </div>
</div>
<div *ngIf="isExpanded">
  <div *ngIf="isLoading" class="floating-box loader-plugin-container">
    <div class="three-body">
      <div class="three-body__dot"></div>
      <div class="three-body__dot"></div>
      <div class="three-body__dot"></div>
    </div>
  </div>
  <div *ngIf="!isLoading && wasSent" class="floating-box">
    <div class="d-flex justify-content-center align-items-center">
      <div class="d-flex flex-column justify-content-center align-items-center">
        <div class="d-flex justify-content-center align-items-center h3">
          🎉
        </div>
        <div class="d-flex justify-content-center align-items-center">
          <h5 class="text-center">¡Gracias por tu feedback!</h5>
        </div>
        <button
          type="button"
          class="btn btn-sm btn-dark btn-rounded ml-3"
          (click)="this.handleCancel()"
        >
          <span class="d-flex align-items-center">
            <span class="mr"> Cerrar </span>
          </span>
        </button>
      </div>
    </div>
  </div>
</div>
