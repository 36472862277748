import { PaymentFacade } from 'src/app/store/payment/payment.facade'
import { Injectable } from '@angular/core'
import { CanActivate } from '@angular/router'
import { TokenService } from '../services/session/token.service'
import { PAYMENT_STEP_KEY } from '../utils/constants/payment'

@Injectable({
  providedIn: 'root',
})
export class SessionGuard implements CanActivate {
  constructor(
    private _token: TokenService,
    private paymentFacade: PaymentFacade
  ) {}

  canActivate(): boolean {
    this._token.isAuthenticated()
    this.validatePaymentForm()
    return true
  }

  private validatePaymentForm() {
    localStorage.removeItem(PAYMENT_STEP_KEY)
    this.paymentFacade.setStepsDefault()
  }
}
