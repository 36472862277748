<div class="table-basic animated-fade-in">
  <nz-table
    #dynamicTable
    [nzData]="questionFacade.questions$ | async"
    [nzShowPagination]="false"
    [nzFrontPagination]="false"
    [nzPaginationPosition]="'top'"
    [nzLoading]="questionFacade.isLoadingQuestionList$ | async"
    [nzShowSizeChanger]="false"
    [nzBordered]="false"
    [nzSize]="'small'"
  >
    <thead>
      <tr>
        <th>Orden</th>
        <th>Pregunta</th>
        <th>Tipo</th>
        <th nzAlign="right">N° Respuestas</th>
        <th nzAlign="center">Estado</th>
        <th nzAlign="center" nzWidth="100px">Opciones</th>
      </tr>
    </thead>
    <tbody cdkDropList (cdkDropListDropped)="dragDropElement($event)">
      <tr
        *ngFor="let question of questionFacade.questions$ | async; index as i"
        [ngStyle]="!question.isActive && { 'backgorund-color': '#D4D4D4' }"
        cdkDrag
      >
        <td>{{ i + 1 }}</td>
        <td>{{ question.title }}</td>
        <td>{{ question.type }}</td>
        <td>
          <span *ngIf="!question.hasActivity" class="float-right">-</span>
          <nz-badge
            *ngIf="question.hasActivity"
            class="float-right"
            nzStandalone
            [nzCount]="question.countResponses"
            [nzStyle]="{
              backgroundColor: '#32137c',
              color: '#fff',
              boxShadow: '0 0 0 1px #000 inset'
            }"
          ></nz-badge>
        </td>
        <td>
          <nz-switch
            class="d-block text-center"
            [nzSize]="'small'"
            [(ngModel)]="question.isActive"
            [nzControl]="true"
            (click)="changeStatus(question)"
            [nzLoading]="question.isLoadingStatus"
          ></nz-switch>
        </td>
        <td>
          <app-question-options
            [question]="question"
            [questions]="questions"
            [surveyId]="surveyId"
          ></app-question-options>
        </td>
      </tr>
    </tbody>
  </nz-table>
</div>
