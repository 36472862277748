import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Select, Store } from '@ngxs/store'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { Observable } from 'rxjs-compat'
import { IResponse } from 'src/app/models/response/IResponse'
import { IUser } from 'src/app/models/response/IUser'
import { UserService } from 'src/app/services/service.index'
import { UserState } from 'src/app/store/user/user.state'
import {
  disableForm,
  enableForm,
  validateForm,
} from 'src/app/utils/formValidations/formValidations'
import { IUpdateUser } from './../../../../models/request/IUpdateUser'
import { UpdateUser } from './../../../../store/user/user.actions'
import {
  ERROR_SERVICE_MESSAGE,
  ERROR_SERVICE_TITLE,
} from './../../../../utils/constants/generic-messages'
import {
  SUCCESS_UPDATE_PROFILE_MESSAGE,
  SUCCESS_UPDATE_PROFILE_TITLE,
} from './../../../../utils/constants/profile'

@Component({
  selector: 'app-general-form',
  templateUrl: './general-form.component.html',
  styleUrls: ['../edit-profile.component.scss'],
})
export class GeneralFormComponent implements OnInit {
  @Select(UserState.getUser) user$: Observable<IUser>

  public user: IUser
  public form: FormGroup
  public loading: boolean

  constructor(
    private fb: FormBuilder,
    private notification: NzNotificationService,
    private _user: UserService,
    private store: Store
  ) {
    this.loading = false
  }

  ngOnInit(): void {
    this.user$.subscribe((state) => {
      if (state) {
        this.user = state
        this.form = this.fb.group({
          name: [
            this.user?.nombreUsuario,
            [
              Validators.required,
              Validators.minLength(3),
              Validators.maxLength(250),
            ],
          ],
          email: [
            {
              value: this.user?.email,
              disabled: true,
            },
            [Validators.required, Validators.email],
          ],
          ocupation: [
            this.user?.cargo,
            [
              // Validators.required,
              //Validators.minLength(3),
              //Validators.maxLength(50),
            ],
          ],
          phone: [this.user.telefono],
        })
      }
    })
  }

  public submitForm(): void {
    validateForm(this.form, ['email'])

    if (this.form.valid) {
      const request: IUpdateUser = {
        name: this.form.get('name')?.value,
        phone: this.form.get('phone')?.value,
        imageProfile: this.user.imagenPerfil,
      }

      this.loading = true
      disableForm(this.form)

      this._user.updateUser(request).subscribe(
        (response: IResponse) => {
          switch (response.status.code) {
            case 400:
              this.notification.create(
                'error',
                ERROR_SERVICE_TITLE,
                ERROR_SERVICE_MESSAGE
              )
              break
            case 200:
            case 201:
              this.store.dispatch(new UpdateUser(this.userMapper(request)))
              this.notification.create(
                'success',
                SUCCESS_UPDATE_PROFILE_TITLE,
                SUCCESS_UPDATE_PROFILE_MESSAGE
              )
              break
          }

          this.loading = false
          enableForm(this.form)
          this.form.controls['email'].disable()
        },
        (error: Error) => {
          console.error(error)
          this.notification.create(
            'error',
            ERROR_SERVICE_TITLE,
            ERROR_SERVICE_MESSAGE
          )
          this.loading = false
          enableForm(this.form)
          this.form.controls['email'].disable()
        }
      )
    }
  }

  private userMapper(user: IUpdateUser): IUser {
    const { name, phone } = user
    return {
      ...this.user,
      nombreUsuario: name,
      telefono: phone,
    }
  }

  public keypressOnlyNumber(event): boolean {
    const charCode = event.which ? event.which : event.keyCode
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false
    }
    return true
  }
}
