<div class="card">
  <nz-skeleton
    *ngIf="(planFacade.isErrorCurrentPlan$ | async) === false"
    [nzLoading]="(planFacade.isLoadingCurrentPlan$ | async) === true"
    [nzActive]="true"
    [nzAvatar]="true"
    [nzParagraph]="{ rows: 5 }"
    [nzRound]="true"
  >
    <div
      *ngIf="(planFacade.isLoadingCurrentPlan$ | async) === false"
      class="row info-plan px-3 h-100"
    >
      <div class="col-12 detail align-self-center">
        <div class="info-plan__suscription">
          <h5 class="text-center">
            Tu plan actual:
            <span class="text-plan">{{
              (planFacade.currentPlan$ | async).name
            }}</span>
          </h5>
        </div>
      </div>
      <div class="col-12 align-self-center mt-3">
        <!-- surveys -->
        <div class="progress">
          <nz-progress
            [nzPercent]="(planFacade.currentPlan$ | async).percentSurveysUsed"
            nzSize="small"
            [nzShowInfo]="false"
            nzStrokeColor="#40189D"
          ></nz-progress>
          <span>Encuestas</span>
          <span class="float-right font-weight-bold"
            >{{ (planFacade.currentPlan$ | async).numberSurveysUsed }}/{{
              (planFacade.currentPlan$ | async).numberPlanSurveys === 0
                ? '∞'
                : (planFacade.currentPlan$ | async).numberPlanSurveys
            }}</span
          >
        </div>
        <!-- responses -->
        <div class="progress">
          <nz-progress
            [nzPercent]="(planFacade.currentPlan$ | async).percentResponsesUsed"
            nzSize="small"
            [nzShowInfo]="false"
            nzStrokeColor="#40189D"
          ></nz-progress>
          <span>Respuestas</span>
          <span class="float-right font-weight-bold"
            >{{
              (planFacade.currentPlan$ | async)?.numberResponsesUsed
                | numberFormat
            }}/{{
              (planFacade.currentPlan$ | async)?.numberPlanResponses
                | numberFormat
            }}</span
          >
        </div>
      </div>
      <div *ngIf="!isBetaProduction" class="col-12 align-self-center mt-3">
        <button
          type="button"
          class="btn btn-dark mt-3 shadow btn d-block mx-auto"
          [routerLink]="'/app/plans-prices'"
        >
          Actualiza tu plan
          <em class="ml-2" nz-icon nzType="rocket" nzTheme="outline"></em>
        </button>
      </div>
    </div>
  </nz-skeleton>
</div>
