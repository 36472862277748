<div [class]="'loader-container ' + bg">
  <div class="loader animated-fade-in">
    <img [src]="logo" alt="" class="img-fluid d-block mx-auto" />
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <small class="d-block mt-2">{{
      (loadingLogout$ | async) ? 'Cerrando sesión...' : label
    }}</small>
  </div>
</div>
