import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { JiraFacade, QuestionFacade, SurveyFacade } from './store.index'

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [SurveyFacade, QuestionFacade, JiraFacade],
})
export class StoreModule {}
