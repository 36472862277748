<nz-spin
  [nzSpinning]="isLoadingCreate"
  [nzSize]="'large'"
  nzTip="Creando encuesta..."
>
  <div class="modal-content">
    <div class="container mb-5">
      <nz-comment nzAuthor="Empati IA" [nzDatetime]="time">
        <nz-avatar
          nz-comment-avatar
          nzIcon="user"
          [nzSrc]="IMG_EMPATI_BOT"
        ></nz-avatar>
        <nz-comment-content>
          <p>
            🌟 Soy Eddie, tu asistente virtual de Empati. 😊✨
            <br />
            He preparado esta encuesta basada en tus necesidades. ¡Vamos a darle
            vida a tus opiniones! 🤖💙🚀
          </p>
          <hr />
          <h5>Encuesta: {{ surveyIAResult.name }}</h5>
        </nz-comment-content>
        <nz-comment-content>
          <span>{{ surveyIAResult.description }}</span>
        </nz-comment-content>
        <nz-comment-content>
          <br />
          <span class="font-weight-bold">Formato de Encuesta: </span>
          <span>{{ surveyIAResult.explainDescription }}</span>
        </nz-comment-content>

        <nz-comment-content>
          <br />
          <span class="font-weight-bold"
            >A continuación las preguntas para tu encuesta:
          </span>
        </nz-comment-content>

        <nz-comment-content>
          <nz-table
            #editRowTable
            nzBordered
            [nzData]="surveyIAResult.questions"
            [nzShowPagination]="false"
            [nzScroll]="{ y: '250px' }"
            nzSize="small"
          >
            <thead>
              <tr>
                <th nzWidth="30%" id="question">Pregunta</th>
                <th id="type">Tipo</th>
                <th id="required">Requerida</th>
                <th id="comment">Comentarios Habilitados</th>
                <th id="scale">Escala</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of editRowTable.data" class="editable-row">
                <td>
                  <div class="editable-cell">
                    <b class="mr-2">{{ data.order }}.</b>
                    {{ data.questionTitle }}
                  </div>
                </td>
                <td>
                  {{ data.questionType }}
                </td>
                <td>
                  <nz-switch
                    [(ngModel)]="data.isMandatory"
                    [nzCheckedChildren]="'Sí'"
                    [nzUnCheckedChildren]="'No'"
                    disabled="true"
                  ></nz-switch>
                </td>
                <td>
                  <!-- Checkbox para comentarios habilitados -->
                  <nz-switch
                    [(ngModel)]="data.isCommentAvailable"
                    [nzCheckedChildren]="'Sí'"
                    [nzUnCheckedChildren]="'No'"
                    disabled="true"
                  ></nz-switch>
                </td>
                <td>
                  {{ data.questionScale }}
                </td>
              </tr>
            </tbody>
          </nz-table>
        </nz-comment-content>

        <nz-comment-content>
          <br />
          <span class="font-weight-bold"
            >Quiero recordarte que la encuesta que hemos preparado para ti no
            está escrita en piedra. Si sientes que hay algo que quieres cambiar
            o ajustar, puedes hacerlo una vez creeada esta encuesta.
          </span>
        </nz-comment-content>

        <nz-comment-action>
          <i
            nz-tooltip
            nzTitle="Like"
            nz-icon
            nzType="like"
            [nzTheme]="likes > 0 ? 'twotone' : 'outline'"
            (click)="like()"
          ></i>
          <span class="count like">{{ likes }}</span>
        </nz-comment-action>
        <nz-comment-action>
          <i
            nz-tooltip
            nzTitle="Dislike"
            nz-icon
            nzType="dislike"
            [nzTheme]="dislikes > 0 ? 'twotone' : 'outline'"
            (click)="dislike()"
          ></i>
          <span class="count dislike">{{ dislikes }}</span>
        </nz-comment-action>

        <nz-comment-action>
          <span (click)="generateAnotherSurveyIA()">
            Generar otra encuesta
          </span>
        </nz-comment-action>
      </nz-comment>
    </div>

    <app-special-button
      buttonText="Crear Encuesta"
      iconType="robot"
      (buttonClicked)="createSurveyIA()"
      nzTooltipTitle="🚀 Funcionalidad en desarrollo"
      [nzTooltipPlacement]="'top'"
      nz-tooltip
    ></app-special-button>
  </div>
</nz-spin>
