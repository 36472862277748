<div class="row">
  <div class="col-xl-12 d-block d-xxl-none mb-3">
    <div class="card-profile">
      <app-options-profile></app-options-profile>
    </div>
  </div>
  <div class="col-xl-12 col-xxl-9">
    <div class="card-profile">
      <router-outlet></router-outlet>
    </div>
  </div>
  <div class="col-xxl-3 d-none d-xxl-block">
    <div class="card-profile">
      <app-options-profile></app-options-profile>
    </div>
  </div>
</div>
