import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { environment } from './../../../../environments/environment'
import { IEmailRecovery } from './../../../models/request/IEmailRecovery'
import { IResponse } from './../../../models/response/IResponse'
import { SessionService } from './../../../services/session/session.service'
import {
  ERROR_SERVICE_MESSAGE,
  ERROR_SERVICE_TITLE,
} from './../../../utils/constants/generic-messages'
import {
  ERROR_RECOVERY_TITLE,
  SUCCESS_RECOVERY_MESSAGE,
  SUCCESS_RECOVERY_TITLE,
} from './../../../utils/constants/session-messages'

const ENV = environment.env

@Component({
  selector: 'app-recovery',
  templateUrl: './recovery.component.html',
  styleUrls: ['../session.scss'],
})
export class RecoveryComponent implements OnInit {
  public loading: boolean
  public validateForm!: FormGroup

  constructor(
    public router: Router,
    private _session: SessionService,
    private notification: NzNotificationService,
    private fb: FormBuilder
  ) {
    this.loading = false
  }

  ngOnInit(): void {
    this.validateForm = this.fb.group({
      email: [null, [Validators.required, Validators.email]],
    })
  }

  public submitForm(): void {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty()
      this.validateForm.controls[i].updateValueAndValidity()
    }

    if (this.validateForm.valid) {
      const request: IEmailRecovery = {
        email: this.validateForm.get('email')?.value,
      }

      this.loading = true

      this._session.sendEmailRecovery(request).subscribe(
        (response: IResponse) => {
          switch (response.status.code) {
            case 204:
            case 400:
              this.notification.create('warning', ERROR_RECOVERY_TITLE, '')
              break
            case 200:
            case 201:
              this.notification.create(
                'success',
                SUCCESS_RECOVERY_TITLE,
                `${SUCCESS_RECOVERY_MESSAGE} ${request.email}`
              )
              break
          }

          this.loading = false
        },
        (error) => {
          this.loading = false

          if (error.status === 404) {
            return this.notification.create('warning', ERROR_RECOVERY_TITLE, '')
          }

          return this.notification.create(
            'error',
            ERROR_SERVICE_TITLE,
            ERROR_SERVICE_MESSAGE
          )
        }
      )
    }
  }
}
