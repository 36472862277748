<div class="card-purple">
  <div class="card-content">
    <h6 class="title">Panel de Control de Indicadores de Satisfacción 📊</h6>
    <span class="sub-title"
      >Elige una opción y descubre las respuestas de tus clientes. ¡Adelante!
      🚀🔍</span
    >
    <div class="options mt-3">
      <app-radio-dark [(selectedOption)]="selectedOption"></app-radio-dark>
    </div>
  </div>
</div>

<div
  *ngIf="selectedOption"
  class="card w-100 mt-3 text-center animated-fade-in"
>
  <h5 class="mt-2">
    Próximamente: Más Herramientas para tu Panel de Control 🛠️
  </h5>
  <span>
    Estamos trabajando en nuevas formas de brindarte insights más profundos
    sobre la satisfacción de tus clientes. ¡Mantente atento para las próximas
    actualizaciones en tu Panel de Control de Indicadores de Satisfacción! 📈🌟
  </span>
</div>

<div
  *ngIf="selectedOption"
  class="icon-wrapper-up px-3"
  [ngClass]="{
    'animated-fade-in': selectedOption,
    'animated-fade-out': !selectedOption
  }"
  (click)="selectedOption = 0"
  nz-tooltip
  nzTooltipTitle="Ocultar"
  nzTooltipColor="black"
  nzTooltipPlacement="bottom"
>
  <em nz-icon nzType="arrow-up" nzTheme="outline"></em>
</div>

<div *ngIf="false" class="card w-100 mt-3">
  <div class="row mb-4">
    <div class="col-12 col-md-6 mb-3 mb-md-0">
      <nz-select
        [(ngModel)]="surveySelected"
        (ngModelChange)="getQuestionSurvey(surveySelected)"
        nzShowSearch
        nzPlaceHolder="Selecciona una encuesta"
      >
        <ng-container *ngFor="let survey of surveyList">
          <nz-option
            [nzValue]="survey.surveyId"
            [nzLabel]="survey.name"
          ></nz-option>
        </ng-container>
        <nz-option
          *ngIf="surveyFacade.isLoadingSurveyList$ | async"
          nzDisabled
          nzCustomContent
        >
          <i nz-icon nzType="loading" class="loading-icon"></i>
          Cargando encuestas...
        </nz-option>
      </nz-select>
    </div>
    <div class="col-12 col-md-6">
      <nz-select
        [(ngModel)]="questionSelected"
        (ngModelChange)="getIndicators(questionSelected)"
        nzShowSearch
        nzPlaceHolder="Selecciona una pregunta"
        [disabled]="!surveySelected"
      >
        <nz-option
          *ngIf="isLoading === false"
          [nzValue]="0"
          nzLabel="Todas las preguntas"
        ></nz-option>
        <ng-container *ngFor="let question of questionsSurveySelected">
          <nz-option
            *ngIf="isLoading === false"
            [nzValue]="question.questionId"
            [nzLabel]="question.title"
          ></nz-option>
        </ng-container>

        <nz-option *ngIf="isLoading" nzDisabled nzCustomContent>
          <i nz-icon nzType="loading" class="loading-icon"></i>
          Cargando preguntas...
        </nz-option>
      </nz-select>
    </div>
  </div>

  <div class="row">
    <!-- <div class="col-12 my-3">
      <div class="nps-container">
        <div *ngFor="let option of npsOptions" class="nps-option">
          <span
            nz-icon
            [nzType]="option.icon"
            [nzTheme]="'twotone'"
            [nzTwotoneColor]="option.color"
            class="nps-icon"
          ></span>
          <span
            class="nps-responses mt-2"
            [ngStyle]="{ 'background-color': option.color }"
          >
            {{ option.responses }}
          </span>
        </div>
      </div>
    </div> -->

    <div class="col-12 mb-4 d-none">
      <div class="nps-scale">
        <div *ngFor="let group of npsGroups" class="nps-group">
          <div
            class="nps-scores"
            [style.backgroundColor]="group.color"
            [style.flexBasis]="group.flexBasis"
          >
            <span *ngFor="let score of group.scores">{{ score }}</span>
          </div>
          <div class="nps-group-name">{{ group.group }}</div>
        </div>
      </div>
    </div>
    <div class="col-12 mb-4">
      <div class="formula-container">
        <span class="formula-text">NPS = 45% Promotores - 25% Detractors</span>
        <span class="formula-label">Net Promoter Score Formula</span>
      </div>
    </div>

    <div class="col-12 col-md-8 col-xl-12 col-x2l-8">
      <div class="chart-container">
        <highcharts-chart
          [Highcharts]="Highcharts"
          [options]="npsResponsesIndicators"
          style="width: 100%; height: 300px; display: block"
        >
        </highcharts-chart>
      </div>
    </div>
    <div class="col-12 col-md-4 col-xl-12 col-x2l-4">
      <div class="container">
        <div class="card-indicators detractors">
          <div class="card-content">
            <h4 class="title">Detractores</h4>
            <span class="count">34</span>
            <span class="percentage">(25%)</span>
            <div class="progress">
              <div class="progress-bar" style="width: 25%"></div>
            </div>
          </div>
        </div>
        <div class="card-indicators passive">
          <div class="card-content">
            <h4 class="title">Pasivos</h4>
            <span class="count">35</span>
            <span class="percentage">(35%)</span>
            <div class="progress">
              <div class="progress-bar" style="width: 35%"></div>
            </div>
          </div>
        </div>
        <div class="card-indicators promoters">
          <div class="card-content">
            <h4 class="title">Promotores</h4>
            <span class="count">98</span>
            <span class="percentage">(40%)</span>
            <div class="progress">
              <div class="progress-bar" style="width: 40%"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
