import { ComponentsModule } from './../../components/components.module'
import { RouterModule } from '@angular/router'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { CommonModule } from '@angular/common'
import { NgZorroAntdModule } from './../../ng-zorro-antd.module'

import { UpdatePassComponent } from './update-pass/update-pass.component'
import { RegisterComponent } from './register/register.component'
import { RecoveryComponent } from './recovery/recovery.component'
import { LoginComponent } from './login/login.component'
import { ActivateAccountComponent } from './activate-account/activate-account.component'

@NgModule({
  declarations: [
    LoginComponent,
    RecoveryComponent,
    RegisterComponent,
    UpdatePassComponent,
    ActivateAccountComponent,
  ],
  imports: [
    CommonModule,
    NgZorroAntdModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    ComponentsModule,
  ],
  providers: [],
})
export class SessionModule {}
