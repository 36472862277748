<nz-breadcrumb class="mb-3">
  <nz-breadcrumb-item>
    <a [routerLink]="'/app/survey'"> Encuestas </a>
  </nz-breadcrumb-item>
  <nz-breadcrumb-item>Detalle encuesta</nz-breadcrumb-item>
</nz-breadcrumb>
<app-card-info-survey-detail
  [surveyId]="surveyId"
  [(viewSurveyType)]="viewSurveyType"
  (selectedChange)="onTabSelectedChange($event)"
>
</app-card-info-survey-detail>
<div class="main-survey animated-fade-in">
  <div class="container-detail-survey">
    <div class="row m-0">
      <div class="col-12 col-lg-5 col-xl-4 col-xxl-3 border-left-01">
        <div class="container h-100">
          <nz-tabset
            [nzCanDeactivate]="canDeactivate"
            [nzSize]="'large'"
            nzCentered
          >
            <nz-tab
              nzTitle="Preguntas"
              class="titleTab"
              (nzSelect)="onTabSelectedChange('questions')"
            >
              <nz-skeleton
                [nzLoading]="
                  (questionFacade.isLoadingQuestionList$ | async) === true
                "
                [nzActive]="true"
                [nzAvatar]="true"
                [nzParagraph]="{ rows: 10 }"
                [nzRound]="true"
              >
                <nz-spin
                  [nzTip]="questionFacade?.messageQuestionEditLoading$ | async"
                  [nzSpinning]="
                    (questionFacade.isLoadingCreateQuestion$ | async) ===
                      true ||
                    (questionFacade.isLoadingQuestionList$ | async) === true
                  "
                >
                  <app-question-list
                    [surveyId]="surveyId"
                    [viewSearch]="true"
                    [type]="2"
                  ></app-question-list>
                </nz-spin>
              </nz-skeleton>
              <div class="mb-5"></div>
            </nz-tab>
            <nz-tab nzTitle="Diseño" (nzSelect)="onTabSelectedChange('design')">
              <app-design-survey
                *ngIf="tabSelected === 'design'"
                [(isDesignSettingsChanged)]="isDesignSettingsChanged"
                [(tabSelected)]="tabSelected"
              ></app-design-survey>
            </nz-tab>
            <nz-tab
              nzTitle="Opciones"
              (nzSelect)="onTabSelectedChange('options')"
            >
              <app-option-survey
                *ngIf="tabSelected === 'options'"
                [(isSettingsChanged)]="isSettingsChanged"
                [(tabSelected)]="tabSelected"
              ></app-option-survey>
            </nz-tab>
          </nz-tabset>
          <div
            class="d-none d-md-block my-3"
            *ngIf="titleTabInfo && descriptionTabInfo"
          >
            <hr class="mb-3" />
            <app-card-info
              [title]="titleTabInfo"
              [description]="descriptionTabInfo"
            ></app-card-info>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-7 col-xl-8 col-xxl-9">
        <div
          *ngIf="
            (surveyFacade.isLoadingSurvey$ | async) === true ||
            (questionFacade.isLoadingQuestionList$ | async) === true
          "
          class="mt-5"
        ></div>
        <nz-skeleton
          [nzLoading]="(surveyFacade.isLoadingSurvey$ | async) === true"
          [nzActive]="true"
          [nzAvatar]="true"
          [nzParagraph]="{ rows: 10 }"
          [nzRound]="true"
        >
          <div
            [ngStyle]="
              tabSelected !== 'questions'
                ? { visibility: 'hidden' }
                : { visibility: 'visible' }
            "
            class="options-survey-view text-center mt-5 mt-lg-0 pt-4 pb-2 pb-lg-5"
          >
            <nz-segmented
              *ngIf="options"
              class="float-lg-right"
              [nzOptions]="options"
              nzSize="large"
              [(ngModel)]="viewSurveyType"
              (ngModelChange)="onViewSurveyTypeChange($event)"
            ></nz-segmented>
          </div>
          <!-- preview -->
          <app-survey-mf
            *ngIf="
              (surveyFacade.isLoadingSurvey$ | async) === false &&
              (surveyFacade.survey$ | async) &&
              viewSurveyType === 0
            "
            [url]="(surveyFacade.survey$ | async)?.urlShared"
          ></app-survey-mf>
          <!-- results reports -->
          <app-survey-report
            *ngIf="
              (surveyFacade.isLoadingSurvey$ | async) === false &&
              viewSurveyType === 1
            "
          ></app-survey-report>
        </nz-skeleton>
        <div
          *ngIf="(surveyFacade.isLoadingSurvey$ | async) === true"
          class="mb-5"
        ></div>
      </div>
    </div>
  </div>
</div>
