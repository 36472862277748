import { MyPaymentsComponent } from './my-payments/my-payments.component'
import { UpdatePassProfileComponent } from './update-pass-profile/update-pass-profile.component'
import { EditProfileComponent } from './edit-profile/edit-profile.component'
import { GeneralProfileComponent } from './general-profile/general-profile.component'
import { ProfileComponent } from './profile.component'

import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'

const routes: Routes = [
  {
    path: '',
    component: ProfileComponent,
    children: [
      {
        path: 'general',
        component: GeneralProfileComponent,
        data: { title: 'Perfil' },
      },
      {
        path: 'edit',
        component: EditProfileComponent,
        data: { title: 'Perfil' },
      },
      {
        path: 'update-pass',
        component: UpdatePassProfileComponent,
        data: { title: 'Perfil' },
      },
      {
        path: 'my-payments',
        component: MyPaymentsComponent,
        data: { title: 'Perfil' },
      },
      {
        path: '',
        redirectTo: 'general',
        pathMatch: 'full',
      },
    ],
  },
  {
    path: '',
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    path: '*',
    redirectTo: '',
    pathMatch: 'full',
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProfileRoutingModule {}
