import { Component } from '@angular/core'
import { Select } from '@ngxs/store'
import { Observable } from 'rxjs-compat'
import { IPlan } from 'src/app/models/response/IPlan'
import { IResponse } from 'src/app/models/response/IResponse'
import { IUser } from 'src/app/models/response/IUser'
import { UserService } from 'src/app/services/service.index'
import { PlanState } from 'src/app/store/plan/plan.state'
import { UserState } from 'src/app/store/user/user.state'
import { ICompany } from './../../../models/response/ICompany'
import { COMPANY_TYPE } from './../../../utils/constants/profile'

@Component({
  selector: 'app-general-profile',
  templateUrl: './general-profile.component.html',
  styleUrls: ['./general-profile.component.scss'],
})
export class GeneralProfileComponent {
  @Select(UserState.getUser) user$: Observable<IUser>
  @Select(PlanState.getCurrentPlan) plan$: Observable<IPlan>
  @Select(PlanState.getLoadingPlan) loadingPlan$: Observable<boolean>

  public company: ICompany
  public loadingUser: boolean
  public loadingCompany: boolean
  public errorInfoPlan: boolean

  constructor(private _user: UserService) {
    this.loadingCompany = true
    this.loadingUser = true
    this.errorInfoPlan = false

    this.user$.subscribe((user: IUser) => {
      if (user) {
        this.loadingUser = false
        if (user.tipoUsuario === COMPANY_TYPE && !this.company) {
          this.obtainClientUser()
        }
      }
    })
  }

  private obtainClientUser(): void {
    this._user.getClientUser().subscribe(
      (response: IResponse) => {
        switch (response.status.code) {
          case 400:
            break
          case 200:
            this.company = response.data
            break
        }

        this.loadingCompany = false
      },
      (error: Error) => {
        this.loadingCompany = false
      }
    )
  }
}
