<div class="wrapper mx-auto">
  <div class="option" (click)="selectOption(2)">
    <input
      [checked]="selectedOption === 2"
      value="2"
      name="btn"
      type="radio"
      class="input"
    />
    <div class="btn">
      <span class="span">NPS</span>
    </div>
  </div>
  <div class="option" (click)="selectOption(1)">
    <input
      [checked]="selectedOption === 1"
      value="1"
      name="btn"
      type="radio"
      class="input"
    />
    <div class="btn">
      <span class="span">CSAT</span>
    </div>
  </div>
  <div class="option" (click)="selectOption(3)">
    <input
      [checked]="selectedOption === 3"
      value="3"
      name="btn"
      type="radio"
      class="input"
    />
    <div class="btn">
      <span class="span">CES</span>
    </div>
  </div>
</div>
