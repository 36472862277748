import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { environment } from './../environments/environment'
import { ActivateAccountComponent } from './pages/session/activate-account/activate-account.component'
import { LoginComponent } from './pages/session/login/login.component'
import { RecoveryComponent } from './pages/session/recovery/recovery.component'
import { RegisterComponent } from './pages/session/register/register.component'
import { UpdatePassComponent } from './pages/session/update-pass/update-pass.component'

const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
    data: { title: 'Iniciar sesión | Empati' },
  },
  {
    path: 'login',
    component: LoginComponent,
    data: { title: 'Iniciar sesión | Empati' },
  },
  ...(!environment.production
    ? [
        {
          path: 'register',
          component: RegisterComponent,
          data: { title: 'Registro | Empati' },
        },
      ]
    : []),
  {
    path: 'recovery',
    component: RecoveryComponent,
    data: { title: 'Recuperar contraseña | Empati' },
  },
  {
    path: 'update-pass/:email/:tokenId',
    component: UpdatePassComponent,
    data: { title: 'Actualizar contraseña | Empati' },
  },
  {
    path: 'activate-account',
    component: ActivateAccountComponent,
    data: { title: 'Activar cuenta | Empati' },
  },
  {
    path: 'activate-account/:email/:tokenId',
    component: ActivateAccountComponent,
    data: { title: 'Activar cuenta | Empati' },
  },
  {
    path: 'activate-account/:email',
    component: ActivateAccountComponent,
    data: { title: 'Activar cuenta | Empati' },
  },
  {
    path: '',
    loadChildren: () =>
      import('./pages/app-routing-page.module').then(
        (m) => m.AppRoutingPageModule
      ),
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: 'login',
    pathMatch: 'full',
  },
]

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
