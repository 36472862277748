import {
  Directive,
  EventEmitter,
  HostBinding,
  HostListener,
  Output,
} from '@angular/core'

@Directive({
  selector: '[appDragAndDrop]',
})
export class DragAndDropDirective {
  @HostBinding('.class.fileover') fileOver: boolean
  @Output() fileDropped = new EventEmitter<File>()

  @HostListener('dragOver', ['$event']) onDragOver(evt): void {
    evt.preventDefault()
    evt.stopPropagation()
    this.fileOver = true
  }

  @HostListener('dragLeave', ['$event']) onDragLive(evt): void {
    evt.preventDefault()
    evt.stopPropagation()
  }

  @HostListener('drop', ['$event']) onDrop(evt): void {
    evt.preventDefault()
    evt.stopPropagation()
    this.fileOver = false
    const files = evt.dataTransfer.files
    if (files.length > 0) {
      this.fileDropped.emit(files)
    }
  }
}
