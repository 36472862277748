import { Component, Input, OnDestroy } from '@angular/core'
import * as Highcharts from 'highcharts'
import { Subscription } from 'rxjs-compat'
import { first } from 'rxjs/operators'
import { ReportWeekType } from '../../../models/types/report.types'
import { DashboardFacade } from '../../../store/dashboard/dashboard.facade'
import { QuestionFacade } from '../../../store/question/question.facade'
import { ReportFacade } from '../../../store/reports/report.facade'
import { SurveyFacade } from '../../../store/survey/survey.facade'
import { ICommentsSummary } from './../../../models/request/ICommentsSummary'
import { ISummary } from './../../../models/request/ISummary'
import { IQuestion } from './../../../models/response/IQuestion'
import { chartOptionsDoughnutComments } from './../../../pages/survey/survey-report/mappersCharts/doughnut.mapper'
import { chartOptionsWordCloudComments } from './../../../pages/survey/survey-report/mappersCharts/word-cloud.mapper '
@Component({
  selector: 'app-comments-result',
  templateUrl: './comments-result.component.html',
  styleUrls: ['./comments-result.component.scss'],
})
export class CommentsResultComponent implements OnDestroy {
  Highcharts: typeof Highcharts = Highcharts

  @Input() startDate: string = null
  @Input() endDate: string = null
  @Input() summaryResult: ICommentsSummary = null
  @Input() isLoadingSummary = true

  public donusChartOptionsComments
  public wordCloudChartOptionsComments

  public typeReportWeek: ReportWeekType = 'monthly'
  public surveySelectedId: string = '0'
  public isValidDonusChartData: boolean = false
  private subscription: Subscription
  private isGetSummay: boolean = false

  constructor(
    public questionFacade: QuestionFacade,
    private surveyFacade: SurveyFacade,
    public dashboardFacade: DashboardFacade,
    public reportFacade: ReportFacade
  ) {
    this.subscription = this.questionFacade.question$.subscribe(
      async (question) => {
        if (
          question &&
          question.questionId !== this.surveySelectedId &&
          !this.isGetSummay
        ) {
          this.surveySelectedId = question.questionId
          await this.getCommentsSummary(question)
          this.isGetSummay = true
        }
      }
    )
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

  async getCommentsSummary(question: IQuestion): Promise<void> {
    this.isLoadingSummary = true

    this.donusChartOptionsComments = null
    this.wordCloudChartOptionsComments = null

    const survey = await this.surveyFacade.survey$.pipe(first()).toPromise()
    const request: ISummary = {
      surveyId: survey.surveyId,
      questionTypeId: question.typeId.toString(),
      questionId: question.questionId.toString(),
      timeFrame: this.typeReportWeek,
    }

    this.subscription = this.reportFacade
      .getCommentsSummaryFetch(request)
      .subscribe(
        (res) => {
          console.log('[CommentsResultComponent] getCommentsSummaryFetch', res)

          if (res.status.code === 200) {
            this.generateCharts(res.data)
          }
          this.isLoadingSummary = false
        },
        (error) => {
          this.isLoadingSummary = false
        }
      )
  }

  generateCharts(summaryResult: ICommentsSummary): void {
    this.summaryResult = summaryResult
    const {
      relevantComments,
      nonRelevantComments,
      unansweredComments,
      totalComments,
      relevantCommentsList,
    } = this.summaryResult

    this.wordCloudChartOptionsComments = relevantCommentsList
      ? chartOptionsWordCloudComments(relevantCommentsList)
      : null

    this.isValidDonusChartData = totalComments ? totalComments > 0 : false

    this.donusChartOptionsComments = this.isValidDonusChartData
      ? chartOptionsDoughnutComments(
          ['Relevantes', 'No Relevantes', 'No Contestados'],
          [relevantComments, nonRelevantComments, unansweredComments],
          totalComments
        )
      : null
  }

  public async onRadioChange(event: any): Promise<void> {
    this.typeReportWeek = event
    const question = await this.questionFacade.question$
      .pipe(first())
      .toPromise()
    this.getCommentsSummary(question)
  }
}
