<div class="status-survey row">
  <div class="col-md-4 col-sm-6">
    <nz-spin [nzSpinning]="dashboardFacade.isLoadingGeneralInfo$ | async">
      <div
        class="dlab-cource bg-info"
        [routerLink]="'/app/plans-prices'"
        nz-tooltip
        [nzTooltipTitle]="
          'Has utilizado ' +
          (dashboardFacade.generalInfo$ | async)?.totalAvailableText.replace(
            '/',
            ' de las '
          ) +
          ' respuestas mensuales disponibles en tu plan actual. Para agregar más respuestas, considera actualizar tu plan.'
        "
        nzTooltipColor="black"
        nzTooltipPlacement="bottom"
      >
        <div class="d-flex align-items-center">
          <span class="course-icon">
            <img class="img-icon" [src]="IMG_PLAN_C" alt="" />
          </span>
          <div class="ms-2" *ngIf="dashboardFacade.generalInfo$ | async">
            <h4 class="mb-0">
              {{ (dashboardFacade.generalInfo$ | async).totalAvailableText }}
            </h4>
            <span class="d-block"> Respuestas utilizadas</span>
          </div>
        </div>
        <img class="img-bg" [src]="IMG_PLAN" alt="" />
      </div>
    </nz-spin>
  </div>
  <div class="col-md-4 col-sm-6">
    <nz-spin [nzSpinning]="dashboardFacade.isLoadingGeneralInfo$ | async">
      <div class="dlab-cource" [routerLink]="'/app/survey'">
        <div class="d-flex align-items-center">
          <span class="course-icon">
            <img class="img-icon" [src]="IMG_SURVEY" alt="" />
          </span>
          <div class="ms-2">
            <h4 class="mb-0" *ngIf="dashboardFacade.generalInfo$ | async">
              {{ (dashboardFacade.generalInfo$ | async).totalSurveys }}
            </h4>
            <span>Encuestas</span>
          </div>
        </div>
        <img class="img-bg" [src]="IMG_SURVEY" alt="" />
      </div>
    </nz-spin>
  </div>
  <div class="col-md-4 col-sm-6">
    <nz-spin [nzSpinning]="dashboardFacade.isLoadingGeneralInfo$ | async">
      <div class="dlab-cource bg-primary-light" [routerLink]="'/app/answers'">
        <div class="d-flex align-items-center">
          <span class="course-icon">
            <img class="img-icon" [src]="IMG_RESPONSE_C" alt="" />
          </span>
          <div class="ms-2">
            <h4 class="mb-0" *ngIf="dashboardFacade.generalInfo$ | async">
              80<!-- {{ (dashboardFacade.generalInfo$ | async).totalInteractions }} -->
            </h4>
            <span>Créditos AI</span>
          </div>
        </div>
        <img class="img-bg" [src]="IMG_RESPONSE" alt="" />
      </div>
    </nz-spin>
  </div>
</div>
