import { Component, OnDestroy } from '@angular/core'
import * as Highcharts from 'highcharts'
import { Subscription } from 'rxjs-compat'
import { first } from 'rxjs/operators'
import { chartOptionsDoughnutNPS } from 'src/app/pages/survey/survey-report/mappersCharts/doughnut.mapper'
import { SurveyFacade } from 'src/app/store/store.index'
import { ISummary } from '../../../models/request/ISummary'
import { IQuestion } from '../../../models/response/IQuestion'
import { IResponse } from '../../../models/response/IResponse'
import { ReportWeekType } from '../../../models/types/report.types'
import { DashboardFacade } from '../../../store/dashboard/dashboard.facade'
import { QuestionFacade } from '../../../store/question/question.facade'
import { ReportFacade } from '../../../store/reports/report.facade'

@Component({
  selector: 'app-nps-result',
  templateUrl: './nps-result.component.html',
  styleUrls: ['../csat-result/csat-result.component.scss'],
})
export class NpsResultComponent implements OnDestroy {
  Highcharts: typeof Highcharts = Highcharts

  public donusChartOptions: any = chartOptionsDoughnutNPS(
    ['Detractores', 'Pasivos', 'Promotores'],
    [0, 0, 0],
    0
  )

  public NPS_SCALE_IMG = '../../../assets/images/graphics/nps-scale.png'

  public typeReportWeek: ReportWeekType = 'monthly'
  public surveySelectedId: string = '0'
  public isLoadingSummary = false
  public summaryResult
  public hasResults: boolean = false
  private subscription: Subscription
  private isGetSummay: boolean = false

  constructor(
    public questionFacade: QuestionFacade,
    private surveyFacade: SurveyFacade,
    public dashboardFacade: DashboardFacade,
    public reportFacade: ReportFacade
  ) {
    this.subscription = this.questionFacade.question$.subscribe(
      async (question) => {
        if (
          question &&
          question.questionId !== this.surveySelectedId &&
          question.typeId === 2 &&
          !this.isGetSummay
        ) {
          this.surveySelectedId = question.questionId
          await this.getSummary(question)
          this.isGetSummay = true
        }
      }
    )
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

  public async getSummary(question: IQuestion): Promise<void> {
    const survey = await this.surveyFacade.survey$.pipe(first()).toPromise()

    const request: ISummary = {
      surveyId: survey.surveyId,
      questionTypeId: question.typeId.toString(),
      questionId: question.questionId.toString(),
      timeFrame: this.typeReportWeek,
    }

    this.isLoadingSummary = true

    this.reportFacade.getNpsSummaryFetch(request).subscribe(
      (res: IResponse) => {
        if (res.status.code === 200) {
          this.summaryResult = res.data
          const { averangeScore, npsScore, totalResponses } = this.summaryResult
          this.hasResults = averangeScore && npsScore && totalResponses

          const { promoters, passives, detractors } =
            this.summaryResult.responsesCase

          this.donusChartOptions = chartOptionsDoughnutNPS(
            ['Detractores', 'Pasivos', 'Promotores'],
            [detractors, passives, promoters],
            this.summaryResult.npsScore
          )

          this.isLoadingSummary = false
        }
      },
      (error) => {
        this.isLoadingSummary = false
        this.hasResults = false
      }
    )
  }

  public async onRadioChange(event: any): Promise<void> {
    this.typeReportWeek = event
    const question = await this.questionFacade.question$
      .pipe(first())
      .toPromise()

    this.getSummary(question)
  }
}
