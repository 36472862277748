import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-plan',
  templateUrl: './plan.component.html',
})
export class PlanComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
