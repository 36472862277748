import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { environment } from 'src/environments/environment'
import { IResponse } from '../../models/response/IResponse'
import { IGenerateSurveyIA } from './../../models/request/IGenerateSurveyIA'

@Injectable({
  providedIn: 'root',
})
export class IAService {
  private api: string

  constructor(public http: HttpClient) {
    this.api = environment.api
  }

  public generateSurveyIA(request: IGenerateSurveyIA): Observable<IResponse> {
    const uri = `${this.api}/ia-interaction/generate-survey`
    return this.http.post(uri, request).pipe(map((data: IResponse) => data))
  }
}
