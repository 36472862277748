<form nz-form [formGroup]="form" (ngSubmit)="saveForm()">
  <div class="row w-100">
    <!--Name input-->
    <div class="col-12 col-md-6">
      <app-input-field
        class="w-100 mb-2"
        [groupName]="form"
        controlName="name"
        label="Nombre"
        errorMessage="Escribe tu nombre"
        icon="user"
        [maxLength]="50"
        [disabled]="form.get('name').disabled"
      ></app-input-field>
    </div>

    <!--Email input-->
    <div class="col-12 col-md-6">
      <app-input-field
        type="email"
        class="w-100 mb-2"
        [groupName]="form"
        controlName="email"
        label="Email"
        errorMessage="Ingresa un email válido"
        icon="mail"
        [maxLength]="50"
        [disabled]="form.get('email').disabled"
      ></app-input-field>
    </div>

    <!--Postal Address input-->
    <div class="col-12 col-md-6">
      <app-input-field
        class="w-100 mb-2"
        [groupName]="form"
        controlName="address"
        label="Dirección"
        errorMessage="Escribe Dirección (ej: Singapur #777, Casa)"
        icon="compass"
        [maxLength]="75"
        [disabled]="form.get('address').disabled"
      ></app-input-field>
    </div>

    <!--Country -->
    <div class="col-12 col-md-6">
      <nz-form-item>
        <nz-form-control [nzSpan]="24" nzErrorTip="Selecciona País">
          <nz-select
            nzShowSearch
            [nzPlaceHolder]="'Selecciona tu pais'"
            formControlName="country"
            nzAllowClear
          >
            <nz-option
              *ngFor="let option of countries"
              [nzValue]="option.countryName"
              [nzLabel]="option.countryName"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>

    <!--Ciudad-->
    <div class="col-12 col-md-6">
      <app-input-field
        class="w-100 mb-2"
        [groupName]="form"
        controlName="city"
        label="Ciudad"
        errorMessage="Escribe tu ciudad"
        icon="flag"
        [maxLength]="50"
        [disabled]="form.get('city').disabled"
      ></app-input-field>
    </div>

    <!--Codigo Postal -->
    <div class="col-12 col-md-6">
      <app-input-field
        class="w-100 mb-2"
        [groupName]="form"
        controlName="postalCode"
        label="Código postal"
        errorMessage="Escribe código postal"
        icon="environment"
        [maxLength]="10"
        [disabled]="form.get('postalCode').disabled"
      ></app-input-field>
    </div>

    <div class="d-block ml-lg-auto mt-4">
      <button type="submit" class="btn btn-primary ml-2">
        <span class="mr-2">Continuar</span>
        <span nz-icon nzType="right" nzTheme="outline"></span>
      </button>
    </div>
  </div>
</form>
