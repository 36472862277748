export class UpdateLoadingSession {
  static readonly type = '[SESSION] update loading session'
  constructor(public payload: boolean) {}
}

export class UpdateLoadingSessionPage {
  static readonly type = '[SESSION] update loading session page'
  constructor(public payload: boolean) {}
}

export class UpdateLoadingLogout {
  static readonly type = '[SESSION] update loading logout'
  constructor(public payload: boolean) {}
}

export class UpdateActiveURL {
  static readonly type = '[SESSION] update active URL'
  constructor(public payload: string) {}
}
