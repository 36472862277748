import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import {
  BillService,
  DashboardService,
  DomObserverService,
  IAService,
  PlanService,
  QuestionService,
  ReportService,
  SessionService,
  SettingsService,
  SharedService,
  SidebarService,
  SurveyCategoryService,
  UserService,
} from './service.index'

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    SettingsService,
    SharedService,
    SidebarService,
    SessionService,
    QuestionService,
    UserService,
    PlanService,
    BillService,
    ReportService,
    DashboardService,
    IAService,
    DomObserverService,
    SurveyCategoryService,
  ],
})
export class ServiceModule {}
