import { ChangeDetectorRef, Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'

@Component({
  selector: 'app-advance-survey-form',
  templateUrl: './advance-survey-form.component.html',
  styleUrls: ['./advance-survey-form.component.scss'],
})
export class AdvanceSurveyFormComponent implements OnInit {
  surveyForm: FormGroup
  currentStep = 0
  audiences = [
    { value: 'general', label: 'General', icon: 'assets/icons/general.png' },
    {
      value: 'frecuentes',
      label: 'Clientes Frecuentes',
      icon: 'assets/icons/frecuentes.png',
    },
    {
      value: 'nuevos',
      label: 'Clientes Nuevos',
      icon: 'assets/icons/nuevos.png',
    },
    {
      value: 'departamental',
      label: 'Empleados Departamentales',
      icon: 'assets/icons/departamental.png',
    },
  ]
  selectedAudience: string = null

  constructor(private fb: FormBuilder, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.surveyForm = this.fb.group({
      name: ['', Validators.required],
      objective: ['', Validators.required],
      category: ['', Validators.required],
      audience: ['', Validators.required],
      anonymity: [false],
      dataCollection: [false],
      expirationDate: [null, Validators.required],
    })
    this.currentStep = 0
    this.cdr.detectChanges()
  }

  handleIndexChange(event: any): void {
    console.log(event) // Verifica qué está emitiendo realmente el evento
    this.currentStep = event
  }

  selectAudience(value: string): void {
    this.selectedAudience = value
    this.surveyForm.controls['audience'].setValue(value)
  }

  onFirstStepSubmit(): void {
    if (
      this.surveyForm.controls['name'].valid &&
      this.surveyForm.controls['objective'].valid
    ) {
      this.currentStep++
    }
  }

  onSecondStepSubmit(): void {
    if (
      this.surveyForm.controls['category'].valid &&
      this.surveyForm.controls['audience'].valid
    ) {
      this.currentStep++
    }
  }

  onThirdStepSubmit(): void {
    // Validación adicional si es necesaria para preguntas dinámicas
    this.currentStep++
  }

  onFinalStepSubmit(): void {
    if (this.surveyForm.valid) {
      console.log('Formulario de Encuesta:', this.surveyForm.value)
      // Aquí podrías implementar la lógica para enviar los datos a un servidor o procesarlos como sea necesario
    }
  }

  index = 'First-content'

  pre(): void {
    this.currentStep -= 1
    this.changeContent()
  }

  next(): void {
    this.currentStep += 1
    this.changeContent()
  }

  done(): void {
    console.log('done')
  }

  changeContent(): void {
    switch (this.currentStep) {
      case 0: {
        this.index = 'First-content'
        break
      }
      case 1: {
        this.index = 'Second-content'
        break
      }
      case 2: {
        this.index = 'third-content'
        break
      }
      default: {
        this.index = 'error'
      }
    }
  }
}
