import { Injectable } from '@angular/core'
import { Select, Store } from '@ngxs/store'
import { Observable } from 'rxjs'
import { finalize } from 'rxjs/operators'
import { chartOptionsTendencyResponses } from 'src/app/pages/survey/survey-report/mappersCharts/tendency-week.mapper'
import { DashboardService } from '../../services/pages/dashboard.service'
import { IResponse } from './../../models/response/IResponse'
import { ReportType } from './../../models/types/report.types'
import { chartOptionsWeekResponses } from './../../pages/survey/survey-report/mappersCharts/week-responses.mapper'
import {
  UpdateGeneralInfo,
  UpdateIsLoadingGeneralInfo,
  UpdateIsLoadingWeekResponses,
  UpdateTendencyWeekReport,
  UpdateWeekResponsesReport,
  UpdateisLoadingTendencyWeekResponses,
} from './dashboard.actions'
import {
  DashboardState,
  IGeneralInfo,
  ITendecyWeekResponsesReport,
  IWeekResponsesReport,
} from './dashboard.state'

@Injectable({ providedIn: 'root' })
export class DashboardFacade {
  @Select(DashboardState.getIsLoadingGeneralInfo)
  isLoadingGeneralInfo$: Observable<boolean>
  @Select(DashboardState.getIsLoadingWeekResponses)
  isLoadingWeekResponses$: Observable<boolean>
  @Select(DashboardState.getIsLoadingTendency)
  isLoadingTendency$: Observable<boolean>
  @Select(DashboardState.getGeneralInfo)
  generalInfo$: Observable<IGeneralInfo>
  @Select(DashboardState.getWeekResponsesReport)
  weekResponsesReport$: Observable<IWeekResponsesReport | any>
  @Select(DashboardState.getTendencyWeeksReport)
  tendencyWeekReport$: Observable<ITendecyWeekResponsesReport | any>

  constructor(private store: Store, private _dashboard: DashboardService) {}

  public getQuestionResponsesDataReportFetch(): void {
    this.store.dispatch(new UpdateIsLoadingGeneralInfo(true))

    this._dashboard
      .getGeneralInfo()
      .pipe(
        finalize(() =>
          this.store.dispatch(new UpdateIsLoadingGeneralInfo(false))
        )
      )
      .subscribe((response: IResponse) => {
        switch (response.status.code) {
          case 200:
            this.store.dispatch(new UpdateGeneralInfo(response.data))
            break
          default:
            break
        }
      })
  }

  public getWeekResponsesDataReportFetch(
    surveyId: string = '0',
    typeReportWeek: ReportType = 'surveys'
  ): void {
    this.store.dispatch(new UpdateIsLoadingWeekResponses(true))
    this._dashboard
      .getWeekResponses(surveyId, typeReportWeek)
      .pipe(
        finalize(() => {
          this.store.dispatch(new UpdateIsLoadingWeekResponses(false))
        })
      )
      .subscribe((response: IResponse) => {
        switch (response.status.code) {
          case 200: {
            let categories = response.data.map((item) => item.day)
            const dataSeries = response.data.map((item) => item.value)
            const dataChart = chartOptionsWeekResponses(
              categories,
              dataSeries,
              typeReportWeek
            )
            this.store.dispatch(new UpdateWeekResponsesReport(dataChart))
            break
          }
          default:
            break
        }
      })
  }

  public getTendencyWeeksReportFetch(typeQuestionId: number = 1): void {
    this.store.dispatch(new UpdateisLoadingTendencyWeekResponses(true))
    this._dashboard
      .getTendencyResultSurvey(typeQuestionId)
      .pipe(
        finalize(() =>
          this.store.dispatch(new UpdateisLoadingTendencyWeekResponses(false))
        )
      )
      .subscribe((response: IResponse) => {
        switch (response.status.code) {
          case 200:
            let categories = response.data.map((item) => item.day)
            const dataSeriesPrev = response.data.map(
              (item) => item.previousWeekAverage
            )
            const dataSeriesCurr = response.data.map(
              (item) => item.currentWeekAverage
            )
            const dataChart = chartOptionsTendencyResponses(
              categories,
              dataSeriesCurr,
              dataSeriesPrev
            )
            this.store.dispatch(new UpdateTendencyWeekReport(dataChart))
            break
          default:
            break
        }
      })
  }
}
