import {
  HTTP_INTERCEPTORS,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { TokenService } from '../services/session/token.service'

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private _token: TokenService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.url.startsWith('https://cms.hware.cl/api/')) {
      return next.handle(req)
    }

    req = req.clone({
      setHeaders: {
        Authorization: `Bearer ${this._token.getToken()}`,
      },
    })

    return next.handle(req)
  }
}

export const authInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
]
