import { PlanTypesComponent } from './plan-types/plan-types.component'
import { PlanComponent } from './plan.component'
import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { PaymentProcessComponent } from './payment/payment-process/payment-process.component'
import { PaymentComponent } from './payment/payment.component'

const routes: Routes = [
  {
    path: '',
    component: PlanComponent,
    children: [
      {
        path: '',
        component: PlanTypesComponent,
        data: { title: 'Planes' },
      },
      {
        path: 'payment/:planId/:typeSuscription',
        component: PaymentComponent,
        data: { title: 'Pagar Plan' },
      },
      {
        path: 'process-payment',
        component: PaymentProcessComponent,
        data: { title: 'Validando Pago' },
      },
    ],
  },
  {
    path: '',
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    path: '*',
    redirectTo: '',
    pathMatch: 'full',
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PlanRoutingModule {}
