import { HighchartsChartModule } from 'highcharts-angular'
import { SharedModule } from './../../shared/shared.module'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { NgZorroAntdModule } from 'src/app/ng-zorro-antd.module'
import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ReviewsComponent } from './reviews/reviews.component'
import { DigitalBookComponent } from './digital-book.component'

@NgModule({
  declarations: [ReviewsComponent, DigitalBookComponent],
  imports: [
    CommonModule,
    NgZorroAntdModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    HighchartsChartModule,
  ],
  providers: [],
})
export class DigitalBookModule {}
