<div class="card-plan mx-auto">
  <h3 class="mb-4">Plan {{ infoPlan.name }}</h3>
  <div class="d-block">
    <span class="font-weight-light">Total encuestas</span>
    <span class="float-right font-weight-bold">{{
      infoPlan.numberPlanSurveys
    }}</span>
  </div>
  <div class="d-block">
    <span class="font-weight-light">Respuestas mensuales </span>
    <span class="float-right font-weight-bold">{{
      infoPlan.numberPlanResponses
    }}</span>
  </div>

  <button
    class="btn btn-light-gray d-block mx-auto mt-5"
    [routerLink]="routerPlan"
  >
    <span>Mejorar Plan</span>
    <i nz-icon class="ml-2" nzType="right" nzTheme="outline"></i>
  </button>
</div>
