<nz-form-item [formGroup]="groupName" class="pb-2 custom-text-area">
  <nz-form-control [nzErrorTip]="errorMessage">
    <nz-textarea-count [nzMaxCharacterCount]="maxLength">
      <textarea
        nz-input
        [id]="controlName"
        [placeholder]="placeholder"
        [formControlName]="controlName"
        [rows]="4"
        [maxlength]="maxLength"
        [disabled]="disabled"
        [readOnly]="readOnly"
        (blur)="onBlur()"
        (focus)="onFocus()"
      ></textarea>
    </nz-textarea-count>
    <label [for]="controlName" class="floating-label">
      {{ label }}
    </label>
  </nz-form-control>
</nz-form-item>
