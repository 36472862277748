import { Component } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { IValidatePayment } from 'src/app/models/request/IValidatePayment'
import { PaymentFacade } from 'src/app/store/payment/payment.facade'

@Component({
  selector: 'app-payment-process',
  templateUrl: './payment-process.component.html',
  styleUrls: ['./payment-process.component.scss'],
})
export class PaymentProcessComponent {
  constructor(
    public router: Router,
    private route: ActivatedRoute,
    public paymentFacade: PaymentFacade
  ) {
    this.route.queryParams.subscribe((response: IValidatePayment) => {
      const { token, cancelledId } = response
      const request: IValidatePayment = {
        token,
        cancelledId,
      }

      this.paymentFacade.validatePayment(request)
    })
  }
}
