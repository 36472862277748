import { Pipe, PipeTransform } from '@angular/core'
import { DatePipe } from '@angular/common'

@Pipe({
  name: 'customDate',
})
export class CustomDatePipe implements PipeTransform {
  transform(value: string): string {
    const datePipe = new DatePipe('es-CL')
    const formattedDate = datePipe.transform(
      value,
      "dd 'de' MMMM 'del' yyyy - HH:mm",
      'GMT'
    )
    return formattedDate || ''
  }
}
