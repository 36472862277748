import { Injectable } from '@angular/core'
import { Select, Store } from '@ngxs/store'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { Observable } from 'rxjs'
import { first } from 'rxjs/operators'
import { ICreateQuestion } from 'src/app/models/request/ICreateQuestion'
import { IQuestion } from 'src/app/models/response/IQuestion'
import { IQuestionType } from 'src/app/models/response/IQuestionType'
import { IResponse } from 'src/app/models/response/IResponse'
import { ISurvey } from 'src/app/models/response/ISurvey'
import {
  UpdateIsErrorQuestionList,
  UpdateIsLoadingCreateQuestion,
  UpdateMessageQuestionEditLoading,
  UpdateOriginalQuestionList,
  UpdateQuestion,
  UpdateQuestionTypes,
} from 'src/app/store/question/question.actions'
import { QuestionState } from 'src/app/store/question/question.state'
import { UpdateSurvey } from 'src/app/store/survey/survey.actions'
import { IUpdateQuestion } from '../../models/request/IUpdateQuestion'
import { QuestionService } from '../../services/pages/question.service'
import {
  ERROR_SERVICE_MESSAGE,
  ERROR_SERVICE_TITLE,
} from '../../utils/constants/generic-messages'
import {
  SUCCESS_ARCHIVED_QUESTION_MESSAGE,
  SUCCESS_ARCHIVED_QUESTION_TITLE,
  SUCCESS_ORDERED_QUESTION_MESSAGE,
  SUCCESS_ORDERED_QUESTION_TITLE,
  SUCCESS_UPDATE_QUESTION_MESSAGE,
  SUCCESS_UPDATE_QUESTION_TITLE,
} from '../../utils/constants/question-message'
import { SurveyState } from '../survey/survey.state'
import {
  UpdateLoadingQuestionList,
  UpdateQuestionList,
} from './question.actions'

@Injectable()
export class QuestionFacade {
  @Select(QuestionState.getQuestion) question$: Observable<IQuestion>
  @Select(QuestionState.getQuestionList) questions$: Observable<IQuestion[]>
  @Select(QuestionState.getOriginalQuestionList) originalQuestions$: Observable<
    IQuestion[]
  >
  @Select(QuestionState.getQuestionTypes)
  questionTypes$: Observable<IQuestionType[]>
  @Select(QuestionState.getLoadingQuestionList)
  isLoadingQuestionList$: Observable<boolean>
  @Select(QuestionState.getIsLoadingCreateQuestion)
  isLoadingCreateQuestion$: Observable<boolean>
  @Select(QuestionState.getIsChangesSavingOrder)
  isChangesSavingOrder$: Observable<boolean>
  @Select(QuestionState.getMessageQuestionEditLoading)
  messageQuestionEditLoading$: Observable<string>
  @Select(QuestionState.getIsErrorQuestion)
  isErrorQuestion$: Observable<boolean>
  @Select(QuestionState.getIsErrorQuestionList)
  isErrorQuestionList$: Observable<boolean>

  @Select(SurveyState.getSurvey)
  private survey$: Observable<ISurvey>
  private survey: ISurvey

  constructor(
    private store: Store,
    private _question: QuestionService,
    private notification: NzNotificationService
  ) {
    this.survey$.subscribe((survey: ISurvey) => {
      if (survey) {
        this.survey = survey
      }
    })
  }

  public async obtainQuestionListFetch(
    surveyId: string,
    isLoadingCreateQuestion: boolean = false
  ): Promise<void> {
    if (!isLoadingCreateQuestion) {
      this.store.dispatch(new UpdateQuestionList([]))
      this.store.dispatch(new UpdateLoadingQuestionList(true))
    }
    this.store.dispatch(new UpdateIsLoadingCreateQuestion(true))

    const questions = await this.questions$.pipe(first()).toPromise()

    this._question.listQuestionBySurveyId(surveyId).subscribe(
      async (response: IResponse) => {
        switch (response.status.code) {
          case 201:
          case 200:
            response.data = response.data.map(
              (item: IQuestion, index: number) => {
                return {
                  ...item,
                  orden: index + 1,
                  isChangesSaving: true,
                  isSelected: false,
                }
              }
            )

            this.store.dispatch(
              new UpdateSurvey({
                ...this.survey,
                totalQuestions: response.data.filter((item) => item.isActive)
                  .length,
              })
            )

            this.store.dispatch(new UpdateQuestionList(response.data))
            this.store.dispatch(new UpdateOriginalQuestionList(response.data))
            break

          default:
            this.store.dispatch(new UpdateIsErrorQuestionList(true))
            this.store.dispatch(
              new UpdateQuestionList(questions.length > 0 ? questions : [])
            )
            break
        }

        this.store.dispatch(new UpdateIsLoadingCreateQuestion(false))
        this.store.dispatch(new UpdateLoadingQuestionList(false))
      },
      (error) => {
        this.store.dispatch(
          new UpdateQuestionList(questions.length > 0 ? questions : [])
        )
        this.store.dispatch(new UpdateIsLoadingCreateQuestion(false))
        this.store.dispatch(new UpdateLoadingQuestionList(false))
        this.store.dispatch(new UpdateIsErrorQuestionList(error.status !== 400))
      }
    )
  }

  async loadQuestionsSurveyFetch(surveyId: string): Promise<IQuestion[]> {
    try {
      const response = await this._question
        .listQuestionBySurveyId(surveyId)
        .pipe(first())
        .toPromise()
      return response.data
    } catch (error) {
      return []
    }
  }

  public obtainQuestionTypesFetch(): void {
    this._question.listQuestionTypes().subscribe((response: IResponse) => {
      // TODO: MVP 1 - just use CSAT - NPS for the first mvp
      const questionTypes = response.data.slice(0, 2)
      this.store.dispatch(new UpdateQuestionTypes(questionTypes))
    })
  }

  public updateQuestion(question: IQuestion): void {
    this.store.dispatch(new UpdateQuestion(question))
    if (question) this.updateQuestionList(question, false)
  }

  public changeOptionsFetch(question: IQuestion, surveyId: string): void {
    this.store.dispatch(new UpdateLoadingQuestionList(true))

    question = {
      ...question,
      isLoadingStatus: true,
    }

    const request = {
      questionId: question.questionId,
      surveyId: surveyId,
      isActive: question.isActive,
      isMandatory: question.isMandatory,
      commentAvailable: question.commentAvailable,
    }

    this._question.changeOptionsQuestion(request).subscribe(
      async (response) => {
        switch (response.status.code) {
          case 200:
          case 201:
            this.updateQuestionList({
              ...question,
              isLoadingStatus: false,
            })
            this.store.dispatch(new UpdateLoadingQuestionList(false))

            this.notification.create(
              'success',
              SUCCESS_UPDATE_QUESTION_TITLE,
              SUCCESS_UPDATE_QUESTION_MESSAGE
            )
            const mf: HTMLIFrameElement = document.getElementById(
              'imf'
            ) as HTMLIFrameElement
            if (mf) {
              mf.contentWindow.postMessage(
                { isReset: true, type: 'GET_QUESTIONS' },
                '*'
              )
            }
            break
          default:
            this.store.dispatch(new UpdateLoadingQuestionList(false))
            break
        }
      },
      (error: Error) => {
        this.store.dispatch(new UpdateLoadingQuestionList(false))
        this.notification.create(
          'error',
          ERROR_SERVICE_TITLE,
          ERROR_SERVICE_MESSAGE
        )
      }
    )
  }

  public updateQuestionOrderFetch(
    questionsRequest: IQuestion[],
    surveyId: string
  ) {
    this._question
      .updateOrderQuestionList(questionsRequest, surveyId)
      .subscribe(
        (response: IResponse) => {
          switch (response.status.code) {
            case 200:
            case 201:
              this.notification.create(
                'success',
                SUCCESS_ORDERED_QUESTION_TITLE,
                SUCCESS_ORDERED_QUESTION_MESSAGE
              )
              this.obtainQuestionListFetch(surveyId)
              const mf = document.getElementById(
                'survey-mf'
              ) as HTMLIFrameElement
              if (mf) {
                mf.contentWindow.postMessage(
                  { surveyId: this.survey.surveyId, type: 'GET_QUESTIONS' },
                  '*'
                )
              }
              break
            default:
              this.notification.create(
                'error',
                ERROR_SERVICE_TITLE,
                ERROR_SERVICE_MESSAGE
              )
              break
          }
        },
        (error: Error) => {
          this.notification.create(
            'error',
            ERROR_SERVICE_TITLE,
            ERROR_SERVICE_MESSAGE
          )
        }
      )
  }

  public archivedQuestionFetch(question: IQuestion, surveyId: string): void {
    if (!question.hasActivity) {
      this.store.dispatch(new UpdateLoadingQuestionList(true))
      question = {
        ...question,
        isArchived: !question.isArchived,
        isLoadingStatus: true,
      }

      const request = {
        surveyId: surveyId,
        questionId: question.questionId,
        isArchived: true,
      }

      this._question.deleteQuestionSurvey(request).subscribe(
        async (response: IResponse) => {
          switch (response.status.code) {
            case 200:
            case 201:
              this.updateQuestionList({ ...question, isLoadingStatus: false })
              this.store.dispatch(new UpdateLoadingQuestionList(false))

              this.notification.create(
                'info',
                SUCCESS_ARCHIVED_QUESTION_TITLE,
                SUCCESS_ARCHIVED_QUESTION_MESSAGE
              )

              const mf = document.getElementById(
                'survey-mf'
              ) as HTMLIFrameElement
              if (mf) {
                mf.contentWindow.postMessage(
                  { surveyId: this.survey.surveyId, type: 'GET_QUESTIONS' },
                  '*'
                )
              }
              break
            default:
              this.store.dispatch(new UpdateLoadingQuestionList(false))
              break
          }
        },
        (error: Error) => {
          this.store.dispatch(new UpdateLoadingQuestionList(false))
          this.notification.create(
            'error',
            ERROR_SERVICE_TITLE,
            ERROR_SERVICE_MESSAGE
          )
        }
      )
    }
  }

  public createQuestionFetch(request: ICreateQuestion): void {
    this.store.dispatch(new UpdateIsLoadingCreateQuestion(true))

    this._question.createQuestion(request).subscribe(
      async (response: IResponse) => {
        switch (response.status.code) {
          case 200:
          case 201:
            this.obtainQuestionListFetch(request.surveyId, true)

            break
          default:
            break
        }
      },
      (error: Error) => {
        this.notification.create(
          'error',
          ERROR_SERVICE_TITLE,
          ERROR_SERVICE_MESSAGE
        )
        this.store.dispatch(new UpdateLoadingQuestionList(false))
      }
    )
  }

  public updateQuestionFetch(
    request: IUpdateQuestion,
    question: IQuestion
  ): void {
    this._question.updateQuestion(request).subscribe(
      (response: IResponse) => {
        switch (response.status.code) {
          case 200:
          case 201:
            question = {
              ...question,
              title: request.title,
              optionScale: +request.optionScale,
            }

            this.store.dispatch(new UpdateQuestion(question))
            this.notification.create(
              'success',
              SUCCESS_UPDATE_QUESTION_TITLE,
              SUCCESS_UPDATE_QUESTION_MESSAGE
            )
            break
          default:
            this.notification.create(
              'warning',
              ERROR_SERVICE_TITLE,
              ERROR_SERVICE_MESSAGE
            )
            break
        }
      },
      (error: Error) => {
        this.notification.create(
          'error',
          ERROR_SERVICE_TITLE,
          ERROR_SERVICE_MESSAGE
        )
      }
    )
  }

  public saveMessageQuestionEditLoading(message: string): void {
    this.store.dispatch(new UpdateMessageQuestionEditLoading(message))
  }

  private async updateQuestionList(
    question: IQuestion,
    isLoading: boolean = true
  ): Promise<void> {
    this.store.dispatch(new UpdateLoadingQuestionList(isLoading))

    const questions = await this.questions$.pipe(first()).toPromise()

    const updatedQuestions = questions.map((item: IQuestion) => {
      if (item.questionId === question.questionId) {
        item = question
      }
      return item
    })

    this.store.dispatch(
      new UpdateSurvey({
        ...this.survey,
        totalQuestions: updatedQuestions.filter(
          (item) => item.isActive && !item.isArchived
        ).length,
      })
    )

    if (question.isArchived) {
      this.store.dispatch(
        new UpdateQuestionList(
          updatedQuestions.filter(
            (item) => item.questionId !== question.questionId
          )
        )
      )
    } else {
      this.store.dispatch(new UpdateQuestionList(updatedQuestions))
    }
    this.store.dispatch(new UpdateLoadingQuestionList(false))
  }
}
