<div *ngIf="!showDrawerMobile" class="sidebar">
  <div class="sidebar-brand">
    <img
      class="img-fluid cursor-pointer img-responsive d-block mx-auto"
      ngClass="{'logo-xs': !showExpandSidebar}"
      [src]="!showExpandSidebar ? logoPathXS : logoPathLG"
      [width]="!showExpandSidebar ? '30px' : '100px'"
      alt=""
    />
  </div>

  <div class="sidebar-menu">
    <ul class="px-0">
      <li *ngFor="let item of menuOptions.menu">
        <div
          class="d-flex item-menu"
          [routerLinkActive]="['active']"
          [routerLink]="['/app' + item.url]"
          (click)="activateMenu(item.title, item.url)"
        >
          <a
            [routerLinkActive]="['active']"
            #childActive="routerLinkActive"
            class="icon-menu"
            [ngClass]="showExpandSidebar ? 'mx-3' : 'mx-auto'"
            [ngStyle]="{ color: !item.available && '#515151' }"
            [nzTooltipTitle]="
              !showExpandSidebar &&
              (item.available ? item.title : item.title + ' (no disponible)')
            "
            nzTooltipPlacement="right"
            nz-tooltip
          >
            <div
              [ngClass]="{
                'animated-fade-in-left':
                  activeMenu === item.title && item.available
              }"
            >
              <i
                [ngClass]="
                  !showExpandSidebar
                    ? 'my-auto d-block mx-auto'
                    : 'my-auto d-block mx-auto'
                "
                nz-icon
                [nzType]="item.icon"
                nzTheme="outline"
              ></i>
            </div>
          </a>
          <span
            class="cursor"
            [ngClass]="{ active: childActive.isActive }"
            *ngIf="showExpandSidebar"
          >
            {{ item.title }}
          </span>
        </div>
      </li>
    </ul>
  </div>
</div>

<div *ngIf="showDrawerMobile">
  <nz-drawer
    [nzClosable]="true"
    [nzVisible]="showExpandSidebar"
    nzPlacement="left"
    nzTitle="Menú"
    (nzOnClose)="changeShowExpandSidebar.emit()"
  >
    <ng-container *nzDrawerContent>
      <div class="sidebar-mobile">
        <div class="sidebar-menu">
          <ul class="px-0">
            <li *ngFor="let item of menuOptions.menu">
              <strong [ngClass]="{ active: childActive.isActive }"></strong>
              <strong [ngClass]="{ active: childActive.isActive }"></strong>

              <div class="">
                <a
                  [routerLink]="['/app' + item.url]"
                  [routerLinkActive]="['active']"
                  #childActive="routerLinkActive"
                  (click)="
                    activeMenu = item.title; changeShowExpandSidebar.emit()
                  "
                  class="ml-auto"
                  [ngStyle]="{ color: !item.available && '#515151' }"
                >
                  <div
                    [ngClass]="{
                      'animated-fade-in-left':
                        activeMenu === item.title && item.available
                    }"
                  >
                    <i
                      class="my-auto d-inline mx-auto"
                      nz-icon
                      [nzType]="item.icon"
                      nzTheme="outline"
                    ></i>
                    <span class="d-inline">{{ item.title }}</span>
                    <i
                      *ngIf="!item.available"
                      class="my-auto d-inline float-right mr-4"
                      nz-icon
                      nzType="lock"
                      nzTheme="outline"
                    ></i>
                  </div>
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </ng-container>
  </nz-drawer>
</div>
