<form
  *ngIf="!isLoadingForm"
  nz-form
  [formGroup]="formQuestion"
  (ngSubmit)="submitForm()"
>
  <nz-alert
    *ngIf="question && question.hasActivity"
    class="mb-3"
    nzType="warning"
    [nzMessage]="questionNoEditTitle"
    [nzDescription]="questionNoEditText"
    nzShowIcon
  ></nz-alert>

  <nz-form-item class="mb-2">
    <nz-form-label nzRequired class="p-0" [nzSpan]="24" nzFor="note"
      >Pregunta</nz-form-label
    >
    <nz-form-control [nzSpan]="24" nzErrorTip="Ingresa pregunta">
      <nz-textarea-count [nzMaxCharacterCount]="200">
        <textarea
          rows="3"
          maxlength="200"
          formControlName="inputQuestion"
          placeholder="Escribe tu pregunta"
          nz-input
        ></textarea>
      </nz-textarea-count>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item class="mb-2">
    <nz-form-label [nzSpan]="24" class="pb-0" nzFor="typeQuestion" nzRequired
      >Tipo Pregunta</nz-form-label
    >
    <nz-form-control [nzSpan]="24" nzErrorTip="Selecciona tipo pregunta">
      <nz-select
        [nzPlaceHolder]="'Elige el tipo de pregunta'"
        (ngModelChange)="selectedType = $event"
        formControlName="selectTypeQuestion"
        nzAllowClear
      >
        <nz-option
          *ngFor="let option of questionFacade.questionTypes$ | async"
          [nzValue]="option.questionTypeId"
          [nzLabel]="option.questionType"
        ></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item *ngIf="selectedType === 1" class="optionCsat">
    <nz-form-label [nzSpan]="24" class="pb-0" nzRequired
      >Escala de opciones</nz-form-label
    >
    <nz-form-control [nzSpan]="24" nzErrorTip="Ingrese Opcion">
      <nz-select
        formControlName="selectOption"
        [nzPlaceHolder]="'Elige una escala'"
      >
        <nz-option [nzValue]="3" [nzLabel]="'3 Opciones'"></nz-option>
        <nz-option [nzValue]="5" [nzLabel]="'5 Opciones'"></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item class="d-flex justify-content-end align-items-center pt-3">
    <nz-form-control [nzSpan]="24">
      <button
        [disabled]="isLoadingCreateQuestion"
        type="submit"
        class="btn btn-primary d-block ml-auto"
      >
        <i
          *ngIf="isLoadingCreateQuestion"
          class="mr-2"
          nz-icon
          [nzType]="'loading'"
        ></i>

        <span *ngIf="isLoadingCreateQuestion">Guardando</span>
        <span
          *ngIf="!isLoadingCreateQuestion"
          class="d-flex align-items-center"
          >{{ nameButton }}</span
        >
      </button>
    </nz-form-control>
  </nz-form-item>
</form>
