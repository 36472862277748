import { JiraFacade } from './../../../store/jira/jira.facade'
import { Component, OnInit, Output, EventEmitter } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { IBilling } from 'src/app/models/request/IBilling'
import { validateForm } from 'src/app/utils/formValidations/formValidations'
import { JiraState } from './../../../store/jira/jira.state'
import { Observable } from 'rxjs-compat'
import { Select } from '@ngxs/store'
@Component({
  selector: 'app-bill-form-request',
  templateUrl: './bill-form-request.component.html',
  styleUrls: ['./bill-form-request.component.scss'],
})
export class BillFormRequestComponent implements OnInit {
  public nameButton: string
  public showFormBillRequest: boolean
  public form!: FormGroup
  public billing: IBilling
  @Output() sendBillRequest = new EventEmitter()
  @Select(JiraState.getLoadingJiraTicketCreated)
  loadingJiraTicketRequest$: Observable<boolean>
  @Select(JiraState.getTicket) idJiraTicket$: Observable<string>
  constructor(private fb: FormBuilder, public jiraFacade: JiraFacade) {}

  ngOnInit(): void {
    this.nameButton = 'Solicitar Factura'
    this.form = this.fb.group({
      companyName: ['' || this.billing?.companyName, Validators.required],
      billingEmail: ['' || this.billing?.billingEmail, Validators.required],
      scopeBusiness: ['' || this.billing?.scopeBusiness, Validators.required],
      taxRegisterNumber: ['' || this.billing?.companyName, Validators.required],
      address: ['' || this.billing?.address, Validators.required],
    })
  }

  submitForm(): void {
    this.createBillingRequest()
  }

  private createBillingRequest(): void {
    validateForm(this.form)
    if (this.form.valid) {
      const billingRequest: IBilling = {
        companyName: this.form.get('companyName').value,
        billingEmail: this.form.get('billingEmail')?.value,
        scopeBusiness: this.form.get('scopeBusiness')?.value,
        taxRegisterNumber: this.form.get('taxRegisterNumber')?.value,
        address: this.form.get('address')?.value,
      }

      this.jiraFacade.createBillingRequestFetch(billingRequest)
      this.idJiraTicket$.subscribe((idTicket: string) => {
        if (idTicket) {
          this.sendBillRequest.emit()
        }
      })
    }
  }
}
