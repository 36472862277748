import { ReportType } from './../../../../models/types/report.types'
import { optionsChartDefault } from './../../../../utils/properties/hightchartOptions'

export const chartOptionsWeekResponses = (
  categories: string[],
  dataSeries: number[],
  typeReport: ReportType
) => {
  const colors = new Array(dataSeries.length - 1)
    .fill('#9b59b6')
    .concat('#2ecc71')
  return {
    lang: optionsChartDefault.lang,
    chart: {
      renderTo: 'container',
      type: 'column',
      options3d: {
        enabled: true,
        alpha: 15,
        beta: 15,
        depth: 50,
        viewDistance: 25,
      },
      backgroundColor: 'transparent',
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: categories,
      title: {
        text: 'Última semana',
      },
    },
    yAxis: {
      title: {
        text:
          typeReport === 'surveys'
            ? 'Cantidad de Encuestas'
            : 'Cantidad de Preguntas respondidas',
      },
    },
    tooltip: {
      headerFormat: '<b>{point.key}</b><br>',
      pointFormat: `Total ${
        typeReport === 'surveys' ? 'Encuestas' : 'Preguntas respondidas'
      }: {point.y}`,
    },
    title: {
      text: '',
    },
    // subtitle: {
    //   text: subtitle,
    // },
    legend: {
      enabled: false,
    },
    plotOptions: {
      column: {
        depth: 25,
        colorByPoint: true,
        colors: colors,
      },
    },
    series: [
      {
        data: dataSeries,
        type: 'column',
      },
    ],
  }
}
