import { Component, Input, OnInit, forwardRef } from '@angular/core'
import { FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms'

@Component({
  selector: 'app-text-area-field',
  templateUrl: './text-area-field.component.html',
  styleUrls: ['./text-area-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextAreaFieldComponent),
      multi: true,
    },
  ],
})
export class TextAreaFieldComponent implements OnInit {
  @Input() controlName: string
  @Input() groupName: FormGroup
  @Input() label: string
  @Input() errorMessage: string
  @Input() placeholder: string = 'Escribe aquí...'
  @Input() maxLength = 250
  @Input() disabled = false
  @Input() readOnly = false

  public isFocused: boolean = false
  public hasValue: boolean

  ngOnInit() {
    this.groupName.get(this.controlName).valueChanges.subscribe((value) => {
      this.hasValue = !!value
    })
  }

  onFocus() {
    this.isFocused = true
  }

  onBlur() {
    this.isFocused = false
  }
}
