import { Component, Input, OnInit } from '@angular/core'
import { FormGroup } from '@angular/forms'

@Component({
  selector: 'app-select-field',
  templateUrl: './select-field.component.html',
  styleUrls: ['./select-field.component.scss'],
})
export class SelectFieldComponent implements OnInit {
  @Input() controlName: string
  @Input() groupName: FormGroup
  @Input() label: string
  @Input() errorMessage: string
  @Input() options: any[] // Lista de opciones para el select
  @Input() placeholder: string = 'Seleccionar...'
  @Input() tagMode: boolean = false

  public isFocused: boolean = true

  ngOnInit(): void {}

  onFocus(): void {
    this.isFocused = true
  }

  onBlur(): void {
    this.isFocused = false
  }

  // Método para determinar si hay un valor seleccionado
  get hasValue(): boolean {
    const control = this.groupName.get(this.controlName)
    return control && control.value
  }
}
