import { ViewportScroller } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { PaymentFacade } from 'src/app/store/payment/payment.facade'
import { ICompanyInformation } from './../../../../store/payment/payment.state'
import {
  COMERCIAL_INFORMATION_ID,
  PAYMENT_METHOD_ID,
  PAYMENT_METHOD_NAME,
} from './../../../../utils/constants/payment'
import { validateForm } from './../../../../utils/formValidations/formValidations'

@Component({
  selector: 'app-billing-company-form',
  templateUrl: './billing-company-form.component.html',
  styleUrls: ['./billing-company-form.component.scss'],
})
export class BillingCompanyFormComponent implements OnInit {
  public form: FormGroup

  constructor(
    private fb: FormBuilder,
    public paymentFacade: PaymentFacade,
    private viewportScroller: ViewportScroller
  ) {}

  ngOnInit(): void {
    this.paymentFacade.companyInformation$.subscribe(
      (companyInformation: ICompanyInformation) => {
        this.form = this.fb.group({
          nameBusiness: [
            companyInformation ? companyInformation?.nameBusiness : '',
          ],
          secondaryEmail: [
            companyInformation ? companyInformation?.secondaryEmail : '',
            [Validators.email],
          ],
          scopeBusiness: [
            companyInformation ? companyInformation?.scopeBusiness : '',
          ],
          rut: [companyInformation ? companyInformation?.rut : ''],
        })
      }
    )
  }

  public saveForm(): void {
    validateForm(this.form)

    if (this.form.valid) {
      const companyInformation = {
        nameBusiness: this.form.get('nameBusiness').value,
        secondaryEmail: this.form.get('secondaryEmail').value,
        scopeBusiness: this.form.get('scopeBusiness').value,
        rut: this.form.get('rut').value,
      }

      this.paymentFacade.updateCompanyInformation(companyInformation)
      this.paymentFacade.UpdateStepById(
        COMERCIAL_INFORMATION_ID,
        PAYMENT_METHOD_NAME,
        true
      )
      this.paymentFacade.updateIsValidForm(true)

      setTimeout(() => {
        this.viewportScroller.scrollToAnchor(PAYMENT_METHOD_ID)
      }, 1)
    } else this.paymentFacade.updateIsValidForm(false)
  }
}
