import { Component, EventEmitter, Output } from '@angular/core'

@Component({
  selector: 'app-confirm-checkbox-component',
  templateUrl: './confirm-checkbox-component.component.html',
  styleUrls: ['./confirm-checkbox-component.component.scss'],
})
export class ConfirmCheckboxComponentComponent {
  @Output() confirmExitTutorial = new EventEmitter<boolean>()
  public dontShowAgain = false
}
