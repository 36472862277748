import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

@Component({
  selector: 'app-card-info',
  templateUrl: './card-info.component.html',
  styleUrls: ['./card-info.component.scss'],
})
export class CardInfoComponent implements OnInit {
  @Input() public title: string
  @Input() public description: string
  @Input() public actionText?: string
  @Output() public actionClicked = new EventEmitter<void>()

  public actionStartIndex = 0
  public actionEndIndex = 0

  ngOnInit() {
    if (this.actionText) this.findTextIndices(this.description, this.actionText)
  }

  onActionClick(): void {
    this.actionClicked.emit()
  }

  private findTextIndices(fullText: string, targetText: string): void {
    const startIndex = fullText.indexOf(targetText)
    if (startIndex !== -1) {
      const endIndex = startIndex + targetText.length
      this.actionStartIndex = startIndex
      this.actionEndIndex = endIndex
    }
  }
}
