<div class="maintenance">
  <div class="row align-items-center">
    <div class="col-md-12 col-lg-4">
      <div class="info">
        <span class="badge">Empati</span>
        <h1>Esta página se encuentra en construcción</h1>

        <span>
          Estamos trabajando para brindarte una mejor experiencia, pronto
          estaremos de vuelta con nuevas funcionalidades.
        </span>
      </div>
    </div>
    <div class="col-md-12 col-lg-8">
      <img [src]="IMG_INFO" class="img-fluid" alt="" />
    </div>
  </div>
</div>
