import { RouterModule } from '@angular/router'
import { DashboardComponent } from './dashboard.component'
import { PipesModule } from './../../pipes/pipes.module'
import { ComponentsModule } from './../../components/components.module'
import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { NgZorroAntdModule } from 'src/app/ng-zorro-antd.module'
import { QuestionModule } from '../question/question.module'
import { ClipboardModule } from 'ngx-clipboard'

import { HighchartsChartModule } from 'highcharts-angular'
import { ResponseTimelineComponent } from './response-timeline/response-timeline.component'
import { StatusSurveyComponent } from './status-survey/status-survey.component'

@NgModule({
  imports: [
    CommonModule,
    NgZorroAntdModule,
    FormsModule,
    ReactiveFormsModule,
    QuestionModule,
    ComponentsModule,
    ClipboardModule,
    PipesModule,
    RouterModule,
    HighchartsChartModule,
  ],
  declarations: [
    DashboardComponent,
    ResponseTimelineComponent,
    StatusSurveyComponent,
  ],
  exports: [],
})
export class DashboardModule {}
