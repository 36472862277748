import { Component, OnInit, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { NzCarouselComponent } from 'ng-zorro-antd/carousel'
import { IAboutPlanInfo } from 'src/app/models/response/IAboutPlanInfo'
import { PlanFacade } from 'src/app/store/plan/plan.facade'
import data from './plans-data-info.json'

@Component({
  selector: 'app-card-info-plans',
  templateUrl: './card-info-plans.component.html',
  styleUrls: ['./card-info-plans.component.scss'],
})
export class CardInfoPlansComponent implements OnInit {
  @ViewChild(NzCarouselComponent, { static: false })
  carouselSurvey: NzCarouselComponent

  public GRAPHIC_ROCKET = './assets/images/graphics/rocket-4.gif'
  public routerPlan: string
  public dataAboutSurvey: IAboutPlanInfo[]

  constructor(public router: Router, public planFacade: PlanFacade) {
    const url = this.router.routerState.snapshot.url
    this.routerPlan = url.split('survey').join('plans-prices')

    this.dataAboutSurvey = data
  }

  ngOnInit(): void {}

  public nextSlide(): void {
    this.carouselSurvey.next()
  }

  public prevSlide(): void {
    this.carouselSurvey.pre()
  }
}
