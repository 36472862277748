import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute } from '@angular/router'
import { Select } from '@ngxs/store'
import { IQuestion } from 'src/app/models/response/IQuestion'
import { ISurvey } from './../../../models/response/ISurvey'
import { QuestionFacade } from './../../../store/question/question.facade'
import { SurveyState } from './../../../store/survey/survey.state'
import {
  QUESTION_NO_EDIT_TEXT,
  QUESTION_NO_EDIT_TITLE,
} from './../../../utils/constants/question-message'
import { validateForm } from './../../../utils/formValidations/formValidations'

import { Observable } from 'rxjs-compat'

@Component({
  selector: 'app-question-form',
  templateUrl: './question-form.component.html',
  styleUrls: ['./question-form.component.scss'],
})
export class QuestionFormComponent implements OnInit {
  @Input() public editQuestion: boolean
  @Output() public handleCancel = new EventEmitter()

  @Select(SurveyState.getSurvey) private survey$: Observable<ISurvey>

  private surveyId: string
  private survey: ISurvey
  public questions: IQuestion[]
  public question: IQuestion
  public formQuestion: FormGroup
  public selectedType: number
  public optionCreate: boolean
  public isVisible: boolean
  public isLoadingCreateQuestion: boolean
  public isLoadingForm: boolean
  public nameButton: string

  public questionNoEditText: string = QUESTION_NO_EDIT_TEXT
  public questionNoEditTitle: string = QUESTION_NO_EDIT_TITLE

  constructor(
    private fb: FormBuilder,
    public activateRoute: ActivatedRoute,
    public questionFacade: QuestionFacade
  ) {
    this.surveyId = this.activateRoute.snapshot.params.surveyId
    this.selectedType = 0
    this.optionCreate = false
    this.isLoadingCreateQuestion = false
    this.isLoadingForm = true
    this.editQuestion = false
  }

  ngOnInit(): void {
    this.nameButton =
      this.editQuestion === true ? 'Editar Pregunta' : 'Crear Pregunta'
    this.initializeForm()

    this.survey$.subscribe((item: ISurvey) => {
      if (item) {
        this.survey = item
      }
    })
  }

  private initializeForm(): void {
    if (this.editQuestion) {
      this.questionFacade.question$.subscribe((question: IQuestion) => {
        if (question) {
          this.question = question
          this.formQuestion = this.fb.group({
            inputQuestion: [
              { value: question.title, disabled: question.hasActivity },
              Validators.required,
            ],
            selectTypeQuestion: [
              {
                value: +question.typeId,
                disabled: question.hasActivity,
              },
              Validators.required,
            ],
            selectOption: [
              {
                value: +question.optionScale,
                disabled: question.hasActivity,
              },
              Validators.required,
            ],
          })

          this.isLoadingForm = false
        }
      })

      return
    }

    this.isLoadingForm = false
    this.formQuestion = this.fb.group({
      inputQuestion: ['', Validators.required],
      selectTypeQuestion: ['', Validators.required],
      selectOption: ['', Validators.required],
    })
  }

  public submitForm(): void {
    if (this.isValidForm()) {
      this.editQuestion ? this.updateQuestion() : this.createQuestionFetch()
    }
  }

  private isValidForm(): boolean {
    const exceptions =
      this.formQuestion.controls['selectTypeQuestion'].value === 1
        ? []
        : ['selectOption']

    validateForm(this.formQuestion, exceptions)

    if (exceptions.length > 0) {
      return (
        this.formQuestion.controls['inputQuestion'].valid &&
        this.formQuestion.controls['selectTypeQuestion'].valid
      )
    } else {
      return this.formQuestion.valid
    }
  }

  public updateQuestion(): void {
    this.questionFacade.saveMessageQuestionEditLoading(
      'Actualizando pregunta...'
    )

    this.isLoadingCreateQuestion = true

    this.formQuestion.disable()

    const inputQuestion = this.formQuestion.get('inputQuestion')?.value
    const selectTypeQuestion =
      +this.formQuestion.get('selectTypeQuestion')?.value
    const selectOption = +this.formQuestion.get('selectOption')?.value

    const request = {
      questionId: this.question.questionId,
      typeId: +selectTypeQuestion,
      title: inputQuestion,
      optionScale: selectOption ? selectOption : 0,
    }

    this.questionFacade.updateQuestionFetch(request, this.question)

    this.isLoadingCreateQuestion = false
    this.formQuestion.enable()
    this.handleCancel.emit()
  }

  public createQuestionFetch(): void {
    this.questionFacade.saveMessageQuestionEditLoading(
      'Creando y configurando pregunta...'
    )

    this.isLoadingCreateQuestion = true

    this.formQuestion.disable()

    const inputQuestion: string = this.formQuestion.get('inputQuestion')?.value
    const typeQuestion: number =
      this.formQuestion.get('selectTypeQuestion')?.value
    const optionTypeCsat: number = this.formQuestion.get('selectOption')?.value

    const requestCreateQuestion = {
      questionTitle: inputQuestion,
      questionType: typeQuestion,
      questionScale: optionTypeCsat,
      questionTypeForm: 1,
      surveyId: this.surveyId,
    }

    this.questionFacade.createQuestionFetch(requestCreateQuestion)
    this.formQuestion.enable()
    this.handleCancel.emit()
  }
}
