import { Component, OnDestroy } from '@angular/core'
import * as Highcharts from 'highcharts'
import { Subscription } from 'rxjs-compat'
import { first } from 'rxjs/operators'
import { IQuestion } from 'src/app/models/response/IQuestion'
import { ISummary } from '../../../models/request/ISummary'
import { ReportWeekType } from '../../../models/types/report.types'
import { DashboardFacade } from '../../../store/dashboard/dashboard.facade'
import { QuestionFacade } from '../../../store/question/question.facade'
import { ReportFacade } from '../../../store/reports/report.facade'
import { SurveyFacade } from '../../../store/survey/survey.facade'
import { chartOptionsQuestionResponses } from './../../../pages/survey/survey-report/mappersCharts/options.mapper'
@Component({
  selector: 'app-option-result',
  templateUrl: './option-result.component.html',
  styleUrls: ['./option-result.component.scss'],
})
export class OptionResultComponent implements OnDestroy {
  Highcharts: typeof Highcharts = Highcharts

  // public barCharOptions = chart

  public resultChartBarOptions: any = chartOptionsQuestionResponses(
    ['1', '2', '3', '4', '5'],
    [0, 0, 0, 0, 0]
  )

  public CSAT_SCALE_IMG = '../../../assets/images/graphics/csat-scale.png'

  public typeReportWeek: ReportWeekType = 'monthly'
  public surveySelectedId: string = '0'
  public isLoadingSummary = false
  private subscription: Subscription
  private isGetSummay: boolean = false

  constructor(
    public questionFacade: QuestionFacade,
    private surveyFacade: SurveyFacade,
    public dashboardFacade: DashboardFacade,
    public reportFacade: ReportFacade
  ) {
    this.subscription = this.questionFacade.question$.subscribe(
      async (question) => {
        if (
          question &&
          question.questionId !== this.surveySelectedId &&
          (question.typeId === 3 || question.typeId === 4) &&
          !this.isGetSummay
        ) {
          this.surveySelectedId = question.questionId
          await this.getSummary(question)
          this.isGetSummay = true
        }
      }
    )
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

  public async getSummary(question: IQuestion): Promise<void> {
    try {
      const survey = await this.surveyFacade.survey$.pipe(first()).toPromise()

      const request: ISummary = {
        surveyId: survey.surveyId,
        questionTypeId: question.typeId.toString(),
        questionId: question.questionId.toString(),
        timeFrame: this.typeReportWeek,
      }

      this.isLoadingSummary = true

      const res = await this.reportFacade
        .getOptionsQuestionSummaryFetch(request)
        .toPromise()

      if (res.status.code === 200) {
        const summaryResult = res.data.optionsData

        const categories = summaryResult.map((item) => item.option)
        const dataSeries = summaryResult.map((item) => item.count)

        this.resultChartBarOptions = chartOptionsQuestionResponses(
          categories,
          dataSeries
        )
      }

      this.isLoadingSummary = false
    } catch (error) {
      console.error('Error fetching summary:', error)
      this.isLoadingSummary = false
    }
  }

  public async onRadioChange(event: any): Promise<void> {
    this.typeReportWeek = event
    const question = await this.questionFacade.question$
      .pipe(first())
      .toPromise()

    this.getSummary(question)
  }
}
