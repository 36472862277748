import { Injectable } from '@angular/core'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import {
  PAYMENT_COMERCIAL_KEY,
  PAYMENT_PERSON_KEY,
  PAYMENT_STEPS,
  PAYMENT_STEP_KEY,
} from './../../utils/constants/payment'
import {
  UpdateCompanyInformation,
  UpdateIsLoadingDocument,
  UpdateIsLoadingMyPayments,
  UpdateIsLoadingPaymentForm,
  UpdateIsLoadingProcessPayment,
  UpdateIsValidForm,
  UpdateMethodPayment,
  UpdateMyPayments,
  UpdatePersonalInformation,
  UpdateStepById,
  UpdateSteps,
} from './payment.actions'

export interface IStepPaymentForm {
  id: string
  name: string
  active: boolean
  disabled: boolean
  complete: boolean
}

export interface IPersonalInformation {
  name: string
  email: string
  address: string
  country: string
  city: string
  postalCode: string
}

export interface ICompanyInformation {
  nameBusiness: string
  secondaryEmail: string
  scopeBusiness: string
  rut: string
}
export interface IMyPayments {
  amount: string
  date: string
  detail: string
  id: string
  statusBilling: { id: string; name: string }
  statusPayment: { id: string; name: string }
}
export interface IPaymentModel {
  steps: IStepPaymentForm[]
  isValidForm: boolean
  personalInformation: IPersonalInformation
  companyInformation: ICompanyInformation
  methodPayment: string
  isLoadingPaymentForm: boolean
  isLoadingProcessPayment: boolean
  isLoadingMyPayments: boolean
  isLoadingDocument: boolean
  myPayments: IMyPayments
}

const getSteps = (): IStepPaymentForm[] => {
  const key = PAYMENT_STEP_KEY

  try {
    const data = JSON.parse(localStorage.getItem(key))
    return data ? data : PAYMENT_STEPS
  } catch (error) {
    return PAYMENT_STEPS
  }
}

const obtainLocalStorage = (key: string) => {
  try {
    const data = JSON.parse(localStorage.getItem(key))
    return data ? data : null
  } catch (error) {
    return null
  }
}

@State<IPaymentModel>({
  name: 'Payment',
  defaults: {
    steps: getSteps(),
    isValidForm: false,
    personalInformation: obtainLocalStorage(PAYMENT_PERSON_KEY),
    companyInformation: obtainLocalStorage(PAYMENT_COMERCIAL_KEY),
    methodPayment: null,
    isLoadingPaymentForm: false,
    isLoadingProcessPayment: false,
    isLoadingMyPayments: false,
    isLoadingDocument: false,
    myPayments: null,
  },
})
@Injectable()
export class PaymentState {
  constructor() {}

  @Selector()
  public static getSteps(state: IPaymentModel): IStepPaymentForm[] {
    return state.steps
  }

  @Selector()
  public static getIsValidFormPayment(state: IPaymentModel): boolean {
    return state.isValidForm
  }

  @Selector()
  public static getPersonalInformation(
    state: IPaymentModel
  ): IPersonalInformation {
    return state.personalInformation
  }

  @Selector()
  public static getCompanyInformation(
    state: IPaymentModel
  ): ICompanyInformation {
    return state.companyInformation
  }

  @Selector()
  public static getMethodPayment(state: IPaymentModel): string {
    return state.methodPayment
  }

  @Selector()
  public static getMyPayments(state: IPaymentModel): IMyPayments {
    return state.myPayments
  }

  @Selector()
  public static getIsLoadingMyPayments(state: IPaymentModel): boolean {
    return state.isLoadingMyPayments
  }

  @Selector()
  public static getIsLoadingPaymentForm(state: IPaymentModel): boolean {
    return state.isLoadingPaymentForm
  }

  @Selector()
  public static getIsLoadingProcessPayment(state: IPaymentModel): boolean {
    return state.isLoadingProcessPayment
  }

  @Selector()
  public static getIsLoadingDocument(state: IPaymentModel): boolean {
    return state.isLoadingDocument
  }

  @Action(UpdateIsValidForm)
  updateIsValidForm(
    { getState, setState }: StateContext<IPaymentModel>,
    { payload }: UpdateIsValidForm
  ): void {
    const state = getState()
    setState({
      ...state,
      isValidForm: payload,
    })
  }

  @Action(UpdateStepById)
  UpdateStepById(
    { getState, setState }: StateContext<IPaymentModel>,
    { id, name, active }: UpdateStepById
  ): void {
    const state = getState()
    let steps = JSON.parse(JSON.stringify(state.steps))

    steps = steps.map((item) => {
      item.active = false
      if (item.name === name) {
        item.active = active
      }
      if (item.id === id) {
        item.complete = true
      }

      return item
    })

    const isValidForm = !steps.find((x) => x.complete === false)

    const key = '573P14n'
    localStorage.setItem(key, JSON.stringify(steps))

    setState({
      ...state,
      steps,
      isValidForm,
    })
  }

  @Action(UpdateSteps)
  updateSteps(
    { getState, setState }: StateContext<IPaymentModel>,
    { payload }: UpdateSteps
  ): void {
    const state = getState()
    localStorage.setItem(PAYMENT_STEP_KEY, JSON.stringify(payload))
    setState({
      ...state,
      steps: payload,
    })
  }

  @Action(UpdatePersonalInformation)
  updatePersonalInformation(
    { getState, setState }: StateContext<IPaymentModel>,
    { payload }: UpdatePersonalInformation
  ): void {
    const state = getState()
    localStorage.setItem(PAYMENT_PERSON_KEY, JSON.stringify(payload))
    setState({
      ...state,
      personalInformation: payload,
    })
  }

  @Action(UpdateCompanyInformation)
  updateCompanyInformation(
    { getState, setState }: StateContext<IPaymentModel>,
    { payload }: UpdateCompanyInformation
  ): void {
    const state = getState()
    localStorage.setItem(PAYMENT_COMERCIAL_KEY, JSON.stringify(payload))
    setState({
      ...state,
      companyInformation: payload,
    })
  }

  @Action(UpdateMethodPayment)
  updateMethodPayment(
    { getState, setState }: StateContext<IPaymentModel>,
    { payload }: UpdateMethodPayment
  ): void {
    const state = getState()
    setState({
      ...state,
      methodPayment: payload,
    })
  }

  @Action(UpdateMyPayments)
  updateMyPayments(
    { getState, setState }: StateContext<IPaymentModel>,
    { payload }: UpdateMyPayments
  ): void {
    const state = getState()
    setState({
      ...state,
      myPayments: payload,
    })
  }

  @Action(UpdateIsLoadingPaymentForm)
  updateIsLoadingPaymentForm(
    { getState, setState }: StateContext<IPaymentModel>,
    { payload }: UpdateIsLoadingPaymentForm
  ): void {
    const state = getState()
    setState({
      ...state,
      isLoadingPaymentForm: payload,
    })
  }

  @Action(UpdateIsLoadingProcessPayment)
  updateIsLoadingProcessPayment(
    { getState, setState }: StateContext<IPaymentModel>,
    { payload }: UpdateIsLoadingProcessPayment
  ): void {
    const state = getState()
    setState({
      ...state,
      isLoadingProcessPayment: payload,
    })
  }

  @Action(UpdateIsLoadingMyPayments)
  updateIsLoadingMyPayments(
    { getState, setState }: StateContext<IPaymentModel>,
    { payload }: UpdateIsLoadingMyPayments
  ): void {
    const state = getState()
    setState({
      ...state,
      isLoadingMyPayments: payload,
    })
  }

  @Action(UpdateIsLoadingDocument)
  updateIsLoadingDocument(
    { getState, setState }: StateContext<IPaymentModel>,
    { payload }: UpdateIsLoadingDocument
  ): void {
    const state = getState()
    setState({
      ...state,
      isLoadingDocument: payload,
    })
  }
}
