<div
  *ngIf="(paymentFacade.isLoadingProcessPayment$ | async) === false"
  class="animated fadeIn"
>
  <div *ngIf="paymentFacade.isSuccessPayment === false">
    <app-payment-failed> </app-payment-failed>
  </div>
  <div *ngIf="paymentFacade.isSuccessPayment === true">
    <app-payment-success-billing
      [infoPayment]="paymentFacade.infoPayment"
    ></app-payment-success-billing>
  </div>
</div>
