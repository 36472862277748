<div class="container-options">
  <div class="info-profile row align-items-center m-0">
    <div *ngIf="user" class="col-sm-7 col-md-6 col-xxl-12">
      <div class="row m-0">
        <div class="col-12 p-0">
          <div class="img-profile">
            <input
              type="file"
              id="fileDropRef"
              accept=".png, .jpg, jpeg"
              alt=""
              (change)="fileBrowseHandler($event.target)"
              (mouseover)="mouseInteraction(true)"
              (mouseout)="mouseInteraction(false)"
            />
            <nz-avatar
              #fileDropRef
              for="fileDropRef"
              class="img-profile d-block mx-auto"
              [nzGap]="4"
              [ngStyle]="
                (progress > 0 && progress < 100) || isMouserOverProfile
                  ? {
                      'background-color': '#40189d',
                      transition: 'all 0.25s ease'
                    }
                  : {
                      'background-image':
                        'url(' + (user$ | async).imagenPerfil + ')'
                    }
              "
              [nzText]="
                progress > 0 && progress < 100 && progress.toString() + '%'
              "
              [nzIcon]="
                isMouserOverProfile &&
                (progress <= 0 || progress >= 100) &&
                'upload'
              "
              [nzSize]="100"
            ></nz-avatar>
          </div>
          <span
            class="change-image-profile"
            (change)="fileBrowseHandler($event.target)"
            >Cambiar imagen de perfil</span
          >
        </div>

        <div class="col-12">
          <div class="my-3">
            <span class="user-name text-center">{{
              (user$ | async).nombreUsuario
            }}</span>
            <span class="subtitle text-center">{{
              (user$ | async).cargo
            }}</span>
          </div>
        </div>
      </div>
    </div>

    <hr class="w-75 my-5 d-none d-xxl-block" />

    <div class="col-sm-5 col-md-6 col-xxl-12 border-left">
      <div class="options">
        <div *ngFor="let item of optionsProfile" class="row my-3">
          <div class="col-2">
            <i nz-icon [nzType]="item.icon" nzTheme="outline"></i>
          </div>
          <div class="col-10">
            <a
              [routerLink]="item.url"
              routerLinkActive="active"
              class="title-option"
              >{{ item.title }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
