<div class="row page-titles">
  <div class="col-md-5 align-self-center">
    <h3 class="text-themecolor">{{ titulo }}</h3>
  </div>
  <div class="col-md-7 align-self-center">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">Inicio</li>
      <li class="breadcrumb-item active">
        <a href="javascript:void(0)">{{ titulo }}</a>
      </li>
    </ol>
  </div>
</div>
