import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { HighchartsChartModule } from 'highcharts-angular'
import { LoaderSectionComponent } from 'src/app/components/loader-section/loader-section.component'
import { NgZorroAntdModule } from 'src/app/ng-zorro-antd.module'
import { PipesModule } from '../pipes/pipes.module'
import { BreadcrumbsReportComponent } from './breadcrumbs-report/breadcrumbs-report.component'
import { CardBlogComponent } from './card-blog/card-blog.component'
import { CardInfoComponent } from './card-info/card-info.component'
import { ConfirmCheckboxComponentComponent } from './confirm-checkbox-component/confirm-checkbox-component.component'
import { CsatResponseItemComponent } from './csat-response-item/csat-response-item.component'
import { InfoPlanLiquidComponent } from './info-plan-liquid/info-plan-liquid.component'
import { InputFieldComponent } from './input-field/input-field.component'
import { LoaderV2Component } from './loader-v2/loader-v2.component'
import { LoaderComponent } from './loader/loader.component'
import { NotificationComponent } from './notification/notification.component'
import { NpsIndicatorInfoComponent } from './nps-indicator-info/nps-indicator-info.component'
import { NpsResponseItemComponent } from './nps-response-item/nps-response-item.component'
import { CsatMobileComponent } from './plugin-survey/csat-mobile/csat-mobile.component'
import { NpsMobileComponent } from './plugin-survey/nps-mobile/nps-mobile.component'
import { PluginSurveyComponent } from './plugin-survey/plugin-survey.component'
import { CommentsResultComponent } from './questions-type-result/comments-result/comments-result.component'
import { CommentsInsightComponent } from './questions-type-result/comments-result/commets-insight/comments-insight.component'
import { CsatResultComponent } from './questions-type-result/csat-result/csat-result.component'
import { NpsResultComponent } from './questions-type-result/nps-result/nps-result.component'
import { OptionResultComponent } from './questions-type-result/option-result/option-result.component'
import { RadioDarkComponent } from './radio-dark/radio-dark.component'
import { SelectFieldComponent } from './select-field/select-field.component'
import { SpecialButtonComponent } from './special-button/special-button.component'
import { SurveyResultComponent } from './survey-result/survey-result.component'
import { TextAreaFieldComponent } from './text-area-field/text-area-field.component'
import { Win98popupComponent } from './win98popup/win98popup.component'

@NgModule({
  declarations: [
    LoaderComponent,
    LoaderSectionComponent,
    LoaderV2Component,
    InfoPlanLiquidComponent,
    InputFieldComponent,
    SelectFieldComponent,
    TextAreaFieldComponent,
    Win98popupComponent,
    NpsResponseItemComponent,
    SurveyResultComponent,
    CsatResponseItemComponent,
    NpsIndicatorInfoComponent,
    RadioDarkComponent,
    PluginSurveyComponent,
    NpsMobileComponent,
    CsatMobileComponent,
    CardBlogComponent,
    CardInfoComponent,
    CsatResultComponent,
    NpsResultComponent,
    OptionResultComponent,
    CommentsResultComponent,
    SpecialButtonComponent,
    BreadcrumbsReportComponent,
    ConfirmCheckboxComponentComponent,
    NotificationComponent,
    CommentsInsightComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NgZorroAntdModule,
    RouterModule,
    PipesModule,
    HighchartsChartModule,
  ],
  exports: [
    LoaderComponent,
    LoaderSectionComponent,
    LoaderV2Component,
    InfoPlanLiquidComponent,
    InputFieldComponent,
    SelectFieldComponent,
    TextAreaFieldComponent,
    Win98popupComponent,
    NpsResponseItemComponent,
    SurveyResultComponent,
    CsatResponseItemComponent,
    NpsIndicatorInfoComponent,
    RadioDarkComponent,
    PluginSurveyComponent,
    CardBlogComponent,
    CardInfoComponent,
    CsatResultComponent,
    NpsResultComponent,
    OptionResultComponent,
    CommentsResultComponent,
    SpecialButtonComponent,
    BreadcrumbsReportComponent,
    ConfirmCheckboxComponentComponent,
    NotificationComponent,
    CommentsInsightComponent,
  ],
  providers: [],
})
export class ComponentsModule {}
