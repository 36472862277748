import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { ICreateQuestion } from 'src/app/models/request/ICreateQuestion'
import { IUpdateQuestion } from 'src/app/models/request/IUpdateQuestion'
import { IResponse } from 'src/app/models/response/IResponse'
import { environment } from 'src/environments/environment'
import { IUpdateStatusArchivedQuestionSurvey } from './../../models/request/IUpdateStatusArchivedQuestionSurvey'
import { IUpdateOptionsQuestionSurvey } from './../../models/request/IUpdateStatusQuestionSurvey'
import { IQuestion } from './../../models/response/IQuestion'

@Injectable({
  providedIn: 'root',
})
export class QuestionService {
  private api: string

  constructor(public http: HttpClient) {
    this.api = environment.api
  }

  public createQuestion(request: ICreateQuestion): Observable<IResponse> {
    const uri = `${this.api}/survey/question`
    return this.http.post(uri, request).pipe(map((data: IResponse) => data))
  }

  public listQuestionTypes(): Observable<IResponse> {
    const uri = `${this.api}/v1/public/catalog/question/type-list`
    return this.http.get(uri).pipe(map((data: IResponse) => data))
  }

  public listQuestionBySurveyId(surveyId: string): Observable<IResponse> {
    const uri = `${this.api}/survey/${surveyId}/questions`
    return this.http.get(uri).pipe(map((data: IResponse) => data))
  }

  public updateQuestion(request: IUpdateQuestion): Observable<IResponse> {
    const uri = `${this.api}/survey/question/${request.questionId}`
    return this.http.put(uri, request).pipe(map((data: IResponse) => data))
  }

  public updateOrderQuestionList(
    request: IQuestion[],
    surveyId: string
  ): Observable<IResponse> {
    const uri = `${this.api}/survey/questions/order/${surveyId}`
    return this.http.put(uri, request).pipe(map((data: IResponse) => data))
  }

  public deleteQuestionSurvey(
    request: IUpdateStatusArchivedQuestionSurvey
  ): Observable<IResponse> {
    const uri = `${this.api}/surveys/question/archive-status`
    return this.http.put(uri, request).pipe(map((data: IResponse) => data))
  }

  public changeOptionsQuestion(
    request: IUpdateOptionsQuestionSurvey
  ): Observable<IResponse> {
    const uri = `${this.api}/surveys/question/options`
    return this.http.put(uri, request).pipe(map((data: IResponse) => data))
  }
}
