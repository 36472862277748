import {
  IGeneralInfo,
  ITendecyWeekResponsesReport,
  IWeekResponsesReport,
} from './dashboard.state'

export class UpdateGeneralInfo {
  static readonly type = '[DASHBOARD] update general info'
  constructor(public payload: IGeneralInfo) {}
}

export class UpdateWeekResponsesReport {
  static readonly type = '[DASHBOARD] update week responses report'
  constructor(public payload: IWeekResponsesReport | any) {}
}

export class UpdateIsLoadingGeneralInfo {
  static readonly type = '[DASHBOARD] update loading general info'
  constructor(public payload: boolean) {}
}

export class UpdateIsLoadingWeekResponses {
  static readonly type = '[DASHBOARD] update loading week responses report'
  constructor(public payload: boolean) {}
}

export class UpdateTendencyWeekReport {
  static readonly type = '[DASHBOARD] update tendency responses report'
  constructor(public payload: ITendecyWeekResponsesReport | any) {}
}

export class UpdateisLoadingTendencyWeekResponses {
  static readonly type = '[DASHBOARD] update loading tendency responses report'
  constructor(public payload: boolean) {}
}
