import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { IValidatePayment } from 'src/app/models/request/IValidatePayment'
import { IResponse } from 'src/app/models/response/IResponse'
import { environment } from 'src/environments/environment'
import { IPayment } from './../../models/request/IPayment'
import { IUpdateDocumentPayment } from './../../models/request/IUpdateDocumentPayment'

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  private api: string

  constructor(public http: HttpClient) {
    this.api = environment.api
  }

  public validatePayment(request: IValidatePayment): Observable<IResponse> {
    const uri = `${this.api}/confirm-payment-webpay`
    return this.http.post(uri, request).pipe(map((data: IResponse) => data))
  }

  public paymentPlan(request: IPayment): Observable<IResponse> {
    const uri = `${this.api}/start-payment-webpay`
    return this.http.post(uri, request).pipe(map((data: IResponse) => data))
  }

  public getMyPayments(): Observable<IResponse> {
    const uri = `${this.api}/my-payments`
    return this.http.get(uri).pipe(map((data: IResponse) => data))
  }

  public getCountries(): Observable<IResponse> {
    const uri = `${this.api}/v1/public/catalog/countries`
    return this.http.get(uri).pipe(map((data: IResponse) => data))
  }
  public getDocument(
    paymentId: number | string,
    documentTypeId: number
  ): Observable<IResponse> {
    const uri = `${this.api}/payment/${paymentId}/document/${documentTypeId}`
    return this.http.get(uri).pipe(map((data: IResponse) => data))
  }

  public updateDocument(
    request: IUpdateDocumentPayment
  ): Observable<IResponse> {
    const uri = `${this.api}/payment/document`
    return this.http.put(uri, request).pipe(map((data: IResponse) => data))
  }
}
