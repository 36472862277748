<div class="loader-container">
  <div class="loader animated-fade-in">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <small class="d-block mt-2">{{ title }}...</small>
  </div>
</div>
