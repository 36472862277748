import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  Renderer2,
  SimpleChanges,
} from '@angular/core'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { IPlan } from 'src/app/models/response/IPlan'
import { SurveyService } from 'src/app/services/pages/survey.service'
import { PlanFacade } from 'src/app/store/plan/plan.facade'
import {
  ERROR_CREATE_SURVEY_LIMIT_MESSAGE,
  ERROR_CREATE_SURVEY_LIMIT_TITLE,
} from 'src/app/utils/constants/survey-messages'
import { SurveyFacade } from './../../../store/survey/survey.facade'

@Component({
  selector: 'app-header-section-survey',
  templateUrl: './header-section-survey.component.html',
  styleUrls: ['./header-section-survey.component.scss'],
})
export class HeaderSectionSurveyComponent implements OnChanges {
  @Output() isModeSurveyArchivedChange = new EventEmitter<boolean>()
  @Output() isVisibleFormWithIAChange = new EventEmitter<boolean>()
  @Output() surveyTypeFilterChange = new EventEmitter<string>()
  @Input() isModeSurveyArchived: boolean = false
  @Input() isVisibleFormWithIA: boolean = false

  public surveyTypeFilter: string = 'ALL'
  public isVisibleForm: boolean = false

  constructor(
    public surveyFacade: SurveyFacade,
    public planFacade: PlanFacade,
    private readonly el: ElementRef,
    private readonly renderer: Renderer2,
    private _notification: NzNotificationService,
    private _surveyService: SurveyService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isModeSurveyArchived) {
      if (changes.isModeSurveyArchived.currentValue === false) {
        this.surveyTypeFilter = 'ALL'
        this.surveyTypeFilterChange.emit(this.surveyTypeFilter)
      }
    }
  }

  filterSurveys(filterType: string = 'ALL'): void {
    this.surveyTypeFilter = filterType
    this.surveyTypeFilterChange.emit(filterType)

    switch (filterType) {
      case 'ALL':
      case 'FAVORITE':
        this.isModeSurveyArchived = false
        this.isModeSurveyArchivedChange.emit(this.isModeSurveyArchived)
        this.surveyFacade.getSurveyList(this.isModeSurveyArchived)
        break
      case 'ARCHIVED':
        this.isModeSurveyArchived = true
        this.isModeSurveyArchivedChange.emit(this.isModeSurveyArchived)
        this.surveyFacade.getSurveyList(this.isModeSurveyArchived)
        break
      default:
        this.surveyTypeFilter = 'ALL'
        break
    }
  }

  showModal(): void {
    this.isVisibleForm = true
  }

  handleOk(): void {
    this.isVisibleForm = false
  }

  handleCancel(): void {
    this.isVisibleForm = false
  }

  handleModal(): void {
    this.isVisibleForm = false
    this.createSurveyIA()
  }

  showModalIA(isVisible: boolean): void {
    this.isVisibleFormWithIA = isVisible
    if (!isVisible) this.isVisibleFormWithIAChange.emit(false)
  }

  createSurveyIA(): void {
    let countTotal = 0
    let numberSurveysUsed = 0

    this.planFacade.currentPlan$.subscribe((currentPlan: IPlan) => {
      numberSurveysUsed = currentPlan.numberSurveysUsed
      countTotal = currentPlan.numberPlanSurveys
    })

    if (numberSurveysUsed === countTotal) {
      this._notification.create(
        'info',
        ERROR_CREATE_SURVEY_LIMIT_TITLE,
        ERROR_CREATE_SURVEY_LIMIT_MESSAGE
      )
    } else {
      this.isVisibleFormWithIA = true
      setTimeout(() => {
        this.updateModalDOM()
      }, 500)
    }
  }

  updateModalDOM(): void {
    const elemento =
      this.el.nativeElement.ownerDocument.querySelector('.ant-modal-content')

    if (elemento) {
      this.renderer.addClass(elemento, 'outline-gradient-1')
    }
  }
}
