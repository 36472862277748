import { Component, OnInit } from '@angular/core'
import { ResponsesFacade } from 'src/app/store/responses/responses.facade'

@Component({
  selector: 'app-response-timeline',
  templateUrl: './response-timeline.component.html',
  styleUrls: ['./response-timeline.component.scss'],
})
export class ResponseTimelineComponent implements OnInit {
  public currenPage: number

  constructor(public responsesFacade: ResponsesFacade) {}

  ngOnInit(): void {}

  public getColor(responses: number): string {
    switch (responses) {
      case 1:
        return '#ff5f7f'
      case 2:
        return '#ff5f7f'
      case 3:
        return '#f1c40f'
      case 4:
        return '#68cd7f'
      case 5:
        return '#27ae60'
      default:
        return ''
    }
  }

  public getType(typeQuestion: number): string {
    switch (typeQuestion) {
      case 1:
        return 'frown'
      case 2:
        return 'frown'
      case 3:
        return 'meh'
      case 4:
        return 'smile'
      case 5:
        return 'smile'
      default:
        return ''
    }
  }
}
