import { JiraState } from 'src/app/store/jira/jira.state'
import { IBilling } from 'src/app/models/request/IBilling'
import { BillService } from '../../services/pages/bill.service'
import {
  ERROR_SERVICE_TITLE,
  ERROR_SERVICE_MESSAGE,
} from '../../utils/constants/generic-messages'
import { IResponse } from 'src/app/models/response/IResponse'
import { Injectable } from '@angular/core'
import { Select, Store } from '@ngxs/store'
import {
  UpdateIdJiraTicketCreated,
  UpdateLoadingJiraTicketCreated,
  CleanIdJiraTicketCreated,
} from './jira.actions'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { catchError, finalize } from 'rxjs/operators'
import { Observable, throwError } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class JiraFacade {
  @Select(JiraState.getTicket) idTicketCreated$: Observable<string>
  @Select(JiraState.getLoadingJiraTicketCreated)
  loadingJiraTicketRequest$: Observable<boolean>

  constructor(
    private _bill: BillService,
    private store: Store,
    private notification: NzNotificationService
  ) {}

  public createBillingRequestFetch(request: IBilling): void {
    this.store.dispatch(new UpdateLoadingJiraTicketCreated(true))
    this._bill
      .billingRequest(request)
      .pipe(
        catchError((error: Error) => {
          this.notification.create(
            'error',
            ERROR_SERVICE_TITLE,
            ERROR_SERVICE_MESSAGE
          )
          return throwError(error)
        }),
        finalize(() =>
          this.store.dispatch(new UpdateLoadingJiraTicketCreated(false))
        )
      )
      .subscribe((response: IResponse) => {
        if (response.status.code === 201) {
          this.store.dispatch(new UpdateIdJiraTicketCreated(response.data))
        }
      })
  }

  public initializerTicketCreated(): void {
    this.store.dispatch(new CleanIdJiraTicketCreated())
  }
}
