import { AES, enc, mode, pad } from 'crypto-js'

const secretKey = '3mp471&h4m4nwr3'

export const encryptMessage = (message: string): string => {
  const cipherText = AES.encrypt(message, secretKey).toString()
  const urlSafeCipherText = cipherText
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '')
  return urlSafeCipherText
}

export const decryptMessage = (cipherText: string): string => {
  if (!cipherText) return ''
  const originalCipherText = cipherText.replace(/-/g, '+').replace(/_/g, '/')
  const bytes = AES.decrypt(originalCipherText, secretKey)
  const originalText = bytes.toString(enc.Utf8)
  return originalText
}

export const encryptKeyStorage = (message: string): string => {
  const cipherText = AES.encrypt(message, enc.Utf8.parse(secretKey), {
    mode: mode.ECB,
    padding: pad.Pkcs7,
  }).ciphertext.toString(enc.Base64)

  const urlSafeCipherText = cipherText
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '')
  return urlSafeCipherText
}

export const decryptKeyStorage = (cipherText: string): string => {
  const originalCipherText = cipherText
    .replace(/-/g, '+')
    .replace(/_/g, '/')
    .padEnd(cipherText.length + (4 - (cipherText.length % 4)), '=')

  const decrypted = AES.decrypt(
    { ciphertext: enc.Base64.parse(originalCipherText) } as any,
    enc.Utf8.parse(secretKey),
    {
      mode: mode.ECB,
      padding: pad.Pkcs7,
    }
  )

  return decrypted.toString(enc.Utf8)
}
