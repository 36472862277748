import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop'
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { Store } from '@ngxs/store'
import { ReportFacade } from 'src/app/store/reports/report.facade'
import { arraysOfObjectsAreEqual } from 'src/app/utils/array/array-utils'
import { IQuestion } from './../../../models/response/IQuestion'
import {
  UpdateQuestion,
  UpdateQuestionList,
} from './../../../store/question/question.actions'
import { QuestionFacade } from './../../../store/question/question.facade'

@Component({
  selector: 'app-view-question-list',
  templateUrl: './view-question-list.component.html',
  styleUrls: ['./view-question-list.component.scss'],
})
export class ViewQuestionListComponent implements OnInit {
  @Input() public surveyId: string
  @Output() public obtainQuestionList = new EventEmitter()

  public questions: IQuestion[]
  public originalQuestions: IQuestion[]
  public isLoadingQuestionList: boolean
  public isVisibleModal: boolean
  public isVisibleDelete: boolean
  public isVisibleIconDelete: boolean
  public messageDelete: string
  public editQuestion: boolean
  public question: IQuestion
  public switchValue: string
  public questionSelected: string

  constructor(
    private store: Store,
    public questionFacade: QuestionFacade,
    private reportFacade: ReportFacade
  ) {
    this.isVisibleModal = false
    this.editQuestion = false
    this.isVisibleDelete = false
    this.isVisibleIconDelete = false
    this.isLoadingQuestionList = true
  }

  ngOnInit(): void {
    this.questionFacade.questions$.subscribe((questions: IQuestion[]) => {
      if (questions) {
        this.questions = questions
        this.isLoadingQuestionList = false
      }
    })

    this.questionFacade.originalQuestions$.subscribe(
      (questions: IQuestion[]) => {
        if (questions) {
          this.originalQuestions = [...questions]
        }
      }
    )
  }

  public dragDropElement(event: CdkDragDrop<IQuestion[]>): void {
    const originalArray = [...this.originalQuestions].map((item: IQuestion) => {
      return { ...item, isChangesSaving: false }
    })

    const actualArray = [...this.questions].map(
      (item: IQuestion, index: number) => {
        return {
          ...item,
          isChangesSaving: false,
        }
      }
    )
    moveItemInArray(actualArray, event.previousIndex, event.currentIndex)

    const discrepancies = this.nonMatchingItems(
      actualArray,
      this.originalQuestions
    )

    const updatedArray = this.updateChangesSaving(actualArray, discrepancies)
    const isChangeArray = !arraysOfObjectsAreEqual(updatedArray, originalArray)

    this.questions = !isChangeArray
      ? [...this.originalQuestions]
      : [...updatedArray]

    this.store.dispatch(new UpdateQuestionList(this.questions))
  }

  public editQuestionModal(question: IQuestion): void {
    this.store.dispatch(new UpdateQuestion(question))
    this.isVisibleModal = true
    this.editQuestion = true
  }

  public deleteQuestionModal(question: IQuestion): void {
    this.isVisibleDelete = true
    this.messageDelete = 'Se eliminara la pregunta. Estas seguro?'
  }

  public activeDesactiveQuestionModal(question: IQuestion): void {
    this.store.dispatch(new UpdateQuestion(question))
    this.question = question
    this.editQuestion = true
  }

  public disabledQuestion(question: IQuestion): void {
    console.log('disabledQuetion')
  }

  public handleCancel(): void {
    this.isVisibleModal = false
  }

  public changeStatus(): void {}

  public obtainQuestionListFromTable(): void {
    this.obtainQuestionList.emit()
  }

  public selectItem(question: IQuestion): void {
    if (question.questionId === this.questionSelected) return
    this.questionSelected = question.questionId

    const questionUpdated = { ...question, isSelected: true }
    this.store.dispatch(new UpdateQuestion(questionUpdated))
    const mf = document.getElementById('survey-mf') as HTMLIFrameElement
    if (mf) {
      mf.contentWindow.postMessage(
        { question, type: 'QUESTION', isVisibleForm: false },
        '*'
      )
    }
  }

  public activeQuestions(questions: IQuestion[]): IQuestion[] {
    return questions.filter((item) => item.isActive)
  }
  public disableQuestions(questions: IQuestion[]): IQuestion[] {
    return questions.filter((item) => !item.isActive)
  }

  private nonMatchingItems(actualArray: any[], originalArray: any[]): any[] {
    return actualArray.filter((item, index) => {
      return item.questionId !== originalArray[index].questionId
    })
  }

  private updateChangesSaving(actualArray: any[], discrepancies: any[]): any[] {
    return actualArray.map((item) => {
      const isChanged = discrepancies.some(
        (discItem) => discItem.questionId === item.questionId
      )
      return {
        ...item,
        isChangesSaving: !isChanged,
      }
    })
  }
}
