import { optionsChartDefault } from '../../../../utils/properties/hightchartOptions'

export const chartOptionsWordCloudComments = (commentsData: string[]) => {
  const processedData = processCommentsData(commentsData)
  return {
    lang: optionsChartDefault.lang,
    credits: {
      enabled: false,
    },
    series: [
      {
        type: 'wordcloud',
        data: processedData,
        name: 'Frecuencia',
        // rotation: {
        //   from: 0,
        //   orientations: 5,
        //   to: 90,
        // },
        style: {
          fontFamily: 'Arial, sans-serif',
          fontWeight: 'bold',
        },
      },
    ],
    title: {
      text: '',
    },
    // subtitle: {
    //   text: 'Palabras más frecuentes en comentarios',
    // },
    chart: {
      renderTo: 'container',
      backgroundColor: 'transparent',
    },
    colors: ['#5DA5DA', '#FAA43A', '#60BD68', '#F17CB0', '#B2912F'],
    tooltip: {
      formatter: function () {
        return (
          'La palabra <b>' +
          this.point.name +
          '</b> aparece ' +
          this.point.weight +
          ' veces.'
        )
      },
    },
    plotOptions: {
      wordcloud: {
        minFontSize: 10,
        maxFontSize: 25,
        spiral: 'archimedean',
      },
    },
  }
}

const processCommentsData = (commentsArray: string[]) => {
  const wordCount = {}

  commentsArray.forEach((comment: string) => {
    const cleanedComment = comment
      .toLowerCase()
      .replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, '')

    const words = cleanedComment.split(/\s+/)
    words.forEach((word: string) => {
      if (
        stopWordsSpanish.includes(word) ||
        stopWordsEnglish.includes(word) ||
        word.length < 3
      ) {
        return
      }

      if (!wordCount[word]) {
        wordCount[word] = 0
      }
      wordCount[word]++
    })
  })

  return Object.keys(wordCount).map((word) => ({
    name: word,
    weight: wordCount[word],
  }))
}

const stopWordsSpanish = [
  'y',
  'de',
  'en',
  'el',
  'la',
  'los',
  'las',
  'un',
  'una',
  'unos',
  'unas',
  'que',
  'con',
  'para',
  'por',
  'se',
  'su',
  'sus',
  'al',
  'del',
  'es',
  'lo',
  'como',
  'más',
  'pero',
  'sus',
  'le',
  'ya',
  'o',
  'fue',
  'este',
  'ha',
  'sí',
  'porque',
  'esta',
  'son',
  'entre',
  'está',
  'cuando',
  'muy',
  'sin',
  'sobre',
  'ser',
  'tiene',
  'también',
  'me',
  'hasta',
  'hay',
  'donde',
  'han',
  'quien',
  'están',
  'estado',
  'desde',
  'todo',
  'nos',
  'durante',
  'estados',
  'todos',
  'uno',
  'les',
  'ni',
  'contra',
  'otros',
  'fueron',
  'ese',
  'eso',
  'había',
  'ante',
  'ellos',
  'e',
  'esto',
  'mí',
  'antes',
  'algunos',
  'qué',
  'unos',
  'yo',
  'otro',
  'otras',
  'otra',
  'él',
  'tanto',
  'esa',
  'estos',
  'mucho',
  'quienes',
  'nada',
  'muchos',
  'cual',
  'sea',
  'poco',
  'ella',
  'estar',
  'haber',
  'estas',
  'estaba',
  'estamos',
  'algunas',
  'algo',
]
const stopWordsEnglish = [
  'and',
  'of',
  'in',
  'the',
  'a',
  'an',
  'is',
  'it',
  'for',
  'with',
  'on',
  'as',
  'by',
  'this',
  'that',
  'at',
  'to',
  'from',
  'up',
  'out',
]
