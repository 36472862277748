import { State, Action, StateContext, Selector } from '@ngxs/store'
import {
  UpdateActiveURL,
  UpdateLoadingLogout,
  UpdateLoadingSession,
  UpdateLoadingSessionPage,
} from './session.actions'
import { Injectable } from '@angular/core'

export interface SessionStateModel {
  activeURL: string
  loadingSession: boolean
  loadingSessionPage: boolean
  loadingLogout: boolean
}

@State<SessionStateModel>({
  name: 'session',
  defaults: {
    activeURL: null,
    loadingSession: true,
    loadingSessionPage: true,
    loadingLogout: false,
  },
})
@Injectable()
export class SessionState {
  @Selector()
  public static getLoadingSession(payload: SessionStateModel): boolean {
    return payload.loadingSession
  }

  @Selector()
  public static getLoadingSessionPage(payload: SessionStateModel): boolean {
    return payload.loadingSessionPage
  }

  @Selector()
  public static getLoadingLogout(payload: SessionStateModel): boolean {
    return payload.loadingLogout
  }

  @Selector()
  public static getActiveURL(state: SessionStateModel): string {
    return state.activeURL
  }

  @Action(UpdateLoadingSession)
  updateLoadingSession(
    { getState, setState }: StateContext<SessionStateModel>,
    { payload }: UpdateLoadingSession
  ): void {
    const state = getState()
    setState({
      ...state,
      loadingSession: payload,
    })
  }

  @Action(UpdateLoadingSessionPage)
  updateLoadingPageSession(
    { getState, setState }: StateContext<SessionStateModel>,
    { payload }: UpdateLoadingSessionPage
  ): void {
    const state = getState()
    setState({
      ...state,
      loadingSessionPage: payload,
    })
  }

  @Action(UpdateLoadingLogout)
  updateLoadingLogout(
    { getState, setState }: StateContext<SessionStateModel>,
    { payload }: UpdateLoadingLogout
  ): void {
    const state = getState()
    setState({
      ...state,
      loadingLogout: payload,
    })
  }

  @Action(UpdateActiveURL)
  updateActiveURL(
    { getState, setState }: StateContext<SessionStateModel>,
    { payload }: UpdateActiveURL
  ): void {
    const state = getState()
    setState({
      ...state,
      activeURL: payload,
    })
  }
}
