import { Injectable } from '@angular/core'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { IQuestion } from 'src/app/models/response/IQuestion'
import { IQuestionType } from './../../models/response/IQuestionType'
import {
  UpdateIsErrorQuestion,
  UpdateIsErrorQuestionList,
  UpdateIsLoadingCreateQuestion,
  UpdateLoadingQuestion,
  UpdateLoadingQuestionList,
  UpdateMessageQuestionEditLoading,
  UpdateOriginalQuestionList,
  UpdateQuestion,
  UpdateQuestionList,
  UpdateQuestionTypes,
} from './question.actions'

export interface IQuestionModel {
  question: IQuestion
  questions: IQuestion[]
  originalQuestions: IQuestion[]
  questionTypes: IQuestionType[]
  isLoadingQuestion: boolean
  isErrorQuestion: boolean
  isErrorQuestionList: boolean
  isChangesSavingOrder: boolean
  isLoadingQuestionList: boolean
  isLoadingCreateQuestion: boolean
  messageQuestionEditLoading: string
}

@State<IQuestionModel>({
  name: 'question',
  defaults: {
    question: null,
    questions: [],
    originalQuestions: [],
    isErrorQuestion: false,
    isErrorQuestionList: false,
    questionTypes: [],
    isLoadingQuestion: true,
    isChangesSavingOrder: false,
    isLoadingQuestionList: true,
    isLoadingCreateQuestion: false,
    messageQuestionEditLoading: null,
  },
})
@Injectable()
export class QuestionState {
  @Selector()
  public static getQuestion(state: IQuestionModel): IQuestion {
    return state.question
  }

  @Selector()
  public static getQuestionList(state: IQuestionModel): IQuestion[] {
    return state.questions
  }

  @Selector()
  public static getOriginalQuestionList(state: IQuestionModel): IQuestion[] {
    return state.originalQuestions
  }

  @Selector()
  public static getLoadingQuestion(state: IQuestionModel): boolean {
    return state.isLoadingQuestion
  }

  @Selector()
  public static getLoadingQuestionList(state: IQuestionModel): boolean {
    return state.isLoadingQuestionList
  }

  @Selector()
  public static getIsErrorQuestion(state: IQuestionModel): boolean {
    return state.isErrorQuestion
  }

  @Selector()
  public static getIsErrorQuestionList(state: IQuestionModel): boolean {
    return state.isErrorQuestionList
  }

  @Selector()
  public static getQuestionTypes(state: IQuestionModel): IQuestionType[] {
    return state.questionTypes
  }

  @Selector()
  public static getIsLoadingCreateQuestion(state: IQuestionModel): boolean {
    return state.isLoadingCreateQuestion
  }

  @Selector()
  public static getIsChangesSavingOrder(state: IQuestionModel): boolean {
    return state.isChangesSavingOrder
  }

  @Selector()
  public static getMessageQuestionEditLoading(state: IQuestionModel): string {
    return state.messageQuestionEditLoading
  }

  @Action(UpdateQuestion)
  updateQuestion(
    { getState, setState }: StateContext<IQuestionModel>,
    { payload }: UpdateQuestion
  ): void {
    const state = getState()
    if (!payload) {
      setState({
        ...state,
        question: null,
      })
      return
    }
    const questions = state.questions.map((question) => {
      if (question.questionId === payload.questionId) {
        return payload
      } else {
        return { ...question, isSelected: false }
      }
    })
    setState({
      ...state,
      question: payload,
      questions,
      originalQuestions: questions,
    })
  }

  @Action(UpdateLoadingQuestion)
  updateLoadingQuestion(
    { getState, setState }: StateContext<IQuestionModel>,
    { payload }: UpdateLoadingQuestion
  ): void {
    const state = getState()
    setState({
      ...state,
      isLoadingQuestion: payload,
    })
  }

  @Action(UpdateLoadingQuestionList)
  updateLoadingQuestionList(
    { getState, setState }: StateContext<IQuestionModel>,
    { payload }: UpdateLoadingQuestionList
  ): void {
    const state = getState()
    setState({
      ...state,
      isLoadingQuestionList: payload,
    })
  }

  @Action(UpdateIsErrorQuestion)
  updateIsErrorQuestion(
    { getState, setState }: StateContext<IQuestionModel>,
    { payload }: UpdateIsErrorQuestion
  ): void {
    const state = getState()
    setState({
      ...state,
      isErrorQuestion: payload,
    })
  }

  @Action(UpdateIsErrorQuestionList)
  updateIsErrorQuestionList(
    { getState, setState }: StateContext<IQuestionModel>,
    { payload }: UpdateIsErrorQuestionList
  ): void {
    const state = getState()
    setState({
      ...state,
      isErrorQuestionList: payload,
    })
  }

  @Action(UpdateQuestionList)
  updateQuestionList(
    { getState, setState }: StateContext<IQuestionModel>,
    { payload }: UpdateQuestionList
  ): void {
    const state = getState()
    const isChangesSavingOrder = payload.find(
      (item: IQuestion) => !item.isChangesSaving
    )

    setState({
      ...state,
      questions: payload,
      isChangesSavingOrder: !!isChangesSavingOrder,
    })
  }

  @Action(UpdateOriginalQuestionList)
  updateOriginalQuestionList(
    { getState, setState }: StateContext<IQuestionModel>,
    { payload }: UpdateOriginalQuestionList
  ): void {
    const state = getState()

    setState({
      ...state,
      originalQuestions: payload,
    })
  }

  @Action(UpdateQuestionTypes)
  updateQuestionTypes(
    { getState, setState }: StateContext<IQuestionModel>,
    { payload }: UpdateQuestionTypes
  ): void {
    const state = getState()
    setState({
      ...state,
      questionTypes: payload,
    })
  }

  @Action(UpdateIsLoadingCreateQuestion)
  updateIsLoadingCreateQuestion(
    { getState, setState }: StateContext<IQuestionModel>,
    { payload }: UpdateIsLoadingCreateQuestion
  ): void {
    const state = getState()
    setState({
      ...state,
      isLoadingCreateQuestion: payload,
    })
  }

  @Action(UpdateMessageQuestionEditLoading)
  updateMessageQuestionEditLoading(
    { getState, setState }: StateContext<IQuestionModel>,
    { payload }: UpdateMessageQuestionEditLoading
  ): void {
    const state = getState()
    setState({
      ...state,
      messageQuestionEditLoading: payload,
    })
  }
}
