import { Component, Inject, OnInit } from '@angular/core'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { CookieService } from 'ngx-cookie-service'
import { IQuestion } from 'src/app/models/response/IQuestion'
import { FeedBackService } from 'src/app/services/shared/feedback.service'
import { environment } from 'src/environments/environment'
import { v4 as uuidv4 } from 'uuid'
import { IResponseAnswer } from './../../models/request/ISendFeedback'
import { IResponse } from './../../models/response/IResponse'
import {
  SUCCESS_FEEDBACK_MESSAGE,
  SUCCESS_FEEDBACK_TITLE,
} from './../../utils/constants/generic-messages'
@Component({
  selector: 'app-plugin-survey',
  templateUrl: './plugin-survey.component.html',
  styleUrls: ['./plugin-survey.component.scss'],
})
export class PluginSurveyComponent implements OnInit {
  public isExpanded = false
  public response: number | string | null = null
  public responses: IResponseAnswer[] = []
  public comment: string | null = null
  public wasSent: boolean = false
  public isLoading: boolean = false
  public showFeedback: boolean = false
  public hasCookie: boolean = false
  public showComment: boolean = false

  public surveyQuestions: IQuestion[]
  public currentQuestionIndex = 0
  public loadingSendFeed: boolean = false
  public shouldRenderComponent: boolean = true

  constructor(
    @Inject(CookieService) private _cookieService: CookieService,
    public _feedBackService: FeedBackService,
    private notification: NzNotificationService
  ) {
    this.getQuestionFeedback()
  }

  ngOnInit(): void {
    this.hasCookie = this._cookieService.check(
      environment.survey_session_feedback_key
    )
  }
  toggle() {
    this.isExpanded = !this.isExpanded
    this.currentQuestionIndex = 0
  }

  handleSubmit() {
    if (this.currentQuestionIndex === this.surveyQuestions.length) {
      this.isLoading = true
    }
  }

  handleCancel() {
    this.wasSent = false
    this.isExpanded = false
    this.response = null
    this.comment = null
    this.hasCookie = true
  }

  handleChange(response: number | string) {
    this.response = response
    this.showComment =
      this.response !== null &&
      this.surveyQuestions[this.currentQuestionIndex].commentAvailable
  }

  getQuestionFeedback(): void {
    this._feedBackService.getQuestionFeedback().subscribe(
      (response: IResponse) => {
        switch (response.status.code) {
          case 200:
            this.surveyQuestions = response.data.questions
            this.showFeedback = true
            break
        }
      },
      (error: Error) => {
        this.showFeedback = false
      }
    )
  }

  nextQuestion() {
    const currentQuestion = this.surveyQuestions[this.currentQuestionIndex]
    const currentResponse = this.responses.find(
      (response) => response.questionId === currentQuestion.questionId
    )

    const nextQuestion = this.surveyQuestions[this.currentQuestionIndex + 1]
    const nextResponse = this.responses.find(
      (response) => response.questionId === nextQuestion.questionId
    )

    if (!currentResponse) {
      this.responses.push({
        questionId: currentQuestion.questionId,
        answer: this.response.toString(),
        comment: this.comment,
      })
      this.response = null
      this.showComment = false
    }

    this.response = nextResponse ? nextResponse.answer : null
    this.comment = nextResponse ? nextResponse.comment : null
    this.showComment = nextResponse?.answer
      ? nextQuestion.commentAvailable
      : false

    this.currentQuestionIndex++
    this.setShouldRenderComponent()
  }

  previousQuestion() {
    const currentQuestion = this.surveyQuestions[this.currentQuestionIndex - 1]
    const currentResponse = this.responses.find(
      (response) => response.questionId === currentQuestion.questionId
    )

    if (currentResponse) {
      this.showComment = currentQuestion.commentAvailable

      this.response = currentResponse.answer
      this.comment = currentResponse.comment
    }

    this.setShouldRenderComponent()

    if (this.currentQuestionIndex > 0) {
      this.currentQuestionIndex--
    }
  }

  setShouldRenderComponent() {
    // Forzar la recreación del componente
    this.shouldRenderComponent = false
    setTimeout(() => (this.shouldRenderComponent = true), 0)
  }

  async setSessionFeedbackSurvey() {
    const uuid = uuidv4()
    const request = {
      surveyId: environment.survey_feedback_id,
      channel: 'PLUGIN',
      identifier: uuid,
    }

    try {
      await this._feedBackService.sendSessionFeedBack(request).toPromise()
      this._cookieService.set(environment.survey_session_feedback_key, uuid, 30)
    } catch (error) {
      console.error(error)
    }
  }

  async sendFeedBack() {
    const currentQuestion = this.surveyQuestions[this.currentQuestionIndex]

    await this.setSessionFeedbackSurvey()

    const uuidCookie = this._cookieService.get(
      environment.survey_session_feedback_key
    )

    this.responses.push({
      questionId: currentQuestion.questionId,
      answer: this.response.toString(),
      comment: this.comment,
    })
    this.response = null
    this.comment = null
    this.showComment = false

    const request = {
      surveyId: environment.survey_feedback_id,
      channel: 'PLUGIN',
      sessionId: uuidCookie,
      answers: this.responses,
    }

    this._cookieService.set(
      environment.survey_session_feedback_key,
      request.surveyId,
      30
    )

    this.loadingSendFeed = true
    this._feedBackService.sendFeedBack(request).subscribe({
      next: () => {
        this.notification.create(
          'success',
          SUCCESS_FEEDBACK_TITLE,
          SUCCESS_FEEDBACK_MESSAGE
        )

        this.isLoading = false
        this.wasSent = true
        this.loadingSendFeed = false
      },
      error: (error: Error) => {
        this._cookieService.delete(environment.survey_session_feedback_key)
        console.error(error)
      },
    })
  }
}
