<div class="plugin-nps">
  <div>
    <h6 class="question-title">{{ question.title }}</h6>

    <div class="nps-container mx-auto">
      <button
        *ngFor="let index of scaleNumbers"
        [ngClass]="getButtonClass(index)"
        [ngStyle]="getButtonStyle(index)"
        (click)="handleChange(index)"
      >
        {{ index }}
      </button>
    </div>
  </div>
</div>
