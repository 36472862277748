import { UserState } from 'src/app/store/user/user.state'
import { IUser } from './../../../models/response/IUser'
import { UserService } from './../../../services/pages/user.service'
import { UpdateUser } from './../../../store/user/user.actions'
import {
  ERROR_SERVICE_MESSAGE,
  ERROR_SERVICE_TITLE,
} from './../../../utils/constants/generic-messages'
import {
  SUCCESS_UPLOAD_IMG_PROFILE_TITLE,
  WARNING_UPLOAD_IMG_FORMAT_MESSAGE,
  WARNING_UPLOAD_IMG_SIZE_MESSAGE,
} from './../../../utils/constants/profile'

import { Component } from '@angular/core'
import { Select, Store } from '@ngxs/store'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { Observable } from 'rxjs-compat'
import { IUpdateUser } from 'src/app/models/request/IUpdateUser.js'
import { IOptionProfile } from 'src/app/models/response/IOptionProfile'
import { IResponse } from './../../../models/response/IResponse'
import data from './options-profile.json'

@Component({
  selector: 'app-options-profile',
  templateUrl: './options-profile.component.html',
  styleUrls: ['./options-profile.component.scss'],
})
export class OptionsProfileComponent {
  @Select(UserState.getUser) user$: Observable<IUser>
  private files: File[]
  public user: IUser
  public progress: number
  public isMouserOverProfile: boolean
  public loading: boolean
  public optionsProfile: IOptionProfile[]

  constructor(
    private store: Store,
    private notification: NzNotificationService,
    private _user: UserService
  ) {
    this.progress = 0
    this.isMouserOverProfile = false
    this.files = []
    this.optionsProfile = data
    this.user$.subscribe((state) => {
      if (state) {
        this.user = state
        this.loading = false
      }
    })
  }

  public mouseInteraction(isOver: boolean): void {
    this.isMouserOverProfile = isOver
  }

  public fileBrowseHandler(files): void {
    const file = files.files[0]
    if (this.validateFile(file)) {
      this.prepareFile(file)
    }
  }

  private validateFile(file: File): boolean {
    const validTypes = ['image/png', 'image/jpeg', 'image/jpg']
    const maxSize = 2097152
    const { size, type } = file

    const isValidType = validTypes.find((item) => type === item) ? true : false
    const isValidSize = maxSize >= size

    if (!isValidType) {
      this.notification.create(
        'warning',
        WARNING_UPLOAD_IMG_FORMAT_MESSAGE,
        null
      )
    } else if (!isValidSize) {
      this.notification.create('warning', WARNING_UPLOAD_IMG_SIZE_MESSAGE, null)
    }

    return isValidType && isValidSize
  }

  private uploadFilesSimulator(index: number): void {
    setTimeout(() => {
      if (index === this.files.length) {
        return
      } else {
        const progressInterval = setInterval(() => {
          if (this.progress === 100) {
            clearInterval(progressInterval)
            this.uploadFilesSimulator(index + 1)
          } else if (this.progress < 90) {
            this.progress += 2
          }
        }, 50)
      }
    }, 1500)
  }

  private prepareFile(file: File): void {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      const fileB64 = reader.result
      this.files.push(file)
      this.uploadFilesSimulator(0)
      this.updateImageProfile(fileB64)
    }
  }

  private updateImageProfile(profileImageB64): void {
    const request: IUpdateUser = {
      imageProfile: profileImageB64,
    }

    this.loading = true
    this.progress = 0

    this._user.updateUser(request).subscribe(
      (response: IResponse) => {
        switch (response.status.code) {
          case 400:
            this.progress = 100
            this.notification.create(
              'error',
              ERROR_SERVICE_TITLE,
              ERROR_SERVICE_MESSAGE
            )
            break
          case 200:
          case 201:
            this.progress = 100
            this.uploadFilesSimulator(0)
            this.store.dispatch(new UpdateUser(this.userMapper(request)))
            this.notification.create(
              'success',
              SUCCESS_UPLOAD_IMG_PROFILE_TITLE,
              null
            )
            break
        }

        this.loading = false
      },
      (error: Error) => {
        console.error(error)
        this.notification.create(
          'error',
          ERROR_SERVICE_TITLE,
          ERROR_SERVICE_MESSAGE
        )
        this.loading = false
      }
    )
  }

  private userMapper(user: IUpdateUser): IUser {
    const { imageProfile } = user
    return {
      ...this.user,
      imagenPerfil: imageProfile,
    }
  }
}
