import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core'
import { NzSegmentedOption } from 'ng-zorro-antd/segmented'
import { environment } from '../../../../../environments/environment'
import { SurveyFacade } from '../../../../store/survey/survey.facade'

@Component({
  selector: 'app-survey-mf',
  templateUrl: './survey-mf.component.html',
  styleUrls: ['./survey-mf.component.scss'],
})
export class SurveyMFComponent implements OnChanges {
  @ViewChild('temp', { static: true, read: TemplateRef })
  templateRef!: TemplateRef<{
    $implicit: NzSegmentedOption
    index: number
  }>
  @Input() url: string
  public deviceSelected: string
  public options = [
    { label: 'user1', value: 'user1', useTemplate: true },
    { label: 'user2', value: 'user2', useTemplate: true },
    { label: 'user3', value: 'user3', useTemplate: true },
  ]

  @ViewChild('mf') mf: ElementRef

  constructor(public surveyFacade: SurveyFacade) {
    this.deviceSelected = 'laptop'
    this.getDeviceSelectedResponsive()
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.getDeviceSelectedResponsive()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.url) return
    const { currentValue } = changes.url
    this.url = currentValue ? this.getUriEnviroment(currentValue) : null
  }

  private getUriEnviroment(url: string): string {
    url = url.split('URL').join('DEMO')
    const urlArr = url.split('#/')
    const domain = environment.domain_survey_web
    return `${domain}/#/${urlArr[1]}`
  }

  private getDeviceSelectedResponsive(): void {
    if (
      window.innerWidth < 1200 ||
      (window.innerWidth > 1200 && window.innerWidth < 1500)
    ) {
      this.deviceSelected = 'laptop'
    }
  }
}
