import { FormGroup } from '@angular/forms'

export const validateForm = (form: FormGroup, exceptions = []) => {
  Object.keys(form.controls).forEach((field) => {
    const control = form.get(field)
    if (!exceptions.includes(field)) {
      control.markAsTouched({ onlySelf: true })
    } else {
      form.controls[field].markAsPristine()
      form.controls[field].markAsUntouched()
    }
  })
}

export const disableForm = (form) => {
  for (const i in form.controls) {
    form.controls[i].disable()
  }
}

export const enableForm = (form) => {
  for (const i in form.controls) {
    form.controls[i].enable()
  }
}
