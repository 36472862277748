import { Injectable } from '@angular/core'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { IUser } from 'src/app/models/response/IUser'
import { UpdateLoadingUser, UpdateUser } from './user.actions'

export interface UserStateModel {
  user: IUser
  loadingUser: boolean
}

@State<UserStateModel>({
  name: 'user',
  defaults: {
    user: null,
    loadingUser: true,
  },
})
@Injectable()
export class UserState {
  @Selector()
  public static getUser(state: UserStateModel): IUser {
    return state.user
  }

  @Selector()
  public static getLoadingUser(state: UserStateModel): boolean {
    return state.loadingUser
  }

  @Action(UpdateUser)
  updateUser(
    { getState, setState }: StateContext<UserStateModel>,
    { payload }: UpdateUser
  ): void {
    const state = getState()
    localStorage.setItem('user', JSON.stringify(payload))
    setState({
      ...state,
      user: payload,
    })
  }

  @Action(UpdateLoadingUser)
  updateLoadingUser(
    { getState, setState }: StateContext<UserStateModel>,
    { payload }: UpdateLoadingUser
  ): void {
    const state = getState()
    setState({
      ...state,
      loadingUser: payload,
    })
  }
}
