import {
  SUCCESS_UPDATE_PROFILE_MESSAGE,
  SUCCESS_UPDATE_PROFILE_TITLE,
} from './../../../../utils/constants/profile'
import {
  ERROR_SERVICE_MESSAGE,
  ERROR_SERVICE_TITLE,
} from './../../../../utils/constants/generic-messages'
import { IResponse } from 'src/app/models/response/IResponse'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { UserService } from 'src/app/services/service.index'
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { ICompany } from './../../../../models/response/ICompany'
import { IUpdateCompany } from './../../../../models/request/IUpdateCompany'
import { Component, Input, OnInit } from '@angular/core'
import {
  disableForm,
  enableForm,
  validateForm,
} from 'src/app/utils/formValidations/formValidations'

@Component({
  selector: 'app-company-form',
  templateUrl: './company-form.component.html',
  styleUrls: ['../edit-profile.component.scss'],
})
export class CompanyFormComponent implements OnInit {
  @Input() public company: ICompany
  public form!: FormGroup
  public loading: boolean

  constructor(
    private fb: FormBuilder,
    private notification: NzNotificationService,
    private _user: UserService
  ) {
    this.loading = false
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      name: [
        this.company.nombreCliente,
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(250),
        ],
      ],
      activity: [this.company.rubro, []],
      webSite: [
        this.company.sitioWeb,
        [Validators.minLength(3), Validators.maxLength(250)],
      ],
    })
  }

  public submitForm(): void {
    validateForm(this.form)

    if (this.form.valid) {
      const request: IUpdateCompany = {
        nombreCliente: this.form.get('name').value,
        rubro: this.form.get('activity').value,
        sitioWeb: this.form.get('webSite').value,
        direccion: null,
        comuna: null,
        region: null,
        telefono: null,
        facebook: null,
        instagram: null,
        logo: null,
      }

      this.loading = true
      disableForm(this.form)

      this._user.updateClientCompany(request).subscribe(
        (response: IResponse) => {
          switch (response.status.code) {
            case 400:
              this.notification.create(
                'error',
                ERROR_SERVICE_TITLE,
                ERROR_SERVICE_MESSAGE
              )
              break
            case 200:
            case 201:
              this.notification.create(
                'success',
                SUCCESS_UPDATE_PROFILE_TITLE,
                SUCCESS_UPDATE_PROFILE_MESSAGE
              )
              break
          }

          this.loading = false
          enableForm(this.form)
        },
        (error: Error) => {
          console.error(error)
          this.notification.create(
            'error',
            ERROR_SERVICE_TITLE,
            ERROR_SERVICE_MESSAGE
          )
          this.loading = false
          enableForm(this.form)
        }
      )
    }
  }
}
