<div class="projects-section-line">
  <div *ngIf="false" class="projects-status">
    <div class="item-status">
      <span class="status-number">{{
        (surveyFacade.surveyListInfo$ | async).totalSurveys
      }}</span>
      <span class="status-type">Total</span>
    </div>

    <div
      class="item-status"
      *ngIf="
        (surveyFacade.surveyListInfo$ | async).totalSurveys !==
        (surveyFacade.surveyListInfo$ | async).activedSurveys
      "
    >
      <span class="status-number">{{
        (surveyFacade.surveyListInfo$ | async).activedSurveys
      }}</span>
      <span class="status-type">Activas</span>
    </div>
    <div
      class="item-status"
      *ngIf="(surveyFacade.surveyListInfo$ | async).disabledSurveys > 0"
    >
      <span class="status-number">{{
        (surveyFacade.surveyListInfo$ | async).disabledSurveys
      }}</span>
      <span class="status-type">Desactivadas</span>
    </div>
    <div
      class="item-status"
      *ngIf="(surveyFacade.surveyListInfo$ | async).archivedSurveys > 0"
    >
      <span class="status-number">{{
        (surveyFacade.surveyListInfo$ | async).archivedSurveys
      }}</span>
      <span class="status-type">Archivadas</span>
    </div>
  </div>
  <div
    class="view-actions mt-3"
    *ngIf="(surveyFacade.surveyListInfo$ | async).totalSurveys > 0"
  >
    <button
      type="button"
      class="btn my-auto mr-3"
      [ngClass]="
        surveyTypeFilter === 'ALL' ? 'btn-option-active shadow' : 'btn-option'
      "
      (click)="filterSurveys('ALL')"
      [disabled]="surveyFacade.isLoadingSurveyList$ | async"
    >
      <em class="mr-3" nz-icon nzType="unordered-list" nzTheme="outline"></em>
      Todas
    </button>
    <button
      type="button"
      class="btn my-auto mr-3"
      [ngClass]="
        surveyTypeFilter === 'FAVORITE'
          ? 'btn-option-active shadow'
          : 'btn-option'
      "
      (click)="filterSurveys('FAVORITE')"
      [disabled]="surveyFacade.isLoadingSurveyList$ | async"
    >
      <em class="mr-2" nz-icon nzType="star" nzTheme="outline"></em>
      Destacadas
    </button>
    <button
      type="button"
      class="btn my-auto mr-3"
      [ngClass]="
        surveyTypeFilter === 'ARCHIVED'
          ? 'btn-option-active shadow'
          : 'btn-option'
      "
      (click)="filterSurveys('ARCHIVED')"
      [disabled]="surveyFacade.isLoadingSurveyList$ | async"
    >
      <em class="mr-2" nz-icon nzType="file-done" nzTheme="outline"></em>
      Archivadas ({{ (surveyFacade.surveyListInfo$ | async).archivedSurveys }})
    </button>
  </div>

  <div class="view-actions ml-auto">
    <button
      class="btn btn-option-active mr-3"
      title="Crear encuesta"
      nz-tooltip
      [nzTooltipTitle]="'Crea tu encuesta 📝'"
      nzTooltipColor="black"
      nzTooltipPlacement="bottom"
      (click)="showModal()"
      [disabled]="surveyFacade.isLoadingSurveyList$ | async"
    >
      <span class="mr-2" nz-icon nzType="plus" nzTheme="outline"></span>
      Crear Encuesta
    </button>
    <app-special-button
      id="create-survey-ia"
      buttonText="Crear con IA"
      iconType="robot"
      nz-tooltip
      [nzTooltipTitle]="'Crea tu encuesta con inteligencia artificial 🤖'"
      (buttonClicked)="createSurveyIA()"
      [disabled]="surveyFacade.isLoadingSurveyList$ | async"
    ></app-special-button>

    <!-- <button
      class="view-btn list-view"
      title="Ver lista"
      nz-tooltip
      [nzTooltipTitle]="'¡Próximamente! 🚀 Funcionalidad en desarrollo'"
      nzTooltipColor="black"
      nzTooltipPlacement="bottom"
    >
      <span nz-icon nzType="unordered-list" nzTheme="outline"></span>
    </button>
    <button class="view-btn grid-view active" title="Ver tarjetas">
      <span nz-icon nzType="appstore" nzTheme="outline"></span>
    </button> -->
  </div>
</div>

<!-- Modal create survey IA -->
<div class="border-modal-ia">
  <nz-modal
    *ngIf="isVisibleFormWithIA"
    [hidden]="!isVisibleFormWithIA"
    #modalWithBorder
    [nzFooter]="null"
    [(nzVisible)]="isVisibleFormWithIA"
    nzTitle="Crea tu nueva encuesta con IA"
    (nzOnCancel)="showModalIA(false)"
    (nzOnOk)="showModalIA(false)"
    [nzMaskClosable]="false"
    [nzClosable]="false"
    class="outline-gradient-1"
  >
    <ng-container *nzModalContent>
      <app-survey-form-ia
        (handleCancel)="showModalIA(false)"
      ></app-survey-form-ia>

      <!-- <app-advance-survey-form></app-advance-survey-form> -->
    </ng-container>
  </nz-modal>
</div>

<!-- Modal create survey -->
<nz-modal
  [nzFooter]="null"
  [(nzVisible)]="isVisibleForm"
  (nzOnCancel)="handleCancel()"
  (nzOnOk)="handleOk()"
  [nzMaskClosable]="false"
  style="width: 900px"
>
  <ng-container *nzModalContent>
    <app-survey-form
      *ngIf="isVisibleForm"
      (handleCancel)="handleCancel()"
      (handleModal)="handleModal()"
      [editSurvey]="false"
    ></app-survey-form>
  </ng-container>
</nz-modal>
