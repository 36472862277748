import { Injectable } from '@angular/core'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import {
  UpdateIsLoadingLastResponses,
  UpdateLastResponses,
  UpdatePage,
  UpdateResponses,
} from './responses.actions'

export interface ResponsesStateModel {
  responses: any
  lastResponses: any
  currentPage: number
  isLoadingLastResponses: boolean
}

@State<ResponsesStateModel>({
  name: 'responses',
  defaults: {
    responses: null,
    lastResponses: null,
    currentPage: 1,
    isLoadingLastResponses: true,
  },
})
@Injectable()
export class ResponsesState {
  @Selector()
  public static getResponses(state: ResponsesStateModel): any {
    return state.responses
  }
  @Selector()
  public static getLastResponses(state: ResponsesStateModel): any {
    return state.lastResponses
  }

  @Selector()
  public static getCurrentPage(state: ResponsesStateModel): any {
    return state.currentPage
  }

  @Selector()
  public static getIsLoadingLastResponses(state: ResponsesStateModel): boolean {
    return state.isLoadingLastResponses
  }

  @Action(UpdateResponses)
  updateReponses(
    { getState, setState }: StateContext<ResponsesStateModel>,
    { payload }: UpdateResponses
  ): void {
    const state = getState()
    setState({
      ...state,
      responses: payload,
    })
  }

  @Action(UpdatePage)
  updatePage(
    { getState, setState }: StateContext<ResponsesStateModel>,
    { payload }: UpdatePage
  ): void {
    const state = getState()
    setState({
      ...state,
      currentPage: payload,
    })
  }

  @Action(UpdateLastResponses)
  updateLastReponses(
    { getState, setState }: StateContext<ResponsesStateModel>,
    { payload }: UpdateLastResponses
  ): void {
    const state = getState()
    setState({
      ...state,
      lastResponses:
        state.currentPage > 1
          ? [...state.lastResponses, ...payload.data]
          : payload.data,
    })
  }

  @Action(UpdateIsLoadingLastResponses)
  updateIsLastReponses(
    { getState, setState }: StateContext<ResponsesStateModel>,
    { payload }: UpdateIsLoadingLastResponses
  ): void {
    const state = getState()
    setState({
      ...state,
      isLoadingLastResponses: payload,
    })
  }
}
