import { optionsChartDefault } from '../../../../utils/properties/hightchartOptions'

export const chartOptionsTrend = (labels: any, dataSeries: any) => {
  return {
    lang: optionsChartDefault.lang,
    chart: {
      renderTo: 'container',
      type: 'line',
      backgroundColor: 'transparent',
      spacing: [20, 20, 20, 20],
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: labels,
      title: {
        text: 'Fechas',
        style: {
          color: '#333',
          fontWeight: 'bold',
        },
      },
      labels: {
        style: {
          color: '#333',
        },
      },
      lineColor: '#333',
    },
    yAxis: {
      title: {
        text: 'Cantidad de respuestas',
        style: {
          color: '#333',
          fontWeight: 'bold',
        },
      },
      labels: {
        style: {
          color: '#333',
        },
      },
      gridLineColor: '#ddd',
      lineColor: '#333',
    },
    tooltip: {
      headerFormat: '<b>{point.key}</b><br>',
      pointFormat: 'Total respuestas: {point.y}',
      backgroundColor: 'rgba(255, 255, 255, 0.85)',
      borderColor: '#333',
      borderRadius: 5,
      style: {
        color: '#333',
      },
    },
    title: {
      text: 'Tendencias de respuestas en el tiempo',
      style: {
        color: '#333',
        fontWeight: 'bold',
        fontSize: '24px',
      },
      margin: 20,
    },
    subtitle: {
      text: 'Desde: Nombre de la encuesta',
      style: {
        color: '#666',
        fontSize: '16px',
      },
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      series: {
        marker: {
          fillColor: '#9b59b6',
          lineWidth: 2,
          lineColor: null,
        },
      },
      line: {
        color: '#9b59b6',
        lineWidth: 4,
      },
    },
    series: [
      {
        data: dataSeries,
        showInLegend: false,
      },
    ],
  }
}
