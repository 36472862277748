import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core'
import * as Highcharts from 'highcharts'
import { ICommentsSummary } from '../../../../models/request/ICommentsSummary'
import { ReportWeekType } from '../../../../models/types/report.types'
import { chartOptionsDoughnutComments } from '../../../../pages/survey/survey-report/mappersCharts/doughnut.mapper'
import { chartOptionsWordCloudComments } from '../../../../pages/survey/survey-report/mappersCharts/word-cloud.mapper '
import { DashboardFacade } from '../../../../store/dashboard/dashboard.facade'
import { QuestionFacade } from '../../../../store/question/question.facade'
import { ReportFacade } from '../../../../store/reports/report.facade'

@Component({
  selector: 'app-comments-insight',
  templateUrl: './comments-insight.component.html',
  styleUrls: ['./comments-insight.component.scss'],
})
export class CommentsInsightComponent implements OnChanges, OnDestroy {
  Highcharts: typeof Highcharts = Highcharts

  @Input() startDate: string = null
  @Input() endDate: string = null
  @Input() summaryResult: ICommentsSummary = null
  @Input() isLoadingSummary = true

  public donusChartOptionsComments
  public wordCloudChartOptionsComments

  public typeReportWeek: ReportWeekType = 'monthly'
  public surveySelectedId: string = '0'
  public isValidDonusChartData: boolean = false

  constructor(
    public questionFacade: QuestionFacade,
    public dashboardFacade: DashboardFacade,
    public reportFacade: ReportFacade
  ) {}

  ngOnDestroy(): void {
    console.info('[CommentsResultComponent] destroy')
  }

  async ngOnChanges(changes: SimpleChanges): Promise<Promise<Promise<void>>> {
    if (changes?.summaryResult?.currentValue) {
      this.generateCharts(changes?.summaryResult?.currentValue)
    }
  }

  generateCharts(summaryResult: ICommentsSummary): void {
    this.summaryResult = summaryResult
    const {
      relevantComments,
      nonRelevantComments,
      unansweredComments,
      totalComments,
      relevantCommentsList,
    } = this.summaryResult

    this.wordCloudChartOptionsComments = relevantCommentsList
      ? chartOptionsWordCloudComments(relevantCommentsList)
      : null

    this.isValidDonusChartData = totalComments ? totalComments > 0 : false

    this.donusChartOptionsComments = this.isValidDonusChartData
      ? chartOptionsDoughnutComments(
          ['Relevantes', 'No Relevantes', 'No Contestados'],
          [relevantComments, nonRelevantComments, unansweredComments],
          totalComments
        )
      : null
  }
}
