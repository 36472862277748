<div
  *ngIf="
    (sessionFacade.isLoadingSession$ | async) === true ||
    (sessionFacade.isLoadingLogout$ | async) === true ||
    (paymentFacade.isLoadingProcessPayment$ | async) === true
  "
>
  <app-loader
    [bg]="'dark'"
    [label]="
      (paymentFacade.isLoadingProcessPayment$ | async) === true
        ? 'Procesando pago...'
        : null
    "
  ></app-loader>
</div>
<div
  appLoadVoiceflow
  class="main-container-pro"
  *ngIf="
    (sessionFacade.isLoadingSession$ | async) === false &&
    (sessionFacade.isLoadingLogout$ | async) === false
  "
>
  <div
    [ngClass]="
      !showSidebarMobile
        ? !showExpandSidebar
          ? 'sidebar sidebar-container-contract'
          : 'sidebar sidebar-container-expand'
        : 'w-100 d-none d-xl-block'
    "
  >
    <app-sidebar
      [showExpandSidebar]="showExpandSidebar"
      (changeShowExpandSidebar)="changeShowExpandSidebar()"
    ></app-sidebar>
  </div>

  <div
    *ngIf="
      (sessionFacade.isLoadingSession$ | async) === false &&
      (sessionFacade.isLoadingLogout$ | async) === false
    "
    [ngClass]="
      !showSidebarMobile
        ? !showExpandSidebar
          ? 'page-contract w-100'
          : 'page-contract w-100'
        : 'w-100'
    "
  >
    <!--  ml-3 page-contract w-100 | Para dar archo a menu  -->
    <app-header
      [showSidebarMobile]="showSidebarMobile"
      [showExpandSidebar]="showExpandSidebar"
      (changeShowExpandSidebar)="changeShowExpandSidebar()"
    ></app-header>
    <main class="main-content animated-fade-in">
      <router-outlet></router-outlet>
    </main>
  </div>

  <app-config-survey-mf *ngIf="env === 'dev'"></app-config-survey-mf>
</div>

<app-plugin-survey></app-plugin-survey>
