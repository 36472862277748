<nz-spin [nzSpinning]="isLoadingSummary">
  <div class="card detail-question-result my-4">
    <div class="period-group-button">
      <nz-radio-group
        [(ngModel)]="typeReportWeek"
        nzButtonStyle="solid"
        (ngModelChange)="onRadioChange($event)"
      >
        <label nz-radio-button nzValue="monthly" for="monthly">Mensual</label>
        <label nz-radio-button nzValue="weekly" for="weekly">Semanal</label>
        <label nz-radio-button nzValue="daily" for="daily">Diario</label>
      </nz-radio-group>
    </div>
    <app-comments-insight
      [summaryResult]="summaryResult"
      [isLoadingSummary]="isLoadingSummary"
    ></app-comments-insight>
  </div>
</nz-spin>
