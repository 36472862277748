export class UpdateLastResponses {
  static readonly type = '[DASHBOARD] update last responses'
  constructor(public payload: any) {}
}
export class UpdateResponses {
  static readonly type = '[DASHBOARD] update responses'
  constructor(public payload: any) {}
}

export class UpdatePage {
  static readonly type = '[DASHBOARD] update current page'
  constructor(public payload: any) {}
}

export class UpdateIsLoadingLastResponses {
  static readonly type = '[DASHBOARD] update loading last responses'
  constructor(public payload: any) {}
}
