import { ViewportScroller } from '@angular/common'
import { Component, Input, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { PaymentFacade } from 'src/app/store/payment/payment.facade'
import { validateForm } from 'src/app/utils/formValidations/formValidations'
import {
  COMERCIAL_INFORMATION_NAME,
  PERSONAL_INFORMATION_ID,
} from '../../../../utils/constants/payment'
import { IPersonalInformation } from './../../../../store/payment/payment.state'

@Component({
  selector: 'app-billing-form',
  templateUrl: './billing-form.component.html',
  styleUrls: ['./billing-form.component.scss'],
})
export class BillingFormComponent implements OnInit {
  @Input() countries: [{ id: number; countryName: string }]
  public form: FormGroup
  public selectCountry: number

  constructor(
    private fb: FormBuilder,
    public paymentFacade: PaymentFacade,
    private viewportScroller: ViewportScroller
  ) {}

  ngOnInit(): void {
    this.initializeForm()
  }

  public initializeForm(): void {
    this.paymentFacade.personalInformation$.subscribe(
      (personalInformation: IPersonalInformation) => {
        this.form = this.fb.group({
          name: [
            personalInformation ? personalInformation?.name : '',
            [Validators.required, Validators.maxLength(50)],
          ],
          email: [
            personalInformation ? personalInformation?.email : '',
            [Validators.required, Validators.email, Validators.maxLength(70)],
          ],
          address: [
            personalInformation ? personalInformation?.address : '',
            [Validators.required, Validators.maxLength(75)],
          ],
          country: [
            personalInformation ? personalInformation?.country : '',
            [Validators.required, Validators.maxLength(50)],
          ],
          city: [
            personalInformation ? personalInformation?.city : '',
            [Validators.required, Validators.maxLength(50)],
          ],
          postalCode: [
            personalInformation ? personalInformation?.postalCode : '',
            Validators.maxLength(10),
          ],
        })
      }
    )
  }

  public saveForm(): void {
    for (const i in this.form.controls) {
      this.form.controls[i].markAsDirty()
      this.form.controls[i].updateValueAndValidity()
    }
    validateForm(this.form)

    if (this.form.valid) {
      const personalInformation = {
        name: this.form.get('name').value,
        email: this.form.get('email').value,
        address: this.form.get('address').value,
        country: this.form.get('country').value,
        city: this.form.get('city').value,
        postalCode: this.form.get('postalCode').value,
      }

      this.paymentFacade.updatePersonalInformation(personalInformation)
      this.paymentFacade.UpdateStepById(
        PERSONAL_INFORMATION_ID,
        COMERCIAL_INFORMATION_NAME,
        true
      )
      this.paymentFacade.updateIsValidForm(true)

      setTimeout(() => {
        this.viewportScroller.scrollToAnchor('comercial_information')
      }, 1)
    } else this.paymentFacade.updateIsValidForm(false)
  }
}
