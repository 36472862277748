import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { HighchartsChartModule } from 'highcharts-angular'
import { SharedModule } from '../shared/shared.module'
import { ComponentsModule } from './../components/components.module'
import { AppRoutingPageModule } from './app-routing-page.module'
import { DashboardModule } from './dashboard/dashboard.module'
import { DigitalBookModule } from './digital-book/digital-book.module'
import { ProfileModule } from './profile/profile.module'
import { SurveyModule } from './survey/survey.module'
import { UsersModule } from './users/users.module'

import { LoadVoiceflowDirective } from '../directives/load-voiceflow.directive'
import { NgZorroAntdModule } from '../ng-zorro-antd.module'
import { MaintenanceComponent } from './maintenance/maintenance.component'
import { PagesComponent } from './pages.component'
import { QuestionModule } from './question/question.module'
import { ReportComponent } from './report/report.component'

@NgModule({
  declarations: [
    PagesComponent,
    ReportComponent,
    MaintenanceComponent,
    LoadVoiceflowDirective,
  ],
  imports: [
    CommonModule,
    AppRoutingPageModule,
    NgZorroAntdModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    SurveyModule,
    QuestionModule,
    ProfileModule,
    DashboardModule,
    ComponentsModule,
    HighchartsChartModule,
    UsersModule,
    DigitalBookModule,
  ],
  providers: [],
})
export class PagesModule {}
