<nz-steps [nzCurrent]="currentStep" (nzIndexChange)="handleIndexChange($event)">
  <nz-step
    nzTitle="Finished"
    nzIcon="info-circle"
    [nzDisabled]="false"
    nzDescription="This is a description."
  ></nz-step>
  <nz-step
    nzTitle="In Progress"
    nzIcon="usergroup-add"
    nzDescription="This is a description."
  ></nz-step>
  <nz-step
    nzTitle="Waiting"
    nzIcon="form"
    nzDescription="This is a description."
  ></nz-step>
  <nz-step
    nzTitle="Configuración y Lanzamiento"
    nzDescription="This is a description."
    nzIcon="setting"
  >
  </nz-step>
</nz-steps>

<div class="steps-content">
  <h1>{{ currentStep }}</h1>

  <div *ngIf="currentStep === 0">
    <form nz-form [formGroup]="surveyForm" (ngSubmit)="onFirstStepSubmit()">
      <nz-form-item>
        <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="name" nzRequired
          >Nombre de la encuesta</nz-form-label
        >
        <nz-form-control [nzSm]="18" [nzXs]="24">
          <input
            nz-input
            formControlName="name"
            placeholder="Introduce el nombre de la encuesta"
          />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="objective" nzRequired
          >Objetivo de la encuesta</nz-form-label
        >
        <nz-form-control [nzSm]="18" [nzXs]="24">
          <textarea
            nz-input
            formControlName="objective"
            rows="3"
            placeholder="Describe brevemente el objetivo de la encuesta"
          ></textarea>
        </nz-form-control>
      </nz-form-item>
      <button
        nz-button
        nzType="primary"
        [disabled]="
          !surveyForm.controls['name'].valid ||
          !surveyForm.controls['objective'].valid
        "
      >
        Siguiente
      </button>
    </form>
  </div>
  <div *ngIf="currentStep === 1">
    <form nz-form [formGroup]="surveyForm" (ngSubmit)="onSecondStepSubmit()">
      <nz-form-item>
        <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="category" nzRequired
          >Categoría de encuesta</nz-form-label
        >
        <nz-form-control [nzSm]="18" [nzXs]="24">
          <nz-select
            formControlName="category"
            nzPlaceHolder="Selecciona una categoría"
          >
            <nz-option nzValue="cliente" nzLabel="Cliente"></nz-option>
            <nz-option nzValue="empleado" nzLabel="Empleado"></nz-option>
            <nz-option nzValue="producto" nzLabel="Producto"></nz-option>
            <nz-option nzValue="servicio" nzLabel="Servicio"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="audience" nzRequired
          >Público objetivo</nz-form-label
        >
        <nz-form-control [nzSm]="18" [nzXs]="24">
          <div nz-row [nzGutter]="16">
            <div nz-col [nzSpan]="6" *ngFor="let audience of audiences">
              <nz-card
                nzHoverable
                (click)="selectAudience(audience.value)"
                [nzBordered]="selectedAudience === audience.value"
              >
                <nz-card-meta>
                  <ng-template #avatar>
                    <img [src]="audience.icon" alt="{{ audience.label }}" />
                  </ng-template>
                  nzTitle="{{ audience.label }}"
                </nz-card-meta>
              </nz-card>
            </div>
          </div>
        </nz-form-control>
      </nz-form-item>

      <button
        nz-button
        nzType="primary"
        [disabled]="
          !surveyForm.controls['category'].valid ||
          !surveyForm.controls['audience'].valid
        "
      >
        Siguiente
      </button>
    </form>
  </div>
  <div *ngIf="currentStep === 2">
    <form nz-form [formGroup]="surveyForm" (ngSubmit)="onThirdStepSubmit()">
      <!-- Aquí se podría implementar un componente dinámico para la generación de preguntas -->
      <app-dynamic-question-builder
        [form]="surveyForm"
      ></app-dynamic-question-builder>
      <button nz-button nzType="primary">Siguiente</button>
    </form>
  </div>
  <div *ngIf="currentStep === 3">
    <form nz-form [formGroup]="surveyForm" (ngSubmit)="onFinalStepSubmit()">
      <nz-form-item>
        <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="anonimato"
          >Anonimato</nz-form-label
        >
        <nz-form-control [nzSm]="18" [nzXs]="24">
          <nz-switch formControlName="anonymity"></nz-switch>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="dataCollection"
          >Recolección de datos</nz-form-label
        >
        <nz-form-control [nzSm]="18" [nzXs]="24">
          <nz-switch formControlName="dataCollection"></nz-switch>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="expirationDate" nzRequired
          >Fecha de expiración</nz-form-label
        >
        <nz-form-control [nzSm]="18" [nzXs]="24">
          <nz-date-picker
            formControlName="expirationDate"
            nzPlaceHolder="Selecciona la fecha de expiración"
          ></nz-date-picker>
        </nz-form-control>
      </nz-form-item>
      <button nz-button nzType="primary">Lanzar Encuesta</button>
    </form>
  </div>
</div>
<div class="steps-action">
  <button nz-button nzType="default" (click)="pre()" *ngIf="currentStep > 0">
    <span>Previous</span>
  </button>
  <button nz-button nzType="default" (click)="next()" *ngIf="currentStep < 2">
    <span>Next</span>
  </button>
  <button nz-button nzType="primary" (click)="done()" *ngIf="currentStep === 2">
    <span>Done</span>
  </button>
</div>
