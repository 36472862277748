import { Component, Input, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { NzCarouselComponent } from 'ng-zorro-antd/carousel'
import { IAboutSurvey } from 'src/app/models/response/IAboutSurvey'
import { IPlan } from 'src/app/models/response/IPlan'
import { SurveyFacade } from 'src/app/store/store.index'
import { PlanFacade } from '../../../store/plan/plan.facade'
import { UserFacade } from '../../../store/user/user.facade'
import { environment } from './../../../../environments/environment'

@Component({
  selector: 'app-card-info-survey',
  templateUrl: './card-info-survey.component.html',
  styleUrls: ['./card-info-survey.component.scss'],
})
export class CardInfoSurveyComponent {
  @Input() isLoadingCardInfo: boolean = true
  @ViewChild(NzCarouselComponent, { static: false })
  carouselSurvey: NzCarouselComponent
  public isBetaProduction = environment.production

  public LOGO_COMPANY = './assets/images/logos/light-large-logo.png'

  public routerPlan: string
  public dataAboutSurvey: IAboutSurvey[]
  public isVisibleModal: boolean

  public IMG_SURVEY = '../../../../assets/images/icons/survey-list.png'
  public IMG_RESPONSE = '../../../../assets/images/icons/response.png'
  public IMG_RESPONSE_C = '../../../../assets/images/icons/response-c.png'
  public IMG_PLAN = '../../../../assets/images/icons/information.png'
  public IMG_PLAN_C = '../../../../assets/images/icons/information-c.png'
  public IMG_NOT_FOUND = '../../../../assets/images/icons/no-results.png'
  public IMG_ROCKET = '../../../../assets/images/graphics/rocket-5.gif'

  constructor(
    public router: Router,
    public userFacade: UserFacade,
    public planFacade: PlanFacade,
    public surveyFacade: SurveyFacade
  ) {
    const url = this.router.routerState.snapshot.url
    this.routerPlan = url.split('survey').join('plans-prices')
    this.surveyFacade.getAboutFetch()
    this.isVisibleModal = false
    this.planFacade.currentPlan$.subscribe((res: IPlan) => {
      if (res) this.isVisibleModal = res.responseLimitExceeded
    })
  }

  public nextSlide(): void {
    this.carouselSurvey.next()
  }

  public prevSlide(): void {
    this.carouselSurvey.pre()
  }
}
