<div class="loader-container">
  <div class="bot-image">
    <nz-avatar
      [nzSize]="200"
      nzIcon="user"
      [nzSrc]="IMG_EMPATI_BOT"
    ></nz-avatar>
  </div>
  <svg height="0" width="0">
    <defs>
      <filter
        color-interpolation-filters="sRGB"
        height="200%"
        y="-50%"
        width="200%"
        x="-50%"
        id="goo"
      >
        <feGaussianBlur
          result="blur"
          stdDeviation="8"
          in="SourceGraphic"
        ></feGaussianBlur>
        <feColorMatrix
          result="cm"
          values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 21 -7"
          mode="matrix"
          in="blur"
        ></feColorMatrix>
      </filter>
    </defs>
  </svg>

  <svg height="180" width="320" viewBox="0 0 320 180">
    <g filter="url(#goo)">
      <circle cy="90" cx="160" r="24" fill="#275EFE" class="circle"></circle>
      <circle
        cy="90"
        cx="160"
        r="24"
        fill="#275EFE"
        class="circle right"
      ></circle>
    </g>
  </svg>

  <h6 class="text-center mt-3">
    Estoy procesando tu solicitud. Este proceso puede tomar un momento.
    ¡Agradecemos tu paciencia!
  </h6>
</div>
