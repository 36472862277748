import { ChangeDetectorRef, Component, OnInit } from '@angular/core'
import * as Highcharts from 'highcharts'
import { first } from 'rxjs/operators'
import { IQuestion } from 'src/app/models/response/IQuestion'
import { QuestionFacade } from 'src/app/store/question/question.facade'
import { ReportType } from './../../../models/types/report.types'
import { DashboardFacade } from './../../../store/dashboard/dashboard.facade'
import { ReportFacade } from './../../../store/reports/report.facade'
import { SurveyFacade } from './../../../store/survey/survey.facade'
import { chartOptionsGeneralResponses } from './mappersCharts/responses.mapper'

const weekDays = {
  Sunday: { es: 'Domingo', en: 'Sunday' },
  Monday: { es: 'Lunes', en: 'Monday' },
  Tuesday: { es: 'Martes', en: 'Tuesday' },
  Wednesday: { es: 'Miércoles', en: 'Wednesday' },
  Thursday: { es: 'Jueves', en: 'Thursday' },
  Friday: { es: 'Viernes', en: 'Friday' },
  Saturday: { es: 'Sábado', en: 'Saturday' },
}

type OptionsTypeChange = 'isActive' | 'commentAvailable' | 'isMandatory'

@Component({
  selector: 'app-survey-report',
  templateUrl: './survey-report.component.html',
  styleUrls: ['./survey-report.component.scss'],
})
export class SurveyReportComponent implements OnInit {
  Highcharts: typeof Highcharts = Highcharts
  chartOptions: any
  chartOptionsTrend

  public questionsSurvey: IQuestion[] = []
  public questionSelectedId: string = '0'
  public questionSelected: IQuestion = null
  public lastSelectedId: string = '0'
  public surveySelected: string = '0'
  public isLoading = false
  public typeReport: ReportType = 'surveys'

  constructor(
    public questionFacade: QuestionFacade,
    public dashboardFacade: DashboardFacade,
    private surveyFacade: SurveyFacade,
    public reportFacade: ReportFacade,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.questionFacade.questions$.subscribe((questions: IQuestion[]) => {
      if (questions) {
        this.questionsSurvey = questions
      }
    })
  }

  ngOnInit(): void {
    this.reportFacade.trendTotalResponsesDataReport$.subscribe((options) => {
      if (options) {
        this.chartOptionsTrend = { ...options }
      }
    })

    this.questionFacade.question$.subscribe((question: IQuestion) => {
      if (question) {
        this.questionSelected = question
        this.questionSelectedId = question.questionId
      }
    })
  }

  public async changeOption(
    question: IQuestion,
    optionType: OptionsTypeChange
  ): Promise<void> {
    this.questionSelected = {
      ...question,
      [optionType]: !question[optionType],
    }

    console.log(
      '[optionType-questionSelected]: ',
      optionType,
      question[optionType],
      this.questionSelected
    )

    const survey = await this.surveyFacade.survey$.pipe(first()).toPromise()
    this.questionFacade.changeOptionsFetch(
      this.questionSelected,
      survey.surveyId
    )
  }

  public generateOptionsWeekChart(questionResponsesDataReport: any): void {
    const { categories, values } = this.splitChartData(
      questionResponsesDataReport
    )
    const options = chartOptionsGeneralResponses(categories, values)
    this.chartOptions = options
  }

  private splitChartData(data: any[]): {
    categories: string[]
    values: number[]
  } {
    const categories: string[] = []
    const values: number[] = []

    for (const item of data) {
      const day = weekDays[item.day].es
      categories.push(day)
      values.push(item.value)
    }

    return { categories, values }
  }

  public getIndicators(questionId: string): void {
    if (questionId === this.lastSelectedId) return
    this.lastSelectedId = questionId
    if (questionId === '0') return this.questionFacade.updateQuestion(null)

    const question = this.questionsSurvey.find(
      (q) => q.questionId == questionId
    )

    const questionUpdated = { ...question, isSelected: true }
    this.questionFacade.updateQuestion(questionUpdated)
  }
}
