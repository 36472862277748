<div class="container">
  <div class="row justify-content-center">
    <div class="col-12 col-lg-8 col-xl-7">
      <div class="card message-box _failed">
        <img class="mx-auto mb-3" [src]="errorImage" alt="" />
        <h2>Tu pago ha fallado</h2>
        <p>Puedes intentar realizar el pago nuevamente</p>

        <button
          type="button"
          (click)="redirectTryPayment()"
          class="btn btn-danger btn-rounded mt-5 col-10 col-md-5 mx-auto"
        >
          <span class="align-items-center"> Intentalo nuevamente </span>
        </button>
      </div>
    </div>
  </div>
</div>
