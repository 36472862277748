<section
  id="login"
  class="login"
  style="background-image: url('./assets/images/bg/bg-login-01.jpg') !important"
>
  <div class="login__background">
    <div class="row align-items-center h-100">
      <div
        class="col-lg-5 col-md-12 align-self-center animated fadeIn d-none d-lg-block"
      >
        <img
          src="./assets/images/logos/light-large-logo.png"
          class="img-fluid d-block mx-auto"
          alt=""
        />
      </div>
      <div
        class="col-lg-7 col-md-12 align-self-center animated fadeIn form-col"
      >
        <div class="container">
          <div class="card p-xs-2 p-sm-5 mx-auto">
            <div *ngIf="!accountCreated" class="card-body">
              <img
                src="./assets/images/logos/dark-large-logo.png"
                class="img-fluid mr-auto logo-form d-none mb-2"
                alt=""
              />
              <h1 class="text-dark">Crear una cuenta</h1>
              <p class="text-dark mb-5">
                ¿Ya tienes una cuenta?
                <a [routerLink]="['/login']"> Iniciar sesión </a>
              </p>

              <form
                nz-form
                [formGroup]="registerForm"
                class="login-form"
                (ngSubmit)="submitForm()"
              >
                <!-- Email -->
                <app-input-field
                  class="w-100"
                  [groupName]="registerForm"
                  controlName="email"
                  label="Email"
                  errorMessage="Ingresa dirección de correo electrónico válido"
                  icon="mail"
                ></app-input-field>

                <!-- FullName -->
                <app-input-field
                  class="w-100"
                  [groupName]="registerForm"
                  controlName="fullName"
                  label="Nombre completo"
                  errorMessage="Ingresa tu nombre completo"
                  icon="user"
                ></app-input-field>

                <!-- Password -->
                <app-input-field
                  class="w-100"
                  [groupName]="registerForm"
                  controlName="password"
                  label="Contraseña"
                  errorMessage="Ingresa una contraseña"
                  secondaryErrorMessage="Tu contraseña debe contener al menos un número, una
                  letra mayúscula, una minúscula y un largo entre 6 y 18
                  caracteres"
                  icon="lock"
                  inputType="password"
                ></app-input-field>

                <!-- RepeatPassword -->
                <app-input-field
                  class="w-100"
                  [groupName]="registerForm"
                  controlName="repeatPassword"
                  label="Repetir contraseña"
                  errorMessage="Confirma tu contraseña"
                  secondaryErrorMessage="Las dos contraseñas que ingresaste son inconsistentes"
                  icon="lock"
                  inputType="password"
                ></app-input-field>

                <div class="d-flex">
                  <button
                    [disabled]="loading"
                    type="submit"
                    class="btn btn-primary ml-auto"
                  >
                    <span *ngIf="loading" class="mr-2"
                      >Validando tus datos</span
                    >
                    <i *ngIf="loading" nz-icon [nzType]="'loading'"></i>
                    <span *ngIf="!loading">Crear cuenta</span>
                  </button>
                </div>

                <div class="mt-3">
                  <small class="text-muted mt-xs-2">
                    Protegido por reCAPTCHA y sujeto a
                    <a
                      class=""
                      href="https://policies.google.com/privacy?hl=es"
                      aria-label="Política de privacidad de Google"
                      rel="noopener"
                      target="_blank"
                      >Política de privacidad</a
                    >
                    y
                    <a
                      class=""
                      href="https://policies.google.com/terms?hl=es"
                      aria-label="Condiciones del servicio de Google"
                      rel="noopener"
                      target="_blank"
                      >Condiciones del servicio</a
                    >
                    de Google.
                  </small>
                </div>
              </form>
            </div>
            <div *ngIf="accountCreated" class="card-body">
              <img
                src="./assets/images/logos/dark-large-logo.png"
                class="img-fluid mr-auto logo-form d-none mb-2"
              />
              <h1 class="text-dark">Tu cuenta ha sido creada!</h1>
              <p class="text-dark mb-5">
                Hola
                <span class="text-primary font-weight-bold">{{
                  requestRegister.name
                }}</span>
                nos alegra que formes parte de esta experiencia. Hemos enviado
                un correo a
                <span class="text-info"> {{ requestRegister.email }}</span
                >. Revisa tu buzón para que realices la validación de tu cuenta.
              </p>

              <div class="d-flex">
                <button
                  type="button"
                  (click)="router.navigate(['/login'])"
                  class="btn btn-primary ml-auto"
                >
                  <span>Ir a inicio de sesión</span>
                </button>
              </div>
              <div class="mt-3">
                <small class="text-muted mt-xs-2">
                  Protegido por reCAPTCHA y sujeto a
                  <a
                    class=""
                    href="https://policies.google.com/privacy?hl=es"
                    aria-label="Política de privacidad de Google"
                    rel="noopener"
                    target="_blank"
                    >Política de privacidad</a
                  >
                  y
                  <a
                    class=""
                    href="https://policies.google.com/terms?hl=es"
                    aria-label="Condiciones del servicio de Google"
                    rel="noopener"
                    target="_blank"
                    >Condiciones del servicio</a
                  >
                  de Google.
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
