import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core'
import { Router } from '@angular/router'
import { Select } from '@ngxs/store'
import { Observable } from 'rxjs-compat'
import { IPlan } from 'src/app/models/response/IPlan'
import { PlanState } from 'src/app/store/plan/plan.state'
import { SessionFacade } from 'src/app/store/session/session.facade'

import data from './data-sidebar.json'

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
})
export class SidebarComponent implements OnInit {
  @Select(PlanState.getCurrentPlan) plan$: Observable<IPlan>
  @Select(PlanState.getLoadingPlan) loadingPlan$: Observable<boolean>
  @Input() showExpandSidebar: boolean
  @Output() changeShowExpandSidebar = new EventEmitter<File>()

  public logoPathXS: string = './assets/images/logos/light-xs-logo.png'
  public logoPathLG: string = './assets/images/logos/light-large-logo.png'

  public menuOptions
  public activeMenu: string
  public showDrawerMobile: boolean

  constructor(public sessionFacade: SessionFacade, private router: Router) {
    this.sessionFacade.updateActiveURL(this.router.url)
    this.menuOptions = data
    this.showDrawerMobile = window.innerWidth < 1200
  }

  ngOnInit(): void {
    if (window.innerWidth <= 768) this.changeShowExpandSidebar.emit()
  }

  public activateMenu(title: string, url: string) {
    setTimeout(() => {
      this.sessionFacade.updateActiveURL(`/app${url}`)
      this.activeMenu = title
    }, 1)
  }

  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    this.showDrawerMobile = window.innerWidth < 1200
  }
}
