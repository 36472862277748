import { Component, EventEmitter, Input, Output } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { Router } from '@angular/router'
import { Store } from '@ngxs/store'
import * as FileSaver from 'file-saver'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { ClipboardService } from 'ngx-clipboard'
import { first } from 'rxjs/operators'
import { ICategoryResponse } from 'src/app/models/response/ICategoryResponse'
import { IResponse } from 'src/app/models/response/IResponse'
import { ISurvey } from 'src/app/models/response/ISurvey'
import { SurveyService } from 'src/app/services/pages/survey.service'
import { ERROR_SERVICE_TITLE } from 'src/app/utils/constants/generic-messages'
import { cleanText } from 'src/app/utils/converts/strings'
import {
  IMassiveSurveyQuestion,
  IMassiveSurveyQuestionRequest,
} from './../../../models/request/IMassiveSurveyQuestion'
import { IQuestion } from './../../../models/response/IQuestion'
import { ISurveyListInfo } from './../../../models/response/ISurveyListInfo'
import { SurveyCategoryService } from './../../../services/pages/survey-category.service'
import { QuestionFacade } from './../../../store/question/question.facade'
import {
  UpdateSurveyCategories,
  UpdateSurveyList,
} from './../../../store/survey/survey.actions'
import { SurveyFacade } from './../../../store/survey/survey.facade'
import { ERROR_SERVICE_MESSAGE } from './../../../utils/constants/generic-messages'
import {
  ERROR_CREATE_CATEGORY_MESSAGE,
  ERROR_CREATE_SURVEY_EXIST_MESSAGE,
  ERROR_CREATE_SURVEY_EXIST_TITLE,
  ERROR_CREATE_SURVEY_LIMIT_MESSAGE,
  ERROR_CREATE_SURVEY_LIMIT_TITLE,
  SUCCESS_CLONE_SURVEY_MESSAGE,
  SUCCESS_CLONE_SURVEY_TITLE,
  SUCCESS_COPY_SURVEY_MESSAGE,
  SUCCESS_COPY_SURVEY_TITLE,
} from './../../../utils/constants/survey-messages'

@Component({
  selector: 'app-card-survey',
  templateUrl: './card-survey.component.html',
  styleUrls: ['./card-survey.component.scss'],
})
export class CardSurveyComponent {
  @Output() isModeSurveyArchivedChange = new EventEmitter<boolean>()
  @Input() public viewNewSurveyCard = false
  @Input() public survey: ISurvey
  @Input() public typeStatusSurvey: number
  @Input() public typeViewSurvey: string = 'card'
  @Input() public isModeSurveyArchived: boolean = false

  public viewDetailSurvey: boolean
  public viewOptionsSurvey: boolean
  public viewRecentActivity: boolean
  public viewInfoSurvey: boolean
  public loadingQR: boolean
  public loadingDownloadQR: boolean
  public isVisibleQR: boolean
  public displayImageQR
  public isLoadingToggleSurvey: boolean
  public isLoadingCloneSurvey: boolean
  public isVisibleSurveyCloneModal: boolean
  public countArchivedSurvey: number
  public questions: IQuestion[]
  public form: FormGroup

  public ICON_INFO = './assets/images/icons/icon_info_card.svg'
  public ICON_OPTION = './assets/images/icons/icon_option_y.svg'

  public isCategorySelectVisible = false
  public listOfCategories = []

  constructor(
    public router: Router,
    public surveyFacade: SurveyFacade,
    public questionFacade: QuestionFacade,
    private readonly _survey: SurveyService,
    private readonly _notification: NzNotificationService,
    private readonly _clipboardService: ClipboardService,
    private readonly _surveyCategory: SurveyCategoryService,
    private readonly store: Store
  ) {
    this.viewDetailSurvey = true
    this.viewOptionsSurvey = true
    this.viewRecentActivity = true
    this.viewInfoSurvey = true
    this.isLoadingToggleSurvey = false
    this.isVisibleSurveyCloneModal = false
    this.isLoadingCloneSurvey = false

    surveyFacade.surveyListInfo$.subscribe(
      (surveyListInfo: ISurveyListInfo) => {
        if (surveyListInfo) {
          this.countArchivedSurvey = surveyListInfo.archivedSurveys
        }
      }
    )

    surveyFacade.surveyCategories$.subscribe(
      (categories: ICategoryResponse[]) => {
        this.listOfCategories = categories ?? []
      }
    )
  }

  public viewDetail(surveyId: string): void {
    this.router.navigate([`app/survey/${surveyId}`])
  }

  public addTag(newCategory: string) {
    const newCategoryTrim = newCategory.trim()
    if (newCategoryTrim && newCategoryTrim.length <= 25) {
      this._surveyCategory
        .addNewCategory({
          surveyId: this.survey.surveyId,
          categoryName: newCategoryTrim,
        })
        .subscribe(
          async (response: IResponse) => {
            if (response.status.code === 201) {
              const surveyId = this.survey.surveyId
              this.listOfCategories = [
                ...this.listOfCategories,
                {
                  categoryId: response.data.categoryId,
                  categoryName: newCategoryTrim,
                  surveysAssociated: [surveyId],
                },
              ]
              this.store.dispatch(
                new UpdateSurveyCategories(this.listOfCategories)
              )

              const surveyList = await this.surveyFacade.surveyList$
                .pipe(first())
                .toPromise()
              const newSurveyList = surveyList.map((survey) => {
                if (survey.surveyId === surveyId) {
                  return { ...survey, category: newCategoryTrim }
                }
                return survey
              })
              this.store.dispatch(new UpdateSurveyList(newSurveyList))
            }
          },
          (error) => {
            switch (error.status) {
              case 409:
                this._notification.create(
                  'info',
                  ERROR_CREATE_SURVEY_EXIST_TITLE,
                  ERROR_CREATE_CATEGORY_MESSAGE
                )
                break
              default:
                this._notification.create(
                  'error',
                  ERROR_SERVICE_TITLE,
                  ERROR_SERVICE_MESSAGE
                )
                break
            }
          }
        )
    }
  }

  public assignCategory(surveyId: string, categoryId: string) {
    this._surveyCategory
      .assignExistingCategory({
        surveyId,
        categoryId,
      })
      .subscribe(
        async (response: IResponse) => {
          if (response.status.code === 200) {
            const categoryName = this.listOfCategories.find(
              (category) => category.categoryId === categoryId
            ).categoryName
            const surveyList = await this.surveyFacade.surveyList$
              .pipe(first())
              .toPromise()
            const newSurveyList = surveyList.map((survey) => {
              if (survey.surveyId === surveyId) {
                return { ...survey, category: categoryName }
              }
              return survey
            })
            this.store.dispatch(new UpdateSurveyList(newSurveyList))
          }
        },
        (error) => {
          this._notification.create(
            'error',
            ERROR_SERVICE_TITLE,
            ERROR_SERVICE_MESSAGE
          )
        }
      )
  }

  public toggleFavorite(survey: ISurvey): void {
    const { surveyId } = survey
    this._survey.toggleFavoriteSurveyFetch(surveyId).subscribe(
      async (response: IResponse) => {
        if (response.status.code === 200) {
          const surveyList = await this.surveyFacade.surveyList$
            .pipe(first())
            .toPromise()
          const newSurveyList = surveyList.map((survey) => {
            if (survey.surveyId === surveyId) {
              return { ...survey, isFavorite: !survey.isFavorite }
            }
            return survey
          })
          this.store.dispatch(new UpdateSurveyList(newSurveyList))
        }
      },
      (error) => {
        this._notification.create(
          'error',
          ERROR_SERVICE_TITLE,
          ERROR_SERVICE_MESSAGE
        )
      }
    )
  }

  public displayQR(): void {
    this.isVisibleQR = true
    this.loadingQR = true

    this._survey
      .QRbySurveyId(this.survey.surveyId)
      .subscribe((response: IResponse) => {
        switch (response.status.code) {
          case 200:
            this.displayImageQR = response.data
            this.loadingQR = false
            break

          default:
            break
        }
      })
  }

  handleCancel(): void {
    this.isVisibleQR = false
  }

  handleOk(): void {
    this.isVisibleQR = false
  }

  handleFormChange(updatedForm: FormGroup) {
    this.form = updatedForm
  }

  public downloadImage(): void {
    const nameQR = `${cleanText(this.survey.name)}_QR.png`
    FileSaver.saveAs(this.displayImageQR, nameQR)
  }

  public copyLink(): void {
    const copyUrl = this._clipboardService.copyFromContent(
      this.survey.urlShared
    )
    if (copyUrl) {
      this._notification.create(
        'success',
        SUCCESS_COPY_SURVEY_TITLE,
        SUCCESS_COPY_SURVEY_MESSAGE
      )
    }
  }

  public toggleSurvey(surveyId: string, isActive: number): void {
    isActive = isActive === 1 ? 0 : 1
    this.isLoadingToggleSurvey = true
    this.surveyFacade.toggleSurveyFetch(surveyId, isActive).add(() => {
      this.isLoadingToggleSurvey = false
    })
  }

  public toggleArchiveSurvey(surveyId: string, isArchived: number): void {
    this.isLoadingToggleSurvey = true
    this.surveyFacade
      .archiveSurveyFetch(surveyId, isArchived, this.countArchivedSurvey)
      .add(() => {
        this.isLoadingToggleSurvey = false
        if (this.countArchivedSurvey <= 1) {
          this.isModeSurveyArchived = false
          this.isModeSurveyArchivedChange.emit(this.isModeSurveyArchived)
        }
      })
  }

  public deleteSurvey(surveyId: string): void {
    this._survey.deleteSurvey(surveyId).subscribe(
      (response) => {
        if (response.status.code === 201) {
          this.surveyFacade.getSurveyList()
        }
      },
      (error) => {
        this._notification.create(
          'error',
          ERROR_SERVICE_TITLE,
          ERROR_SERVICE_MESSAGE
        )
      }
    )
  }

  public async viewSurveyCloneModal(surveyId: string): Promise<void> {
    this.isVisibleSurveyCloneModal = true
    this.questions = await this.questionFacade.loadQuestionsSurveyFetch(
      surveyId
    )
  }

  public async cloneSurvey(survey: ISurvey): Promise<void> {
    const request: IMassiveSurveyQuestionRequest = {
      name: this.form.get('name').value,
      description: this.form.get('description').value,
      formatType: this.form.get('surveyFormat').value,
      isRepeatMode: this.form.get('isRepeatModeSurvey').value,
      isTabletMode: this.form.get('isTabletModeSurvey').value,
      isActivedRedirectUrl: this.form.get('isActiveRedirectUrl').value,
      redirectUrl: this.form.get('redirectUrl').value,
      presentationText: survey.presentationText,
      mainBgType: survey.mainBgType,
      contentBgType: survey.contentBgType,
      questions: this.mapQuestionToSurveyMasivaQuestions(this.questions),
    }
    this.createCloneSurvey(request)
  }

  private createCloneSurvey(request: IMassiveSurveyQuestionRequest): void {
    this.isLoadingCloneSurvey = true

    this._survey.createMassiveSurvey(request).subscribe(
      (response) => {
        if (response.status.code === 201) {
          this._notification.create(
            'success',
            SUCCESS_CLONE_SURVEY_TITLE,
            SUCCESS_CLONE_SURVEY_MESSAGE
          )
          this.router.navigate([`/app/survey/${response.data.surveyId}`])
        }
        this.isLoadingCloneSurvey = false
      },
      (error) => {
        switch (error.status) {
          case 409:
            this._notification.create(
              'info',
              ERROR_CREATE_SURVEY_EXIST_TITLE,
              ERROR_CREATE_SURVEY_EXIST_MESSAGE
            )
            break
          case 406:
            this._notification.create(
              'info',
              ERROR_CREATE_SURVEY_LIMIT_TITLE,
              ERROR_CREATE_SURVEY_LIMIT_MESSAGE
            )
            break
          default:
            this._notification.create(
              'error',
              ERROR_SERVICE_TITLE,
              ERROR_SERVICE_MESSAGE
            )
            break
        }
        console.log(error)
        this.isLoadingCloneSurvey = false
      }
    )
  }

  private mapQuestionToSurveyMasivaQuestions(
    questions: IQuestion[]
  ): IMassiveSurveyQuestion[] {
    const mappedQuestions: IMassiveSurveyQuestion[] = []
    questions.forEach((question: IQuestion) => {
      const questionSurvey: IMassiveSurveyQuestion = {
        order: question.orden,
        questionTitle: question.title,
        questionType: question.typeId,
        isMandatory: question.isMandatory,
        isCommentAvailable: question.commentAvailable,
        questionScale: question.optionScale,
      }
      mappedQuestions.push(questionSurvey)
    })
    return mappedQuestions
  }
}
