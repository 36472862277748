<div class="row">
  <div class="col-xl-7 col-lg-12 col-md-12 col-sm-12">
    <div class="row">
      <div class="col-12">
        <div class="primary-custom-card1 card p-1">
          <div class="card-body">
            <div class="row">
              <div class="col-12 col-sm-4">
                <div class="prime-card">
                  <img
                    class="img-fluid"
                    src="../../../assets/images/bg/hi.png"
                    alt=""
                  />
                </div>
              </div>

              <div class="col-12 col-sm-8 mt-3 mt-lg-0">
                <div class="text-justified align-items-center">
                  <app-notification
                    *ngIf="(userFacade.user$ | async).isVerfied === false"
                    [text]="
                      'Tu cuenta aún no ha sido activada. Por favor, revisa tu correo electrónico y sigue las instrucciones para activar tu cuenta.'
                    "
                    [actionText]="'Reenviar correo de activación'"
                    [counterActionEnabled]="counterTime"
                    (handleChange)="handleNotificationUserChange()"
                  ></app-notification>
                  <h4 class="text-dark font-weight-semibold mb-3 mt-2">
                    👋 ¡Hola,
                    <span class="text-primary"
                      >{{ (userFacade.user$ | async)?.nombreUsuario }}!</span
                    >
                  </h4>
                  <h6>
                    Es genial verte de nuevo. ¡Descubre lo que hemos preparado
                    para ti!
                  </h6>
                  <p class="plan-text text-dark mt-4 mb-3">
                    🚀 Actualiza tu plan ahora y
                    <strong>¡Lleva tu experiencia al siguiente nivel!</strong>
                  </p>

                  <p class="d-none font-weight-semibold tx-12 mb-4">
                    ¿Tienes preguntas sobre la facturación? Estamos aquí para
                    ayudarte. Chatea con nuestro soporte o envíanos un correo a
                    <a href="mailto:hola@hware.cl">hola@hware.cl</a>.
                  </p>
                  <button
                    *ngIf="
                      !isBetaProduction && (userFacade.user$ | async).isVerfied
                    "
                    type="button"
                    class="btn btn-dark mb-3 shadow btn d-block mx-auto d-lg-inline"
                    [routerLink]="'/app/plans-prices'"
                  >
                    Actualiza tu plan
                    <i
                      class="ml-2"
                      nz-icon
                      nzType="rocket"
                      nzTheme="outline"
                    ></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <app-status-survey></app-status-survey>
      </div>
      <div class="col-12">
        <div class="card">
          <form nz-form [formGroup]="formChart">
            <nz-form-item class="mb-2">
              <nz-form-control
                class="ml-auto"
                [nzSpan]="12"
                nzErrorTip="Selecciona tipo pregunta"
              >
                <nz-select
                  [nzPlaceHolder]="'Elige el tipo de pregunta'"
                  (ngModelChange)="submitForm((selectedType = $event))"
                  formControlName="selectTypeQuestion"
                  [disabled]="
                    (dashboardFacade.isLoadingTendency$ | async) === true
                  "
                >
                  <nz-option
                    *ngFor="let option of questionFacade.questionTypes$ | async"
                    [nzValue]="option.questionTypeId"
                    [nzLabel]="option.questionType"
                  ></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </form>
          <nz-spin [nzSpinning]="dashboardFacade.isLoadingTendency$ | async">
            <div style="width: 100%; height: 350px">
              <highcharts-chart
                *ngIf="(dashboardFacade.isLoadingTendency$ | async) === false"
                [Highcharts]="Highcharts"
                [options]="dashboardFacade.tendencyWeekReport$ | async"
                style="width: 100%; height: 350px; display: block"
              ></highcharts-chart>
            </div>
          </nz-spin>
        </div>
      </div>
      <div class="col-12 mt-4">
        <div class="card">
          <div class="item-summary">
            <h5 class="font-weight-bold mb-1">{{ title }}</h5>
            <span class="text-muted">{{ subtitle }}</span>
            <nz-spin
              [nzSpinning]="
                (dashboardFacade.isLoadingWeekResponses$ | async) === true
              "
              class="spin-container"
            >
              <div class="container mt-4">
                <div [hidden]="true" class="d-block mb-3 float-right">
                  <nz-radio-group
                    [(ngModel)]="typeReport"
                    nzButtonStyle="solid"
                    (ngModelChange)="getWeekResponsesDataReport($event)"
                  >
                    <label nz-radio-button nzValue="surveys"
                      >Encuestas Completadas</label
                    >
                    <label nz-radio-button nzValue="questions"
                      >Preguntas Respondidas</label
                    >
                  </nz-radio-group>
                </div>
                <highcharts-chart
                  *ngIf="
                    (questionFacade.question$ | async) === null &&
                    (dashboardFacade.isLoadingWeekResponses$ | async) === false
                  "
                  [Highcharts]="Highcharts"
                  [options]="dashboardFacade.weekResponsesReport$ | async"
                  style="width: 100%; height: 405px; display: block"
                ></highcharts-chart>
              </div>
            </nz-spin>
          </div>
          <!-- <nz-spin
            [nzSpinning]="dashboardFacade.isLoadingWeekResponses$ | async"
          >
            <div style="width: 100%; height: 405px">
              <highcharts-chart
                *ngIf="
                  (dashboardFacade.isLoadingWeekResponses$ | async) === false
                "
                [Highcharts]="Highcharts"
                [options]="dashboardFacade.weekResponsesReport$ | async"
                style="width: 100%; height: 405px; display: block"
              ></highcharts-chart>
            </div>
          </nz-spin> -->
        </div>
      </div>
    </div>
  </div>
  <div class="col-xl-5 col-lg-12 col-md-12 col-sm-12">
    <div class="row mt-4 mt-xl-0">
      <div class="col-12 mb-3">
        <nz-spin [nzSpinning]="loadingBlog">
          <nz-carousel
            nzEffect="scrollx"
            [nzAutoPlay]="true"
            [nzAutoPlaySpeed]="5000"
            class="carousel-container"
          >
            <div
              class="carousel-item"
              nz-carousel-content
              *ngFor="let blogSlice of slicedBlogs"
            >
              <div class="row p-0">
                <div class="col-12 col-sm-6" *ngFor="let item of blogSlice">
                  <app-card-blog [blog]="item"></app-card-blog>
                </div>
              </div>
            </div>
          </nz-carousel>
        </nz-spin>
      </div>

      <div class="col-12">
        <app-nps-indicator-info></app-nps-indicator-info>
      </div>

      <div class="col-12 mt-4">
        <nz-spin
          [nzSpinning]="responsesFacade.isLoadingLastResponses$ | async"
          style="width: 100%; min-height: 350px"
          [class]="
            (responsesFacade.isLoadingLastResponses$ | async) === true
              ? 'card'
              : ''
          "
        >
          <div
            *ngIf="
              (responsesFacade.lastResponses$ | async) !== null &&
              (responsesFacade.lastResponses$ | async).length > 0
            "
          >
            <div
              *ngIf="showLastResponsesOption"
              class="icon-wrapper-up px-3"
              [ngClass]="{
                'animated-fade-in': showLastResponsesOption,
                'animated-fade-out': !showLastResponsesOption
              }"
              (click)="scrollToTop()"
            >
              <span
                class="mr-2"
                nz-icon
                nzType="arrow-up"
                nzTheme="outline"
              ></span>
              Nuevas respuestas
            </div>

            <div class="card fade-out">
              <h5 class="mb-4">Respuestas</h5>
              <div #scrollableDiv (scroll)="onScroll()" class="last-responses">
                <app-response-timeline></app-response-timeline>
              </div>
            </div>

            <div class="icon-wrapper-down px-3" (click)="loadData()">
              <span
                class="mr-2 animated-fade-in"
                nz-icon
                nzType="arrow-down"
                nzTheme="outline"
              ></span>
              ver más
            </div>
          </div>
          <div
            class="info-bg-empty card"
            *ngIf="
              ((responsesFacade.lastResponses$ | async) === null ||
                (responsesFacade.lastResponses$ | async).length === 0) &&
              (responsesFacade.isLoadingLastResponses$ | async) === false
            "
          >
            <div class="text-justified align-items-center">
              <h4 class="text-dark text-center">
                Al parecer aún no tienes respuestas
              </h4>
              <img
                class="icon-not-found mx-auto my-2"
                [src]="IMG_NOT_FOUND"
                alt=""
              />
              <span class="text-dark text-center">
                Para comenzar, crea tu primera pregunta, genera <br />
                interacciones y luego podrás ver las estadísticas aquí.
              </span>
            </div>
          </div>
        </nz-spin>
      </div>
    </div>
  </div>
</div>
