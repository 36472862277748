import { Component } from '@angular/core'

@Component({
  selector: 'app-config-survey-mf',
  templateUrl: './config-survey-mf.component.html',
  styleUrls: ['./config-survey-mf.component.scss'],
})
export class ConfigSurveyMfComponent {
  public domainSurveyClient: string

  constructor() {
    this.domainSurveyClient = localStorage.getItem('domain_survey_web')
  }

  onClick(): void {
    const mf = document.getElementById('survey-mf') as HTMLIFrameElement
    if (mf) mf.contentWindow.postMessage({ isReset: true, type: 'RESET' }, '*')
  }
}
