import { NgModule } from '@angular/core'
import { CustomDatePipe } from './custom-date.pipe'
import { FavoriteFilterPipe } from './favorite-filter.pipe'
import { NumberFormatPipe } from './number-format.pipe'
import { PlansFilterPipe } from './plan-filter.pipe'
import { SafePipe } from './safe-url.pipe'
import { ShortTextPipe } from './short-text.pipe'

@NgModule({
  declarations: [
    ShortTextPipe,
    SafePipe,
    NumberFormatPipe,
    CustomDatePipe,
    FavoriteFilterPipe,
    PlansFilterPipe,
  ],
  imports: [],
  providers: [],
  exports: [
    ShortTextPipe,
    SafePipe,
    NumberFormatPipe,
    CustomDatePipe,
    FavoriteFilterPipe,
    PlansFilterPipe,
  ],
})
export class PipesModule {}
