import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { QuestionFacade } from './../../store/question/question.facade'
import { SurveyFacade } from './../../store/survey/survey.facade'

export interface ScheduledReport {
  id: number
  name: string
  surveyName: string
  frequency: string
  nextRun: Date
}

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
})
export class ReportComponent implements OnInit {
  @ViewChild('dateRangePicker') dateRangePicker: ElementRef
  public SURVE_GRAPHIC_IMG = './assets/images/graphics/survey-character-01.png'

  reportForm: FormGroup
  programerReportForm: FormGroup
  isSubmitForm = false
  isViewReport = false
  ranges = this.getRangeDate()
  startDate: string
  endDate: string

  viewTabType: number
  isReportSettingsChanged: boolean = false
  isLoadingReport: boolean = false
  tabSelected: string

  isEditModalVisible: boolean = false
  editMode: boolean = false
  selectedReport: any
  scheduledReports: ScheduledReport[] = [
    {
      id: 1,
      surveyName: 'Encuesta de Satisfacción del Cliente',
      name: 'Reporte de Satisfacción del Cliente',
      frequency: 'Semanal',
      nextRun: new Date('2023-12-17T10:00:00'),
    },
    {
      id: 2,
      name: 'Análisis de Respuestas Abiertas',
      surveyName: 'Encuesta de Satisfacción del Cliente',
      frequency: 'Mensual',
      nextRun: new Date('2023-12-01T10:00:00'),
    },
    {
      id: 3,
      name: 'Métricas de Participación',
      surveyName: 'Encuesta de Satisfacción del Cliente',
      frequency: 'Diario',
      nextRun: new Date('2023-11-30T10:00:00'),
    },
    {
      id: 4,
      name: 'Reporte de NPS',
      surveyName: 'Encuesta de Satisfacción del Cliente',
      frequency: 'Trimestral',
      nextRun: new Date('2024-01-01T10:00:00'),
    },
    {
      id: 5,
      name: 'Resumen de Comentarios',
      surveyName: 'Encuesta de Satisfacción del Cliente',
      frequency: 'Anual',
      nextRun: new Date('2024-12-31T10:00:00'),
    },
  ]
  selectedIndex: number = 0

  constructor(
    private fb: FormBuilder,
    public surveyFacade$: SurveyFacade,
    public questionFacade$: QuestionFacade
  ) {
    this.surveyFacade$.resetSurveyFacade()
    this.tabSelected = 'generateReport'
  }

  ngOnInit(): void {
    this.reportForm = this.fb.group({
      surveyId: [null, Validators.required],
      questionId: ['all', Validators.nullValidator],
      dateRange: ['', Validators.required],
    })

    this.programerReportForm = this.fb.group({
      name: [''],
      frequency: [''],
      nextRun: [null],
    })

    this.surveyFacade$.getSurveyList()

    this.reportForm.get('surveyId').valueChanges.subscribe((surveyId) => {
      if (!this.isSubmitForm) {
        this.onSurveyChange(surveyId)
      }
    })
  }

  disabledDate = (current: Date): boolean => {
    const today = new Date()
    today.setHours(0, 0, 0, 0)

    const tomorrow = new Date(today)
    tomorrow.setDate(tomorrow.getDate() + 1)
    return current && current.getTime() >= tomorrow.getTime()
  }

  onOpenChange(open: boolean): void {
    if (!open) {
      const { value } = this.reportForm.controls.dateRange
      if (value && value[0] && value[1]) {
        if (value[0].getTime() > value[1].getTime()) {
          this.reportForm.controls.dateRange.reset()
        }
      }
    }
  }

  onSurveyChange(surveyId: string): void {
    if (surveyId) {
      this.questionFacade$.obtainQuestionListFetch(surveyId)
      this.reportForm.get('questionId').setValue('all')
    }
  }

  getReport(): void {
    for (const i in this.reportForm.controls) {
      this.reportForm.controls[i].markAsDirty()
      if (this.reportForm.controls[i].invalid) {
        this.reportForm.controls[i].updateValueAndValidity()
      }
    }

    if (this.reportForm.valid) {
      const dateRange = this.reportForm.get('dateRange').value

      const startDate = dateRange[0]
      const endDate = dateRange[1]

      this.startDate = startDate ? this.formatDate(startDate) : ''
      this.endDate = endDate ? this.formatDate(endDate) : ''

      this.isViewReport = true
      this.isSubmitForm = true

      // Aquí iría la lógica para generar el reporte
    } else {
      this.isSubmitForm = false
    }
  }

  private formatDate(date: Date): string {
    return `${date.getDate().toString().padStart(2, '0')}/${(
      date.getMonth() + 1
    )
      .toString()
      .padStart(2, '0')}/${date.getFullYear()}`
  }

  onTabSelectedChange(newTabSelected: string): void {
    if (newTabSelected === 'design') {
      this.viewTabType = 0
    }

    if (!this.isReportSettingsChanged) {
      this.tabSelected = newTabSelected
    }
  }

  showCreateReportModal(): void {
    this.editMode = false
    this.selectedReport = null
    this.programerReportForm.reset()
    this.isEditModalVisible = true
  }

  showEditReportModal(report: any): void {
    this.editMode = true
    this.selectedReport = report
    this.programerReportForm.patchValue(report)
    this.isEditModalVisible = true
  }

  handleCreateReport(): void {
    // Lógica para manejar la creación de un reporte programado
    this.isEditModalVisible = false
  }

  handleCancel(): void {
    this.isEditModalVisible = false
  }

  handleCancelCreateReport(): void {
    this.isEditModalVisible = false
  }

  editReport(report: any): void {
    // Lógica para manejar la edición de un reporte programado
    // Puedes reutilizar el modal de creación con algunos ajustes
  }

  confirmDeleteReport(report: any): void {
    // Lógica para manejar la eliminación de un reporte programado
    // Puede incluir una confirmación antes de la eliminación
  }

  handleEditReport(): void {
    if (this.programerReportForm.valid) {
      // Aquí colocas la lógica para procesar los datos del formulario
      // Por ejemplo, enviar los datos actualizados a tu backend
      console.log('Reporte actualizado:', this.programerReportForm.value)
    }
    this.isEditModalVisible = false
  }

  handleCancelEditReport(): void {
    this.isEditModalVisible = false
    // Opcional: Puedes resetear el formulario aquí si lo deseas
    this.programerReportForm.reset()
  }

  private getRangeDate() {
    const today = new Date()
    today.setHours(0, 0, 0, 0)

    const last7Days = new Date()
    last7Days.setDate(today.getDate() - 7)
    const sameDayLastMonth = new Date()
    sameDayLastMonth.setMonth(today.getMonth() - 1)
    return {
      Hoy: [today, today],
      'Últimos 7 Días': [last7Days, today],
      'Mismo Día del Mes Pasado': [sameDayLastMonth, today],
    }
  }
}
