import { IPlan } from 'src/app/models/response/IPlan'
import { Component, Input, OnInit } from '@angular/core'
import { Router } from '@angular/router'

@Component({
  selector: 'app-current-card-plan',
  templateUrl: './current-card-plan.component.html',
  styleUrls: ['./current-card-plan.component.scss'],
})
export class CurrentCardPlanComponent implements OnInit {
  @Input() public infoPlan: IPlan
  public routerPlan: string

  constructor(public router: Router) {
    const url = this.router.routerState.snapshot.url
    this.routerPlan = url.split('profile/general').join('plans-prices')
  }

  ngOnInit(): void {}
}
