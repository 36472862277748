import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { environment } from '../../../../environments/environment'
import { IResponse } from '../../../models/response/IResponse'
import {
  ERROR_SERVICE_MESSAGE,
  ERROR_SERVICE_TITLE,
} from '../../../utils/constants/generic-messages'
import {
  CLIENT_EXIST_REGISTER_MESSAGE,
  CLIENT_EXIST_REGISTER_TITLE,
  USER_EXIST_REGISTER_MESSAGE,
  USER_EXIST_REGISTER_TITLE,
} from '../../../utils/constants/session-messages'
import { IRegister } from './../../../models/request/IRegister'
import { SessionService } from './../../../services/session/session.service'

const ACCESS_TOKEN = environment.access_token
const ENV = environment.env

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['../session.scss'],
})
export class RegisterComponent implements OnInit {
  public loading: boolean
  public accountCreated: boolean
  public requestRegister: IRegister

  public registerForm!: FormGroup

  constructor(
    private _session: SessionService,
    public router: Router,
    private notification: NzNotificationService,
    private fb: FormBuilder
  ) {
    this.loading = false
    this.accountCreated = false

    const userStorage = localStorage.getItem(ACCESS_TOKEN)
    if (userStorage) {
      this.router.navigate([`/app/dashboard`])
    }
  }

  ngOnInit(): void {
    this.registerForm = this.fb.group({
      email: [null, [Validators.required, Validators.email]],
      fullName: [null, [Validators.required]],
      password: [
        null,
        [
          Validators.required,
          Validators.pattern('((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{6,18})'),
        ],
      ],
      repeatPassword: [null, [Validators.required, this.confirmationValidator]],
    })
  }

  public submitForm(): void {
    const email: string = this.registerForm.get('email')?.value

    for (const i in this.registerForm.controls) {
      this.registerForm.controls[i].markAsDirty()
      this.registerForm.controls[i].updateValueAndValidity()
    }

    if (this.registerForm.valid) {
      this.requestRegister = {
        name: this.registerForm.get('fullName')?.value,
        email,
        password: this.registerForm.get('password')?.value,
        password_confirmation: this.registerForm.get('repeatPassword')?.value,
        environment: ENV,
      }

      this.loading = true

      this._session.register(this.requestRegister).subscribe(
        (response: IResponse) => {
          switch (response.status.code) {
            case 200:
            case 201:
              this.accountCreated = true
              break
            default:
              this.notification.create(
                'error',
                ERROR_SERVICE_TITLE,
                ERROR_SERVICE_MESSAGE
              )
              break
          }

          this.loading = false
        },
        (error) => {
          switch (error.status) {
            case 400:
              this.notification.create(
                'info',
                USER_EXIST_REGISTER_TITLE,
                USER_EXIST_REGISTER_MESSAGE
              )
              break
            case 401:
              this.notification.create(
                'info',
                CLIENT_EXIST_REGISTER_TITLE,
                CLIENT_EXIST_REGISTER_MESSAGE
              )

              break
            default:
              this.notification.create(
                'error',
                ERROR_SERVICE_TITLE,
                ERROR_SERVICE_MESSAGE
              )
              break
          }
          this.loading = false
        }
      )
    }
  }

  private confirmationValidator = (
    control: FormControl
  ): { [s: string]: boolean } => {
    if (!control.value) {
      return { required: true }
    } else if (control.value !== this.registerForm.controls.password.value) {
      return { confirm: true, error: true }
    }
    return {}
  }
}
