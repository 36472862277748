import { Component } from '@angular/core'
import { environment } from 'src/environments/environment'
import {
  encryptKeyStorage,
  encryptMessage,
} from './../../utils/converts/encrypt'

const DEV_DOMAIN = 'http://localhost:3000'
const QA_DOMAIN = 'https://encuesta.empati.cl'

export const API_DOMAIN_LIST_DEFAULT = [
  {
    name: '🤖 PROD - https://apis.empati.cl/api',
    api: 'https://apis.empati.cl/api',
  },
  {
    name: '🧪 DEV  - http://api-empati.test:80/api',
    api: 'http://api-empati.test:80/api',
  },
  {
    name: '🧪 DEV  - http://empati.localhost.cl/api',
    api: 'http://empati.localhost.cl/api',
  },
]

export const SURVEY_DOMAIN_LIST_DEFAULT = [
  {
    name: `🤖 PROD - ${QA_DOMAIN}`,
    api: QA_DOMAIN,
  },
  {
    name: `🧪 DEV  - ${DEV_DOMAIN}`,
    api: DEV_DOMAIN,
  },
]

export const DOMAIN_LIST_DEFAULT = [
  {
    name: `🤖 QA - Valida sesión`,
    api: 'qa',
  },
  {
    name: `🧪 DEV - No valida sesión`,
    api: 'dev',
  },
]

@Component({
  selector: 'app-config-site-dev',
  templateUrl: './config-site-dev.component.html',
  styleUrls: ['./config-site-dev.component.scss'],
})
export class ConfigSiteDevComponent {
  public visible: boolean = false
  public redux_logger = false
  public domainSurveyClient: string
  public apiDomain: string
  public domain: string

  public domainList: any[]
  public apiDomainList: any[]
  public domainSurveyList: any[]

  constructor() {
    this.redux_logger = environment.redux_logger
    this.domainSurveyClient = environment.domain_survey_web
    this.apiDomain = environment.api
    this.domain = environment.env

    this.apiDomainList = this.getApiDomainList()
    this.domainSurveyList = SURVEY_DOMAIN_LIST_DEFAULT
    this.domainList = DOMAIN_LIST_DEFAULT
  }

  public getApiDomainList() {
    const encryptedKey = encryptKeyStorage('api_domain_list')
    const newApiDomainList = JSON.parse(localStorage.getItem(encryptedKey))
    return newApiDomainList || API_DOMAIN_LIST_DEFAULT
  }

  public addNewDomain(e: any): void {
    if (e.value.trim().length < 0) return
    const newDomain = {
      name: `🔖 DEV  - ${e.value.trim()}`,
      api: e.value.trim(),
    }
    const newDomainList = [...this.apiDomainList, newDomain]
    this.apiDomainList = newDomainList
    const encryptedKey = encryptKeyStorage('api_domain_list')
    localStorage.setItem(encryptedKey, JSON.stringify(newDomainList))
  }

  public changeReduxLogger(): void {
    const encryptedKey = encryptKeyStorage('redux_logger')
    const encryptedDomain = encryptMessage(this.redux_logger.toString())
    localStorage.setItem(encryptedKey, encryptedDomain)

    console.info(
      `%c🫣 ReduxLogger ha sido ${
        this.redux_logger ? 'Activado 🟢' : 'Desactivado 🟠'
      } `,
      `display: inline-block; border: 3px solid ${
        this.redux_logger ? 'green' : 'red'
      }; border-radius: 7px;
        padding: 10px; margin: 20px;`
    )
  }

  public changeDomain(): void {
    const encryptedKey = encryptKeyStorage('domain')
    const encryptedDomain = encryptMessage(this.domain)
    localStorage.setItem(encryptedKey, encryptedDomain)

    console.info(
      `%c🫣 El Dominio ha sido actualizado a ${this.domain} `,
      `display: inline-block; border: 3px solid blue; border-radius: 7px;
        padding: 10px; margin: 20px;`
    )
  }

  public changeAPIDomain(): void {
    const encryptedKey = encryptKeyStorage('api_domain')
    const encryptedDomain = encryptMessage(this.apiDomain)
    localStorage.setItem(encryptedKey, encryptedDomain)

    console.info(
      `%c🫣 ApiDomain ha sido actualizado a ${this.apiDomain} `,
      `display: inline-block; border: 3px solid blue; border-radius: 7px;
        padding: 10px; margin: 20px;`
    )
  }

  public changeDomainSurvey(): void {
    const encryptedKey = encryptKeyStorage('domain_survey_web')
    const encryptedDomain = encryptMessage(this.domainSurveyClient)
    localStorage.setItem(encryptedKey, encryptedDomain)

    console.info(
      `%c🫣 DomainSurveyClient ha sido actualizado a ${this.domainSurveyClient} `,
      `display: inline-block; border: 3px solid blue; border-radius: 7px;
        padding: 10px; margin: 20px;`
    )
  }

  public saveConfig(): void {
    window.location.reload()
  }

  public open(): void {
    this.visible = true
  }

  public close(): void {
    this.visible = false
  }
}
