import { State, Action, StateContext, Selector } from '@ngxs/store'
import { Injectable } from '@angular/core'
import {
  UpdateIsLoadingQuestionResponses,
  UpdateIsLoadingTrendTotalResponses,
  UpdateQuestionResponsesDataReport,
  UpdateSurveyResponsesDataReport,
  UpdateTrendTotalResponsesDataReport,
} from './report.actions'

export interface ILoadingReport {
  isLoadingQuestionResponses: boolean
  isLoadingTrendTotalResponses: boolean
}

export interface IDataReport {
  labels: string[]
  data: number[]
}

export interface ReportStateModel {
  questionResponsesDataReport: number[]
  surveyResponsesDataReport: any
  trendTotalResponsesDataReport: any
  loading: ILoadingReport
}

@State<ReportStateModel>({
  name: 'report',
  defaults: {
    questionResponsesDataReport: null,
    surveyResponsesDataReport: null,
    trendTotalResponsesDataReport: null,
    loading: {
      isLoadingQuestionResponses: true,
      isLoadingTrendTotalResponses: true,
    },
  },
})
@Injectable()
export class ReportState {
  @Selector()
  public static getQuestionResponsesDataReport(
    state: ReportStateModel
  ): number[] {
    return state.questionResponsesDataReport
  }

  @Selector()
  public static getSurveyResponsesDataReport(
    state: ReportStateModel
  ): number[] {
    return state.surveyResponsesDataReport
  }

  @Selector()
  public static getTrendTotalResponsesDataReport(
    state: ReportStateModel
  ): IDataReport {
    return state.trendTotalResponsesDataReport
  }

  @Selector()
  public static getLoading(state: ReportStateModel): ILoadingReport {
    return state.loading
  }

  @Selector()
  public static getIsLoadingQuestionResponses(
    state: ReportStateModel
  ): boolean {
    return state.loading.isLoadingQuestionResponses
  }

  @Selector()
  public static getIsLoadingTrendTotalResponses(
    state: ReportStateModel
  ): boolean {
    return state.loading.isLoadingTrendTotalResponses
  }

  @Action(UpdateQuestionResponsesDataReport)
  updateQuestionResponsesDataReport(
    { getState, setState }: StateContext<ReportStateModel>,
    { payload }: UpdateQuestionResponsesDataReport
  ): void {
    const state = getState()
    setState({
      ...state,
      questionResponsesDataReport: payload,
    })
  }

  @Action(UpdateSurveyResponsesDataReport)
  updateSurveyResponsesDataReport(
    { getState, setState }: StateContext<ReportStateModel>,
    { payload }: UpdateSurveyResponsesDataReport
  ): void {
    const state = getState()
    setState({
      ...state,
      surveyResponsesDataReport: payload,
    })
  }

  @Action(UpdateTrendTotalResponsesDataReport)
  updateTrendTotalResponsesDataReport(
    { getState, setState }: StateContext<ReportStateModel>,
    { payload }: UpdateTrendTotalResponsesDataReport
  ): void {
    const state = getState()
    setState({
      ...state,
      trendTotalResponsesDataReport: payload,
    })
  }

  @Action(UpdateIsLoadingQuestionResponses)
  updateIsLoadingQuestionResponses(
    { getState, setState }: StateContext<ReportStateModel>,
    { payload }: UpdateIsLoadingQuestionResponses
  ): void {
    const state = getState()

    setState({
      ...state,
      loading: { ...state.loading, isLoadingQuestionResponses: payload },
    })
  }

  @Action(UpdateIsLoadingTrendTotalResponses)
  updateIsLoadingTrendTotalResponses(
    { getState, setState }: StateContext<ReportStateModel>,
    { payload }: UpdateIsLoadingTrendTotalResponses
  ): void {
    const state = getState()

    setState({
      ...state,
      loading: { ...state.loading, isLoadingTrendTotalResponses: payload },
    })
  }
}
