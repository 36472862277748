<form nz-form [formGroup]="form" (ngSubmit)="submitForm()">
  <div class="row">
    <div class="col-12 col-md-6">
      <app-input-field
        class="w-100 mb-3"
        [groupName]="form"
        controlName="name"
        label="Nombre *"
        errorMessage="Ingresa nombre"
        icon="user"
        [maxLength]="50"
      ></app-input-field>
    </div>

    <div class="col-12 col-md-6">
      <app-input-field
        class="w-100 mb-3"
        [groupName]="form"
        controlName="email"
        label="Email *"
        errorMessage="Ingresa email"
        icon="mail"
        [disabled]="true"
        [maxLength]="100"
      ></app-input-field>
    </div>

    <!-- <div class="col-12 col-md-6">
      <app-input-field
        class="w-100 mb-3"
        [groupName]="form"
        controlName="ocupation"
        label="Ocupación"
        [maxLength]="25"
        errorMessage="Ingresa ocupación"
        icon="ocupation"
      ></app-input-field>
    </div> -->

    <div class="col-12 col-md-6">
      <app-input-field
        class="w-100 mb-3"
        [groupName]="form"
        controlName="phone"
        label="Teléfono"
        [maxLength]="15"
        errorMessage="Ingresa teléfono"
        icon="phone"
        (keypress)="keypressOnlyNumber($event)"
      ></app-input-field>
    </div>
  </div>

  <nz-form-item class="button-create">
    <nz-form-control>
      <button
        [disabled]="loading || !form.valid"
        type="submit"
        class="btn btn-primary d-block ml-auto mt-3"
      >
        <i *ngIf="loading" nz-icon [nzType]="'loading'"></i>
        <span *ngIf="loading" class="ml-2">Guardando</span>
        <span *ngIf="!loading" class="d-flex align-items-center"
          >Actualizar
        </span>
      </button>
    </nz-form-control>
  </nz-form-item>
</form>
