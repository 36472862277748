import { Component, Input, OnInit } from '@angular/core'
import { PaymentFacade } from 'src/app/store/payment/payment.facade'
import { PlanFacade } from './../../../../store/plan/plan.facade'

@Component({
  selector: 'app-summary-payment-card',
  templateUrl: './summary-payment-card.component.html',
  styleUrls: ['./summary-payment-card.component.scss'],
})
export class SummaryPaymentCardComponent implements OnInit {
  @Input() planId: string
  @Input() typeSuscription: string = 'monthly'

  constructor(
    public paymentFacade: PaymentFacade,
    public planFacade: PlanFacade
  ) {}

  ngOnInit(): void {
    this.planFacade.obtainPlanDetailFetch(this.planId, this.typeSuscription)
    this.planFacade.selectedPlan$.subscribe()
  }
}
