export const defaultStepOptions = {
  cancelIcon: {
    enabled: true,
  },
  scrollTo: {
    behavior: 'smooth' as ScrollBehavior,
    block: 'center' as ScrollLogicalPosition,
  },
  showCancelLink: true,
  onCancel() {
    enableElements('#info-plan')
    enableElements('#survey-list')
  },
  onComplete() {
    enableElements('#info-plan')
    enableElements('#survey-list')
  },
}

export const defaultSteps = [
  {
    attachTo: {
      element: '#survey-welcome',
      on: 'bottom' as PopperPlacement,
    },
    buttons: [
      {
        action() {
          disableElements('#survey-list')
          return this.next()
        },
        text: 'Siguiente',
      },
    ],
    id: 'intro',
    title: 'Bienvenido al módulo de Encuestas',
    text: [
      'Este espacio está diseñado para que crees encuestas intuitivas y recojas datos valiosos de manera eficiente. Comienza una nueva encuesta con un clic o utiliza nuestras herramientas de IA para obtener recomendaciones personalizadas. Cada función está pensada para maximizar la participación del usuario y simplificar tu análisis de datos.',
    ],
  },
  {
    attachTo: {
      element: '#survey-list',
      on: 'bottom' as PopperPlacement,
    },
    buttons: [
      {
        action() {
          return this.back()
        },
        classes: 'shepherd-button-secondary',
        text: 'Atrás',
      },
      {
        action() {
          return this.next()
        },
        text: 'Siguiente',
      },
    ],
    id: 'intro',
    title: 'Controla Tus Encuestas',
    text: [
      `Aquí tienes una visión completa de tus encuestas, donde puedes organizar y revisar tus proyectos actuales.<br><br>
      Para expandir tu alcance, las opciones <strong>Crear encuesta</strong> y <strong>Crear con IA</strong> están listas para facilitarte el diseño de nuevas experiencias.`,
    ],
  },
  {
    attachTo: {
      element: '#create-survey',
      on: 'bottom' as PopperPlacement,
    },
    buttons: [
      {
        action() {
          return this.back()
        },
        classes: 'shepherd-button-secondary',
        text: 'Atrás',
      },
      {
        action() {
          return this.next()
        },
        text: 'Siguiente',
      },
    ],
    id: 'intro',
    title: 'Crear Encuestas',
    text: [
      `Elige entre dos opciones para empezar tu proyecto:<br><br>
      <strong>Crear Encuesta</strong>: Te ofrece un lienzo en blanco para diseñar tus cuestionarios desde cero, permitiéndote personalizarlos con total libertad y creatividad.<br><br>
      <strong>Crear con IA</strong>: Te ayuda a generar encuestas automáticamente utilizando inteligencia artificial, optimizando tiempo y esfuerzo mientras mantienes la calidad en tus cuestionarios.`,
    ],
  },
  {
    attachTo: {
      element: '#create-survey-ia',
      on: 'bottom' as PopperPlacement,
    },
    buttons: [
      {
        action() {
          return this.back()
        },
        classes: 'shepherd-button-secondary',
        text: 'Atrás',
      },
      {
        action() {
          return this.next()
        },
        text: 'Siguiente',
      },
    ],
    id: 'intro',
    title: 'Crear con IA',
    text: [
      "Selecciona <strong>'Crear con IA'</strong> para aprovechar nuestra <strong>tecnología inteligente</strong> que te orienta en la elaboración de encuestas, asegurando <strong>preguntas relevantes</strong> y <strong>formatos atractivos</strong> para una mayor tasa de respuesta.",
    ],
  },
  {
    attachTo: {
      element: '#survey-list-info',
      on: 'bottom' as PopperPlacement,
    },
    buttons: [
      {
        action() {
          return this.back()
        },
        classes: 'shepherd-button-secondary',
        text: 'Atrás',
      },
      {
        action() {
          disableElements('#info-plan')
          return this.next()
        },
        text: 'Siguiente',
      },
    ],
    classes: '',
    id: 'intro',
    title: 'Sobre tus Encuestas',
    text: [
      'Esta es la sección de <strong>resumen de tus encuestas</strong>, donde cada tarjeta refleja el <strong>pulso de las interacciones</strong> de tus usuarios. A simple vista, puedes evaluar la <strong>satisfacción general</strong> y el <strong>nivel de participación</strong>. Los <strong>indicadores clave</strong> como el porcentaje de satisfacción y el número de respuestas te brindan una <strong>instantánea del impacto</strong> de cada encuesta.',
    ],
  },
  {
    attachTo: {
      element: '#info-plan',
      on: 'bottom' as PopperPlacement,
    },
    buttons: [
      {
        action() {
          return this.back()
        },
        classes: 'shepherd-button-secondary',
        text: 'Atrás',
      },
      {
        action() {
          enableElements('#info-plan')
          enableElements('#survey-list')
          return this.next()
        },
        text: 'Finalizar',
      },
    ],
    id: 'intro',
    title: 'Tu Plan Actual',
    text: [
      'Tu plan te permite gestionar hasta <strong>encuestas activas</strong> con un <strong>límite de respuestas</strong>. Estas se renuevan de manera mensual, y si superas tus límites, puedes <strong>ampliar tu plan</strong>.',
    ],
  },
]

type PopperPlacement =
  | 'auto'
  | 'auto-start'
  | 'auto-end'
  | 'top'
  | 'bottom'
  | 'right'
  | 'left'
  | 'top-start'
  | 'top-end'
  | 'bottom-start'
  | 'bottom-end'
  | 'right-start'
  | 'right-end'
  | 'left-start'
  | 'left-end'

const disableElements = (id: string) => {
  Array.from(document.querySelectorAll(id)).forEach((element) => {
    ;(element as HTMLElement).style.pointerEvents = 'none'
  })
}

export const enableElements = (id: string) => {
  Array.from(document.querySelectorAll(id)).forEach((element) => {
    ;(element as HTMLElement).style.pointerEvents = 'auto'
  })
}
