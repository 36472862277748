import { Injectable } from '@angular/core'
import { SafeResourceUrl } from '@angular/platform-browser'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { ISurvey } from 'src/app/models/response/ISurvey'
import { IAboutSurvey } from './../../models/response/IAboutSurvey'
import { ICategoryResponse } from './../../models/response/ICategoryResponse'
import {
  ISurveyListInfo,
  initialValuesSuryveListInfo,
} from './../../models/response/ISurveyListInfo'
import {
  UpdateAboutSurvey,
  UpdateErrorSurveyService,
  UpdateImageSurveyQR,
  UpdateIsDisabledIconsShared,
  UpdateIsLoadingQrSurvey,
  UpdateLoadingSectionSurvey,
  UpdateLoadingSurvey,
  UpdateLoadingSurveyList,
  UpdateSurvey,
  UpdateSurveyCategories,
  UpdateSurveyList,
  UpdateSurveyListInfo,
} from './survey.actions'

export interface ISurveyModel {
  survey: ISurvey
  imageSurveyQR: string
  surveyList: ISurvey[]
  surveyCategories: ICategoryResponse[]
  surveyListInfo: ISurveyListInfo
  aboutSurvey: IAboutSurvey[]
  surveyListArchived: ISurvey[]
  isDisabledIconSharedSurvey: boolean
  loading: ILoadingSurvey
  errorService: IErrorSurveyService
}

export interface ILoadingSurvey {
  isLoadingSurvey?: boolean
  isLoadingSurveyList?: boolean
  isLoadingSurveyCategories?: boolean
  isLoadingQrSurvey?: boolean
  isLoadingAboutSurvey?: boolean
}

export interface IErrorSurveyService {
  errorSurveyList?: boolean
  errorSurveyCategories?: boolean
  errorSurveyDetail?: boolean
  errorSurveyQr?: boolean
  errorAboutSurvey?: boolean
}

@State<ISurveyModel>({
  name: 'survey',
  defaults: {
    survey: null,
    imageSurveyQR: null,
    surveyList: [],
    surveyCategories: [],
    surveyListInfo: initialValuesSuryveListInfo,
    aboutSurvey: null,
    surveyListArchived: [],
    isDisabledIconSharedSurvey: true,
    loading: {
      isLoadingSurvey: true,
      isLoadingSurveyList: true,
      isLoadingSurveyCategories: true,
      isLoadingQrSurvey: false,
      isLoadingAboutSurvey: true,
    },
    errorService: {
      errorSurveyList: false,
      errorSurveyCategories: false,
      errorSurveyDetail: false,
      errorSurveyQr: false,
      errorAboutSurvey: false,
    },
  },
})
@Injectable()
export class SurveyState {
  @Selector()
  public static getState(state: ISurveyModel): ISurveyModel {
    return state
  }

  @Selector()
  public static getSurvey(state: ISurveyModel): ISurvey {
    return state.survey
  }

  @Selector()
  public static getSurveyCategories(state: ISurveyModel): ICategoryResponse[] {
    return state.surveyCategories
  }

  @Selector()
  public static getSurveyList(state: ISurveyModel): ISurvey[] {
    return state.surveyList
  }

  @Selector()
  public static getAboutSurvey(state: ISurveyModel): IAboutSurvey[] {
    return state.aboutSurvey
  }

  @Selector()
  public static getSurveyListInfo(state: ISurveyModel): ISurveyListInfo {
    return state.surveyListInfo
  }

  @Selector()
  public static getSurveyListArchived(state: ISurveyModel): ISurvey[] {
    return state.surveyListArchived
  }

  @Selector()
  public static getLoadingSectionSurvey(state: ISurveyModel): ILoadingSurvey {
    return state.loading
  }

  @Selector()
  public static getLoadingSurvey(state: ISurveyModel): boolean {
    return state.loading.isLoadingSurvey
  }

  @Selector()
  public static getLoadingSurveyList(state: ISurveyModel): boolean {
    return state.loading.isLoadingSurveyList
  }

  @Selector()
  public static getisLoadingQrSurvey(state: ISurveyModel): boolean {
    return state.loading.isLoadingQrSurvey
  }

  @Selector()
  public static getIsLoadingAboutSurvey(state: ISurveyModel): boolean {
    return state.loading.isLoadingAboutSurvey
  }

  @Selector()
  public static getIsDisabledIconSharedSurvey(state: ISurveyModel): boolean {
    return state.isDisabledIconSharedSurvey
  }

  @Selector()
  public static getImageSurveyQR(state: ISurveyModel): SafeResourceUrl {
    return state.imageSurveyQR
  }

  @Selector()
  public static getErrorSurveyService(
    state: ISurveyModel
  ): IErrorSurveyService {
    return state.errorService
  }

  @Action(UpdateSurvey)
  updateSurvey(
    { getState, setState }: StateContext<ISurveyModel>,
    { payload }: UpdateSurvey
  ): void {
    const state = getState()

    if (state.surveyList?.length < 1 && !payload) {
      setState({
        ...state,
        survey: null,
      })
      return
    }

    const surveyList = state.surveyList
      ? state.surveyList.map((survey: ISurvey) => {
          if (survey.surveyId === payload.surveyId) {
            survey = payload
          }
          return survey
        })
      : []
    setState({
      ...state,
      survey: payload,
      surveyList,
      loading: { ...state.loading, isLoadingSurvey: false },
    })
  }

  @Action(UpdateLoadingSurvey)
  updateLoadingSurvey(
    { getState, setState }: StateContext<ISurveyModel>,
    { payload }: UpdateLoadingSurvey
  ): void {
    const state = getState()
    setState({
      ...state,
      loading: { ...state.loading, isLoadingSurvey: payload },
    })
  }

  @Action(UpdateLoadingSurveyList)
  updateLoadingSurveyList(
    { getState, setState }: StateContext<ISurveyModel>,
    { payload }: UpdateLoadingSurveyList
  ): void {
    const state = getState()
    setState({
      ...state,
      loading: { ...state.loading, isLoadingSurveyList: payload },
    })
  }

  @Action(UpdateIsLoadingQrSurvey)
  updateIsLoadingQrSurvey(
    { getState, setState }: StateContext<ISurveyModel>,
    { payload }: UpdateIsLoadingQrSurvey
  ): void {
    const state = getState()
    setState({
      ...state,
      loading: { ...state.loading, isLoadingQrSurvey: payload },
    })
  }

  @Action(UpdateSurveyList)
  updateSurveyList(
    { getState, setState }: StateContext<ISurveyModel>,
    { payload, isArchived = 0 }: UpdateSurveyList
  ): void {
    const state = getState()
    if (isArchived === 0) {
      setState({
        ...state,
        surveyList: payload,
      })
    } else {
      setState({
        ...state,
        surveyList: payload,
        loading: { ...state.loading, isLoadingSurveyList: false },
      })
    }
  }

  @Action(UpdateSurveyCategories)
  updateSurveyCategories(
    { getState, setState }: StateContext<ISurveyModel>,
    { payload }: UpdateSurveyCategories
  ): void {
    const state = getState()
    setState({
      ...state,
      surveyCategories: payload,
    })
  }

  @Action(UpdateSurveyListInfo)
  updateSurveyListInfo(
    { getState, setState }: StateContext<ISurveyModel>,
    { payload }: UpdateSurveyListInfo
  ): void {
    const state = getState()

    setState({
      ...state,
      surveyListInfo: payload,
    })
  }

  @Action(UpdateAboutSurvey)
  updateAboutSurvey(
    { getState, setState }: StateContext<ISurveyModel>,
    { payload }: UpdateAboutSurvey
  ): void {
    const state = getState()

    setState({
      ...state,
      aboutSurvey: payload,
    })
  }

  @Action(UpdateIsDisabledIconsShared)
  updateIsDisabledIconsShared(
    { getState, setState }: StateContext<ISurveyModel>,
    { payload }: UpdateIsDisabledIconsShared
  ): void {
    const state = getState()
    setState({
      ...state,
      isDisabledIconSharedSurvey: payload,
    })
  }

  @Action(UpdateImageSurveyQR)
  updateImageSurveyQR(
    { getState, setState }: StateContext<ISurveyModel>,
    { payload }: UpdateImageSurveyQR
  ): void {
    const state = getState()
    setState({
      ...state,
      imageSurveyQR: payload,
    })
  }

  @Action(UpdateLoadingSectionSurvey)
  updateLoadingSectionSurvey(
    { getState, setState }: StateContext<ISurveyModel>,
    { payload }: UpdateLoadingSectionSurvey
  ): void {
    const state = getState()
    setState({
      ...state,
      loading: { ...state.loading, ...payload },
    })
  }

  @Action(UpdateErrorSurveyService)
  updateErrorSurveyService(
    { getState, setState }: StateContext<ISurveyModel>,
    { payload }: UpdateErrorSurveyService
  ): void {
    const state = getState()
    setState({
      ...state,
      errorService: { ...state.errorService, ...payload },
    })
  }
}
