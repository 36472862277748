import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-nps-response-item',
  templateUrl: './nps-response-item.component.html',
  styleUrls: ['./nps-response-item.component.scss'],
})
export class NpsResponseItemComponent {
  @Input() npsScore: number | null = null
  public tootltipScore: string

  getNpsClass(): string {
    this.tootltipScore = String(this.npsScore)
    if (this.npsScore <= 6) {
      return 'detractor'
    } else if (this.npsScore <= 8) {
      return 'passive'
    } else if (this.npsScore <= 10) {
      return 'promoter'
    } else {
      return ''
    }
  }
}
