import { Component, Input, OnInit } from '@angular/core'
import { PaymentFacade } from 'src/app/store/payment/payment.facade'
import { IProcessInfoPayment } from './../../../../models/response/IProcessInfoPayment'
import { pdfToB64 } from './../../../../utils/converts/file'

@Component({
  selector: 'app-payment-success-billing',
  templateUrl: './payment-success-billing.component.html',
  styleUrls: ['./payment-success-billing.component.scss'],
})
export class PaymentSuccessBillingComponent implements OnInit {
  @Input() infoPayment: IProcessInfoPayment
  public isLoadingDownloadButton = true

  constructor(private paymentFacade: PaymentFacade) {}

  async ngOnInit() {
    setTimeout(async () => {
      const docName = `Comprobante_ORD${this.infoPayment.paymentId}_empati`
      const idHTMLElement = 'invoice'
      const pdfB64 = await pdfToB64(idHTMLElement, docName)
      this.updateDocument(pdfB64)
    }, 100)
  }

  private updateDocument(document: string): void {
    this.paymentFacade
      .updateDocumentFetch({
        paymentId: this.infoPayment.paymentId,
        document,
        documentTypeId: 1,
      })
      .then((response) => {
        this.isLoadingDownloadButton = false
      })
      .catch((error) => {
        this.isLoadingDownloadButton = false
      })
  }

  public downloadInvoice(): void {
    this.paymentFacade.getDocumentFetch(this.infoPayment.paymentId, 1)
  }
}
