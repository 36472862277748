import { Pipe, PipeTransform } from '@angular/core'
import { ISurvey } from '../models/response/ISurvey'

@Pipe({
  name: 'favoriteFilter',
})
export class FavoriteFilterPipe implements PipeTransform {
  transform(surveys: ISurvey[], showFavorites: boolean): any[] {
    if (!surveys) return []
    if (showFavorites) {
      return surveys.filter(
        (survey) => survey?.isFavorite && +survey?.isArchived !== 1
      )
    }
    return surveys
  }
}
