import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs-compat'
import { map } from 'rxjs/operators'
import { IResponse } from 'src/app/models/response/IResponse'

@Injectable({
  providedIn: 'root',
})
export class BlogService {
  private api: string
  constructor(public http: HttpClient) {
    this.api = 'https://cms.hware.cl/api'
  }

  public getBlogs(): Observable<IResponse> {
    let params = new HttpParams()
      .set('filters[categoria][$eq]', 'Experiencia del Cliente')
      .set('sort', 'title:asc')

    const uri = `https://cms.hware.cl/api/blogs`
    return this.http
      .get(uri, {
        params,
      })
      .pipe(map((data: IResponse) => data))
  }
}
