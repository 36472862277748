<a (click)="visible = true" class="float-config">
  <div class="icon-opt">
    <span nz-icon nzType="tool" nzTheme="outline" class="float-icon"></span>
  </div>
</a>

<div class="config">
  <nz-drawer
    [nzVisible]="visible"
    class="m-0 p-0"
    nzTitle="Configuraciones Ambiente Desarrollo"
    (nzOnClose)="close()"
  >
    <form class="conf-form" nz-form *nzDrawerContent>
      <div class="m-0 container-form">
        <div class="mt-5">
          <label class="d-block">💜 Ambiente:</label>
          <nz-select
            [(ngModel)]="domain"
            (ngModelChange)="changeDomain()"
            nzShowSearch
            nzAllowClear
            nzPlaceHolder="Selecciona dominio"
            [ngModelOptions]="{ standalone: true }"
          >
            <nz-option
              *ngFor="let item of domainList"
              [nzLabel]="item.name"
              [nzValue]="item.api"
            ></nz-option>
          </nz-select>
        </div>
        <div class="mt-5">
          <label class="d-block">💜 Dominio API Empati:</label>
          <nz-select
            [(ngModel)]="apiDomain"
            (ngModelChange)="changeAPIDomain()"
            nzShowSearch
            nzAllowClear
            [nzDropdownRender]="renderTemplate"
            nzPlaceHolder="Selecciona dominio"
            [ngModelOptions]="{ standalone: true }"
          >
            <nz-option
              *ngFor="let item of apiDomainList"
              [nzLabel]="item.name"
              [nzValue]="item.api"
            ></nz-option>
          </nz-select>
          <ng-template #renderTemplate>
            <hr />
            <div class="container">
              <input type="text" nz-input #inputElement />
              <a class="add-item" (click)="addNewDomain(inputElement)">
                <span nz-icon nzType="plus"></span>
                Agregar dominio
              </a>
            </div>
          </ng-template>
        </div>
        <div class="mt-5">
          <label class="d-block">💜 Dominio Encuesta web cliente:</label>
          <nz-select
            [(ngModel)]="domainSurveyClient"
            (ngModelChange)="changeDomainSurvey()"
            nzShowSearch
            nzAllowClear
            nzPlaceHolder="Selecciona dominio"
            [ngModelOptions]="{ standalone: true }"
          >
            <nz-option
              *ngFor="let item of domainSurveyList"
              [nzLabel]="item.name"
              [nzValue]="item.api"
            ></nz-option>
          </nz-select>
        </div>
        <div class="mt-5">
          <label class="d-block">💜 Redux Logger:</label>
          <nz-radio-group
            [(ngModel)]="redux_logger"
            (ngModelChange)="changeReduxLogger()"
            name="redux_logger"
          >
            <span nz-radio [nzValue]="true" class="d-block">Activar Log</span>
            <span nz-radio [nzValue]="false" class="d-block">Desactivar</span>
          </nz-radio-group>
        </div>

        <button
          (click)="saveConfig()"
          type="button"
          class="btn btn-primary d-block mx-auto mt-5 mx-auto text-center"
        >
          <span>Aplicar cambios</span>
        </button>
      </div>
    </form>

    hola
    <app-feature-flags></app-feature-flags>
  </nz-drawer>
</div>
