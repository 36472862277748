import { SurveyDetailComponent } from './survey-detail/survey-detail.component'
import { SurveyComponent } from './survey.component'
import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'

const routes: Routes = [
  {
    path: '',
    component: SurveyComponent,
  },
  {
    path: ':surveyId',
    component: SurveyDetailComponent,
    data: { title: 'Detalle Encuesta' },
  },
  {
    path: '',
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    path: '*',
    redirectTo: '',
    pathMatch: 'full',
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SurveyRoutingModule {}
