import { Component, OnInit } from '@angular/core'
import { Router, ActivationEnd, Data } from '@angular/router'
import { filter, map } from 'rxjs/operators'
import { Title, Meta, MetaDefinition } from '@angular/platform-browser'
import { Observable } from 'rxjs'

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styles: [],
})
export class BreadcrumbsComponent implements OnInit {
  public titulo: string

  constructor(
    private router: Router,
    private title: Title,
    private meta: Meta
  ) {
    this.getDataRoute().subscribe((data) => {
      this.titulo = data.titulo
      this.title.setTitle(this.titulo)

      const metaTag: MetaDefinition = {
        name: 'description',
        content: this.titulo,
      }

      this.meta.updateTag(metaTag)
    })
  }

  ngOnInit(): void {}

  // Obtener la información que corresponde a las rutas establecidas
  getDataRoute(): Observable<Data> {
    return this.router.events.pipe(
      filter((event: ActivationEnd) => event instanceof ActivationEnd),
      filter((event: ActivationEnd) => event.snapshot.firstChild === null),
      map((event: ActivationEnd) => event.snapshot.data)
    )
  }
}
