<div class="card">
  <div class="card-body">
    <div class="row">
      <div class="col-xl-3 col-lg-3">
        <div class="review-bx d-flex align-items-center">
          <div class="review-img">
            <img
              src="https://kamr.dexignlab.com/react/demo/static/media/pic-1.359a8879d92de07ef27c.jpg"
              alt=""
            />
          </div>
          <div class="review-content">
            <h2 class="font-w500">Zain Torff</h2>
            <p class="font-w400 m-0">#EMP-00025</p>
          </div>
        </div>
        <p class="review-date font-w400">
          Tuesday, January 24th 2021 at 2:56 AM
        </p>
      </div>
      <div class="col-xl-9 col-lg-9">
        <div class="description-bx d-inline-block d-sm-flex align-items-center">
          <div class="description-content">
            <h3 class="font-w500">I love that room service</h3>
            <p class="sub-title font-w400 lh-2">
              Agradecer el Trabajo y profesionalismo de la empresa Humanware,
              junto a ellos logramos los objetivos planteados en la solución de
              los problemas de servicios
            </p>
            <div
              class="d-inline-block d-xl-flex align-items-center justify-content-between"
            >
              <div class="badge-bx">
                <span class="badge light badge-success">Felicitaciones</span>
              </div>
              <div class="button-bx mt-2 mt-xl-0">
                <button class="btn btn-success">Responder</button
                ><button class="btn btn-primary ml-2">Cerrar</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
