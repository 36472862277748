import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { IResponse } from 'src/app/models/response/IResponse'
import { environment } from 'src/environments/environment'
import { ISummary } from './../../models/request/ISummary'
import { ITrendingSummary } from './../../models/request/ITrendingSummary'

@Injectable({ providedIn: 'root' })
export class ReportService {
  protected api: string

  constructor(public http: HttpClient) {
    this.api = environment.api
  }

  public getQuestionResponsesDataReport(
    questionId: string,
    startDate?: string,
    endDate?: string
  ): Observable<IResponse> {
    const uri = `${this.api}/report/question/${questionId}/responses/${startDate}/${endDate}`
    return this.http.get(uri).pipe(map((data: IResponse) => data))
  }

  public getSurveyResponsesDataReport(
    surveyId: string,
    startDate?: string,
    endDate?: string
  ): Observable<IResponse> {
    const uri = `${this.api}/report/survey/${surveyId}/responses`
    return this.http.get(uri).pipe(map((data: IResponse) => data))
  }

  public getTrendTotalResponsesDataReport(
    surveyId: string,
    questionType: number,
    groupBy: string = 'day',
    questionId: string = null
  ): Observable<IResponse> {
    const uri = `${
      this.api
    }/report/survey/${surveyId}/trending-interactions/${questionType}/${groupBy}${
      questionId ? '/' + questionId : ''
    }`
    return this.http.get(uri).pipe(map((data: IResponse) => data))
  }

  public getCsatSummary(payload: ISummary): Observable<IResponse> {
    const uri = `${this.api}/report/question/csat/summary`
    return this.http.post(uri, payload).pipe(map((data: IResponse) => data))
  }
  public getNpsSummary(payload: ISummary): Observable<IResponse> {
    const uri = `${this.api}/report/question/nps/summary`
    return this.http.post(uri, payload).pipe(map((data: IResponse) => data))
  }

  public getOptionsQuestionSummary(payload: ISummary): Observable<IResponse> {
    const uri = `${this.api}/report/question/options/summary`
    return this.http.post(uri, payload).pipe(map((data: IResponse) => data))
  }

  public getCommentsSummary(payload: ISummary): Observable<IResponse> {
    const uri = `${this.api}/report/question/comments/summary`
    return this.http.post(uri, payload).pipe(map((data: IResponse) => data))
  }

  public getTrendingSummarySurvey(
    payload: ITrendingSummary
  ): Observable<IResponse> {
    const uri = `${this.api}/report/survey/trending-summary`
    return this.http.post(uri, payload).pipe(map((data: IResponse) => data))
  }
}
