import { optionsChartDefault } from './../../../../utils/properties/hightchartOptions'

export const chartOptionsDoughnutCSAT = (
  categories: string[],
  dataSeries: any[],
  csatScore: number | string
) => {
  const colorsCSAT = ['#e74c3c', '#e67e22', '#f1c40f', '#2ecc71', '#27ae60']

  return {
    lang: optionsChartDefault.lang,
    chart: {
      type: 'pie',
      renderTo: 'container',
      options3d: {
        enabled: true,
        alpha: 0, // Angulo de inclinación
      },
      backgroundColor: 'transparent',
      events: {
        load: function () {
          const centerX = this.plotWidth / 2 + this.plotLeft
          const centerY = this.plotHeight / 2 + this.plotTop

          this.renderer
            .text('<p>CSAT</p><br>' + csatScore + '%', centerX, centerY)
            .css({
              color: '#444444',
              fontSize: '20px',
              marginTop: '10px',
              fontWeight: 800,
              textAlign: 'center',
            })
            .attr({
              align: 'center',
              zIndex: 5,
            })
            .add()
        },
      },
    },
    title: {
      text: '',
    },
    // subtitle: {
    //   text: 'Porcentaje de respuestas por categoría',
    // },
    tooltip: {
      pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
    },
    accessibility: {
      point: {
        valueSuffix: '%',
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        depth: 25, // Grosor de la dona
        // dataLabels: {
        //   enabled: false,
        //   format: '<b>{point.name}</b>: {point.percentage:.1f} %',
        // },
        dataLabels: {
          enabled: true, // Cambiar a false si no quieres ver los labels
          distance: -30, // Ajustar según sea necesario para mover los labels hacia adentro
          format: '<b>{point.percentage:.1f} %</b>',
          style: {
            fontWeight: 'bold',
            color: 'white',
            textOutline: 'none',
          },
        },
        showInLegend: true,
      },
      series: {
        distance: 15,
        states: {
          inactive: {
            opacity: 0.2, // Ajusta este valor según tus necesidades
          },
        },
      },
    },
    legend: {
      useHTML: true,
      align: 'center', // Centra la leyenda horizontalmente
      verticalAlign: 'bottom', // Ubica la leyenda debajo del gráfico
      layout: 'horizontal', // Organiza los ítems de la leyenda horizontalmente
      itemMarginBottom: 0, // Espacio entre ítems de la leyenda
      labelFormatter: function () {
        // Calcula el porcentaje
        const total = this.series.data.reduce((sum, point) => sum + point.y, 0)
        const percentage = (this.y / total) * 100
        let emoji

        switch (this.name) {
          case 'Muy Satisfecho':
            emoji =
              '<span class="chart-badge" style="background-color:#27ae60;"></span>'
            break
          case 'Satisfecho':
            emoji =
              '<span class="chart-badge" style="background-color:#2ecc71;"></span>'
            break
          case 'Neutral':
            emoji =
              '<span class="chart-badge" style="background-color:#f1c40f;"></span>'
            break
          case 'Insatisfecho':
            emoji =
              '<span class="chart-badge" style="background-color:#e67e22;"></span>'
            break
          case 'Muy Insatisfecho':
            emoji =
              '<span class="chart-badge" style="background-color:#e74c3c;"></span>'
            break
          default:
            emoji =
              '<span class="chart-badge" style="background-color:#e4e4e4;"></span>'
            break
        }

        return `<span class="emoji-chart">${emoji}</span> ${this.name}`
      },
      itemStyle: {
        fontSize: '12px',
        fontWeight: 400,
      },
    },
    series: [
      {
        name: 'Porcentaje',
        data: dataSeries.map((item, index) => ({
          name: categories[index],
          y: item,
          color: colorsCSAT[index], // Asigna el color correspondiente a cada categoría
        })),
        size: '100%', // Tamaño del gráfico
        innerSize: '55%', // Tamaño del hueco interno para la dona
        animation: {
          duration: 1000,
          easing: 'easeOutBounce',
        },
      },
    ],

    credits: {
      enabled: false,
    },
    exporting: {
      buttons: {
        contextButton: {
          enabled: true,
        },
      },
    },
  }
}

export const chartOptionsDoughnutNPS = (
  categories: string[],
  dataSeries: any[],
  npsScore: number | string
) => {
  const colors = ['#E2416B', '#FAB60F', '#3FB185']

  return {
    lang: optionsChartDefault.lang,
    chart: {
      type: 'pie',
      renderTo: 'container',
      options3d: {
        enabled: true,
        alpha: 0, // Angulo de inclinación
      },
      backgroundColor: 'transparent',
      events: {
        load: function () {
          const centerX = this.plotWidth / 2 + this.plotLeft
          const centerY = this.plotHeight / 2 + this.plotTop

          this.renderer
            .text('<p>NPS</p><br>' + npsScore, centerX, centerY)
            .css({
              color: '#444444',
              fontSize: '20px',
              marginTop: '10px',
              fontWeight: 800,
              textAlign: 'center',
            })
            .attr({
              align: 'center',
              zIndex: 5,
            })
            .add()
        },
      },
    },
    title: {
      text: '',
    },
    tooltip: {
      pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
    },
    accessibility: {
      point: {
        valueSuffix: '%',
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: false,
        cursor: 'pointer',
        depth: 25, // Grosor de la dona
        dataLabels: {
          enabled: true, // Cambiar a false si no quieres ver los labels
          distance: -30, // Ajustar según sea necesario para mover los labels hacia adentro
          format: '<b>{point.percentage:.1f} %</b>',
          style: {
            fontWeight: 'bold',
            color: 'white',
            textOutline: 'none',
          },
        },
        showInLegend: false,
      },
      series: {
        distance: 15,
        states: {
          inactive: {
            opacity: 0.2, // Ajusta este valor según tus necesidades
          },
        },
      },
    },
    legend: {
      useHTML: true,
      align: 'center', // Centra la leyenda horizontalmente
      verticalAlign: 'bottom', // Ubica la leyenda debajo del gráfico
      layout: 'horizontal', // Organiza los ítems de la leyenda horizontalmente
      itemMarginBottom: 5, // Espacio entre ítems de la leyenda
      itemStyle: {
        fontSize: '14px',
        fontWeight: 400,
      },
      symbolHeight: 12,
      labelFormatter: function () {
        return '<span style="color:' + this.color + '">' + this.name + '</span>'
      },
    },
    series: [
      {
        name: 'Porcentaje',
        data: dataSeries.map((item, index) => ({
          name: categories[index],
          y: item,
          color: colors[index], // Asigna el color correspondiente a cada categoría
        })),
        size: '100%', // Tamaño del gráfico
        innerSize: '55%', // Tamaño del hueco interno para la dona
        animation: {
          duration: 1000,
          easing: 'easeOutBounce',
        },
      },
    ],

    credits: {
      enabled: false,
    },
    exporting: {
      buttons: {
        contextButton: {
          enabled: true,
        },
      },
    },
  }
}

export const chartOptionsDoughnutAbandonedRate = (
  categories: string[],
  dataSeries: any[],
  abandonRate: number | string
) => {
  const colors = ['#1abc9c', '#ff7f50']

  return {
    lang: optionsChartDefault.lang,
    chart: {
      type: 'pie',
      renderTo: 'container',
      options3d: {
        enabled: true,
        alpha: 0, // Angulo de inclinación
      },
      backgroundColor: 'transparent',
      events: {
        load: function () {
          const centerX = this.plotWidth / 2 + this.plotLeft
          const centerY = this.plotHeight / 2 + this.plotTop

          this.renderer
            .text(
              `<p style="font-size: 12px">Tasa Abandono</p><br>  ${abandonRate}%`,
              centerX,
              centerY
            )
            .css({
              color: '#444444',
              fontSize: '20px',
              marginTop: '10px',
              fontWeight: 800,
              textAlign: 'center',
            })
            .attr({
              align: 'center',
              zIndex: 5,
            })
            .add()
        },
      },
    },
    title: {
      text: '',
    },
    tooltip: {
      pointFormat: '{series.name}: <b>{point.y}</b>',
    },
    accessibility: {
      point: {
        valueSuffix: '%',
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: false,
        cursor: 'pointer',
        depth: 25, // Grosor de la dona
        dataLabels: {
          enabled: true, // Cambiar a false si no quieres ver los labels
          distance: -30, // Ajustar según sea necesario para mover los labels hacia adentro
          format: '<b>{point.y}</b>',
          style: {
            fontWeight: 'bold',
            color: 'white',
            textOutline: 'none',
          },
        },
        showInLegend: true,
      },
      series: {
        distance: 15,
        states: {
          inactive: {
            opacity: 0.2, // Ajusta este valor según tus necesidades
          },
        },
      },
    },
    legend: {
      useHTML: true,
      align: 'right', // Centra la leyenda horizontalmente
      verticalAlign: 'middle', // Ubica la leyenda debajo del gráfico
      layout: 'vertical', // Organiza los ítems de la leyenda horizontalmente
      itemMarginBottom: 0, // Espacio entre ítems de la leyenda
      labelFormatter: function () {
        // Calcula el porcentaje
        let badge

        switch (this.name) {
          case 'Encuestas Completadas':
            badge =
              '<span class="chart-badge" style="background-color:#1abc9c;"></span>'
            break
          case 'Encuestas Abandonadas':
            badge =
              '<span class="chart-badge" style="background-color:#ff7f50;"></span>'
            break
          default:
            badge =
              '<span class="chart-badge" style="background-color:#e4e4e4;"></span>'
            break
        }

        return `${badge} ${this.name}`
      },
      itemStyle: {
        fontSize: '12px',
        fontWeight: 400,
      },
    },
    series: [
      {
        name: 'Total',
        data: dataSeries.map((item, index) => ({
          name: categories[index],
          y: item,
          color: colors[index],
        })),
        size: '100%', // Tamaño del gráfico
        innerSize: '55%', // Tamaño del hueco interno para la dona
        animation: {
          duration: 1000,
          easing: 'easeOutBounce',
        },
      },
    ],

    credits: {
      enabled: false,
    },
    exporting: {
      buttons: {
        contextButton: {
          enabled: true,
        },
      },
    },
  }
}

export const chartOptionsDoughnutComments = (
  categories: string[],
  dataSeries: any[],
  totalCommets: number | string
) => {
  const colors = ['#3498db', '#95a5a6', '#f1c40f']

  return {
    lang: optionsChartDefault.lang,
    chart: {
      type: 'pie',
      renderTo: 'container',
      options3d: {
        enabled: true,
        alpha: 0, // Angulo de inclinación
      },
      backgroundColor: 'transparent',
      events: {
        load: function () {
          const centerX = this.plotWidth / 2 + this.plotLeft
          const centerY = this.plotHeight / 2 + this.plotTop

          this.renderer
            .text('<p>Total</p><br>' + totalCommets, centerX, centerY)
            .css({
              color: '#444444',
              fontSize: '20px',
              marginTop: '10px',
              fontWeight: 800,
              textAlign: 'center',
            })
            .attr({
              align: 'center',
              zIndex: 5,
            })
            .add()
        },
      },
    },
    title: {
      text: '',
    },
    tooltip: {
      pointFormat: '{series.name}: <b>{point.y}</b>',
    },
    accessibility: {
      point: {
        valueSuffix: '%',
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: false,
        cursor: 'pointer',
        depth: 25, // Grosor de la dona
        dataLabels: {
          enabled: true, // Cambiar a false si no quieres ver los labels
          distance: -30, // Ajustar según sea necesario para mover los labels hacia adentro
          format: '<b>{point.y}</b>',
          style: {
            fontWeight: 'bold',
            color: 'white',
            textOutline: 'none',
          },
        },
        showInLegend: true,
      },
      series: {
        distance: 15,
        states: {
          inactive: {
            opacity: 0.2, // Ajusta este valor según tus necesidades
          },
        },
      },
    },
    legend: {
      useHTML: true,
      align: 'right', // Centra la leyenda horizontalmente
      verticalAlign: 'middle', // Ubica la leyenda debajo del gráfico
      layout: 'vertical', // Organiza los ítems de la leyenda horizontalmente
      itemMarginBottom: 0, // Espacio entre ítems de la leyenda
      labelFormatter: function () {
        // Calcula el porcentaje
        let badge

        switch (this.name) {
          //             ['Relevantes', 'No Relevantes', 'No Contestados'],

          case 'Relevantes':
            badge =
              '<span class="chart-badge" style="background-color:#3498db;"></span>'
            break
          case 'No Relevantes':
            badge =
              '<span class="chart-badge" style="background-color:#95a5a6;"></span>'
            break
          case 'No Contestados':
            badge =
              '<span class="chart-badge" style="background-color:#f1c40f;"></span>'
            break
          default:
            badge =
              '<span class="chart-badge" style="background-color:#95a5a6;"></span>'
            break
        }

        return `${badge} ${this.name}`
      },
      itemStyle: {
        fontSize: '12px',
        fontWeight: 400,
      },
    },
    series: [
      {
        name: 'Total',
        data: dataSeries.map((item, index) => ({
          name: categories[index],
          y: item,
          color: colors[index],
        })),
        size: '100%', // Tamaño del gráfico
        innerSize: '55%', // Tamaño del hueco interno para la dona
        animation: {
          duration: 1000,
          easing: 'easeOutBounce',
        },
      },
    ],

    credits: {
      enabled: false,
    },
    exporting: {
      buttons: {
        contextButton: {
          enabled: true,
        },
      },
    },
  }
}
