<div class="wrapper">
  <ul class="breadcrumbs">
    <li class="first">
      <a href="#" (click)="onBreadcrumbClick($event)">
        <em nz-icon nzType="pie-chart" nzTheme="outline"></em>
      </a>
    </li>
    <li>
      <a href="#" (click)="onBreadcrumbClick($event)"
        >First Level Interior Page</a
      >
    </li>
    <li>
      <a href="#" (click)="onBreadcrumbClick($event)"
        >Second Level Interior Page</a
      >
    </li>
  </ul>
</div>
