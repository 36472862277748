import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { ISurvey } from 'src/app/models/response/ISurvey'
import {
  ContentBgType,
  FormatType,
  MainBgType,
} from './../../../../models/response/ISurvey'
import { SurveyFacade } from './../../../../store/survey/survey.facade'

interface IOriginalSettings {
  formatType: FormatType
  contentBgType: ContentBgType
  mainBgType: MainBgType
}

@Component({
  selector: 'app-design-survey',
  templateUrl: './design-survey.component.html',
  styleUrls: ['./design-survey.component.scss'],
})
export class DesignSurveyComponent implements OnInit {
  @Output() public isDesignSettingsChangedChange = new EventEmitter<boolean>()
  @Output() public tabSelectedChange = new EventEmitter<string>()

  @Input() public isDesignSettingsChanged = false
  @Input() public tabSelected: string

  public formatType: FormatType
  public contentBgType: ContentBgType
  public mainBgType: MainBgType
  private survey: ISurvey
  public isLoading = false
  public originalSettings: IOriginalSettings

  constructor(private surveyFacade: SurveyFacade) {
    this.surveyFacade.survey$.subscribe((survey: ISurvey) => {
      if (survey) {
        this.survey = survey
        this.formatType = survey.formatType ? survey.formatType : 'layout-1'
        this.mainBgType = survey.mainBgType ? survey.mainBgType : 'dark'
        this.contentBgType = survey.contentBgType
          ? survey.contentBgType
          : 'light-desing-1'

        this.originalSettings = {
          formatType: this.formatType,
          contentBgType: this.contentBgType,
          mainBgType: this.mainBgType,
        }
      }
    })
  }

  ngOnInit() {
    this.updateFormatType(this.formatType)
    this.updateMainBgType(this.mainBgType)
  }

  public updateFormatType(formatType: FormatType): void {
    this.formatType = formatType
    this.isDesignSettingsChanged =
      this.originalSettings.formatType !== formatType
    this.isDesignSettingsChangedChange.emit(this.isDesignSettingsChanged)

    const mf = document.getElementById('survey-mf') as HTMLIFrameElement
    if (mf) {
      mf.contentWindow.postMessage(
        {
          formatType,
          type: 'FORMAT_TYPE',
        },
        '*'
      )
    }
  }

  public updateMainBgType(mainBgType: MainBgType): void {
    this.mainBgType = mainBgType
    this.isDesignSettingsChanged =
      this.originalSettings.mainBgType !== mainBgType
    this.isDesignSettingsChangedChange.emit(this.isDesignSettingsChanged)

    const mf = document.getElementById('survey-mf') as HTMLIFrameElement
    if (mf) {
      mf.contentWindow.postMessage(
        {
          mainBgType,
          type: 'MAIN_BG_TYPE',
        },
        '*'
      )
    }
  }

  public updateContentBgType(contentBgType: ContentBgType): void {
    this.contentBgType = contentBgType
    this.isDesignSettingsChanged =
      this.originalSettings.contentBgType !== contentBgType
    this.isDesignSettingsChangedChange.emit(this.isDesignSettingsChanged)

    const mf = document.getElementById('survey-mf') as HTMLIFrameElement
    if (mf) {
      mf.contentWindow.postMessage(
        {
          contentBgType,
          type: 'CONTENT_BG_TYPE',
        },
        '*'
      )
    }
  }

  public resetConfigurationSurvey(): void {
    const mf = document.getElementById('survey-mf') as HTMLIFrameElement
    if (mf) {
      mf.contentWindow.postMessage(
        {
          type: 'RESET',
        },
        '*'
      )
    }
  }

  public handleSubmit(): void {
    const requestUpdateSurvey = {
      ...this.survey,
      formatType: this.formatType,
      contentBgType: this.contentBgType,
      mainBgType: this.mainBgType,
    }

    this.isLoading = true
    this.surveyFacade.updateSurveyFetch(requestUpdateSurvey).subscribe(
      () => {
        this.isDesignSettingsChanged = false
        this.isDesignSettingsChangedChange.emit(this.isDesignSettingsChanged)
      }, // manejar la respuesta si es necesario
      (error) => {
        console.error('Error en la actualización', error)
        this.isLoading = false
      },
      () => {
        this.isLoading = false
      }
    )
  }
}
