export const PERSON_TYPE = 'persona'
export const COMPANY_TYPE = 'empresa'

export const SUCCESS_UPDATE_PROFILE_TITLE = '¡Perfil Actualizado! 🎉'
export const SUCCESS_UPDATE_PROFILE_MESSAGE =
  'Tus datos se han guardado correctamente. ¡Todo listo! ✅'

export const SUCCESS_UPLOAD_IMG_PROFILE_TITLE =
  '¡Imagen de Perfil Actualizada! 🖼️'
export const SUCCESS_DOWNLOAD_VOUCHER_TITLE = '¡Descarga en Proceso! 📥'
export const SUCCESS_DOWNLOAD_BILLING_VOUCHER_MESSAGE =
  'Tu factura se está descargando... ¡Gracias por esperar! 🧾'
export const SUCCESS_DOWNLOAD_PAYMENT_VOUCHER_MESSAGE =
  'Tu comprobante se está descargando ahora. ¡Gracias por tu paciencia! 📜'

export const WARNING_UPLOAD_IMG_FORMAT_MESSAGE =
  '¡Atención! Solo se permiten archivos en formato .jpg, .jpeg, .png 🚫'
export const WARNING_UPLOAD_IMG_SIZE_MESSAGE =
  'El tamaño de la imagen no puede exceder los 2MB. Por favor, intenta con una imagen más pequeña 📏'

export const INFO_NO_FILE_AVAILABLE_TITLE = '¡Archivo No Disponible! 🚫'
export const INFO_NO_FILE_AVAILABLE_MESSAGE =
  'Lo sentimos, pero el archivo que estás buscando no está disponible en este momento. Por favor, inténtalo más tarde o contacta con soporte para más ayuda. 🕒🛠️'
