import { Component, OnInit } from '@angular/core'
import { environment } from './../../../environments/environment'
import { PlanFacade } from './../../store/plan/plan.facade'

@Component({
  selector: 'app-info-plan-liquid',
  templateUrl: './info-plan-liquid.component.html',
  styleUrls: ['./info-plan-liquid.component.scss'],
})
export class InfoPlanLiquidComponent implements OnInit {
  public isBetaProduction = environment.production
  constructor(public planFacade: PlanFacade) {}

  ngOnInit(): void {}
}
