import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'numberFormat',
})
export class NumberFormatPipe implements PipeTransform {
  transform(value: number, decimals: number = 0): string {
    if (value === undefined || value === null) {
      return ''
    }

    const formattedValue = value
      .toLocaleString(undefined, {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
      })
      .replace(/,/g, '.')
    return formattedValue
  }
}
