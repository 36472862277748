<nz-form-item [formGroup]="groupName" class="pb-2">
  <nz-form-control [nzErrorTip]="errorMessage">
    <nz-select
      [id]="controlName"
      nzShowSearch
      nzAllowClear
      [nzMode]="tagMode ? 'tags' : 'default'"
      [nzPlaceHolder]="placeholder"
      [formControlName]="controlName"
      (focus)="onFocus()"
      (blur)="onBlur()"
    >
      <nz-option
        *ngFor="let item of options"
        [nzLabel]="item"
        [nzValue]="item"
      ></nz-option>
    </nz-select>
    <label [for]="controlName" [class.floating-label]="hasValue || isFocused">{{
      label
    }}</label>
  </nz-form-control>
</nz-form-item>
