import { Component, EventEmitter, Input, Output } from '@angular/core'

@Component({
  selector: 'app-radio-dark',
  templateUrl: './radio-dark.component.html',
  styleUrls: ['./radio-dark.component.scss'],
})
export class RadioDarkComponent {
  @Output() selectedOptionChange = new EventEmitter<number>()
  @Input() selectedOption: number = 0

  selectOption(option: number) {
    this.selectedOption = option
    this.selectedOptionChange.emit(this.selectedOption)
  }
}
