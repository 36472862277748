<div class="timeline-responses">
  <nz-spin [nzSpinning]="responsesFacade.isLoadingLastResponses$ | async">
    <nz-timeline>
      <nz-timeline-item
        *ngFor="let r of responsesFacade.lastResponses$ | async"
      >
        <div class="timeline px-3 py-2">
          <div class="row align-items-center">
            <div class="mb-sm-2 col-sm-12 mb-sm-0 col-md-6 col-xl-12 col-x3l-6">
              <span class="d-block survey-timeline"
                ><strong>Encuesta:</strong> {{ r.survey }}</span
              >
              <span class="question-timeline">{{ r.question }}</span>
              <span class="question-date">
                <nz-tag [nzColor]="'#9b59b6'" class="m-0 mr-2"
                  >id: {{ r.sessionId | slice: 0:8 }}</nz-tag
                >{{ r.responseDate | customDate }}</span
              >
            </div>
            <div class="item-responses col-sm-12 col-md-6 col-xl-12 col-x3l-6">
              <hr class="d-md-none d-xl-block d-x3l-none" />
              <div
                class="d-flex d-md-block d-xl-flex d-x3l-block justify-content-center align-items-center"
              >
                <nz-tag class="m-0"
                  ><small class="font-weight-bold">{{
                    r.questionType.name
                  }}</small></nz-tag
                >
              </div>
              <div
                class="d-flex d-md-block d-xl-flex d-x3l-block justify-content-center align-items-center"
              >
                <!-- CSAT RESPONSE [1] -->
                <div *ngIf="r.questionType.id === 1" class="mt-2 d-flex">
                  <app-csat-response-item
                    [csatScore]="r.response"
                    [csatType]="1"
                  >
                  </app-csat-response-item>
                  <span
                    *ngIf="r.comment"
                    class="ml-3"
                    nz-tooltip
                    [nzTooltipTrigger]="r.comment.length > 100 ? 'hover' : null"
                    [nzTooltipPlacement]="'top'"
                    [nzTooltipTitle]="r.comment"
                    >🗨️ {{ r.comment | shortText: 0:100 }}</span
                  >
                </div>
                <!-- NPS RESPONSE [2] -->
                <div *ngIf="r.questionType.id === 2">
                  <app-nps-response-item
                    [npsScore]="r.response"
                  ></app-nps-response-item>
                  <span
                    *ngIf="r.comment"
                    class="ml-3"
                    nz-tooltip
                    [nzTooltipTrigger]="r.comment.length > 100 ? 'hover' : null"
                    [nzTooltipPlacement]="'top'"
                    [nzTooltipTitle]="r.comment"
                    >🗨️ {{ r.comment | shortText: 0:100 }}</span
                  >
                </div>
                <!-- SELECT MULTIPLE [3] -->
                <div class="container-multiple" *ngIf="r.questionType.id === 3">
                  <div
                    class="selected-option w-100 p-0"
                    *ngFor="let item of r.response"
                  >
                    <span
                      nz-tooltip
                      [nzTooltipTrigger]="
                        r.response.length > 20 ? 'hover' : null
                      "
                      [nzTooltipPlacement]="'top'"
                      [nzTooltipTitle]="r.response"
                      >☑️{{ item }}
                    </span>
                  </div>
                </div>
                <!-- SELECTION ÚNICA [4] -->
                <div *ngIf="r.questionType.id === 4">
                  <div class="selected-option w-100">
                    <span
                      nz-tooltip
                      [nzTooltipTrigger]="
                        r.response.length > 20 ? 'hover' : null
                      "
                      [nzTooltipPlacement]="'top'"
                      [nzTooltipTitle]="r.response"
                      >☑️{{ r.response }}
                    </span>
                  </div>
                </div>
                <!-- TEXT SHORT [5] -->
                <div *ngIf="r.questionType.id === 5">
                  <div class="text-short">
                    <span
                      nz-tooltip
                      [nzTooltipTrigger]="
                        r.response.length > 130 ? 'hover' : null
                      "
                      [nzTooltipPlacement]="'top'"
                      [nzTooltipTitle]="r.response"
                      >🗨️ {{ r.response | shortText: 0:130 }}
                    </span>
                  </div>
                </div>
                <!-- TEXT LONG [6] -->
                <div class="h-50" *ngIf="r.questionType.id === 6">
                  <div class="text-large">
                    <span
                      nz-tooltip
                      [nzTooltipTrigger]="
                        r.response.length > 130 ? 'hover' : null
                      "
                      [nzTooltipPlacement]="'top'"
                      [nzTooltipTitle]="r.response"
                      >🗨️ {{ r.response | shortText: 0:130 }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nz-timeline-item>
      <ng-template #dotTemplate>
        <span nz-icon nzType="clock-circle-o" style="font-size: 16px"></span>
      </ng-template>
    </nz-timeline>
  </nz-spin>
</div>
