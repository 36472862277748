import { Injectable } from '@angular/core'
import { Select, Store } from '@ngxs/store'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { Observable } from 'rxjs'
import { IEmailRecovery } from 'src/app/models/request/IEmailRecovery'
import { SessionService } from 'src/app/services/service.index'
import { IResponse } from './../../models/response/IResponse'
import {
  ERROR_SERVICE_MESSAGE,
  ERROR_SERVICE_TITLE,
} from './../../utils/constants/generic-messages'
import {
  ERROR_RECOVERY_TITLE,
  SUCCESS_RECOVERY_MESSAGE,
  SUCCESS_RECOVERY_TITLE,
} from './../../utils/constants/session-messages'
import { UpdateActiveURL } from './session.actions'
import { SessionState } from './session.state'

@Injectable({ providedIn: 'root' })
export class SessionFacade {
  @Select(SessionState.getActiveURL) activeURL$: Observable<string>
  @Select(SessionState.getLoadingSession) isLoadingSession$: Observable<boolean>
  @Select(SessionState.getLoadingSessionPage)
  isLoadingSessionPage$: Observable<boolean>
  @Select(SessionState.getLoadingLogout)
  isLoadingLogout$: Observable<boolean>

  constructor(
    private store: Store,
    private _session: SessionService,
    private notification: NzNotificationService
  ) {}

  public updateActiveURL(activeURL: string): void {
    this.store.dispatch(new UpdateActiveURL(activeURL))
  }

  public sendMailActivationAccountFetch(request: IEmailRecovery) {
    this._session.sendMailActivationAccount(request).subscribe(
      (response: IResponse) => {
        switch (response.status.code) {
          case 204:
          case 400:
            this.notification.create('warning', ERROR_RECOVERY_TITLE, '')
            break
          case 200:
          case 201:
            this.notification.create(
              'success',
              SUCCESS_RECOVERY_TITLE,
              `${SUCCESS_RECOVERY_MESSAGE} ${request.email}`
            )
            break
        }
      },
      (error) => {
        if (error.status === 404) {
          return this.notification.create('warning', ERROR_RECOVERY_TITLE, '')
        }

        return this.notification.create(
          'error',
          ERROR_SERVICE_TITLE,
          ERROR_SERVICE_MESSAGE
        )
      }
    )
  }
}
