<section id="login" class="login 5">
  <div class="login__background">
    <div class="row align-items-center h-100">
      <div
        class="col-lg-5 col-md-12 align-self-center animated fadeIn d-none d-lg-block"
      >
        <img
          src="./assets/images/logos/light-large-logo.png"
          class="img-fluid d-block mx-auto"
          alt=""
        />
      </div>
      <div
        class="col-lg-7 col-md-12 align-self-center animated fadeIn form-col"
      >
        <div class="container">
          <div class="card p-xs-2 p-sm-5 mx-auto">
            <div class="card-body">
              <img
                src="./assets/images/logos/dark-large-logo.png"
                class="img-fluid mr-auto logo-form d-none mb-2"
                alt=""
              />
              <h1 class="text-dark">Inicio de sesión</h1>
              <p *ngIf="!isBetaProduction" class="text-dark mb-5">
                ¿Eres un nuevo usuario?
                <a [routerLink]="['/register']"> Crear una cuenta </a>
              </p>

              <form
                nz-form
                [formGroup]="validateForm"
                class="login-form"
                (ngSubmit)="submitForm()"
              >
                <app-input-field
                  class="w-100 mb-2"
                  [groupName]="validateForm"
                  controlName="userName"
                  label="Email"
                  [errorMessage]="
                    !loading && 'Ingresa dirección de correo electrónico válido'
                  "
                  icon="mail"
                ></app-input-field>

                <app-input-field
                  class="w-100 mb-3"
                  [groupName]="validateForm"
                  controlName="password"
                  label="Contraseña"
                  [errorMessage]="!loading && 'Ingresa tu contraseña'"
                  icon="lock"
                  inputType="password"
                ></app-input-field>

                <div class="login-form-margin w-100">
                  <div class="row">
                    <div class="col-6">
                      <nz-switch
                        nzSize="small"
                        formControlName="remember"
                      ></nz-switch>
                      <span class="ml-2">Recuerdame</span>
                    </div>
                    <div class="col-6 px-0">
                      <a [routerLink]="['/recovery']"
                        >¿Olvidaste tu contraseña?</a
                      >
                    </div>
                  </div>
                </div>
                <div class="d-flex">
                  <button
                    [disabled]="loading"
                    type="submit"
                    class="btn btn-primary ml-auto"
                  >
                    <i
                      *ngIf="loading"
                      class="mr-3"
                      nz-icon
                      [nzType]="'loading'"
                    ></i>
                    <span *ngIf="loading">Ingresando</span>
                    <span *ngIf="!loading">Iniciar sesión</span>
                  </button>
                </div>
                <div class="mt-3">
                  <small class="text-muted mt-xs-2">
                    Protegido por reCAPTCHA y sujeto a
                    <a
                      class=""
                      href="https://policies.google.com/privacy?hl=es"
                      aria-label="Política de privacidad de Google"
                      rel="noopener"
                      target="_blank"
                      >Política de privacidad</a
                    >
                    y
                    <a
                      class=""
                      href="https://policies.google.com/terms?hl=es"
                      aria-label="Condiciones del servicio de Google"
                      rel="noopener"
                      target="_blank"
                      >Condiciones del servicio</a
                    >
                    de Google.
                  </small>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
