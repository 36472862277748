<div class="plugin-csat">
  <h6 class="question-title">{{ question.title }}</h6>
  <div class="csat-container mx-auto">
    <nz-rate
      [ngModel]="response ? response : 0"
      [nzCharacter]="characterIcon"
    ></nz-rate>
    <ng-template #characterIcon let-index>
      <ng-container [ngSwitch]="index">
        <span
          class="csat-option"
          [ngClass]="{ selected: index === +response - 1 }"
          nz-icon
          [nzTheme]="'twotone'"
          [nzTwotoneColor]="getCsatColor(index)"
          [nzType]="getCsatClass(index)"
          (click)="handleChange(index)"
        ></span>
      </ng-container>
    </ng-template>
  </div>
</div>
