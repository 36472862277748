import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { PlanModule } from './../plan/plan.module'
import { ProfileRoutingModule } from './profile-routing.module'
import { ComponentsModule } from './../../components/components.module'
import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ProfileComponent } from './profile.component'
import { GeneralProfileComponent } from './general-profile/general-profile.component'
import { OptionsProfileComponent } from './options-profile/options-profile.component'
import { EditProfileComponent } from './edit-profile/edit-profile.component'
import { UpdatePassProfileComponent } from './update-pass-profile/update-pass-profile.component'
import { NgZorroAntdModule } from 'src/app/ng-zorro-antd.module'
import { GeneralFormComponent } from './edit-profile/general-form/general-form.component'
import { CompanyFormComponent } from './edit-profile/company-form/company-form.component'
import { DragAndDropDirective } from '../../directives/drag-and-drop.directive'
import { MyPaymentsComponent } from './my-payments/my-payments.component'
import { BillFormRequestComponent } from './bill-form-request/bill-form-request.component'
@NgModule({
  declarations: [
    ProfileComponent,
    GeneralProfileComponent,
    OptionsProfileComponent,
    EditProfileComponent,
    UpdatePassProfileComponent,
    GeneralFormComponent,
    CompanyFormComponent,
    DragAndDropDirective,
    MyPaymentsComponent,
    BillFormRequestComponent,
  ],
  imports: [
    CommonModule,
    NgZorroAntdModule,
    ComponentsModule,
    ReactiveFormsModule,
    FormsModule,
    ProfileRoutingModule,
    PlanModule,
  ],
  providers: [],
})
export class ProfileModule {}
