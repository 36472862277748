<div class="container-fluid animated-fade-in">
  <div class="row">
    <div class="col-12">
      <div class="row">
        <div class="col-xl-3 col-sm-6">
          <div class="card bg-secondary">
            <div class="card-body">
              <div
                class="student-box d-flex justify-content-between align-items-center flex-wrap"
                style="position: relative"
              >
                <div>
                  <span class="student-icon">
                    <svg
                      width="40"
                      height="40"
                      viewBox="0 0 40 40"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14.1665 19.1666C17.8331 19.1666 20.8331 16.1666 20.8331 12.4999C20.8331 8.83325 17.8331 5.83325 14.1665 5.83325C10.4998 5.83325 7.49981 8.83325 7.49981 12.4999C7.49981 16.1666 10.4998 19.1666 14.1665 19.1666ZM21.6665 17.6666C24.4998 19.9999 28.6665 19.4999 30.9998 16.6666C33.3331 13.8333 32.8331 9.66659 29.9998 7.33325C28.8331 6.33325 27.3331 5.83325 25.8331 5.83325C24.3331 5.83325 22.8331 6.33325 21.6665 7.33325C24.4998 9.66659 24.9998 13.6666 22.6665 16.6666C22.4998 16.9999 21.9998 17.3333 21.6665 17.6666ZM38.1665 32.3333C37.1665 26.1666 31.9998 21.6666 25.8331 21.6666C24.3331 21.6666 22.9998 21.8333 21.6665 22.3333C25.9998 23.8333 29.1665 27.6666 29.8331 32.1666C29.9998 32.9999 29.3331 33.9999 28.3331 33.9999H28.1665H36.4998C37.4998 33.9999 38.1665 33.3333 38.1665 32.3333C38.1665 32.4999 38.1665 32.3333 38.1665 32.3333ZM14.1665 21.6666C7.99981 21.6666 2.66647 26.1666 1.83314 32.3333C1.66647 33.1666 2.33314 34.1666 3.33314 34.1666H3.49981H24.8331C25.8331 34.1666 26.4998 33.4999 26.4998 32.4999V32.3333C25.6665 26.1666 20.3331 21.6666 14.1665 21.6666Z"
                        fill="var(--secondary)"
                      ></path>
                    </svg>
                  </span>
                  <h4>43.322</h4>
                  <span class="pb-3 d-block">Total Students</span>
                </div>
                <div id="NewCustomers" style="min-height: 100px">
                  <div
                    id="apexcharts5xlx6uz4f"
                    class="apexcharts-canvas apexcharts5xlx6uz4f apexcharts-theme-light"
                    style="width: 120px; height: 100px"
                  >
                    <svg
                      id="SvgjsSvg1133"
                      width="120"
                      height="100"
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      xmlns:svgjs="http://svgjs.com/svgjs"
                      class="apexcharts-svg"
                      xmlns:data="ApexChartsNS"
                      transform="translate(0, 0)"
                      style="background: transparent"
                    >
                      <g
                        id="SvgjsG1135"
                        class="apexcharts-inner apexcharts-graphical"
                        transform="translate(0, 0)"
                      >
                        <defs id="SvgjsDefs1134">
                          <clipPath id="gridRectMask5xlx6uz4f">
                            <rect
                              id="SvgjsRect1138"
                              width="127"
                              height="103"
                              x="-3.5"
                              y="-1.5"
                              rx="0"
                              ry="0"
                              opacity="1"
                              stroke-width="0"
                              stroke="none"
                              stroke-dasharray="0"
                              fill="#fff"
                            ></rect>
                          </clipPath>
                          <clipPath id="gridRectMarkerMask5xlx6uz4f">
                            <rect
                              id="SvgjsRect1139"
                              width="124"
                              height="104"
                              x="-2"
                              y="-2"
                              rx="0"
                              ry="0"
                              opacity="1"
                              stroke-width="0"
                              stroke="none"
                              stroke-dasharray="0"
                              fill="#fff"
                            ></rect>
                          </clipPath>
                        </defs>
                        <g
                          id="SvgjsG1145"
                          class="apexcharts-xaxis"
                          transform="translate(0, 0)"
                        >
                          <g
                            id="SvgjsG1146"
                            class="apexcharts-xaxis-texts-g"
                            transform="translate(0, -4)"
                          ></g>
                          <line
                            id="SvgjsLine1153"
                            x1="0"
                            y1="101"
                            x2="120"
                            y2="101"
                            stroke="#e0e0e0"
                            stroke-dasharray="0"
                            stroke-width="1"
                          ></line>
                        </g>
                        <g id="SvgjsG1155" class="apexcharts-grid">
                          <g
                            id="SvgjsG1156"
                            class="apexcharts-gridlines-horizontal"
                          >
                            <line
                              id="SvgjsLine1163"
                              x1="0"
                              y1="0"
                              x2="120"
                              y2="0"
                              stroke="rgba(255,255,255,.3)"
                              stroke-dasharray="0"
                              class="apexcharts-gridline"
                            ></line>
                            <line
                              id="SvgjsLine1164"
                              x1="0"
                              y1="20"
                              x2="120"
                              y2="20"
                              stroke="rgba(255,255,255,.3)"
                              stroke-dasharray="0"
                              class="apexcharts-gridline"
                            ></line>
                            <line
                              id="SvgjsLine1165"
                              x1="0"
                              y1="40"
                              x2="120"
                              y2="40"
                              stroke="rgba(255,255,255,.3)"
                              stroke-dasharray="0"
                              class="apexcharts-gridline"
                            ></line>
                            <line
                              id="SvgjsLine1166"
                              x1="0"
                              y1="60"
                              x2="120"
                              y2="60"
                              stroke="rgba(255,255,255,.3)"
                              stroke-dasharray="0"
                              class="apexcharts-gridline"
                            ></line>
                            <line
                              id="SvgjsLine1167"
                              x1="0"
                              y1="80"
                              x2="120"
                              y2="80"
                              stroke="rgba(255,255,255,.3)"
                              stroke-dasharray="0"
                              class="apexcharts-gridline"
                            ></line>
                            <line
                              id="SvgjsLine1168"
                              x1="0"
                              y1="100"
                              x2="120"
                              y2="100"
                              stroke="rgba(255,255,255,.3)"
                              stroke-dasharray="0"
                              class="apexcharts-gridline"
                            ></line>
                          </g>
                          <g
                            id="SvgjsG1157"
                            class="apexcharts-gridlines-vertical"
                          >
                            <line
                              id="SvgjsLine1158"
                              x1="0"
                              y1="0"
                              x2="0"
                              y2="100"
                              stroke="rgba(255,255,255,.3)"
                              stroke-dasharray="0"
                              class="apexcharts-gridline"
                            ></line>
                            <line
                              id="SvgjsLine1159"
                              x1="30"
                              y1="0"
                              x2="30"
                              y2="100"
                              stroke="rgba(255,255,255,.3)"
                              stroke-dasharray="0"
                              class="apexcharts-gridline"
                            ></line>
                            <line
                              id="SvgjsLine1160"
                              x1="60"
                              y1="0"
                              x2="60"
                              y2="100"
                              stroke="rgba(255,255,255,.3)"
                              stroke-dasharray="0"
                              class="apexcharts-gridline"
                            ></line>
                            <line
                              id="SvgjsLine1161"
                              x1="90"
                              y1="0"
                              x2="90"
                              y2="100"
                              stroke="rgba(255,255,255,.3)"
                              stroke-dasharray="0"
                              class="apexcharts-gridline"
                            ></line>
                            <line
                              id="SvgjsLine1162"
                              x1="120"
                              y1="0"
                              x2="120"
                              y2="100"
                              stroke="rgba(255,255,255,.3)"
                              stroke-dasharray="0"
                              class="apexcharts-gridline"
                            ></line>
                          </g>
                          <line
                            id="SvgjsLine1170"
                            x1="0"
                            y1="100"
                            x2="120"
                            y2="100"
                            stroke="transparent"
                            stroke-dasharray="0"
                          ></line>
                          <line
                            id="SvgjsLine1169"
                            x1="0"
                            y1="1"
                            x2="0"
                            y2="100"
                            stroke="transparent"
                            stroke-dasharray="0"
                          ></line>
                        </g>
                        <g
                          id="SvgjsG1140"
                          class="apexcharts-line-series apexcharts-plot-series"
                        >
                          <g
                            id="SvgjsG1141"
                            class="apexcharts-series"
                            seriesName="NetxProfit"
                            data:longestSeries="true"
                            rel="1"
                            data:realIndex="0"
                          >
                            <path
                              id="SvgjsPath1144"
                              d="M 0 95C 8.399999999999999 95 15.600000000000001 45 24 45C 32.4 45 39.6 95 48 95C 56.4 95 63.6 20 72 20C 80.4 20 87.6 70 96 70C 104.4 70 111.6 20 120 20"
                              fill="none"
                              fill-opacity="1"
                              stroke="rgba(255,255,255,1)"
                              stroke-opacity="1"
                              stroke-linecap="butt"
                              stroke-width="3"
                              stroke-dasharray="0"
                              class="apexcharts-line"
                              index="0"
                              clip-path="url(#gridRectMask5xlx6uz4f)"
                              pathTo="M 0 95C 8.399999999999999 95 15.600000000000001 45 24 45C 32.4 45 39.6 95 48 95C 56.4 95 63.6 20 72 20C 80.4 20 87.6 70 96 70C 104.4 70 111.6 20 120 20"
                              pathFrom="M -1 120L -1 120L 24 120L 48 120L 72 120L 96 120L 120 120"
                            ></path>
                            <g
                              id="SvgjsG1142"
                              class="apexcharts-series-markers-wrap"
                              data:realIndex="0"
                            ></g>
                          </g>
                          <g
                            id="SvgjsG1143"
                            class="apexcharts-datalabels"
                            data:realIndex="0"
                          ></g>
                        </g>
                        <line
                          id="SvgjsLine1171"
                          x1="0"
                          y1="0"
                          x2="120"
                          y2="0"
                          stroke="#b6b6b6"
                          stroke-dasharray="0"
                          stroke-width="1"
                          class="apexcharts-ycrosshairs"
                        ></line>
                        <line
                          id="SvgjsLine1172"
                          x1="0"
                          y1="0"
                          x2="120"
                          y2="0"
                          stroke-dasharray="0"
                          stroke-width="0"
                          class="apexcharts-ycrosshairs-hidden"
                        ></line>
                        <g
                          id="SvgjsG1173"
                          class="apexcharts-yaxis-annotations"
                        ></g>
                        <g
                          id="SvgjsG1174"
                          class="apexcharts-xaxis-annotations"
                        ></g>
                        <g
                          id="SvgjsG1175"
                          class="apexcharts-point-annotations"
                        ></g>
                      </g>
                      <g
                        id="SvgjsG1154"
                        class="apexcharts-yaxis"
                        rel="0"
                        transform="translate(-18, 0)"
                      ></g>
                      <g id="SvgjsG1136" class="apexcharts-annotations"></g>
                    </svg>
                    <div
                      class="apexcharts-legend"
                      style="max-height: 50px"
                    ></div>
                  </div>
                </div>
                <div class="resize-triggers">
                  <div class="expand-trigger">
                    <div style="width: 262px; height: 172px"></div>
                  </div>
                  <div class="contract-trigger"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-lg-4 mt-4">
      <div class="card">
        <highcharts-chart
          [Highcharts]="Highcharts"
          [options]="chartOptionsWords"
          style="width: 100%; height: 350px; display: block"
        ></highcharts-chart>
      </div>
    </div>

    <div class="col-12 col-lg-8 mt-4">
      <nz-tabset>
        <nz-tab
          nzTitle="Mis observaciones"
          (nzSelect)="tabSelected = 'my-observations'"
        >
          <app-reviews></app-reviews>
        </nz-tab>
        <nz-tab
          nzTitle="Todas las observaciones"
          (nzSelect)="tabSelected = 'my-observations'"
        >
        </nz-tab>
      </nz-tabset>
    </div>
  </div>
</div>
