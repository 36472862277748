import confetti from 'canvas-confetti'

export function celebrate() {
  const duration = 10000

  confetti({
    particleCount: 300,
    spread: 360,
    startVelocity: 30,
    origin: { y: 0.4 },
    colors: ['#800080', '#9B30FF', '#D8BFD8'],
  })

  // array de strins con 3 colores en hexadecimal de un color morado

  setTimeout(() => confetti.reset(), duration)
}
