import { IUser } from 'src/app/models/response/IUser'

export class UpdateUser {
  static readonly type = '[USER] update user'
  constructor(public payload: IUser) {}
}

export class UpdateLoadingUser {
  static readonly type = '[USER] update loading user'
  constructor(public payload: boolean) {}
}
