import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core'
import { Store } from '@ngxs/store'
import { IQuestion } from 'src/app/models/response/IQuestion'
import { QuestionService } from 'src/app/services/service.index'
import { QuestionFacade } from 'src/app/store/question/question.facade'
import { UpdateQuestion } from './../../../store/question/question.actions'
import {
  QUESTION_NO_DELETE_TITLE,
  QUESTION_NO_EDIT_TEXT,
} from './../../../utils/constants/question-message'

@Component({
  selector: 'app-question-options',
  templateUrl: './question-options.component.html',
  styleUrls: ['./question-options.component.scss'],
})
export class QuestionOptionsComponent implements AfterViewInit {
  @Input() question: IQuestion
  @Input() questions: IQuestion[]
  @Input() surveyId: string
  @Input() confirmPlacement: string = 'left'
  @ViewChild('mf') mf: ElementRef

  public ICON_OPTION = './assets/images/icons/icon_option_y.svg'

  public editQuestion: boolean
  public isVisibleModalEdit: boolean
  public isVisibleModalDelete: boolean

  public isVisibleDelete: boolean
  public isVisibleIconDelete: boolean
  public messageDelete: string

  public questionNoEditText: string = QUESTION_NO_EDIT_TEXT
  public questionNoEditTitle: string = QUESTION_NO_DELETE_TITLE

  constructor(
    private store: Store,
    public questionFacade: QuestionFacade,
    private _question: QuestionService
  ) {
    this.editQuestion = false
    this.isVisibleModalEdit = false
    this.isVisibleModalDelete = false
    this.isVisibleDelete = false
    this.isVisibleIconDelete = false
  }

  public deleteQuestionModal(question: IQuestion): void {
    this.isVisibleDelete = true
    this.messageDelete = 'Se eliminara la pregunta. Estas seguro?'
  }

  ngAfterViewInit(): void {}

  public activeDesactiveQuestionModal(question: IQuestion): void {
    this.store.dispatch(new UpdateQuestion(question))
    this.question = question
    this.editQuestion = true
  }

  public handleCancel(): void {
    this.isVisibleModalEdit = false
    this.isVisibleModalDelete = false
  }

  public editQuestionModal(question: IQuestion): void {
    this.store.dispatch(new UpdateQuestion(question))
    const mf = document.getElementById('survey-mf') as HTMLIFrameElement
    if (mf) {
      mf.contentWindow.postMessage(
        { question, type: 'QUESTION', isVisibleEditForm: true },
        '*'
      )
    }
  }

  public removeQuestion(question: IQuestion): void {
    this.questionFacade.archivedQuestionFetch(question, this.surveyId)
  }

  public changeStatus(question: IQuestion): void {
    this.questionFacade.changeOptionsFetch(question, this.surveyId)
  }
}
