import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { JiraFacade } from 'src/app/store/store.index'
import { PaymentFacade } from '../../../store/payment/payment.facade'
import { IMyPayment } from './../../../models/response/IMyPayment'
import {
  SUCCESS_DOWNLOAD_BILLING_VOUCHER_MESSAGE,
  SUCCESS_DOWNLOAD_VOUCHER_TITLE,
} from './../../../utils/constants/profile'

@Component({
  selector: 'app-my-payments',
  templateUrl: './my-payments.component.html',
})
export class MyPaymentsComponent implements OnInit {
  public ICON_OPTION = './assets/images/icons/icon_option_y.svg'
  public nameButtonSuccessFulBillingRequestModal: string
  public isVisibleBillingFormModal: boolean
  public isVisibleSuccesfulBillRequest: boolean
  public isVisibleSuccessFulBillingRequestModal: boolean
  public isVisibleBillingOptionRequest: boolean
  public loading: boolean
  public myPayments: IMyPayment[]
  public pageIndex = 1
  public pageSize = 10
  public total = 1

  @Output() handleCancel = new EventEmitter()

  constructor(
    private notification: NzNotificationService,
    public jiraFacade: JiraFacade,
    public paymentFacade: PaymentFacade
  ) {
    this.isVisibleBillingFormModal = false
    this.isVisibleSuccesfulBillRequest = false
    this.isVisibleBillingOptionRequest = false
    this.paymentFacade.getMyPayments()
  }

  ngOnInit(): void {
    this.nameButtonSuccessFulBillingRequestModal = 'Continuar'
    this.getMyPaymentsDataPerPage(1)
  }

  public getMyPaymentsDataPerPage(page: any): void {
    this.paymentFacade.myPayments$.subscribe((myPayments: IMyPayment[]) => {
      if (myPayments) {
        this.total = myPayments.length
        this.pageIndex = page
        const start = (this.pageIndex - 1) * this.pageSize
        const end = start + this.pageSize
        this.myPayments = myPayments.slice(start, end)
      }
    })
  }

  public getStatusPayment(status: any): string {
    const Status = {
      3: 'Rechazado',
      1: 'En proceso',
      2: 'Pagado',
    } as const

    return Status[status.id]
  }

  public getTypeIconStatusPayment(status: any): string {
    const Status = {
      3: 'error',
      1: 'processing',
      2: 'success',
    } as const

    return Status[status.id]
  }

  public getStatusBilling(status: any): string {
    const StatusBilling = {
      0: 'Solicitar Factura',
      1: 'Factura Solicitada',
      2: 'Descargar Factura',
    } as const

    return StatusBilling[status.id]
  }

  showBillingFormModal(): void {
    this.isVisibleSuccesfulBillRequest = false
    this.isVisibleBillingFormModal = true
  }

  handleBillingFormModalOk(): void {
    this.isVisibleSuccesfulBillRequest = true
  }

  handleBillingFormModalCancel(): void {
    this.jiraFacade.initializerTicketCreated()
    this.isVisibleSuccesfulBillRequest = false
    this.isVisibleBillingFormModal = false
  }

  public downloadVoucher(paymentId): void {
    this.paymentFacade.getDocumentFetch(paymentId, 1)
  }
  public downloadBilling(): void {
    this.notification.create(
      'success',
      SUCCESS_DOWNLOAD_VOUCHER_TITLE,
      SUCCESS_DOWNLOAD_BILLING_VOUCHER_MESSAGE
    )
  }
}
