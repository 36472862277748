import { Injectable } from '@angular/core'
import { Select, Store } from '@ngxs/store'
import * as FileSaver from 'file-saver'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { Observable, combineLatest } from 'rxjs'
import { catchError, first, tap } from 'rxjs/operators'
import { IAboutSurvey } from 'src/app/models/response/IAboutSurvey'
import { ICategoryResponse } from 'src/app/models/response/ICategoryResponse'
import { QuestionFacade } from 'src/app/store/question/question.facade'
import { SurveyState } from 'src/app/store/survey/survey.state'
import { cleanText } from 'src/app/utils/converts/strings'
import { environment } from 'src/environments/environment'
import { IResponse } from './../../models/response/IResponse'
import { ISurvey } from './../../models/response/ISurvey'
import { ISurveyListInfo } from './../../models/response/ISurveyListInfo'
import { SurveyCategoryService } from './../../services/pages/survey-category.service'
import { SurveyService } from './../../services/pages/survey.service'
import {
  ERROR_SERVICE_MESSAGE,
  ERROR_SERVICE_TITLE,
} from './../../utils/constants/generic-messages'
import {
  ERROR_CREATE_SURVEY_LIMIT_MESSAGE,
  ERROR_CREATE_SURVEY_LIMIT_TITLE,
  SUCCESS_ACTIVE_SURVEY_MESSAGE,
  SUCCESS_ACTIVE_SURVEY_TITLE,
  SUCCESS_ARCHIVED_SURVEY_MESSAGE,
  SUCCESS_ARCHIVED_SURVEY_TITLE,
  SUCCESS_DESACTIVE_SURVEY_MESSAGE,
  SUCCESS_DESACTIVE_SURVEY_TITLE,
  SUCCESS_UPDATE_SURVEY_MESSAGE,
  SUCCESS_UPDATE_SURVEY_TITLE,
} from './../../utils/constants/survey-messages'
import {
  UpdateAboutSurvey,
  UpdateErrorSurveyService,
  UpdateImageSurveyQR,
  UpdateIsDisabledIconsShared,
  UpdateIsLoadingQrSurvey,
  UpdateLoadingSectionSurvey,
  UpdateLoadingSurvey,
  UpdateLoadingSurveyList,
  UpdateSurvey,
  UpdateSurveyCategories,
  UpdateSurveyList,
  UpdateSurveyListInfo,
} from './survey.actions'
import { IErrorSurveyService, ISurveyModel } from './survey.state'

const ACCESS_TOKEN = environment.access_token

@Injectable()
export class SurveyFacade {
  @Select(SurveyState.getState) surveyState$: Observable<ISurveyModel>
  @Select(SurveyState.getSurvey) survey$: Observable<ISurvey>
  @Select(SurveyState.getSurveyList) surveyList$: Observable<ISurvey[]>
  @Select(SurveyState.getSurveyCategories) surveyCategories$: Observable<
    ICategoryResponse[]
  >
  @Select(SurveyState.getSurveyListArchived) surveyListArchived$: Observable<
    ISurvey[]
  >
  @Select(SurveyState.getSurveyListInfo)
  surveyListInfo$: Observable<ISurveyListInfo>
  @Select(SurveyState.getLoadingSurvey) isLoadingSurvey$: Observable<boolean>
  @Select(SurveyState.getisLoadingQrSurvey) isLoadingQR$: Observable<boolean>
  @Select(SurveyState.getLoadingSurveyList)
  isLoadingSurveyList$: Observable<boolean>
  @Select(SurveyState.getIsDisabledIconSharedSurvey)
  isDisabledIconSharedSurvey$: Observable<boolean>
  @Select(SurveyState.getImageSurveyQR) imageSurveyQR$: Observable<string>
  @Select(SurveyState.getAboutSurvey)
  aboutSurvey$: Observable<IAboutSurvey[]>
  @Select(SurveyState.getErrorSurveyService)
  errorService$: Observable<IErrorSurveyService>
  @Select(SurveyState.getIsLoadingAboutSurvey)
  loadingAboutSurvey$: Observable<boolean>

  constructor(
    private store: Store,
    private _survey: SurveyService,
    private _surveyCategory: SurveyCategoryService,
    private _notification: NzNotificationService,
    private questionFacade: QuestionFacade
  ) {}

  public resetSurveyFacade(): void {
    this.store.dispatch(new UpdateSurveyList([]))
    this.store.dispatch(new UpdateSurvey(null))
    this.store.dispatch(new UpdateSurveyListInfo(null))
    this.store.dispatch(new UpdateAboutSurvey(null))
    this.store.dispatch(new UpdateIsLoadingQrSurvey(false))
    this.store.dispatch(new UpdateIsDisabledIconsShared(false))
    this.store.dispatch(new UpdateImageSurveyQR(null))
    this.store.dispatch(new UpdateAboutSurvey([]))
    this.store.dispatch(new UpdateErrorSurveyService(null))
    this.store.dispatch(
      new UpdateLoadingSectionSurvey({
        isLoadingAboutSurvey: false,
      })
    )
  }

  public getSurveyList(isArchived = false): void {
    this.store.dispatch(
      new UpdateErrorSurveyService({ errorSurveyList: false })
    )
    this.store.dispatch(new UpdateLoadingSurveyList(true))
    this._survey.listSurveys(isArchived).subscribe(
      (response: IResponse) => {
        if (response.status.code === 200) {
          this.store.dispatch(
            new UpdateSurveyList(
              response.data.surveyList.length > 0
                ? response.data.surveyList
                : null
            )
          )
          this.store.dispatch(new UpdateSurveyListInfo(response.data.info))
        }
        this.store.dispatch(new UpdateLoadingSurveyList(false))
      },
      (error) => {
        this.store.dispatch(new UpdateLoadingSurveyList(false))
        if (error.status === 500) {
          this.store.dispatch(
            new UpdateErrorSurveyService({ errorSurveyList: true })
          )
        }
      }
    )
  }

  public getSurveyCategories(): void {
    this.store.dispatch(
      new UpdateErrorSurveyService({ errorSurveyCategories: false })
    )
    this.store.dispatch(
      new UpdateLoadingSectionSurvey({ isLoadingSurveyCategories: true })
    )
    this._surveyCategory.listCategories().subscribe(
      (response: IResponse) => {
        if (response.status.code === 200) {
          this.store.dispatch(new UpdateSurveyCategories(response.data))
        }
        this.store.dispatch(
          new UpdateLoadingSectionSurvey({ isLoadingSurveyCategories: false })
        )
      },
      (error) => {
        this.store.dispatch(
          new UpdateLoadingSectionSurvey({ isLoadingSurveyCategories: false })
        )
        this.store.dispatch(
          new UpdateErrorSurveyService({ errorSurveyCategories: true })
        )
      }
    )
  }

  public filterSurveysArchived(isArchived: boolean): void {
    this.surveyList$.subscribe((surveyList: ISurvey[]) => {
      if (surveyList.length > 0) {
        let newSurveyList = [...surveyList]
        const archivedSurveyList = newSurveyList.filter(
          (survey: ISurvey) => +survey.isArchived === (isArchived ? 1 : 0)
        )
        this.store.dispatch(new UpdateSurveyList(archivedSurveyList, 0))
      }
    })
  }

  public getAboutFetch(surveyId: string = null): void {
    this.store.dispatch(
      new UpdateLoadingSectionSurvey({ isLoadingAboutSurvey: true })
    )

    this._survey.getAboutSurvey(surveyId).subscribe(
      (response: IResponse) => {
        switch (response.status.code) {
          case 200:
            this.store.dispatch(new UpdateAboutSurvey(response.data))
            break
        }
        this.store.dispatch(
          new UpdateLoadingSectionSurvey({ isLoadingAboutSurvey: false })
        )
      },
      (error) => {
        this.store.dispatch(
          new UpdateLoadingSectionSurvey({ isLoadingAboutSurvey: false })
        )
        this.store.dispatch(new UpdateAboutSurvey([]))

        if (error.status !== 400) {
          this._notification.create(
            'error',
            ERROR_SERVICE_TITLE,
            ERROR_SERVICE_MESSAGE
          )
        }
      }
    )
  }

  public getSurveyDetailFetch(surveyId: string): void {
    this.store.dispatch(new UpdateLoadingSurvey(true))

    this._survey.getSurveyDetail(surveyId).subscribe(
      (response: IResponse) => {
        switch (response.status.code) {
          case 200: {
            const survey = response.data
            const isDisabledIcons = survey.totalQuestions === 0

            this.store.dispatch(
              new UpdateIsDisabledIconsShared(isDisabledIcons)
            )
            this.store.dispatch(new UpdateSurvey(survey))
            break
          }
          case 400:
            break
        }
        this.store.dispatch(new UpdateLoadingSurvey(false))
      },
      (error: Error) => {
        this._notification.create(
          'error',
          ERROR_SERVICE_TITLE,
          ERROR_SERVICE_MESSAGE
        )
        this.store.dispatch(new UpdateLoadingSurvey(false))
      }
    )
  }

  public updateSurveyFetch(request: ISurvey): Observable<IResponse> {
    return this._survey.updateSurveyFetch(request).pipe(
      tap((response: IResponse) => {
        if (response.status.code) {
          const survey = { ...request, logoUrl: response.data.logoUrl }
          this.store.dispatch(new UpdateSurvey(survey))

          this._notification.create(
            'success',
            SUCCESS_UPDATE_SURVEY_TITLE,
            SUCCESS_UPDATE_SURVEY_MESSAGE
          )
        }
      }),
      catchError((error) => {
        switch (error.status) {
          case 406:
            this._notification.create(
              'info',
              ERROR_CREATE_SURVEY_LIMIT_TITLE,
              ERROR_CREATE_SURVEY_LIMIT_MESSAGE
            )
            break
          default:
            this._notification.create(
              'error',
              ERROR_SERVICE_TITLE,
              ERROR_SERVICE_MESSAGE
            )
            break
        }
        throw error
      })
    )
  }

  public getQRSurveyFetch(surveyId: string): void {
    this.store.dispatch(new UpdateIsLoadingQrSurvey(true))
    this._survey.QRbySurveyId(surveyId).subscribe((response: IResponse) => {
      switch (response.status.code) {
        case 200:
          this.store.dispatch(new UpdateImageSurveyQR(response.data))
          this.store.dispatch(new UpdateIsLoadingQrSurvey(false))
          break
        default:
          this._notification.create(
            'warning',
            ERROR_SERVICE_TITLE,
            ERROR_SERVICE_MESSAGE
          )
          this.store.dispatch(new UpdateIsLoadingQrSurvey(false))
          break
      }
    })
  }

  public archiveSurveyFetch(
    surveyId: string,
    isArchived: number,
    countArchivedSurvey: number
  ) {
    return this._survey.archiveSurvey(surveyId, isArchived).subscribe(
      (response: IResponse) => {
        if (response.status.code === 201) {
          this.getSurveyList(!Boolean(isArchived) && countArchivedSurvey > 1)

          this._notification.create(
            +isArchived === 1 ? 'info' : 'success',
            +isArchived === 1
              ? SUCCESS_ARCHIVED_SURVEY_TITLE
              : SUCCESS_ACTIVE_SURVEY_TITLE,
            +isArchived === 1
              ? SUCCESS_ARCHIVED_SURVEY_MESSAGE
              : SUCCESS_ACTIVE_SURVEY_MESSAGE
          )
        }
      },
      (error) => {
        this._notification.create(
          'warning',
          ERROR_SERVICE_TITLE,
          ERROR_SERVICE_MESSAGE
        )
      }
    )
  }

  public toggleSurveyFetch(surveyId: string, isActive: number) {
    return this._survey.toggleSurvey(surveyId, isActive).subscribe(
      (response: IResponse) => {
        if (response.status.code === 201) {
          this.getSurveyList()

          this._notification.create(
            +isActive === 1 ? 'success' : 'info',
            +isActive === 1
              ? SUCCESS_ACTIVE_SURVEY_TITLE
              : SUCCESS_DESACTIVE_SURVEY_TITLE,
            +isActive === 1
              ? SUCCESS_ACTIVE_SURVEY_MESSAGE
              : SUCCESS_DESACTIVE_SURVEY_MESSAGE
          )
        }
      },
      (error) => {
        this._notification.create(
          'warning',
          ERROR_SERVICE_TITLE,
          ERROR_SERVICE_MESSAGE
        )
      }
    )
  }

  public async downloadQRSurvey(): Promise<void> {
    const survey = await this.survey$.pipe(first()).toPromise()
    const imagesSurveyQR = await this.imageSurveyQR$.pipe(first()).toPromise()
    const nameQR = `${cleanText(survey.name)}_QR.png`
    FileSaver.saveAs(imagesSurveyQR, nameQR)
  }

  public sendSurveyMfConfig() {
    const mf = document.getElementById('survey-mf') as HTMLIFrameElement
    const token: string = localStorage.getItem(ACCESS_TOKEN)

    // MULTIPLE PROMISE EXAMPLE
    combineLatest([this.questionFacade.questions$]).subscribe(([questions]) => {
      const activeQuestions = questions.filter((item) => item.isActive)

      mf?.contentWindow?.postMessage(
        {
          editMode: true,
          token,
          isFirstQuestion: activeQuestions && activeQuestions.length <= 0,
          type: 'GENERAL',
        },
        '*'
      )
    })
  }
}
