import { PipesModule } from './../../pipes/pipes.module'
import { ComponentsModule } from './../../components/components.module'
import { NgZorroAntdModule } from './../../ng-zorro-antd.module'
import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { PlanComponent } from './plan.component'
import { CurrentCardPlanComponent } from './current-card-plan/curent-card-plan.component'
import { PaymentProcessComponent } from './payment/payment-process/payment-process.component'
import { PlanTypesComponent } from './plan-types/plan-types.component'
import { PlanRoutingModule } from './plan-routing.module'
import { PaymentComponent } from './payment/payment.component'
import { BillingFormComponent } from './payment/billing-form/billing-form.component'
import { BillingCompanyFormComponent } from './payment/billing-company-form/billing-company-form.component'
import { SummaryPaymentCardComponent } from './payment/summary-payment-card/summary-payment-card.component'
import { CardInfoPlansComponent } from './card-info-plans/card-info-plans.component'
import { PaymentFailedComponent } from './payment/payment-failed/payment-failed.component'
import { PaymentSuccessBillingComponent } from './payment/payment-success-billing/payment-success-billing.component'

@NgModule({
  declarations: [
    PlanComponent,
    CurrentCardPlanComponent,
    PaymentProcessComponent,
    PlanTypesComponent,
    BillingFormComponent,
    SummaryPaymentCardComponent,
    BillingCompanyFormComponent,
    PaymentComponent,
    CardInfoPlansComponent,
    PaymentFailedComponent,
    PaymentSuccessBillingComponent,
  ],
  imports: [
    CommonModule,
    NgZorroAntdModule,
    ReactiveFormsModule,
    FormsModule,
    ComponentsModule,
    PlanRoutingModule,
    PipesModule,
  ],
  exports: [CurrentCardPlanComponent, PaymentProcessComponent],
  providers: [],
})
export class PlanModule {}
