import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { first } from 'rxjs/operators'
import { SurveyFacade } from 'src/app/store/store.index'
import { ISurvey } from './../../../../models/response/ISurvey'

@Component({
  selector: 'app-shared-survey',
  templateUrl: './shared-survey.component.html',
  styleUrls: ['./shared-survey.component.scss'],
})
export class SharedSurveyComponent implements OnInit {
  public form: FormGroup
  private subjectDefault: string =
    '🚀 Participa en Nuestra Encuesta y Ayúdanos a Mejorar!'
  private messageDefault: string =
    '🌟 ¡Tu opinión es muy valiosa para nosotros! Dedica unos minutos para completar esta encuesta y contribuir a mejorar nuestros servicios y productos. Cada respuesta nos acerca más a ofrecerte exactamente lo que necesitas. ¡Gracias por tu tiempo y tus ideas! 🙏 📊'

  constructor(private fb: FormBuilder, public surveyFacade: SurveyFacade) {}

  async ngOnInit(): Promise<void> {
    const survey = (await this.surveyFacade.survey$
      .pipe(first())
      .toPromise()) as ISurvey

    this.subjectDefault = `🚀 Participa en Nuestra Encuesta ${survey.name}!`

    this.form = this.fb.group({
      mails: [null, [Validators.required, Validators.maxLength(200)]],
      subject: [this.subjectDefault, [Validators.maxLength(100)]],
      message: [this.messageDefault, [Validators.maxLength(300)]],
    })
  }

  public submitForm(): void {
    for (const i in this.form.controls) {
      this.form.controls[i].markAsDirty()
      this.form.controls[i].updateValueAndValidity()
    }

    if (this.form.valid) {
      console.log('this.form.value', this.form.value)
    }
  }
}
