import { Component, Input, OnInit } from '@angular/core'
import { FormBuilder, FormControl } from '@angular/forms'
import { ActivatedRoute } from '@angular/router'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { Subscription } from 'rxjs/Subscription'
import { debounceTime, take } from 'rxjs/operators'
import { IQuestion } from 'src/app/models/response/IQuestion'
import { SurveyService } from 'src/app/services/pages/survey.service'
import { QuestionFacade } from 'src/app/store/question/question.facade'
import { ERROR_SERVICE_TITLE } from 'src/app/utils/constants/generic-messages'
import { TABLE_TYPE, VIEW_TYPE } from 'src/app/utils/constants/question-message'
import { INFO_LIMIT_QUESTIONS_MESSAGE } from 'src/app/utils/constants/survey-messages'
import { ERROR_SERVICE_MESSAGE } from './../../../utils/constants/generic-messages'
import {
  SUCCESS_CREATE_QUESTION_MESSAGE,
  SUCCESS_CREATE_QUESTION_TITLE,
} from './../../../utils/constants/question-message'

@Component({
  selector: 'app-question-list',
  templateUrl: './question-list.component.html',
  styleUrls: ['./question-list.component.scss'],
})
export class QuestionListComponent implements OnInit {
  @Input() public surveyId: string
  @Input() public type: number = TABLE_TYPE
  @Input() public viewSearch: boolean = true

  public messageLimitQuestions = INFO_LIMIT_QUESTIONS_MESSAGE
  public isVisibleForm: boolean
  public questionList: IQuestion[]
  public searchElement: string
  public valueFilter: string

  public subscription: Subscription[] = []
  public isVisible: boolean
  public buttonDisabled = false
  public maxQuestion = 20
  public question: IQuestion

  public TABLE_TYPE = TABLE_TYPE
  public VIEW_TYPE = VIEW_TYPE

  public searchForm = this.fb.group({
    search: new FormControl(''),
  })

  constructor(
    public activateRoute: ActivatedRoute,
    private notification: NzNotificationService,
    public _surveyService: SurveyService,
    public questionFacade: QuestionFacade,
    private fb: FormBuilder
  ) {
    this.questionList = []
    this.isVisibleForm = false
    this.isVisible = false
  }

  ngOnInit(): void {
    this.questionFacade.obtainQuestionListFetch(this.surveyId)
    this.questionFacade.obtainQuestionTypesFetch()
    this.initlistener()

    window.onmessage = (e) => {
      if (e.data && e.data?.type === 'GET_QUESTIONS') {
        if (e.data?.isSuccessRequest) {
          this.questionFacade.obtainQuestionListFetch(this.surveyId)
          this.notification.create(
            'success',
            SUCCESS_CREATE_QUESTION_TITLE,
            SUCCESS_CREATE_QUESTION_MESSAGE
          )
          return
        }

        this.notification.create(
          'error',
          ERROR_SERVICE_TITLE,
          ERROR_SERVICE_MESSAGE
        )
      }
    }
  }

  public initlistener(): void {
    const inputSearch = this.searchForm.get('search')
    this.subscription.push(
      inputSearch.valueChanges.pipe(debounceTime(1000)).subscribe((value) => {
        this.valueFilter = value
        this.filterSearch()
      })
    )
  }

  showModal(): void {
    const mf = document.getElementById('survey-mf') as HTMLIFrameElement
    if (mf) {
      mf.contentWindow.postMessage(
        { isVisibleCreateForm: true, type: 'NEW_QUESTION' },
        '*'
      )
    }
  }

  handleCancel(): void {
    this.isVisible = false
  }

  public filterSearch(form?: string): void {
    const currentData = [...this.questionList]
    let filterData = currentData
    if (this.valueFilter.length > 0) {
      filterData = filterData.map((row) => {
        const findRow = Object.values(row).find((col: string) => {
          return typeof col === 'string'
            ? col.toLowerCase().includes(this.valueFilter.toLowerCase())
            : false
        })
        return findRow ? row : null
      })
      filterData = filterData.filter(Boolean)
      this.questionList = filterData
    }
  }

  public saveOrderQuestions(): void {
    this.questionFacade.questions$.pipe(take(1)).subscribe((questions) => {
      const questionOrdered = questions.map((question, index) => ({
        ...question,
        order: index + 1,
      }))
      this.questionFacade.updateQuestionOrderFetch(
        questionOrdered,
        this.surveyId
      )
    })
  }

  public discardChangesOrderQuestions(): void {
    this.questionFacade.saveMessageQuestionEditLoading(
      'Descartando los cambios...'
    )
    this.questionFacade.obtainQuestionListFetch(this.surveyId, true)
  }
}
