<nz-breadcrumb class="mb-3">
  <nz-breadcrumb-item>
    <a [routerLink]="'/app/reports'"> Reportes </a>
  </nz-breadcrumb-item>
</nz-breadcrumb>

<div class="main-report animated-fade-in">
  <div class="container-detail-report">
    <div class="row m-0">
      <div class="col-12">
        <nz-tabset
          [nzSize]="'large'"
          nzCentered
          [nzSelectedIndex]="tabSelected === 'generateReport' ? 0 : 1"
        >
          <nz-tab
            nzTitle="Generar Reportes 📊"
            class="titleTab"
            (nzSelect)="onTabSelectedChange('generateReport')"
          >
            <div class="row">
              <div class="col-12 col-lg-4 col-x2l-3 border-left-01">
                <h5 class="mb-1 mt-2">Filtros de Búsqueda Avanzada</h5>
                <span class="text-muted"
                  >Refina y personaliza los datos que deseas analizar</span
                >
                <nz-spin
                  nzTip="Obteniendo parametros"
                  [nzSpinning]="
                    (surveyFacade$.isLoadingSurveyList$ | async) === true ||
                    (questionFacade$.isLoadingCreateQuestion$ | async) === true
                  "
                >
                  <div class="report-form-container my-4">
                    <form nz-form [formGroup]="reportForm">
                      <div class="form-item">
                        <nz-form-label for="survey" nzRequired
                          >Encuesta</nz-form-label
                        >
                        <nz-form-item>
                          <nz-form-control
                            nzErrorTip="Selecciona una encuesta"
                            [nzValidateStatus]="reportForm.controls['surveyId']"
                          >
                            <nz-select
                              nzShowSearch
                              id="surveyId"
                              formControlName="surveyId"
                              nzPlaceHolder="Selecciona una encuesta"
                            >
                              <nz-option
                                *ngFor="
                                  let survey of surveyFacade$.surveyList$
                                    | async
                                "
                                [nzValue]="survey.surveyId"
                                [nzLabel]="survey.name"
                              >
                              </nz-option>
                            </nz-select>
                          </nz-form-control>
                        </nz-form-item>
                      </div>

                      <div class="form-item">
                        <nz-form-label for="question">Pregunta</nz-form-label>
                        <nz-form-item>
                          <nz-form-control
                            [nzValidateStatus]="
                              reportForm.controls['questionId']
                            "
                          >
                            <nz-select
                              [nzDisabled]="!this.reportForm.value.surveyId"
                              nzShowSearch
                              id="questionId"
                              formControlName="questionId"
                              nzPlaceHolder="Selecciona una pregunta o 'Todas'"
                            >
                              <nz-option
                                nzValue="all"
                                nzLabel="Todas las preguntas"
                              ></nz-option>
                              <nz-option
                                *ngFor="
                                  let question of questionFacade$.questions$
                                    | async
                                "
                                [nzValue]="question.questionId"
                                [nzLabel]="question.title"
                              ></nz-option>
                            </nz-select>
                          </nz-form-control>
                        </nz-form-item>
                      </div>

                      <div class="form-item">
                        <nz-form-label for="dateRange" nzRequired
                          >Rango de Fechas</nz-form-label
                        >
                        <nz-form-item>
                          <nz-form-control
                            [nzErrorTip]="'Selecciona un rango de fechas'"
                            [nzValidateStatus]="
                              reportForm.controls['dateRange']
                            "
                          >
                            <nz-range-picker
                              formControlName="dateRange"
                              [nzDisabledDate]="disabledDate"
                              (nzOnOpenChange)="onOpenChange($event)"
                              [nzFormat]="'dd/MM/yyyy'"
                              [nzRanges]="ranges"
                            >
                            </nz-range-picker>
                          </nz-form-control>
                        </nz-form-item>
                      </div>

                      <div class="form-item mt-3">
                        <button
                          type="button"
                          (click)="getReport()"
                          nz-button
                          nzType="primary"
                          class="form-button"
                        >
                          Generar Reporte
                        </button>
                      </div>
                    </form>
                  </div>
                </nz-spin>
              </div>
              <div class="col-12 col-lg-8 col-x2l-9">
                <!-- <app-breadcrumbs-report></app-breadcrumbs-report> -->
                <div
                  *ngIf="
                    (surveyFacade$.survey$ | async) !== null && isViewReport
                  "
                  class="container text-center"
                >
                  <h3 class="mb-1 mt-4">Centro de Análisis y Reportes</h3>
                  <span class="text-muted"
                    >Obtén perspectivas detalladas y toma decisiones
                    informadas</span
                  >
                  <app-survey-result
                    [(isSubmitForm)]="isSubmitForm"
                    [surveyId]="this.reportForm.value.surveyId"
                    [questionId]="this.reportForm.value.questionId"
                    [startDate]="startDate"
                    [endDate]="endDate"
                  ></app-survey-result>
                </div>

                <section
                  class="container my-5"
                  [hidden]="
                    (surveyFacade$.survey$ | async) !== null && isViewReport
                  "
                >
                  <div class="row align-items-center">
                    <div class="col-md-6">
                      <h2 class="font-weight-bold mb-3">
                        Comienza con tus Reportes
                      </h2>
                      <p class="text-muted">
                        Aplica los filtros necesarios para generar reportes
                        personalizados y obtener insights valiosos para tu
                        negocio. Selecciona una encuesta, define el rango de
                        fechas y descubre las tendencias en las respuestas de
                        tus usuarios.
                      </p>
                      <p class="text-muted">
                        Pueds activar el análisis con IA usando tus créditos de
                        IA para desbloquear análisis avanzados y descubre
                        recomendaciones estratégicas que impulsarán tu negocio.
                      </p>
                    </div>
                    <div class="col-md-6">
                      <img
                        [src]="SURVE_GRAPHIC_IMG"
                        alt="Survey Graphic"
                        class="img-fluid"
                      />
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </nz-tab>
          <nz-tab
            nzTitle="Programar Reportes 🗓️"
            (nzSelect)="onTabSelectedChange('programmerReports')"
          >
            <app-card-info
              title="Funcionalidad en Desarrollo"
              description="'Esta funcionalidad estará disponible próximamente. ¡Gracias por tu paciencia!'"
            ></app-card-info>

            <button
              nz-button
              class="my-5 btn btn-primary"
              (click)="showCreateReportModal()"
              disabled="true"
            >
              Crear Nuevo Reporte
            </button>
            <!-- <nz-table
              #scheduledReportsTable
              [nzData]="scheduledReports"
              nzResponsive
              class="mt-3"
            >
              <thead>
                <tr>
                  <th nzWidth="20%" id="reportName">Nombre del Reporte</th>
                  <th nzWidth="20%" id="surveyName">Encuesta</th>
                  <th nzWidth="10%" id="frequency">Frecuencia</th>
                  <th nzWidth="25%" id="nextExecution">Próxima Ejecución</th>
                  <th nzWidth="25%" id="acciones">Acciones</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let report of scheduledReports">
                  <td>{{ report.name }}</td>
                  <td>{{ report.surveyName }}</td>
                  <td>{{ report.frequency }}</td>
                  <td>{{ report.nextRun | date: 'medium' }}</td>
                  <td>
                    <button
                      nz-button
                      nzType="link"
                      (click)="showEditReportModal(report)"
                    >
                      <i nz-icon nzType="edit" nzTheme="outline"></i> Editar
                    </button>
                    <button
                      nz-button
                      nzType="link"
                      nzDanger
                      (click)="confirmDeleteReport(report)"
                    >
                      <i nz-icon nzType="delete" nzTheme="outline"></i> Eliminar
                    </button>
                  </td>
                </tr>
              </tbody>
            </nz-table> -->
          </nz-tab>
        </nz-tabset>
      </div>
    </div>
  </div>
</div>

<nz-modal
  [(nzVisible)]="isEditModalVisible"
  nzTitle="Editar Reporte"
  (nzOnOk)="handleEditReport()"
  (nzOnCancel)="handleCancelEditReport()"
>
  <ng-template #modalContent>
    <form nz-form [formGroup]="programerReportForm">
      <nz-form-item>
        <nz-form-label [nzFor]="'edit-name'">Nombre del Reporte</nz-form-label>
        <nz-form-control>
          <input nz-input formControlName="name" id="edit-name" />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzFor]="'edit-frequency'">Frecuencia</nz-form-label>
        <nz-form-control>
          <nz-select formControlName="frequency" id="edit-frequency">
            <!-- Opciones de frecuencia -->
          </nz-select>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzFor]="'edit-nextRun'"
          >Próxima Ejecución</nz-form-label
        >
        <nz-form-control>
          <nz-date-picker
            formControlName="nextRun"
            id="edit-nextRun"
          ></nz-date-picker>
        </nz-form-control>
      </nz-form-item>
    </form>
  </ng-template>
</nz-modal>
