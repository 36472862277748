import { optionsChartDefault } from './../../../../utils/properties/hightchartOptions'

export const chartOptionsGeneralResponses = (
  categories: string[],
  data: number[]
) => {
  return {
    lang: optionsChartDefault.lang,
    chart: {
      renderTo: 'container',
      type: 'column',
      options3d: {
        enabled: true,
        alpha: 15,
        beta: 15,
        depth: 50,
        viewDistance: 25,
      },
    },
    credits: {
      enabled: false,
    },
    yAxis: {
      title: {
        text: '<span>Cantidad de respuestas</span>',
      },
    },
    xAxis: {
      title: {
        text: '<span style="padding-top: 1.5rem;">Días de la semana</span>',
      },
      categories: categories,
    },
    tooltip: {
      headerFormat: '<b>{point.key}</b><br>',
      pointFormat: 'Cantidad de respuestas: {point.y}',
    },
    title: {
      text: 'Total de respuestas de tus encuestas',
    },
    subtitle: {
      text: '',
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      column: {
        depth: 25,
      },
    },
    series: [
      {
        data: data,
        colorByPoint: true,
        type: 'column',
      },
    ],
  }
}
