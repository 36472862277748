import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs-compat'
import { map } from 'rxjs/operators'
import { IResponse } from 'src/app/models/response/IResponse'
import { environment } from 'src/environments/environment'
import { ISendFeedback } from './../../models/request/ISendFeedback'
import { ISendSessionFeedback } from './../../models/request/ISendSessionFeedback'

@Injectable({ providedIn: 'root' })
export class FeedBackService {
  protected api: string
  constructor(public http: HttpClient) {
    this.api = environment.api
  }

  public getQuestionFeedback(): Observable<IResponse> {
    const uri = `${this.api}/v1/public/survey/${environment.survey_feedback_id}/questions`
    return this.http.get(uri).pipe(map((data: IResponse) => data))
  }

  public sendFeedBack(request: ISendFeedback): Observable<IResponse> {
    const uri = `${this.api}/v1/public/survey/questions/responses`
    return this.http.post(uri, request).pipe(map((data: IResponse) => data))
  }

  public sendSessionFeedBack(
    request: ISendSessionFeedback
  ): Observable<IResponse> {
    const uri = `${this.api}/v1/public/survey/session`
    return this.http.post(uri, request).pipe(map((data: IResponse) => data))
  }
}
