import { Injectable } from '@angular/core'
import { Select, Store } from '@ngxs/store'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { IResponse } from 'src/app/models/response/IResponse'
import { ISummary } from './../../models/request/ISummary'
import { ITrendingSummary } from './../../models/request/ITrendingSummary'
import { chartOptionsTrend } from './../../pages/survey/survey-report/mappersCharts/trending.mapper'
import { ReportService } from './../../services/pages/report.service'
import {
  UpdateIsLoadingQuestionResponses,
  UpdateIsLoadingTrendTotalResponses,
  UpdateQuestionResponsesDataReport,
  UpdateSurveyResponsesDataReport,
  UpdateTrendTotalResponsesDataReport,
} from './report.actions'
import { ReportState } from './report.state'

@Injectable({ providedIn: 'root' })
export class ReportFacade {
  @Select(ReportState.getIsLoadingQuestionResponses)
  isLoadingQuestionResponses$: Observable<boolean>
  @Select(ReportState.getQuestionResponsesDataReport)
  questionResponsesDataReport$: Observable<number[]>
  @Select(ReportState.getSurveyResponsesDataReport)
  surveyResponsesDataReport$: Observable<any>
  @Select(ReportState.getTrendTotalResponsesDataReport)
  trendTotalResponsesDataReport$: Observable<any>
  @Select(ReportState.getIsLoadingTrendTotalResponses)
  isLoadingTrendTotalResponses$: Observable<boolean>

  constructor(private store: Store, private _report: ReportService) {}

  public obtainQuestionResponsesDataReportFetch(
    questionId: string,
    startDate = '0',
    endDate = '0'
  ): void {
    this.store.dispatch(new UpdateIsLoadingQuestionResponses(true))

    this._report
      .getQuestionResponsesDataReport(questionId, startDate, endDate)
      .subscribe(
        (response: IResponse) => {
          switch (response.status.code) {
            case 200:
              if (response.data) {
                const responseData = this.getDataValues(response.data)
                this.store.dispatch(
                  new UpdateQuestionResponsesDataReport(responseData)
                )
              }
              break
            default:
              break
          }
          this.store.dispatch(new UpdateIsLoadingQuestionResponses(false))
        },
        (error: Error) => {
          this.store.dispatch(new UpdateIsLoadingQuestionResponses(false))
        }
      )
  }

  public obtainSurveyResponsesDataReportFetch(
    surveyId: string,
    startDate = '0',
    endDate = '0'
  ): void {
    this.store.dispatch(new UpdateIsLoadingQuestionResponses(true))

    this._report
      .getSurveyResponsesDataReport(surveyId, startDate, endDate)
      .subscribe(
        (response: IResponse) => {
          switch (response.status.code) {
            case 200:
              this.store.dispatch(
                new UpdateSurveyResponsesDataReport(response.data)
              )
              break
            default:
              break
          }
          this.store.dispatch(new UpdateIsLoadingQuestionResponses(false))
        },
        (error: Error) => {
          this.store.dispatch(new UpdateIsLoadingQuestionResponses(false))
        }
      )
  }

  public obtainTrendTotalResponsesDataReportFetch(
    surveyId: string,
    questionType: number = 1,
    questionId: string = null,
    groupBy: string = 'day'
  ): void {
    this.store.dispatch(new UpdateIsLoadingTrendTotalResponses(true))

    this._report
      .getTrendTotalResponsesDataReport(
        surveyId,
        questionType,
        groupBy,
        questionId
      )
      .pipe(
        map((response: IResponse) => {
          if (response.status.code === 200) {
            const dataChartRes = { ...response.data }
            const { labels, data } = dataChartRes

            return chartOptionsTrend(labels, data)
          } else {
            return null
          }
        })
      )
      .subscribe(
        (dataChart) => {
          if (dataChart) {
            this.store.dispatch(
              new UpdateTrendTotalResponsesDataReport(dataChart)
            )
          }
          this.store.dispatch(new UpdateIsLoadingTrendTotalResponses(false))
        },
        (error: Error) => {
          this.store.dispatch(new UpdateIsLoadingTrendTotalResponses(false))
        }
      )
  }

  public updateSurveyResponsesDataReport(data: any) {
    this.store.dispatch(new UpdateSurveyResponsesDataReport(data))
  }

  public getCsatSummaryFetch(request: ISummary): Observable<IResponse> {
    return this._report.getCsatSummary(request)
  }

  public getNpsSummaryFetch(request: ISummary): Observable<IResponse> {
    return this._report.getNpsSummary(request)
  }

  public getOptionsQuestionSummaryFetch(
    request: ISummary
  ): Observable<IResponse> {
    return this._report.getOptionsQuestionSummary(request)
  }

  public getCommentsSummaryFetch(request: ISummary): Observable<IResponse> {
    return this._report.getCommentsSummary(request)
  }

  public geTrendingSummarySurveyFetch(
    request: ITrendingSummary
  ): Observable<IResponse> {
    return this._report.getTrendingSummarySurvey(request)
  }

  private getDataValues(data: Data): number[] {
    return Object.values(data)
  }
}

interface Data {
  [key: string]: number
}
