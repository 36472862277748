<nz-spin [nzSpinning]="isLoadingSummary">
  <div class="card detail-question-result my-4">
    <div class="period-group-button">
      <nz-radio-group
        [(ngModel)]="typeReportWeek"
        nzButtonStyle="solid"
        (ngModelChange)="onRadioChange($event)"
      >
        <label nz-radio-button nzValue="monthly" for="monthly">Mensual</label>
        <label nz-radio-button nzValue="weekly" for="weekly">Semanal</label>
        <label nz-radio-button nzValue="daily" for="daily">Diario</label>
      </nz-radio-group>
    </div>
    <div class="row m-0 p-0">
      <div class="col-12 mb-3 mb-xl-0">
        <div class="item-summary">
          <h5 class="font-weight-bold">Opciónes más seleccionadas</h5>
          <highcharts-chart
            *ngIf="!isLoadingSummary"
            [Highcharts]="Highcharts"
            [options]="resultChartBarOptions"
            style="width: 100%; height: 495px; display: block"
          ></highcharts-chart>
        </div>
      </div>
    </div>
  </div>
</nz-spin>
