export const environment = {
  production: false,
  env: 'qa',
  api: 'https://apis-qa.empati.cl/api',
  api_jira: 'https://jira.hware.cl/api',
  domain_survey_web: 'https://survey-qa.empati.cl',
  access_token: '1t4PM3@n01ss3s',
  plan_selected_id: 'd473l3s#D1$41nP#4q',
  redux_logger: false,
  survey_feedback_id: 'K7fngn0rUPbYG489rMoP_g',
  survey_session_feedback_key: '4qk4b33f@n01ss3s',
}
