import { IBilling } from 'src/app/models/request/IBilling'
import { IResponse } from 'src/app/models/response/IResponse'
import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from 'src/environments/environment'
import { map, catchError } from 'rxjs/operators'
import { Observable, throwError } from 'rxjs'
import {
  JIRA_ISSUE_TYPE_TASK_NAME,
  JIRA_PROJECT_REQUEST_BILLING_KEY,
  JIRA_PROJECT_REQUEST_BILLING_TITLE,
} from '../../utils/constants/jira'

@Injectable({
  providedIn: 'root',
})
export class BillService {
  private apiJira: string

  constructor(public http: HttpClient) {
    this.apiJira = environment.api_jira
  }

  public billingRequest(requestBilling: IBilling): Observable<IResponse> {
    const uri = `${this.apiJira}/issue`

    const jiraTicketRequest = {
      fields: {
        project: {
          key: JIRA_PROJECT_REQUEST_BILLING_KEY,
        },
        issuetype: {
          name: JIRA_ISSUE_TYPE_TASK_NAME,
        },
        summary: `${JIRA_PROJECT_REQUEST_BILLING_TITLE} - ${requestBilling.taxRegisterNumber}`,
        customfield_10031: requestBilling.billingEmail,
        customfield_10036: requestBilling.companyName,
        customfield_10060: requestBilling.taxRegisterNumber,
        customfield_10061: requestBilling.scopeBusiness,
        customfield_10062: requestBilling.address,
      },
    }
    return this.http.post(uri, jiraTicketRequest).pipe(
      map(
        (data: IResponse) => data,
        (error: Error) => {
          console.error(error)
        }
      ),
      catchError((error) => throwError(error))
    )
  }
}
