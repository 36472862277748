import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { Select, Store } from '@ngxs/store'
import { Observable } from 'rxjs-compat'
import { ICompany } from 'src/app/models/response/ICompany'
import { IResponse } from 'src/app/models/response/IResponse'
import { IUser } from 'src/app/models/response/IUser'
import { UserService } from 'src/app/services/service.index'
import { UserState } from 'src/app/store/user/user.state'
import { COMPANY_TYPE } from './../../../utils/constants/profile'

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EditProfileComponent implements OnInit {
  @Select(UserState.getUser) user$: Observable<IUser>

  public company: ICompany
  public isCompany: boolean
  public loading: boolean

  constructor(private _user: UserService, private store: Store) {
    this.loading = true
    this.isCompany = false

    this.user$.subscribe((user: IUser) => {
      if (user && !this.company) {
        this.obtainClientUser()
        this.isCompany = user.tipoUsuario === COMPANY_TYPE
      }
    })
  }

  ngOnInit(): void {}

  public obtainClientUser(): void {
    this._user.getClientUser().subscribe(
      (response: IResponse) => {
        switch (response.status.code) {
          case 400:
            break
          case 200:
            this.company = response.data
            break
        }
        this.loading = false
      },
      (error: Error) => {
        console.error(error)
        this.loading = false
      }
    )
  }
}
