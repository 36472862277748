<header class="sticky">
  <div class="main-header row m-0 align-items-center">
    <div
      class="col-5 col-xs-7 col-sm-8 col-md-6 menu-wrapper my-auto align-self-center d-flex"
    >
      <!-- Icono -->
      <button
        class="view-btn active-view m-0 mr-3"
        nz-tooltip
        [nzTooltipTitle]="!showExpandSidebar ? 'Ver Menú' : 'Ocultar Menú'"
        nzTooltipColor="black"
        nzTooltipPlacement="bottom"
        (click)="changeShowExpandSidebar.emit()"
      >
        <span
          nz-icon
          [nzType]="!showExpandSidebar ? 'right-square' : 'left-square'"
          nzTheme="outline"
        ></span>
      </button>
      <!-- title -->
      <h4 class="m-0 title-page">{{ titlePage }}</h4>
    </div>

    <div class="col-7 col-xs-5 col-sm-4 col-md-6 align-self-center pr-0">
      <nz-avatar
        *ngIf="userFacade.isLoadingUser$ | async"
        class="d-block mx-auto"
        [nzGap]="4"
        [nzSrc]="''"
        [ngStyle]="{ 'background-color': '#5e636e' }"
        [nzSize]="40"
      ></nz-avatar>

      <div
        class="user-wrapper animated-fade-in"
        *ngIf="(userFacade.isLoadingUser$ | async) === false"
      >
        <div
          class="profile-title"
          nz-dropdown
          nzTrigger="click"
          [nzDropdownMenu]="menu"
        >
          <img [src]="(user$ | async).imagenPerfil" width="40px" alt="" />
          <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul nz-menu>
              <li class="mx-3 my-2">
                <span class="text-secondary">{{
                  (user$ | async).nombreUsuario | shortText: 0:16
                }}</span>
              </li>
              <li nz-menu-divider></li>
              <li nz-menu-item [routerLink]="['/app/profile/general']">
                <span
                  class="mr-2"
                  nz-icon
                  nzType="user"
                  nzTheme="outline"
                ></span>
                Mi Perfil
              </li>
              <li nz-menu-item (click)="logoutSession()">
                <span
                  class="mr-2"
                  nz-icon
                  nzType="logout"
                  nzTheme="outline"
                ></span>
                Cerrar Sesión
              </li>
            </ul>
          </nz-dropdown-menu>
        </div>
      </div>
      <div *ngIf="!isBetaProduction" class="mt-1 flex">
        <div class="nav-link mr-4">
          <a [routerLink]="['/app/profile']">
            <span nz-icon nzType="setting" nzTheme="outline"></span>
          </a>
        </div>
        <div class="nav-link mr-2">
          <a [routerLink]="['/app/plans-prices']">
            <span nz-icon nzType="rocket" nzTheme="outline"></span>
          </a>
        </div>
      </div>
    </div>
  </div>
</header>
