import { IPlanDetail } from './../../models/response/IPlanDetail'
import { IPlan } from 'src/app/models/response/IPlan'
export class UpdateCurrentPlan {
  static readonly type = '[PLAN] update current plan'
  constructor(public payload: IPlan) {}
}

export class UpdateSelectedPlan {
  static readonly type = '[PLAN] update selected plan'
  constructor(public payload: IPlan) {}
}

export class UpdatePlansDetail {
  static readonly type = '[PLAN] update plans detail'
  constructor(public payload: IPlanDetail[]) {}
}

export class UpdateLoadingPlan {
  static readonly type = '[PLAN] update loading PLAN'
  constructor(public payload: boolean) {}
}

export class UpdateLoadingPlansDetail {
  static readonly type = '[PLAN] update loading plans detail'
  constructor(public payload: boolean) {}
}

export class UpdateLoadingCurrentPlan {
  static readonly type = '[PLAN] update loading current plan'
  constructor(public payload: boolean) {}
}

export class UpdateErrorCurrentPlan {
  static readonly type = '[PLAN] update error current plan'
  constructor(public payload: boolean) {}
}
