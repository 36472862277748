import { NgModule } from '@angular/core'
import { NzRateModule } from 'ng-zorro-antd/rate'

import { DragDropModule } from '@angular/cdk/drag-drop'
import { NzAlertModule } from 'ng-zorro-antd/alert'
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete'
import { NzAvatarModule } from 'ng-zorro-antd/avatar'
import { NzBadgeModule } from 'ng-zorro-antd/badge'
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb'
import { NzButtonModule } from 'ng-zorro-antd/button'
import { NzCardModule } from 'ng-zorro-antd/card'
import { NzCarouselModule } from 'ng-zorro-antd/carousel'
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox'
import { NzCollapseModule } from 'ng-zorro-antd/collapse'
import { NzCommentModule } from 'ng-zorro-antd/comment'
import { NzConfigService } from 'ng-zorro-antd/core/config'
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker'
import { NzDrawerModule } from 'ng-zorro-antd/drawer'
import { NzDropDownModule } from 'ng-zorro-antd/dropdown'
import { NzEmptyModule } from 'ng-zorro-antd/empty'
import { NzFormModule } from 'ng-zorro-antd/form'
import { NzIconModule } from 'ng-zorro-antd/icon'
import { NzInputModule } from 'ng-zorro-antd/input'
import { NzModalModule } from 'ng-zorro-antd/modal'
import { NzNotificationModule } from 'ng-zorro-antd/notification'
import { NzPaginationModule } from 'ng-zorro-antd/pagination'
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm'
import { NzPopoverModule } from 'ng-zorro-antd/popover'
import { NzProgressModule } from 'ng-zorro-antd/progress'
import { NzRadioModule } from 'ng-zorro-antd/radio'
import { NzResultModule } from 'ng-zorro-antd/result'
import { NzSegmentedModule } from 'ng-zorro-antd/segmented'
import { NzSelectModule } from 'ng-zorro-antd/select'
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton'
import { NzSpinModule } from 'ng-zorro-antd/spin'
import { NzStepsModule } from 'ng-zorro-antd/steps'
import { NzSwitchModule } from 'ng-zorro-antd/switch'
import { NzTableModule } from 'ng-zorro-antd/table'
import { NzTabsModule } from 'ng-zorro-antd/tabs'
import { NzTagModule } from 'ng-zorro-antd/tag'
import { NzTimelineModule } from 'ng-zorro-antd/timeline'
import { NzToolTipModule } from 'ng-zorro-antd/tooltip'
import { NzUploadModule } from 'ng-zorro-antd/upload'

@NgModule({
  exports: [
    NzInputModule,
    NzSwitchModule,
    NzIconModule,
    NzFormModule,
    NzNotificationModule,
    NzProgressModule,
    NzModalModule,
    NzPopoverModule,
    NzTableModule,
    NzSelectModule,
    NzTabsModule,
    NzPaginationModule,
    DragDropModule,
    NzSpinModule,
    NzRadioModule,
    NzAvatarModule,
    NzDropDownModule,
    NzToolTipModule,
    NzPopconfirmModule,
    NzBadgeModule,
    NzButtonModule,
    NzDrawerModule,
    NzAlertModule,
    NzCarouselModule,
    NzSegmentedModule,
    NzBreadCrumbModule,
    NzTimelineModule,
    NzCardModule,
    NzRateModule,
    NzCollapseModule,
    NzResultModule,
    NzSkeletonModule,
    NzCommentModule,
    NzDatePickerModule,
    NzCheckboxModule,
    NzAutocompleteModule,
    NzUploadModule,
    NzEmptyModule,
    NzStepsModule,
    NzTagModule,
  ],
  providers: [],
})
export class NgZorroAntdModule {
  constructor(private nzConfigService: NzConfigService) {
    this.nzConfigService.set('notification', {
      nzPlacement: 'top',
      nzDuration: 5000,
    })
  }
}
