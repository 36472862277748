<section
  id="login"
  class="login"
  style="background-image: url('./assets/images/bg/bg-login-04.jpg') !important"
>
  <div class="login__background">
    <div class="row align-items-center h-100">
      <div
        class="col-lg-5 col-md-12 align-self-center animated fadeIn d-none d-lg-block"
      >
        <img
          src="../../assets/images/logos/light-large-logo.png"
          class="img-fluid d-block mx-auto"
          alt=""
        />
      </div>
      <div
        class="col-lg-7 col-md-12 align-self-center animated fadeIn form-col"
      >
        <div class="container">
          <div class="card p-xs-2 p-sm-5 mx-auto">
            <div *ngIf="!isValidRequest" class="card-body">
              <img
                src="../../assets/images/logos/dark-large-logo.png"
                class="img-fluid mr-auto logo-form d-none mb-2"
                alt=""
              />
              <h2 class="text-dark">Solicitud Expirada!</h2>
              <!-- <p class="text-dark mb-5">Esta solic</p> -->
            </div>
            <div *ngIf="isValidRequest" class="card-body">
              <img
                src="../../assets/images/logos/dark-large-logo.png"
                class="img-fluid mr-auto logo-form d-none mb-2"
                alt=""
              />
              <h3 class="text-dark">Actualizar contraseña</h3>
              <p class="text-dark mb-5">Actualiza contraseña {{ email }}</p>

              <form
                nz-form
                [formGroup]="validateForm"
                class="login-form"
                (ngSubmit)="submitForm()"
              >
                <!-- Password -->
                <nz-form-item class="mb-3">
                  <nz-form-control [nzErrorTip]="errorTpl" [nzSpan]="24">
                    <nz-input-group nzPrefixIcon="lock">
                      <input
                        type="password"
                        nz-input
                        formControlName="password"
                        placeholder="Contraseña"
                      />
                      <ng-template #errorTpl let-control>
                        <ng-container *ngIf="control.hasError('required')">
                          Ingresa una contraseña
                        </ng-container>
                        <ng-container *ngIf="control.hasError('pattern')">
                          Tu contraseña debe contener al menos un número, una
                          letra mayúscula, una minúscula y un largo entre 6 y 18
                          caracteres
                        </ng-container>
                      </ng-template>
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>
                <!-- RepeatPassword -->
                <nz-form-item class="mb-3">
                  <nz-form-control [nzErrorTip]="errorTpl2" [nzSpan]="24">
                    <nz-input-group nzPrefixIcon="lock">
                      <input
                        type="password"
                        nz-input
                        formControlName="repeatPassword"
                        placeholder="Repite contraseña"
                      />
                      <ng-template #errorTpl2 let-control>
                        <ng-container *ngIf="control.hasError('required')">
                          Confirma tu contraseña
                        </ng-container>
                        <ng-container *ngIf="control.hasError('confirm')">
                          Las dos contraseñas que ingresaste son inconsistentes
                        </ng-container>
                      </ng-template>
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>

                <div class="d-flex">
                  <button
                    [disabled]="loading"
                    type="submit"
                    class="btn btn-primary ml-auto"
                  >
                    <i *ngIf="loading" nz-icon [nzType]="'loading'"></i>
                    <span *ngIf="!loading">Actualizar contraseña</span>
                  </button>
                </div>

                <div class="mt-3">
                  <small class="text-muted mt-xs-2">
                    Protegido por reCAPTCHA y sujeto a
                    <a
                      class=""
                      href="https://policies.google.com/privacy?hl=es"
                      aria-label="Política de privacidad de Google"
                      rel="noopener"
                      target="_blank"
                      >Política de privacidad</a
                    >
                    y
                    <a
                      class=""
                      href="https://policies.google.com/terms?hl=es"
                      aria-label="Condiciones del servicio de Google"
                      rel="noopener"
                      target="_blank"
                      >Condiciones del servicio</a
                    >
                    de Google.
                  </small>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
