<div class="container-fluid animated-fade-in">
  <h4>Cambiar contraseña</h4>
  <div class="general-info mt-5">
    <form
      nz-form
      [formGroup]="validateForm"
      class="login-form"
      (ngSubmit)="submitForm()"
    >
      <div class="row row col-12 col-md-8">
        <div class="col-12 col-md-12">
          <!-- ActualPassword -->
          <app-input-field
            class="w-100 mb-3"
            [groupName]="validateForm"
            controlName="actPassword"
            label="Contraseña actual"
            errorMessage="Ingresa contraseña actual"
            icon="lock"
            [maxLength]="50"
            inputType="password"
          ></app-input-field>
        </div>
        <div class="col-12 col-md-12">
          <!-- NewPassword -->
          <app-input-field
            class="w-100 m-0"
            [groupName]="validateForm"
            controlName="newPassword"
            label="Nueva contraseña"
            errorMessage="Ingresa nueva contraseña"
            icon="lock"
            [maxLength]="50"
            inputType="password"
          ></app-input-field>
        </div>

        <div class="conditions">
          <ul class="conditions-list">
            <ng-container *ngFor="let condition of passwordConditions">
              <li
                [ngClass]="{
                  'green-text': condition.isValid,
                  'red-text': !condition.isValid
                }"
              >
                {{ condition.condition }}
                <span *ngIf="condition.isValid">✔</span>
              </li>
            </ng-container>
          </ul>
        </div>
        <div class="col-12 col-md-12">
          <!-- RepeatNewPassword -->
          <app-input-field
            class="w-100 mb-3"
            [groupName]="validateForm"
            controlName="repeatNewPassword"
            label="Repetir contraseña"
            errorMessage="Confirma tu contraseña"
            secondaryErrorMessage="Las dos contraseñas que ingresaste son inconsistentes"
            icon="lock"
            [maxLength]="50"
            inputType="password"
          ></app-input-field>
        </div>
      </div>

      <nz-form-item class="button-create">
        <nz-form-control>
          <button
            [disabled]="
              isLoading ||
              !hasLowerCase ||
              !hasUpperCase ||
              !hasNumber ||
              !validLength
            "
            type="submit"
            class="btn btn-primary d-block ml-auto mt-3"
          >
            <i *ngIf="isLoading" class="mr-2" nz-icon [nzType]="'loading'"></i>
            <span *ngIf="isLoading">Guardando</span>
            <span *ngIf="!isLoading">Actualizar contraseña</span>
          </button>
        </nz-form-control>
      </nz-form-item>
    </form>
  </div>
</div>
