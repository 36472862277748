<div class="animated-fade-in" *ngIf="!isLoadingQuestionList" cdkDropListGroup>
  <div
    *ngIf="questions.length > 0"
    class="question-items"
    cdkDropList
    [cdkDropListData]="questions"
    (cdkDropListDropped)="dragDropElement($event)"
  >
    <h6 *ngIf="disableQuestions(questions).length > 0" class="mb-3 ml-2">
      Preguntas activas
    </h6>
    <div *ngIf="activeQuestions(questions).length < 1 && questions.length > 0">
      <span class="mt-4 d-block text-center">
        No hay preguntas activas, agrega tu primera pregunta!</span
      >
    </div>
    <div
      *ngFor="let question of activeQuestions(questions); index as i"
      class="question-item cursor row mb-2 px-1"
      [ngClass]="{
        active:
          (questionFacade.question$ | async)?.questionId ===
            question.questionId && question.isSelected,
        'question-disabled': !question.isActive
      }"
      [class.changes-saving]="!question.isChangesSaving"
      (click)="selectItem(question)"
      cdkDrag
    >
      <div class="col-10 mb-2">
        <nz-badge
          *ngIf="!question.isChangesSaving"
          nzStatus="processing"
        ></nz-badge>
        <span class="type">
          {{ question.type }}
        </span>
      </div>
      <div class="col-2 p-0">
        <app-question-options
          [question]="question"
          [questions]="questions"
          [surveyId]="surveyId"
          confirmPlacement="right"
        ></app-question-options>
      </div>
      <div class="col-12 mb-1" (click)="selectItem(question)">
        <p class="title">{{ i + 1 }}. {{ question.title }}</p>
      </div>
    </div>

    <hr *ngIf="disableQuestions(questions).length > 0" class="mt-5 mb-3" />
    <h6 *ngIf="disableQuestions(questions).length > 0" class="mb-3 ml-2">
      Preguntas inactivas
    </h6>
    <div
      *ngFor="let question of disableQuestions(questions); index as i"
      class="question-item question-disabled row mb-2"
    >
      <div class="col-10">
        <nz-badge nzColor="#95a5a6" nzStatus="processing"></nz-badge>
        <span class="type">
          {{ question.type }}
        </span>
      </div>
      <div class="col-2 p-0">
        <app-question-options
          [question]="question"
          [questions]="questions"
          [surveyId]="surveyId"
          confirmPlacement="right"
        ></app-question-options>
      </div>
      <div class="col-12">
        <p>{{ question.title }}</p>
      </div>
    </div>
  </div>

  <div *ngIf="questions.length === 0">
    <span class="d-block text-center">
      No hay preguntas disponibles, agrega tu primera pregunta!</span
    >
  </div>

  <nz-modal
    [nzFooter]="null"
    [(nzVisible)]="isVisibleModal"
    nzTitle="Editar Pregunta"
    (nzOnCancel)="handleCancel()"
  >
    <ng-container *nzModalContent>
      <app-question-form
        *ngIf="isVisibleModal"
        (handleCancel)="handleCancel()"
        (obtainQuestionList)="obtainQuestionListFromTable()"
        [editQuestion]="true"
      ></app-question-form>
    </ng-container>
  </nz-modal>
</div>
