<form
  *ngIf="form"
  nz-form
  class="login-form survey-form"
  [formGroup]="form"
  (ngSubmit)="submitForm()"
>
  <div class="row w-100">
    <div class="col-12 p-0 pr-md-2">
      <app-select-field
        class="w-100"
        [groupName]="form"
        controlName="mails"
        [label]="'Correo(s) destino  (*)'"
        [tagMode]="true"
        placeholder="Ingresa un correo"
        errorMessage="Debes ingresar al menos un correo"
      ></app-select-field>
    </div>
    <div class="col-12 p-0">
      <app-input-field
        class="w-100"
        [groupName]="form"
        controlName="subject"
        label="Asunto (*)"
        errorMessage="Ingresa asunto para el correo"
        icon="audit"
      ></app-input-field>
    </div>
    <div class="col-12 p-0">
      <app-text-area-field
        class="w-100"
        [groupName]="form"
        [maxLength]="300"
        controlName="message"
        label="Mensaje (*)"
        errorMessage="Ingresa mensaje para el correo"
        icon="audit"
      ></app-text-area-field>
    </div>
  </div>

  <app-card-info
    title="Funcionalidad en Desarrollo"
    description="'Esta funcionalidad estará disponible próximamente. ¡Gracias por tu paciencia!'"
  ></app-card-info>
  <button
    type="button"
    class="btn btn-primary d-block ml-auto my-2 mt-4"
    (click)="submitForm()"
    [disabled]="true"
  >
    <span *ngIf="false" class="d-flex align-items-center">
      <span class="mr-2"> Enviando... </span>
      <i nz-icon [nzType]="'loading'"></i>
    </span>
    <span *ngIf="false === false" class="d-flex align-items-center">
      <span class="mr-2"> Enviar Correo </span>
      <em nz-icon nzType="mail" nzTheme="outline"></em
    ></span>
  </button>
</form>
