import { DashboardFacade } from './../../../store/dashboard/dashboard.facade'
import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-status-survey',
  templateUrl: './status-survey.component.html',
  styleUrls: ['./status-survey.component.scss'],
})
export class StatusSurveyComponent implements OnInit {
  public IMG_SURVEY = '../../../../assets/images/icons/survey-list.png'
  public IMG_RESPONSE = '../../../../assets/images/icons/response.png'
  public IMG_RESPONSE_C = '../../../../assets/images/icons/response-c.png'
  public IMG_PLAN = '../../../../assets/images/icons/information.png'
  public IMG_PLAN_C = '../../../../assets/images/icons/information-c.png'

  constructor(public dashboardFacade: DashboardFacade) {}

  ngOnInit(): void {}
}
