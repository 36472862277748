import { CommonModule } from '@angular/common'
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { ClipboardModule } from 'ngx-clipboard'
import { NgZorroAntdModule } from 'src/app/ng-zorro-antd.module'
import { QuestionModule } from '../question/question.module'
import { ComponentsModule } from './../../components/components.module'
import { PipesModule } from './../../pipes/pipes.module'
import { CardSurveyComponent } from './card-survey/card-survey.component'
import { SearchSurveyComponent } from './search-survey/search-survey.component'
import { SurveyRoutingModule } from './survey-routing.module'

import { HighchartsChartModule } from 'highcharts-angular'
import { CardInfoSurveyComponent } from 'src/app/pages/survey/card-info-survey/card-info-survey.component'
import { AdvanceSurveyFormComponent } from './advance-survey-form/advance-survey-form.component'
import { CardInfoSurveyDetailComponent } from './card-info-survey-detail/card-info-survey-detail.component'
import { SharedSurveyComponent } from './card-info-survey-detail/shared-survey/shared-survey.component'
import { HeaderSectionSurveyComponent } from './header-section-survey/header-section-survey.component'
import { DesignSurveyComponent } from './survey-detail/design-survey/design-survey.component'
import { OptionSurveyComponent } from './survey-detail/option-survey/option-survey.component'
import { SurveyDetailComponent } from './survey-detail/survey-detail.component'
import { SurveyMFComponent } from './survey-detail/survey-mf/survey-mf.component'
import { SurveyFormIAComponent } from './survey-form-ia/survey-form-ia.component'
import { SurveyFormMassiveComponent } from './survey-form-ia/survey-form-massive/survey-form-massive.component'
import { SurveyFormComponent } from './survey-form/survey-form.component'
import { SurveyReportComponent } from './survey-report/survey-report.component'
import { SurveyComponent } from './survey.component'
import { TableSurveyComponent } from './table-survey/table-survey.component'

@NgModule({
  imports: [
    CommonModule,
    NgZorroAntdModule,
    FormsModule,
    SurveyRoutingModule,
    ReactiveFormsModule,
    QuestionModule,
    ComponentsModule,
    ClipboardModule,
    PipesModule,
    HighchartsChartModule,
  ],
  declarations: [
    SurveyComponent,
    CardSurveyComponent,
    SurveyFormComponent,
    SurveyFormIAComponent,
    SurveyDetailComponent,
    CardInfoSurveyComponent,
    CardInfoSurveyDetailComponent,
    SearchSurveyComponent,
    TableSurveyComponent,
    SurveyMFComponent,
    SurveyReportComponent,
    DesignSurveyComponent,
    HeaderSectionSurveyComponent,
    SurveyFormMassiveComponent,
    OptionSurveyComponent,
    SharedSurveyComponent,
    AdvanceSurveyFormComponent,
  ],
  exports: [
    SurveyComponent,
    CardSurveyComponent,
    SurveyDetailComponent,
    CardInfoSurveyComponent,
    CardInfoSurveyDetailComponent,
    SurveyReportComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SurveyModule {}
