import { Injectable } from '@angular/core'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { IPlan } from 'src/app/models/response/IPlan'
import { IPlanDetail } from 'src/app/models/response/IPlanDetail'
import { PlanService } from 'src/app/services/service.index'
import { environment } from 'src/environments/environment'
import {
  UpdateCurrentPlan,
  UpdateErrorCurrentPlan,
  UpdateLoadingCurrentPlan,
  UpdateLoadingPlan,
  UpdateLoadingPlansDetail,
  UpdatePlansDetail,
  UpdateSelectedPlan,
} from './plan.actions'

export interface IPlanModel {
  currentPlan: IPlan
  selectedPlan: IPlan
  plansDetail: IPlanDetail[]
  isLoadingPlan: boolean
  isLoadingPlansDetail: boolean
  isLoadingCurrentPlan: boolean
  isErrorCurrentPlan: boolean
}

@State<IPlanModel>({
  name: 'plan',
  defaults: {
    currentPlan: null,
    selectedPlan: null,
    plansDetail: [],
    isLoadingPlan: true,
    isLoadingPlansDetail: true,
    isLoadingCurrentPlan: true,
    isErrorCurrentPlan: false,
  },
})
@Injectable()
export class PlanState {
  constructor(public _plan: PlanService) {}

  @Selector()
  public static getCurrentPlan(state: IPlanModel): IPlan {
    return state.currentPlan
  }

  @Selector()
  public static getSelectedPlan(state: IPlanModel): IPlan {
    if (!state.selectedPlan?.planId) {
      const selectedPlan: IPlan = {
        ...state.selectedPlan,
      }
      return selectedPlan
    }

    return state.selectedPlan
  }

  @Selector()
  public static getPlansDetail(state: IPlanModel): IPlanDetail[] {
    return state.plansDetail
  }

  @Selector()
  public static getLoadingPlan(state: IPlanModel): boolean {
    return state.isLoadingPlan
  }

  @Selector()
  public static getLoadingPlansDetail(state: IPlanModel): boolean {
    return state.isLoadingPlansDetail
  }

  @Selector()
  public static getLoadingCurrentPlan(state: IPlanModel): boolean {
    return state.isLoadingCurrentPlan
  }

  @Selector()
  public static getErrorCurrentPlan(state: IPlanModel): boolean {
    return state.isErrorCurrentPlan
  }

  @Action(UpdateCurrentPlan)
  updateCurrentPlan(
    { getState, setState }: StateContext<IPlanModel>,
    { payload }: UpdateCurrentPlan
  ): void {
    const state = getState()
    setState({
      ...state,
      currentPlan: payload,
    })
  }

  @Action(UpdateSelectedPlan)
  updateSelectedPlan(
    { getState, setState }: StateContext<IPlanModel>,
    { payload }: UpdateSelectedPlan
  ): void {
    const state = getState()
    localStorage.setItem(
      environment.plan_selected_id,
      payload.planId.toString()
    )
    setState({
      ...state,
      selectedPlan: payload,
    })
  }

  @Action(UpdatePlansDetail)
  updatePlansDetail(
    { getState, setState }: StateContext<IPlanModel>,
    { payload }: UpdatePlansDetail
  ): void {
    const state = getState()
    setState({
      ...state,
      plansDetail: payload,
    })
  }

  @Action(UpdateLoadingPlan)
  updateLoadingPlan(
    { getState, setState }: StateContext<IPlanModel>,
    { payload }: UpdateLoadingPlan
  ): void {
    const state = getState()
    setState({
      ...state,
      isLoadingPlan: payload,
    })
  }

  @Action(UpdateLoadingPlansDetail)
  updateLoadingPlansDetail(
    { getState, setState }: StateContext<IPlanModel>,
    { payload }: UpdateLoadingPlansDetail
  ): void {
    const state = getState()
    setState({
      ...state,
      isLoadingPlansDetail: payload,
    })
  }

  @Action(UpdateLoadingCurrentPlan)
  updateLoadingCurrentPlan(
    { getState, setState }: StateContext<IPlanModel>,
    { payload }: UpdateLoadingCurrentPlan
  ): void {
    const state = getState()
    setState({
      ...state,
      isLoadingCurrentPlan: payload,
    })
  }

  @Action(UpdateErrorCurrentPlan)
  updateErrorCurrentPlan(
    { getState, setState }: StateContext<IPlanModel>,
    { payload }: UpdateErrorCurrentPlan
  ): void {
    const state = getState()
    setState({
      ...state,
      isErrorCurrentPlan: payload,
    })
  }
}
