export const SUCCESS_UPDATE_QUESTION_TITLE = '¡Pregunta actualizada! 🎉'
export const SUCCESS_UPDATE_QUESTION_MESSAGE =
  '¡Tu pregunta ha sido actualizada exitosamente!'

export const SUCCESS_CREATE_QUESTION_TITLE = '¡Actualización de encuesta! 😁'
export const SUCCESS_CREATE_QUESTION_MESSAGE =
  'Se ha agregado una nueva pregunta'

export const SUCCESS_ACTIVE_QUESTION_TITLE = '¡Pregunta activada! 🟢'
export const SUCCESS_ACTIVE_QUESTION_MESSAGE =
  '¡Tu pregunta ha sido activada exitosamente!'

export const SUCCESS_DESACTIVE_QUESTION_TITLE = '¡Pregunta deshabilitada! 🔴'
export const SUCCESS_DESACTIVE_QUESTION_MESSAGE =
  '¡Tu pregunta no será visible en la encuesta!'

export const SUCCESS_ARCHIVED_QUESTION_TITLE = '¡Pregunta eliminada! 🗑️'
export const SUCCESS_ARCHIVED_QUESTION_MESSAGE =
  '¡Tu pregunta ha sido eliminada de la encuesta!'

export const SUCCESS_ORDERED_QUESTION_TITLE = '¡Nuevo Orden Establecido! 🎉'
export const SUCCESS_ORDERED_QUESTION_MESSAGE =
  '¡Has cambiado el orden de las preguntas con éxito!'

export const QUESTION_NO_DELETE_TITLE = 'No puedes eliminar esta pregunta'
export const QUESTION_NO_EDIT_TITLE = 'No puedes editar esta pregunta'
export const QUESTION_NO_EDIT_TEXT =
  'Esta pregunta ya cuenta con interacción, si no quieres mostrarla en tus encuestas, puedes deshabilitarla.'

export const TABLE_TYPE = 1
export const VIEW_TYPE = 2
