import { Directive, OnDestroy, OnInit } from '@angular/core'

@Directive({
  selector: '[appLoadVoiceflow]',
})
export class LoadVoiceflowDirective implements OnInit, OnDestroy {
  ngOnInit(): void {
    this.loadVoiceflowScript()
  }

  ngOnDestroy(): void {
    this.removeVoiceflowScript()
  }

  loadVoiceflowScript() {
    const script = document.createElement('script')
    script.src = 'https://cdn.voiceflow.com/widget/bundle.mjs'
    script.type = 'text/javascript'
    script.id = 'voiceflow-script'
    script.onload = () => {
      ;(window as any).voiceflow.chat.load({
        verify: { projectID: '66242843adc8bb3708a9f6e3' },
        url: 'https://general-runtime.voiceflow.com',
        versionID: 'production',
      })
    }
    document.body.appendChild(script)
  }

  removeVoiceflowScript() {
    const script = document.getElementById('voiceflow-script')
    if (script) {
      script.remove()
    }
  }
}
