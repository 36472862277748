import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Store } from '@ngxs/store'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { IUpdateUser } from 'src/app/models/request/IUpdateUser.js'
import { IResponse } from 'src/app/models/response/IResponse'
import { IUser } from 'src/app/models/response/IUser'
import { environment } from 'src/environments/environment'
import { IUpdateCompany } from './../../models/request/IUpdateCompany'
import { IUserTutorial } from './../../models/response/IUser'

@Injectable({ providedIn: 'root' })
export class UserService {
  protected api: string
  public user: IUser

  constructor(
    private router: Router,
    public http: HttpClient,
    private store: Store
  ) {
    this.api = environment.api
  }

  public getUser(): Observable<IResponse> {
    const uri = `${this.api}/user`
    return this.http.get(uri).pipe(map((data: IResponse) => data))
  }

  public getListUser(): Observable<IResponse> {
    const uri = `${this.api}/users`
    return this.http.get(uri).pipe(map((data: IResponse) => data))
  }

  public getClientUser(): Observable<IResponse> {
    const uri = `${this.api}/client`
    return this.http.get(uri).pipe(map((data: IResponse) => data))
  }

  public updateUser(request: IUpdateUser): Observable<IResponse> {
    const uri = `${this.api}/user`
    return this.http.put(uri, request).pipe(map((data: IResponse) => data))
  }

  public updateUserTutorial(request: IUserTutorial): Observable<IResponse> {
    const uri = `${this.api}/user/tutorial`
    return this.http.put(uri, request).pipe(map((data: IResponse) => data))
  }

  public updateClientCompany(request: IUpdateCompany): Observable<IResponse> {
    const uri = `${this.api}/client/`
    return this.http.put(uri, request).pipe(map((data: IResponse) => data))
  }
}
