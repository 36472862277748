import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

@Component({
  selector: 'app-search-survey',
  templateUrl: './search-survey.component.html',
  styleUrls: ['./search-survey.component.scss'],
})
export class SearchSurveyComponent implements OnInit {
  @Output() public obtainSurveys = new EventEmitter()
  @Input() public typeList: string
  @Input() public typeStatusSurvey: number
  @Input() public loadingSurveys: boolean
  @Input() public isCallService: boolean

  constructor() {
    this.typeStatusSurvey = 1
  }

  ngOnInit(): void {}

  public hanldeChange(type: string): void {
    this.typeList = type
  }

  public handleChangeType(typeStatusSurvey): void {
    if (!this.isCallService) {
      this.typeStatusSurvey = typeStatusSurvey
      this.obtainSurveys.emit(typeStatusSurvey)
    }
  }
}
