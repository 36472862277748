<div class="card-info-survey animated-fade-in">
  <div class="row">
    <!-- survey data -->
    <div class="col-xs-12 col-sm-6 col-xl-8">
      <div class="card-image-data">
        <div class="row m-0 h-100">
          <div class="col-3 d-none d-xl-block bg">
            <img
              class="logo-company mt-3 ml-3"
              alt=""
              src="{{ GRAPHIC_ROCKET }}"
            />
          </div>
          <div class="col-xs-12 col-xl-9 info-survey">
            <div class="info-bg">
              <div class="container-info text-center my-3 mx-5">
                <h5 class="px-3">¿Por qué optar por un plan mejorado?</h5>
                <nz-carousel
                  nzEffect="scrollx"
                  [nzAutoPlay]="true"
                  [nzAutoPlaySpeed]="5000"
                >
                  <div
                    nz-carousel-content
                    class="py-0 pt-1"
                    *ngFor="let item of dataAboutSurvey"
                  >
                    <span class="response-text">{{ item.description }}</span>
                    <span class="survey-text font-weight-normal">{{
                      item.author
                    }}</span>
                  </div>
                </nz-carousel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- survey info description -->
    <div class="col-xs-12 col-sm-6 col-xl-4">
      <div class="row info-plan h-100" *ngIf="planFacade.currentPlan$ | async">
        <div class="col-12 detail align-self-center">
          <div class="info-plan__suscription d-flex justify-content-center">
            <h5>Tu plan actual:</h5>
            <h5 class="text-plan ml-3">
              {{ (planFacade.currentPlan$ | async).name }}
            </h5>
          </div>
        </div>
        <div class="col-12 align-self-center">
          <!-- surveys -->
          <div class="progress mx-auto">
            <nz-progress
              [nzPercent]="(planFacade.currentPlan$ | async).percentSurveysUsed"
              nzSize="small"
              [nzShowInfo]="false"
              nzStrokeColor="#40189D"
            ></nz-progress>
            <span>Encuestas</span>
            <span class="float-right font-weight-bold"
              >{{ (planFacade.currentPlan$ | async).numberSurveysUsed }}/{{
                (planFacade.currentPlan$ | async).numberPlanSurveys === 0
                  ? '∞'
                  : (planFacade.currentPlan$ | async).numberPlanSurveys
              }}</span
            >
          </div>
          <!-- responses -->
          <div class="progress mx-auto">
            <nz-progress
              [nzPercent]="
                (planFacade.currentPlan$ | async).percentResponsesUsed
              "
              nzSize="small"
              [nzShowInfo]="false"
              nzStrokeColor="#40189D"
            ></nz-progress>
            <span>Respuestas</span>
            <span class="float-right font-weight-bold"
              >{{ (planFacade.currentPlan$ | async).numberResponsesUsed }}/{{
                (planFacade.currentPlan$ | async).numberPlanResponses
              }}</span
            >
          </div>
        </div>
        <div class="col-12 align-self-end d-none d-sm-block">
          <div class="d-inline icons">
            <i
              nz-icon
              class="mr-3 cursor-pointer"
              nzType="arrow-left"
              nzTheme="outline"
              (click)="prevSlide()"
            ></i>
            <i
              nz-icon
              class="cursor-pointer"
              nzType="arrow-right"
              nzTheme="outline"
              (click)="nextSlide()"
            ></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
