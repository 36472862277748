import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { SessionGuard } from '../guard/session.guard'
import { environment } from './../../environments/environment'
import { DashboardComponent } from './dashboard/dashboard.component'
import { PagesComponent } from './pages.component'
import { ReportComponent } from './report/report.component'

const routes: Routes = [
  {
    path: 'app',
    component: PagesComponent,
    children: [
      {
        path: 'dashboard',
        canActivate: [SessionGuard],
        component: DashboardComponent,
        data: { title: 'Inicio' },
      },
      {
        path: 'profile',
        canActivate: [SessionGuard],
        loadChildren: () =>
          import('./profile/profile.module').then((m) => m.ProfileModule),
        data: { title: 'Perfil' },
      },
      // {
      //   path: 'users',
      //   canActivate: [SessionGuard],
      //   component: UserListComponent,
      //   data: { title: 'Usuarios' },
      // },
      {
        path: 'survey',
        canActivate: [SessionGuard],
        loadChildren: () =>
          import('./survey/survey.module').then((m) => m.SurveyModule),
        data: { title: 'Encuestas' },
      },
      {
        path: 'reports',
        canActivate: [SessionGuard],
        component: ReportComponent,
        data: { title: 'Reportes' },
      },
      // {
      //   path: 'digital-book',
      //   canActivate: [SessionGuard],
      //   component: DigitalBookComponent,
      //   data: { title: 'Libro digital' },
      // },

      ...(!environment.production
        ? [
            {
              path: 'plans-prices',
              canActivate: [SessionGuard],
              loadChildren: () =>
                import('./plan/plan.module').then((m) => m.PlanModule),
              data: { title: 'Planes y pagos' },
            },
          ]
        : []),
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
      {
        path: '*',
        redirectTo: '',
        pathMatch: 'full',
      },
    ],
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AppRoutingPageModule {}
