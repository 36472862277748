import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { IResponse } from 'src/app/models/response/IResponse'
import { environment } from 'src/environments/environment'
import { ReportType } from './../../models/types/report.types'

@Injectable({ providedIn: 'root' })
export class DashboardService {
  protected api: string

  constructor(public http: HttpClient) {
    this.api = environment.api
  }

  public getGeneralInfo(): Observable<IResponse> {
    const uri = `${this.api}/dashboard/general-info`
    return this.http.get(uri).pipe(map((data: IResponse) => data))
  }

  public getWeekResponses(
    surveyId: string = '0',
    typeReportWeek: ReportType = 'surveys'
  ): Observable<IResponse> {
    const uri = `${this.api}/dashboard/week-responses/${surveyId}/${typeReportWeek}`
    return this.http.get(uri).pipe(map((data: IResponse) => data))
  }

  public getAllResponsesPagination(
    page: number,
    perPage: number = 5,
    questiontypeId: number = 0
  ): Observable<IResponse> {
    const questiontypeIdParam =
      questiontypeId !== 0 ? `&question_type_id=${questiontypeId}` : ''
    const uri = `${this.api}/client/responses?page=${page}&per_page=${perPage}${questiontypeIdParam}`
    return this.http.get(uri).pipe(map((data: IResponse) => data))
  }

  public getTendencyResultSurvey(
    questiontypeId: number = 1
  ): Observable<IResponse> {
    const questiontypeIdParam =
      questiontypeId !== 0 ? `&question_type_id=${questiontypeId}` : ''
    const uri = `${this.api}/client/average-weekly-responses?${questiontypeIdParam}`
    return this.http.get(uri).pipe(map((data: IResponse) => data))
  }
}
