import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-win98popup',
  templateUrl: './win98popup.component.html',
  styleUrls: ['./win98popup.component.scss'],
})
export class Win98popupComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
