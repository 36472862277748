import { Component, Input, OnInit } from '@angular/core'
import { Select } from '@ngxs/store'
import { Observable } from 'rxjs-compat'
import { SessionState } from './../../store/session/session.state'

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit {
  @Input() public bg = 'dark'
  @Input() public label = 'Cargando...'
  @Select(SessionState.getLoadingLogout)
  loadingLogout$: Observable<boolean>

  public logo = `./assets/images/logos/${this.bg}-large-logo.png`

  ngOnInit(): void {
    if (this.bg === 'dark') {
      this.logo = `./assets/images/logos/light-large-logo.png`
    } else {
      this.logo = `./assets/images/icons/survey.gif`
    }
  }
}
