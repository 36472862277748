import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core'
import { FormGroup } from '@angular/forms'
import { ActivatedRoute } from '@angular/router'
import { NzCarouselComponent } from 'ng-zorro-antd/carousel'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { ClipboardService } from 'ngx-clipboard'
import { first } from 'rxjs/operators'
import { ISurvey } from 'src/app/models/response/ISurvey'
import {
  SUCCESS_COPY_SURVEY_MESSAGE,
  SUCCESS_COPY_SURVEY_TITLE,
} from 'src/app/utils/constants/survey-messages'
import { SurveyFacade } from '../../../store/survey/survey.facade'
import { environment } from './../../../../environments/environment'

const ENV = environment.production ? 'PROD' : 'QA'

@Component({
  selector: 'app-card-info-survey-detail',
  templateUrl: './card-info-survey-detail.component.html',
  styleUrls: ['./card-info-survey-detail.component.scss'],
})
export class CardInfoSurveyDetailComponent implements OnInit {
  @Output() viewSurveyTypeChange = new EventEmitter<number>()
  @Output() selectedChange = new EventEmitter<string>()

  @Input() surveyId: string
  @Input() viewSurveyType: number

  @ViewChild(NzCarouselComponent, { static: false })
  carouselSurvey: NzCarouselComponent

  public survey: ISurvey
  public LOGO_COMPANY = './assets/images/logos/light-large-logo.png'
  public THINK_GRAPHIC_IMG = './assets/images/graphics/business-3d-detail-2.png'
  public THINK_GRAPHIC_2_IMG = './assets/images/graphics/business-3d-survey.png'

  public isVisibleForm: boolean
  public formEdit: FormGroup
  public loadingDownloadQR: boolean
  public isVisibleModal: boolean
  public isVisibleMailSharedModal: boolean
  public disabledIcon: boolean

  constructor(
    private _clipboardService: ClipboardService,
    private notification: NzNotificationService,
    public activateRoute: ActivatedRoute,
    public surveyFacade: SurveyFacade
  ) {
    this.isVisibleModal = false
    this.isVisibleMailSharedModal = false
  }

  ngOnInit(): void {
    this.surveyFacade.getSurveyDetailFetch(this.surveyId)
    this.surveyFacade.getAboutFetch(this.surveyId)
  }

  public async copyUrlShared(): Promise<void> {
    const survey = await this.surveyFacade.survey$.pipe(first()).toPromise()
    const copyUrl = this._clipboardService.copyFromContent(survey.urlShared)
    if (copyUrl) {
      this.notification.create(
        'success',
        SUCCESS_COPY_SURVEY_TITLE,
        SUCCESS_COPY_SURVEY_MESSAGE
      )
    }
  }

  public showQrSurvey(): void {
    this.isVisibleForm = true
    this.surveyFacade.getQRSurveyFetch(this.surveyId)
  }

  public downloadQrSurvey(): void {
    this.isVisibleForm = true
    this.surveyFacade.downloadQRSurvey()
  }

  //TODO: Redirect to resultsurvey
  public viewResults(): void {
    this.viewSurveyTypeChange.emit(1)
    this.selectedChange.emit('questions')
  }

  handleCancel(): void {
    this.isVisibleForm = false
    this.showMailSharedSurvey(false)
  }

  handleOk(): void {
    this.isVisibleForm = false
    this.showMailSharedSurvey(false)
  }

  showModal(): void {
    this.isVisibleModal = true
  }

  handleCancelModal(): void {
    this.isVisibleModal = false
  }

  public nextSlide(): void {
    this.carouselSurvey.next()
  }

  public prevSlide(): void {
    this.carouselSurvey.pre()
  }

  public showMailSharedSurvey(isVisible: boolean): void {
    this.isVisibleMailSharedModal = isVisible
    if (isVisible) {
      this.surveyFacade.getQRSurveyFetch(this.surveyId)
    }
  }
}
