<div class="billing">
  <div id="invoice" class="">
    <div class="header">
      <div class="row mx-0 mb-3">
        <div class="col-12 mb-4">
          <div class="text-detail-success">
            <span
              class="d-block text-center icon-success mb-3"
              nz-icon
              [nzType]="'check-circle'"
              [nzTheme]="'twotone'"
              [nzTwotoneColor]="'#52c41a'"
            ></span>
            <span class="title text-center"
              >¡Tu pago ha sido recibido con éxito!</span
            >
            <span class="desc d-block text-center"
              >A continuación te mostramos el detalle de tu compra</span
            >
          </div>
        </div>
        <div id="company" class="col-12 col-sm-5 col-lg-6 mt-3">
          <div><span>Humanware SPA</span></div>
          <div>
            Los Militares 5620, of. 906,<br />
            Santiago de Chile
          </div>
          <div><a href="mailto:hola@hware.cl">hola@hware.cl</a></div>
        </div>
        <div id="project" class="col-12 col-sm-7 col-lg-6 mt-3">
          <div><span>Cliente</span> {{ infoPayment.billingDetail.name }}</div>
          <div>
            <span>Dirección</span> {{ infoPayment.billingDetail.address }}
          </div>
          <div>
            <span>Email</span>
            <a href="mailto:{{ infoPayment.billingDetail.email }}">
              {{ infoPayment.billingDetail.email }}</a
            >
          </div>
          <div>
            <span>Fecha</span>
            {{ infoPayment.paymentDate | date: 'dd-MM-YYYY' }}
            {{ infoPayment.paymentDate | date: 'HH:mm a' }}
          </div>
        </div>
      </div>
    </div>

    <table aria-describedby="order" class="order px-2">
      <tbody>
        <tr>
          <td style="width: 33.3%; padding-left: 20px">
            <div class="title">Número de pedido</div>
            <div class="value-order">ORD_{{ infoPayment.paymentId }}</div>
          </td>
          <td style="text-align: center; width: 33.3%">
            <div class="title">Método de pago</div>
            <div class="value">WebPay</div>
          </td>
          <td style="text-align: right; width: 33.3%; padding-right: 20px">
            <div class="title">Total</div>
            <div class="value">${{ infoPayment.total | numberFormat }}</div>
          </td>
        </tr>
      </tbody>
    </table>

    <h5>Detalle pedido</h5>
    <div class="payment-detail-container">
      <div class="resume-payment">
        <div class="resume-datail">
          <div class="title">Sevicio</div>
          <div class="name">Empati</div>
        </div>
        <div class="resume-datail">
          <div class="title">Cliente</div>
          <div class="name">{{ infoPayment.billingDetail.name }}</div>
        </div>
      </div>
      <div style="overflow: auto">
        <table aria-describedby="payment">
          <thead>
            <tr>
              <th class="th-0"></th>
              <th class="product-name">Descripción</th>
              <th class="quantity">Unidad</th>
              <th class="price">Precio</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="icon-product-container mx-auto">
                <img
                  alt="empati"
                  class="icon-product m-auto"
                  src="./assets/images/logos/light-xs-logo.png"
                />
              </td>
              <td class="desc">
                Plan {{ infoPayment.planName }} ({{
                  infoPayment.typeSuscription
                }})
              </td>
              <td class="quantity">
                {{ infoPayment.quantity }}
              </td>
              <td class="product-price">
                ${{ infoPayment.subTotal | numberFormat }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="detail-total-price">
        <div class="mt-3">
          <span clas="total-label">Subtotal:</span>
          <span class="total-value">
            ${{ infoPayment.subTotal | numberFormat }}</span
          >
        </div>
        <div class="mt-1">
          <span clas="total-label">Impuestos (IVA):</span>
          <span class="total-value">
            ${{ infoPayment.iva | numberFormat }}</span
          >
        </div>
        <div class="mt-1">
          <span clas="total-label">Total:</span>
          <span class="total-value">
            ${{ infoPayment.total | numberFormat }}</span
          >
        </div>
      </div>
    </div>
  </div>
  <div class="my-3 mx-auto">
    <button
      type="button"
      class="btn btn-primary"
      (click)="downloadInvoice()"
      [disabled]="isLoadingDownloadButton"
    >
      <span *ngIf="!isLoadingDownloadButton"> Descargar Comprobante </span>
      <span *ngIf="isLoadingDownloadButton">
        Obteniendo comprobante
        <i nz-icon class="ml-2" [nzType]="'loading'"></i>
      </span>
    </button>
    <button
      type="button"
      class="btn btn-primary mx-3"
      [routerLink]="'/app/profile/my-payments'"
    >
      <span> Ir a mis Pagos </span>
    </button>
    <button
      type="button"
      class="btn btn-primary"
      [routerLink]="'/app/dashboard'"
    >
      <span> Ir al inicio </span>
    </button>
  </div>
</div>
<app-loader
  *ngIf="isLoadingDownloadButton"
  [bg]="'light'"
  [label]="'Procesando pago...'"
></app-loader>
