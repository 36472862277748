import { Injectable } from '@angular/core'
import { Select, Store } from '@ngxs/store'
import { Observable } from 'rxjs-compat'
import { finalize } from 'rxjs/operators'
import { IResponse } from 'src/app/models/response/IResponse'
import { ResponsesService } from 'src/app/services/pages/responses.service'
import {
  UpdateIsLoadingLastResponses,
  UpdateLastResponses,
  UpdatePage,
} from './responses.actions'
import { ResponsesState } from './responses.state'

@Injectable({ providedIn: 'root' })
export class ResponsesFacade {
  @Select(ResponsesState.getLastResponses) lastResponses$: Observable<any>
  @Select(ResponsesState.getResponses) responses$: Observable<any>
  @Select(ResponsesState.getCurrentPage) currentPage$: Observable<any>
  @Select(ResponsesState.getIsLoadingLastResponses)
  isLoadingLastResponses$: Observable<any>

  constructor(private store: Store, private _responses: ResponsesService) {}

  public getLastResponses(page: number = 1, perPage: number = 5): Promise<any> {
    this.store.dispatch(new UpdateIsLoadingLastResponses(true))
    return new Promise((resolve, reject) => {
      this._responses
        .getLastResponses(page, perPage)
        .pipe(
          finalize(() => {
            this.store.dispatch(new UpdateIsLoadingLastResponses(false))
          })
        )
        .subscribe(
          (responses: IResponse) => {
            switch (responses.status.code) {
              case 200:
                this.store.dispatch(new UpdatePage(responses.data.currentPage))
                this.store.dispatch(new UpdateLastResponses(responses.data))
                resolve(responses)
                break
              default:
                reject(responses)
                break
            }
          },
          (error) => reject(error)
        )
    })
  }
}
