import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit {
  @Output() public handleChange: EventEmitter<void> = new EventEmitter()

  @Input() public text: string
  @Input() public actionText: string
  @Input() public counterActionEnabled: number = null

  constructor() {}

  ngOnInit(): void {}
}
