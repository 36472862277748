export const optionsChartDefault = {
  lang: {
    downloadCSV: 'Descarga CSV',
    downloadPDF: 'Descarga PDF',
    downloadXLS: 'Descarga XLS',
    downloadPNG: 'Descargar imagen PNG',
    downloadJPEG: 'Descargar imagen JPEG',
    downloadSVG: 'Descargar imagen SVG',
    viewFullscreen: 'Ver en pantalla completa',
    printChart: 'Imprimir gráfico',
    viewData: 'Ver en una tabla',
    noData: 'Al parecer no haz tenido interacciones',
  },
}
