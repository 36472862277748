<div class="csat-icon">
  <span
    nz-icon
    [nzType]="getCsatIcon()"
    [nzTheme]="'twotone'"
    [nzTwotoneColor]="getCsatColor()"
    nz-tooltip
    [nzTooltipTrigger]="'hover'"
    [nzTooltipPlacement]="'top'"
    [nzTooltipTitle]="tootltipScore"
  ></span>
</div>
<div class="csat-score" *ngIf="csatType === 2">{{ csatScore }}</div>
