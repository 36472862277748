import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { Store } from '@ngxs/store'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { IResponse } from 'src/app/models/response/IResponse'
import { FormatType, ISurvey } from 'src/app/models/response/ISurvey'
import { SurveyService } from 'src/app/services/pages/survey.service'
import { UpdateSurvey } from 'src/app/store/survey/survey.actions'
import { ERROR_SERVICE_TITLE } from 'src/app/utils/constants/generic-messages'
import { SUCCESS_CREATE_SURVEY_TITLE } from 'src/app/utils/constants/survey-messages'
import { SurveyFacade } from './../../../store/survey/survey.facade'
import { celebrate } from './../../../utils/animations/confetti'
import { ERROR_SERVICE_MESSAGE } from './../../../utils/constants/generic-messages'
import {
  ERROR_CREATE_SURVEY_EXIST_MESSAGE,
  ERROR_CREATE_SURVEY_EXIST_TITLE,
  ERROR_CREATE_SURVEY_LIMIT_MESSAGE,
  ERROR_CREATE_SURVEY_LIMIT_TITLE,
  SUCCESS_CREATE_SURVEY_MESSAGE,
  SUCCESS_UPDATE_SURVEY_MESSAGE,
  SUCCESS_UPDATE_SURVEY_TITLE,
} from './../../../utils/constants/survey-messages'

const CATEGORIES = [
  'Opinión de Clientes',
  'Prueba de conceptos, productos, servicios',
  'Seguimiento o reconocimiento de marca',
  'Investigación de mercado',
  'Desempeño empleados',
  'Retroalimentación general',
  'Registro para evento',
  'Investigación academica',
  'Votación',
  'Test',
  'Otra',
]

export const SURVEY_FORMAT = [
  'Wizard (Paso a paso)',
  'Página única (Todo en una página)',
]

export const SURVEY_FORMAT_TYPES = [
  { id: 'layout-1', label: 'Wizard (Paso a paso)' },
  { id: 'layout-3', label: 'Página única (Todo en una página)' },
]

@Component({
  selector: 'app-survey-form',
  templateUrl: './survey-form.component.html',
  styleUrls: ['./survey-form.component.scss'],
})
export class SurveyFormComponent implements OnInit {
  public surveyTitle: string

  @Output() handleCancel = new EventEmitter()
  @Output() handleModal = new EventEmitter<void>()
  @Output() formChange = new EventEmitter<FormGroup>()
  @Input() survey: ISurvey
  @Input() editSurvey: boolean
  @Input() isGenerateAction: boolean = true
  @Input() form: FormGroup
  @Input() isCloneSurvey: boolean = false

  public loading: boolean
  public nameButton: string
  public categories = CATEGORIES
  public surveyFormat = SURVEY_FORMAT
  public surveyFormatType: FormatType = 'layout-1'

  constructor(
    public router: Router,
    private fb: FormBuilder,
    private surveyFacade: SurveyFacade,
    private _survey: SurveyService,
    private _notification: NzNotificationService,
    private store: Store
  ) {
    this.loading = false
    this.editSurvey = false
  }

  ngOnInit(): void {
    this.initializeForm()
    if (this.editSurvey === true) {
      this.loadData()
    }
    this.trackFormChanges()
    this.nameButton =
      this.editSurvey === true ? 'Guardar Encuesta' : 'Continuar'

    if (this.form) {
      this.form.valueChanges.subscribe((values) => {
        this.formChange.emit(this.form)
      })
    }
  }

  private initializeForm(): void {
    if (this.form) return
    this.form = this.fb.group({
      name: [null, [Validators.required]],
      description: [null, [Validators.required]],
      surveyFormat: [null, []],
      hasDigitalBook: [false, Validators.required],
      isRepeatModeSurvey: [false, Validators.required],
      isTabletModeSurvey: [false, Validators.required],
      isActiveRedirectUrl: [false, Validators.required],
      redirectUrl: [null, [Validators.maxLength(50)]],
    })
  }

  private loadData(): void {
    if (!this.survey) return
    let surveyCloneName = this.survey.name ?? null
    surveyCloneName = surveyCloneName + ' - (Copia)'

    this.form.patchValue({
      name: this.isCloneSurvey ? surveyCloneName : this.survey?.name,
      description: this.survey?.description,
      surveyFormat: this.getSurveyFormatType(this.survey?.formatType),
      hasDigitalBook: this.survey?.hasDigitalBook,
      isRepeatModeSurvey: this.survey?.isRepeatModeSurvey,
      isTabletModeSurvey: this.survey?.isTabletModeSurvey,
      isActiveRedirectUrl: this.survey?.isActiveRedirectUrl,
      redirectUrl: this.survey?.redirectUrl,
    })
  }

  private getSurveyFormatType(formatType: string): string {
    return SURVEY_FORMAT_TYPES.find(
      (surveyFormatType) => surveyFormatType.id === formatType
    )?.label
  }

  private trackFormChanges(): void {
    this.form
      .get('isTabletModeSurvey')
      .valueChanges.subscribe((isTabletMode) => {
        if (isTabletMode) {
          this.form
            .get('isActiveRedirectUrl')
            .setValue(false, { emitEvent: false })
          this.form
            .get('isRepeatModeSurvey')
            .setValue(false, { emitEvent: false })
        }
      })

    this.form
      .get('isActiveRedirectUrl')
      .valueChanges.subscribe((isActiveRedirect) => {
        if (isActiveRedirect) {
          this.form
            .get('isTabletModeSurvey')
            .setValue(false, { emitEvent: false })
        }
      })

    this.form
      .get('isRepeatModeSurvey')
      .valueChanges.subscribe((isActiveRedirect) => {
        if (isActiveRedirect) {
          this.form
            .get('isTabletModeSurvey')
            .setValue(false, { emitEvent: false })
        }
      })
  }

  public setFormatType(formatType: FormatType): void {
    this.surveyFormatType = formatType
  }

  public submitForm(): void {
    for (const i in this.form.controls) {
      this.form.controls[i].markAsDirty()
      this.form.controls[i].updateValueAndValidity()
    }

    if (this.form.valid)
      return this.editSurvey
        ? this.updateSurveyFetch()
        : this.createSurveyFetch()
  }

  public handleAction(): void {
    this.handleModal.emit()
  }

  private updateSurveyFetch(): void {
    const requestEdit: ISurvey = {
      ...this.survey,
      surveyId: this.survey.surveyId,
      name: this.form.get('name')?.value,
      description: this.form.get('description')?.value,
      formatType: this.surveyFormatType,
      hasDigitalBook: this.form.get('hasDigitalBook').value,
      isRepeatModeSurvey: this.form.get('isRepeatModeSurvey').value,
      isTabletModeSurvey: this.form.get('isTabletModeSurvey').value,
      isActiveRedirectUrl: this.form.get('isActiveRedirectUrl').value,
      redirectUrl: this.form.get('redirectUrl').value,
    }
    this.loading = true
    this.form.disable()

    this._survey.updateSurveyFetch(requestEdit).subscribe(
      (response: IResponse) => {
        switch (response.status.code) {
          case 200:
            this.store.dispatch(
              new UpdateSurvey({
                ...this.survey,
                name: requestEdit.name,
                description: requestEdit.description,
              })
            )
            celebrate()
            this._notification.create(
              'success',
              SUCCESS_UPDATE_SURVEY_TITLE,
              SUCCESS_UPDATE_SURVEY_MESSAGE
            )
            break
          case 400:
            this._notification.create(
              'warning',
              ERROR_SERVICE_TITLE,
              ERROR_SERVICE_MESSAGE
            )
            break
        }
        this.loading = false
        this.form.enable()
        this.handleCancel.emit()
      },
      (error: Error) => {
        this._notification.create(
          'error',
          ERROR_SERVICE_TITLE,
          ERROR_SERVICE_MESSAGE
        )
        this.loading = false
        this.form.enable()
        this.handleCancel.emit()
      }
    )
  }

  private createSurveyFetch(): void {
    if (this.form.valid) {
      const requestCreate: ISurvey = {
        name: this.form.get('name')?.value,
        description: this.form.get('description')?.value,
        formatType: this.surveyFormatType,
        hasDigitalBook: this.form.get('hasDigitalBook').value,
        isRepeatModeSurvey: this.form.get('isRepeatModeSurvey').value,
        isTabletModeSurvey: this.form.get('isTabletModeSurvey').value,
        isActiveRedirectUrl: this.form.get('isActiveRedirectUrl').value,
        redirectUrl: this.form.get('redirectUrl').value,
      }

      this.loading = true
      this._survey.createSurvey(requestCreate).subscribe(
        (response: IResponse) => {
          if (response.status.code) {
            try {
              this.router.navigate([`/app/survey/${response.data.surveyId}`])
              this._notification.create(
                'success',
                SUCCESS_CREATE_SURVEY_TITLE,
                SUCCESS_CREATE_SURVEY_MESSAGE
              )
              this.loading = false
              this.handleCancel.emit()
              celebrate()
              return
            } catch {
              this.router.navigate([`/app/survey`])
            }
          }
        },
        (error) => {
          switch (error.status) {
            case 406:
              this._notification.create(
                'info',
                ERROR_CREATE_SURVEY_LIMIT_TITLE,
                ERROR_CREATE_SURVEY_LIMIT_MESSAGE
              )
              break
            case 409:
              this._notification.create(
                'info',
                ERROR_CREATE_SURVEY_EXIST_TITLE,
                ERROR_CREATE_SURVEY_EXIST_MESSAGE
              )
              break
            default:
              this._notification.create(
                'error',
                ERROR_SERVICE_TITLE,
                ERROR_SERVICE_MESSAGE
              )
              break
          }
          this.loading = false
        }
      )
    }
  }
}
