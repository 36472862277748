import { IAboutSurvey } from 'src/app/models/response/IAboutSurvey'
import { ISurvey } from 'src/app/models/response/ISurvey'
import { ICategoryResponse } from './../../models/response/ICategoryResponse'
import { ISurveyListInfo } from './../../models/response/ISurveyListInfo'
import { IErrorSurveyService, ILoadingSurvey } from './survey.state'

export class UpdateSurvey {
  static readonly type = '[SURVEY] update header'
  constructor(public payload: ISurvey) {}
}

export class UpdateLoadingSurvey {
  static readonly type = '[SURVEY] update loading survey'
  constructor(public payload: boolean) {}
}

export class UpdateLoadingSurveyList {
  static readonly type = '[SURVEY] update loading survey list'
  constructor(public payload: boolean) {}
}

export class UpdateIsLoadingQrSurvey {
  static readonly type = '[SURVEY] update is Loading QR'
  constructor(public payload: boolean) {}
}

export class UpdateSurveyList {
  static readonly type = '[SURVEY] update survey list'
  constructor(public payload: ISurvey[], public isArchived: number = 0) {}
}

export class UpdateSurveyCategories {
  static readonly type = '[SURVEY] update survey categories'
  constructor(public payload: ICategoryResponse[]) {}
}

export class UpdateSurveyListInfo {
  static readonly type = '[SURVEY] update survey list info'
  constructor(public payload: ISurveyListInfo) {}
}

export class UpdateAboutSurvey {
  static readonly type = '[SURVEY] update survey about'
  constructor(public payload: IAboutSurvey[]) {}
}

export class UpdateIsDisabledIconsShared {
  static readonly type = '[SURVEY] update is disabled icons shared'
  constructor(public payload: boolean) {}
}

export class UpdateImageSurveyQR {
  static readonly type = '[SURVEY] update image survey QR'
  constructor(public payload: string) {}
}

export class UpdateErrorSurveyService {
  static readonly type = '[SURVEY] update error service suervey'
  constructor(public payload: IErrorSurveyService) {}
}

export class UpdateLoadingSectionSurvey {
  static readonly type = '[SURVEY] update loading section survey'
  constructor(public payload: ILoadingSurvey) {}
}
