<!-- <div class="preview-device">
  <nz-radio-group class="d-block text-center" [(ngModel)]="deviceSelected">
    <button
      nz-button
      nzSize="large"
      (click)="deviceSelected = 'mobile'"
      [nzType]="deviceSelected === 'mobile' ? 'default' : 'primary'"
      class="ml-2 d-none d-xl-inline"
      nzShape="circle"
      nzTooltipTitle="Visualiza tu encuesta en Mobile"
      nzTooltipPlacement="bottom"
      nz-button
      nz-tooltip
    >
      <span nz-icon nzType="mobile"></span>
    </button>
    <button
      nz-button
      nzSize="large"
      (click)="deviceSelected = 'tablet'"
      [nzType]="deviceSelected === 'tablet' ? 'default' : 'primary'"
      class="ml-2 d-none d-xl-inline"
      nzShape="circle"
      nzTooltipTitle="Visualiza tu encuesta en Tablet"
      nzTooltipPlacement="bottom"
      nz-button
      nz-tooltip
    >
      <span nz-icon nzType="tablet"></span>
    </button>
    <button
      nz-button
      nzSize="large"
      (click)="deviceSelected = 'laptop'"
      [nzType]="deviceSelected === 'laptop' ? 'default' : 'primary'"
      class="ml-2 d-none d-x2l-inline"
      nzShape="circle"
      nzTooltipTitle="Visualiza tu encuesta en computador"
      nzTooltipPlacement="bottom"
      nz-button
      nz-tooltip
    >
      <span nz-icon nzType="desktop"></span>
    </button>
  </nz-radio-group>
</div> -->

<div [class]="'browser ' + deviceSelected">
  <!-- <div class="searchbar">
    <form>
      <input type="url" class="url" value="Vista modo edición" disabled />
    </form>
  </div> -->
  <div class="survey-mf">
    <iframe
      #iframe
      (load)="surveyFacade.sendSurveyMfConfig()"
      id="survey-mf"
      [src]="url | safe"
      title="Previsualización de encuesta"
      allowfullscreen
    ></iframe>
  </div>
</div>
