import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { IQuestion } from 'src/app/models/response/IQuestion'

@Component({
  selector: 'app-csat-mobile',
  templateUrl: './csat-mobile.component.html',
  styleUrls: ['./csat-mobile.component.scss'],
})
export class CsatMobileComponent implements OnInit {
  @Output() handleShow = new EventEmitter<void>()
  @Output() handleSubmit = new EventEmitter<void>()
  @Output() responseChange = new EventEmitter<number>()
  @Output() commentChange = new EventEmitter<string | null>()
  @Input() response: number | string | null = null
  @Input() comment: string | null = null
  @Input() question: IQuestion

  constructor() {}

  ngOnInit(): void {
    this.response = +this.response || null
  }

  getCsatColor(index: number): string {
    const colors = ['#f44336', '#f44336', '#ffc107', '#4caf50', '#4caf50']
    return colors[+index]
  }

  getCsatClass(index: number): string {
    switch (index) {
      case 0:
        return 'frown'
      case 1:
        return 'meh'
      case 2:
        return 'meh'
      case 3:
        return 'smile'
      case 4:
        return 'smile'
      default:
        return ''
    }
  }

  handleChange(index: number): void {
    this.handleSubmit.emit()
    this.responseChange.emit(index + 1)
  }
}
