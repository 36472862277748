<div class="item-summary">
  <div *ngIf="summaryResult?.totalComments > 0" class="row m-0 mt-3 m-xl-0">
    <div *ngIf="isValidDonusChartData" class="col-12">
      <h5 class="font-weight-bold mb-0">Análisis de Comentarios</h5>
      <span class="text-muted"
        >Un vistazo a la efectividad y participación de los usuarios</span
      >
      <div class="item-indicator">
        <span class="label">
          <nz-badge nzStatus="default"></nz-badge>Total de Comentarios:
        </span>
        <span class="value-score">{{ summaryResult?.totalComments }}</span>
      </div>
      <div class="item-indicator">
        <span class="label">
          <nz-badge nzStatus="default"></nz-badge>Comentarios Relevantes:
          <em
            class="ml-2"
            nz-icon
            nzType="info-circle"
            nzTheme="twotone"
            nz-tooltip
            nzTooltipTitle="Comentarios Relevantes: Estos comentarios aportan información valiosa y constructiva. Son indicativos de un compromiso significativo de los participantes con la encuesta."
            nzTooltipColor="black"
          ></em>
        </span>
        <span class="value-score">{{ summaryResult?.relevantComments }}</span>
      </div>
      <div class="item-indicator">
        <span class="label">
          <nz-badge nzStatus="default"></nz-badge>Comentarios No Relevantes:
          <em
            class="ml-2"
            nz-icon
            nzType="info-circle"
            nzTheme="twotone"
            nz-tooltip
            nzTooltipTitle="Comentarios No Relevantes: Esta categoría incluye comentarios que no aportan información útil para el análisis o que son demasiado vagos para interpretar."
            nzTooltipColor="black"
          ></em>
        </span>
        <span class="value-score">{{
          summaryResult?.nonRelevantComments
        }}</span>
      </div>
      <div class="item-indicator">
        <span class="label">
          <nz-badge nzStatus="default"></nz-badge>Comentarios No Contestados:
          <em
            class="ml-2"
            nz-icon
            nzType="info-circle"
            nzTheme="twotone"
            nz-tooltip
            nzTooltipTitle="Comentarios No Contestados: Refleja el número de participantes que eligieron no dejar comentarios. Puede ser un indicador de la rapidez con la que los encuestados completaron la encuesta."
            nzTooltipColor="black"
          ></em>
        </span>
        <span class="value-score">{{ summaryResult?.unansweredComments }}</span>
      </div>
    </div>
    <div *ngIf="isValidDonusChartData" class="col-12 col-x2l-6">
      <div class="mt-4">
        <h5 class="font-weight-bold mb-0">
          Desglose de Comentarios por Categoría
        </h5>
        <span class="text-muted"
          >Proporción de comentarios relevantes, no relevantes y no
          contestados</span
        >
        <highcharts-chart
          [Highcharts]="Highcharts"
          [options]="donusChartOptionsComments"
          style="width: 100%; height: 250px; display: block"
        ></highcharts-chart>
      </div>
    </div>
    <div *ngIf="!isValidDonusChartData" class="col-12">
      <h5 class="font-weight-bold mb-0">Análisis de Comentarios</h5>
      <nz-empty nzNotFoundContent="No hay Comentarios disponibles"></nz-empty>
    </div>
    <div class="col-12 col-x2l-6">
      <div class="mt-4">
        <h5 class="font-weight-bold mb-0">
          Análisis de Palabras en Comentarios Relevantes
        </h5>
        <span class="text-muted"
          >Las palabras más frecuentes que aportan valor a la
          retroalimentación</span
        >
        <highcharts-chart
          [Highcharts]="Highcharts"
          [options]="wordCloudChartOptionsComments"
          style="width: 100%; height: 250px; display: block"
        ></highcharts-chart>
        <div
          *ngIf="!isLoadingSummary && !wordCloudChartOptionsComments"
          class="mt-3"
        >
          <nz-empty
            nzNotFoundContent="No hay Comentarios Relevantes disponibles"
          ></nz-empty>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="summaryResult?.totalComments === 0" class="col-12">
    <h5 class="font-weight-bold mb-0">Análisis de Comentarios</h5>
    <nz-empty nzNotFoundContent="No hay Comentarios disponibles"></nz-empty>
  </div>
</div>
