<div class="container-fluid animated-fade-in">
  <h4>Editar perfil</h4>

  <div class="general-info">
    <div class="row title-section">
      <span class="col-1 my-auto">GENERAL</span>
      <span class="col-11">
        <hr />
      </span>
    </div>
    <nz-skeleton
      [nzLoading]="loading"
      [nzActive]="true"
      [nzAvatar]="true"
      [nzParagraph]="{ rows: 7 }"
      [nzRound]="true"
    >
      <section *ngIf="!loading" class="animated-fade-in">
        <app-general-form></app-general-form>

        <div *ngIf="isCompany" class="row title-section">
          <span class="col-1 my-auto">Empresa</span>
          <span class="col-11">
            <hr />
          </span>
        </div>

        <app-company-form
          *ngIf="isCompany"
          [company]="company"
        ></app-company-form>
      </section>
    </nz-skeleton>
  </div>
</div>
