<form nz-form [formGroup]="form">
  <nz-form-item>
    <span class="d-block mb-1 w-100"
      >Imagen encuesta
      <em
        class="ml-1"
        nz-icon
        nzType="info-circle"
        nzTheme="twotone"
        nz-tooltip
        nzTooltipTitle="Carga un logotipo personalizado que se mostrará en la encuesta. Si no se carga una imagen, se usará el logotipo predeterminado de Empatii"
        nzTooltipColor="black"
      ></em>
    </span>
    <div class="w-100 text-center clearfix">
      <nz-upload
        nzAction="https://www.mocky.io/v2/5cc8019d300000980a055e76"
        nzListType="picture-card"
        [(nzFileList)]="fileList"
        [nzLimit]="1"
        [nzShowButton]="fileList.length < 1"
        [nzPreview]="handlePreview"
        [nzAccept]="'.jpg,.jpeg,.png, .gif'"
        [nzSize]="2120"
        [nzMultiple]="false"
        [nzName]="'logo encuesta'"
      >
        <div>
          <em nz-icon nzType="plus" nzTheme="outline"></em>
          <div style="margin-top: 8px">Subir Imagen</div>
        </div>
      </nz-upload>
      <nz-modal
        [nzVisible]="previewVisible"
        [nzContent]="modalContent"
        [nzFooter]="null"
        (nzOnCancel)="previewVisible = false"
      >
        <ng-template #modalContent>
          <img
            [src]="previewImage"
            [ngStyle]="{ width: '100%' }"
            alt="imagen de previsualización"
          />
        </ng-template>
      </nz-modal>
    </div>
  </nz-form-item>

  <nz-form-item [hidden]="true">
    <span class="d-block mb-1"
      >¿Activar Libro Digital de Comentarios?
      <em
        class="ml-1"
        nz-icon
        nzType="info-circle"
        nzTheme="twotone"
        nz-tooltip
        nzTooltipTitle="Activa esta opción para habilitar un 'Libro Digital' al final de la encuesta. Los participantes podrán dejar reclamos, sugerencias o felicitaciones."
        nzTooltipColor="black"
      ></em>
    </span>
    <div class="w-100">
      <nz-form-control
        nzErrorTip="Indique si la encuesta incluye un libro digital."
      >
        <nz-switch formControlName="hasDigitalBook"></nz-switch>
      </nz-form-control>
    </div>
  </nz-form-item>

  <nz-form-item>
    <span class="d-block mb-1 text-info"
      >¿Modo Tablet?
      <em
        class="ml-1"
        nz-icon
        nzType="info-circle"
        nzTheme="twotone"
        nz-tooltip
        nzTooltipTitle="El 'Modo Tablet' está diseñado para encuestas en dispositivos móviles, garantizando una navegación intuitiva y una visualización optimizada. Esta configuración esencial asegura que, si una encuesta no se completa, se reiniciará automáticamente tras un periodo de inactividad, preparándola para el siguiente usuario. Al finalizar, la encuesta se resetea inmediatamente, listo para una nueva respuesta. Ideal para kioscos o estaciones de auto-servicio donde cada interacción es única y seguida por otra."
        nzTooltipColor="black"
      ></em
    ></span>
    <div class="w-100">
      <nz-form-control nzErrorTip=".">
        <nz-switch formControlName="isTabletModeSurvey"></nz-switch>
      </nz-form-control>
    </div>
  </nz-form-item>

  <nz-form-item>
    <span class="d-block mb-1 text-info"
      >¿Modo Repetición Habilitado?
      <em
        class="ml-1"
        nz-icon
        nzType="info-circle"
        nzTheme="twotone"
        nz-tooltip
        nzTooltipTitle="Habilita esta opción para permitir que un mismo usuario responda la encuesta varias veces. Útil para recoger opiniones o feedbacks repetidos."
        nzTooltipColor="black"
      ></em
    ></span>
    <div class="w-100">
      <nz-form-control nzErrorTip=".">
        <nz-switch formControlName="isRepeatModeSurvey"></nz-switch>
      </nz-form-control>
    </div>
  </nz-form-item>

  <nz-form-item>
    <span class="d-block mb-1 text-info"
      >¿Redirección a página personaliza?
      <em
        class="ml-1"
        nz-icon
        nzType="info-circle"
        nzTheme="twotone"
        nz-tooltip
        nzTooltipTitle="Si se activa, los usuarios serán redirigidos a una URL específica al finalizar la encuesta. Útil para dirigir a los participantes a tu sitio web o a una página de agradecimiento."
        nzTooltipColor="black"
      ></em
    ></span>
    <div class="row m-0 p-0 w-100 mt-2">
      <div class="col-2 p-0">
        <nz-form-control
          nzErrorTip="Indique si después de completar la encuesta, el usuario será redirigido a una URL específica."
        >
          <nz-switch formControlName="isActiveRedirectUrl"></nz-switch>
        </nz-form-control>
      </div>
      <div class="col-10">
        <nz-form-control
          class="w-100"
          nzErrorTip="Ingrese la URL válida para la redirección."
        >
          <app-input-field
            [groupName]="form"
            [disabled]="!form.get('isActiveRedirectUrl').value"
            controlName="redirectUrl"
            label="Enlace personalizado"
            placeholder="https://www.empati.cl"
            icon="audit"
          ></app-input-field>
        </nz-form-control>
      </div>
    </div>
  </nz-form-item>

  <button
    type="button"
    [disabled]="isLoading"
    class="btn btn-primary my-3 d-block mx-auto"
    (click)="onSubmit()"
  >
    <span class="mr-2"> Guardar Cambios </span>
    <span *ngIf="!isLoading" nz-icon nzType="save" nzTheme="fill"></span>
    <span *ngIf="isLoading" nz-icon nzType="loading"></span>
  </button>
</form>
