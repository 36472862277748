<nz-breadcrumb class="mb-3">
  <nz-breadcrumb-item>
    <a [routerLink]="'/app/plans-prices'"> Planes </a>
  </nz-breadcrumb-item>
  <nz-breadcrumb-item>Pagar Plan</nz-breadcrumb-item>
</nz-breadcrumb>
<div id="personal_information"></div>
<div class="row animated-fade-in">
  <div class="col-12 col-lg-8">
    <nz-collapse nzGhost [nzExpandIconPosition]="'right'">
      <nz-collapse-panel
        *ngFor="let step of paymentFacade.steps$ | async; let i = index"
        [attr.data-index]="i"
        [nzActive]="step.active"
        [nzDisabled]="step.disabled"
        [nzHeader]="i + 1 + '. ' + step.name"
        [ngSwitch]="step.name"
        (nzActiveChange)="updateStep(step)"
        class="card p-4 mb-3"
        [ngClass]="{ 'active-collapse': step.active }"
      >
        <app-billing-form
          [countries]="countries"
          *ngSwitchCase="PERSONAL_INFORMATION_NAME"
        ></app-billing-form>

        <app-billing-company-form
          *ngSwitchCase="COMERCIAL_INFORMATION_NAME"
        ></app-billing-company-form>

        <div *ngSwitchCase="PAYMENT_METHOD_NAME">
          <nz-radio-group [(ngModel)]="paymentMethod">
            <label
              nz-radio
              [nzValue]="paymentMethod"
              (click)="changePaymentMethod()"
            >
              <img
                class="image-payment"
                src="../../../../assets/images/graphics/logo-webpay.png"
                alt=""
              />
            </label>
          </nz-radio-group>
          <div class="row col-lg-12 mt-5 mx-0 px-0">
            <button
              [disabled]="(paymentFacade.isValidForm$ | async) === false"
              type="submit"
              class="btn btn-primary d-block ml-auto"
              (click)="payPlan()"
            >
              <span
                *ngIf="(paymentFacade.isLoadingPaymentForm$ | async) === true"
                nz-icon
                [nzType]="'loading'"
              ></span>
              <span
                *ngIf="(paymentFacade.isLoadingPaymentForm$ | async) === false"
                nz-icon
                nzType="rocket"
                nzTheme="outline"
              ></span>
              <span class="ml-2">Pagar Plan</span>
            </button>
          </div>
          <div id="method_payment"></div>
        </div>
      </nz-collapse-panel>
    </nz-collapse>
  </div>
  <div class="col-12 col-lg-4">
    <app-summary-payment-card
      [planId]="planId"
      [typeSuscription]="typeSuscription"
    ></app-summary-payment-card>
  </div>
</div>
