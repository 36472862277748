<div class="design-container">
  <div class="format-container">
    <span class="mr-auto mt-3">Formato</span>

    <div class="row mt-2">
      <div class="col-12 col-sm-6 col-lg-12 col-x2l-6">
        <div
          class="card format"
          [ngClass]="{ active: formatType === 'layout-1' }"
          (click)="updateFormatType('layout-1')"
        >
          <div
            class="d-flex d-x2l-block justify-content-center align-items-center h-100"
          >
            <span
              *ngIf="formatType === 'layout-1'"
              class="icon-selectded"
              nz-icon
              nzType="check-circle"
              nzTheme="fill"
            ></span>
            <span
              class="icon d-block"
              nz-icon
              nzType="solution"
              nzTheme="outline"
            ></span>
            <div
              class="pl-3 pl-x2l-0 mt-0 mt-x2l-2 justify-content-center align-items-center"
            >
              <span class="title">Una pregunta a la vez</span>
              <p class="p-0 m-0">
                Pasa de forma automática a la siguiente pregunta.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-12 col-sm-6 col-lg-12 col-x2l-6 mt-3 mt-sm-0 mt-lg-3 mt-x2l-0"
      >
        <div
          class="card format"
          [ngClass]="{ active: formatType === 'layout-3' }"
          (click)="updateFormatType('layout-3')"
        >
          <div
            class="d-flex d-x2l-block justify-content-center align-items-center h-100"
          >
            <span
              *ngIf="formatType === 'layout-3'"
              class="icon-selectded"
              nz-icon
              nzType="check-circle"
              nzTheme="fill"
            ></span>
            <span
              class="icon d-block"
              nz-icon
              nzType="solution"
              nzTheme="outline"
            ></span>
            <div
              class="pl-3 pl-x2l-0 mt-0 mt-x2l-2 justify-content-center align-items-center"
            >
              <span class="title">Página Única</span>
              <p class="p-0 m-0">
                Muestra todas las preguntas en una sola página.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="pattern-container mt-4">
    <span class="mr-auto mt-3"
      >Fondo principal <span *ngIf="formatType === 'layout-1'">lateral</span>
    </span>
    <div class="row mt-2">
      <div class="col-12 col-sm-6">
        <div
          class="card bg-2"
          [ngClass]="{ active: mainBgType === 'light' }"
          (click)="updateMainBgType('light')"
        >
          <span
            *ngIf="mainBgType === 'light'"
            class="icon-selectded"
            nz-icon
            nzType="check-circle"
            nzTheme="fill"
          ></span>
          <span class="title my-auto mx-auto">Claro</span>
        </div>
      </div>
      <div class="col-12 col-sm-6">
        <div
          class="card bg-4 mt-3 mt-sm-0 mt-x2l-0"
          [ngClass]="{ active: mainBgType === 'dark' }"
          (click)="updateMainBgType('dark')"
        >
          <span
            *ngIf="mainBgType === 'dark'"
            class="icon-selectded"
            nz-icon
            nzType="check-circle"
            nzTheme="fill"
          ></span>
          <span class="title my-auto mx-auto">Oscuro</span>
        </div>
      </div>
      <div class="col-12 col-sm-6">
        <div
          class="card bg-5 mt-3"
          [ngClass]="{ active: mainBgType === 'night-ocean' }"
          (click)="updateMainBgType('night-ocean')"
        >
          <span
            *ngIf="mainBgType === 'night-ocean'"
            class="icon-selectded"
            nz-icon
            nzType="check-circle"
            nzTheme="fill"
          ></span>
          <span class="title my-auto mx-auto">Océano Nocturno</span>
        </div>
      </div>
    </div>
  </div>

  <div class="pattern-container mt-4">
    <span class="mr-auto mt-3">Fondo contenido encuesta</span>
    <div class="row mt-2">
      <div class="col-12 col-sm-6">
        <div
          class="card bg-0"
          [ngClass]="{ active: contentBgType === 'light' }"
          (click)="updateContentBgType('light')"
        >
          <span
            *ngIf="contentBgType === 'light'"
            class="icon-selectded"
            nz-icon
            nzType="check-circle"
            nzTheme="fill"
          ></span>
          <span class="title my-auto mx-auto">Claro</span>
        </div>
      </div>

      <div class="col-12 col-sm-6">
        <div
          class="card bg-1 mt-3 mt-sm-0"
          [ngClass]="{ active: contentBgType === 'light-desing-1' }"
          (click)="updateContentBgType('light-desing-1')"
        >
          <span
            *ngIf="contentBgType === 'light-desing-1'"
            class="icon-selectded"
            nz-icon
            nzType="check-circle"
            nzTheme="fill"
          ></span>
          <span class="title my-auto mx-auto">Diseño Claro I</span>
        </div>
      </div>

      <!-- <div class="col-12 col-sm-6 mt-3">
        <div
          class="card bg-3 mt-3 mt-sm-0 mt-lg-3 mt-x2l-0"
          [ngClass]="{ active: contentBgType === 'dark' }"
          (click)="contentBgType = 'dark'"
        >
          <span
            *ngIf="contentBgType === 'dark'"
            class="icon-selectded"
            nz-icon
            nzType="check-circle"
            nzTheme="fill"
          ></span>
          <span class="title my-auto mx-auto">Oscuro</span>
        </div>
      </div> -->
    </div>
  </div>

  <button
    [disabled]="isLoading"
    class="btn btn-primary mt-5 mb-3 d-block mx-auto"
    (click)="handleSubmit()"
  >
    <span class="mr-2"> Guardar Cambios </span>
    <span *ngIf="!isLoading" nz-icon nzType="save" nzTheme="fill"></span>
    <span *ngIf="isLoading" nz-icon nzType="loading"></span>
  </button>
</div>
