<nz-spin [nzSpinning]="isLoadingSummary">
  <div *ngIf="hasResults" class="card detail-question-result my-4">
    <div class="period-group-button">
      <nz-radio-group
        [(ngModel)]="typeReportWeek"
        nzButtonStyle="solid"
        (ngModelChange)="onRadioChange($event)"
      >
        <label nz-radio-button nzValue="monthly" for="monthly">Mensual</label>
        <label nz-radio-button nzValue="weekly" for="weekly">Semanal</label>
        <label nz-radio-button nzValue="daily" for="daily">Diario</label>
      </nz-radio-group>
    </div>
    <div class="row m-0 p-0">
      <div class="col-12 col-xl-5 px-0 pr-xl-3 mb-3 mb-xl-0">
        <div class="item-summary">
          <h5 class="font-weight-bold">Puntuación del Promotor Neto (NPS)</h5>
          <span class="text-muted">Lealtad y Recomendación del Cliente</span>
          <highcharts-chart
            *ngIf="!isLoadingSummary"
            [Highcharts]="Highcharts"
            [options]="donusChartOptions"
            style="width: 100%; height: 400px; display: block"
          ></highcharts-chart>
          <img
            *ngIf="!isLoadingSummary"
            [src]="NPS_SCALE_IMG"
            alt="NPS Scale"
            class="img-responsive w-100 animated-fade-in"
          />
        </div>
      </div>

      <div class="col-12 col-xl-7 p-0">
        <div class="item-summary">
          <h5>Puntuación NPS</h5>
          <div class="item-indicator mb">
            <span class="label">
              <nz-badge nzStatus="default"></nz-badge>Índice de Satisfacción del
              Cliente
              <em
                class="ml-2"
                nz-icon
                nzType="info-circle"
                nzTheme="twotone"
                nz-tooltip
                nzTooltipTitle="El Net Promoter Score (NPS) mide la lealtad del cliente a través de su disposición a recomendar nuestros servicios. Calculado restando el porcentaje de clientes menos satisfechos (Detractores) del porcentaje de los más satisfechos (Promotores), el NPS ofrece una visión clara de la satisfacción y fidelización de nuestros clientes."
                nzTooltipColor="black"
              ></em>
            </span>

            <span class="value-score">{{ summaryResult?.npsScore }}</span>
          </div>

          <div class="item-indicator mb-4">
            <span class="label">
              <nz-badge nzStatus="default"></nz-badge>Cantidad de respuestas
            </span>

            <span class="value-score">{{ summaryResult?.totalResponses }}</span>
          </div>

          <h5 class="font-weight-bold">Desglose de Respuestas</h5>

          <div class="item-indicator">
            <span class="label">
              <nz-badge nzStatus="processing" nzColor="#27ae60"></nz-badge>
              Respuestas Promotoras (%):</span
            >
            <span class="value"
              >{{ summaryResult?.promotorResponsesPercentage }}%</span
            >
          </div>
          <div class="item-indicator">
            <span class="label">
              <nz-badge nzStatus="processing" nzColor="#f1c40f"></nz-badge>
              Respuestas Pasivas (%):</span
            >
            <span class="value"
              >{{ summaryResult?.passiveResponsesPercentage }}%</span
            >
          </div>
          <div class="item-indicator">
            <span class="label">
              <nz-badge nzStatus="processing" nzColor="#e74c3c"></nz-badge
              >Respuestas Detractoras (%):</span
            >
            <span class="value"
              >{{ summaryResult?.detractorResponsesPercentage }}%</span
            >
          </div>
          <div class="item-trend">
            <h6>Tendencia Mensual</h6>
            <p>{{ summaryResult?.periodidTrend }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!hasResults" class="card detail-question-result my-4">
    <div class="item-summary">
      <h5 class="font-weight-bold">Puntuación del Promotor Neto (NPS)</h5>
      <span class="text-muted">Lealtad y Recomendación del Cliente</span>
      <div class="text-center mt-3">
        <nz-empty nzNotFoundContent="No hay resultados para mostrar"></nz-empty>
      </div>
    </div>
  </div>
</nz-spin>
