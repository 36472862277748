import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'filterPlans',
})
export class PlansFilterPipe implements PipeTransform {
  transform(plans: any[], optionSelected: number): any[] {
    if (!plans || plans.length === 0) {
      return []
    }

    if (optionSelected === 1) {
      return plans.slice(1)
    }

    return plans
  }
}
