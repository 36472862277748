<nz-spin [nzSpinning]="isLoadingSummary">
  <div *ngIf="hasResults" class="card detail-question-result my-4">
    <div class="period-group-button">
      <nz-radio-group
        [(ngModel)]="typeReportWeek"
        nzButtonStyle="solid"
        (ngModelChange)="onRadioChange($event)"
      >
        <label nz-radio-button nzValue="monthly" for="monthly">Mensual</label>
        <label nz-radio-button nzValue="weekly" for="weekly">Semanal</label>
        <label nz-radio-button nzValue="daily" for="daily">Diario</label>
      </nz-radio-group>
    </div>
    <div class="row m-0 p-0">
      <div class="col-12 col-xl-5 px-0 pr-xl-3 mb-3 mb-xl-0">
        <div class="item-summary">
          <h5 class="font-weight-bold">
            Índice de Satisfacción del Cliente (CSAT)
          </h5>
          <span class="text-muted"
            >Evaluación Directa de la Satisfacción del Cliente</span
          >
          <highcharts-chart
            *ngIf="!isLoadingSummary"
            [Highcharts]="Highcharts"
            [options]="donusChartOptions"
            style="width: 100%; height: 495px; display: block"
          ></highcharts-chart>
        </div>
      </div>

      <div class="col-12 col-xl-7 p-0">
        <div class="item-summary">
          <h5>Resumen CSAT</h5>
          <div class="item-indicator">
            <span class="label">
              <nz-badge nzStatus="default"></nz-badge>Puntuación CSAT Promedio
              <em
                class="ml-2"
                nz-icon
                nzType="info-circle"
                nzTheme="twotone"
                nz-tooltip
                nzTooltipTitle="La Puntuación CSAT Promedio es la media de las valoraciones individuales que los clientes han dado a nuestros servicios o productos. Se calcula sumando todas las puntuaciones y dividiéndolas por el número total de respuestas. Un promedio cercano a 5 señala una alta satisfacción, mientras que uno cercano a 1 señala una baja satisfacción."
                nzTooltipColor="black"
              ></em>
            </span>
            <span class="value-score"
              >{{ summaryResult?.averageCsatScore }} / 5</span
            >
          </div>
          <div class="item-indicator mb">
            <span class="label">
              <nz-badge nzStatus="default"></nz-badge>Índice de Satisfacción del
              Cliente
              <em
                class="ml-2"
                nz-icon
                nzType="info-circle"
                nzTheme="twotone"
                nz-tooltip
                nzTooltipTitle="El Índice de Satisfacción del Cliente representa el porcentaje general de clientes que han respondido positivamente a nuestras encuestas CSAT. A diferencia del promedio CSAT, que es una media numérica de todas las respuestas, este índice refleja la proporción de clientes con respuestas en el rango de satisfacción positiva."
                nzTooltipColor="black"
              ></em>
            </span>

            <span class="value-score">{{ summaryResult?.csatScore }}%</span>
          </div>

          <div class="item-indicator mb-4">
            <span class="label">
              <nz-badge nzStatus="default"></nz-badge>Cantidad de respuestas
            </span>

            <span class="value-score">{{ summaryResult?.totalResponses }}</span>
          </div>

          <h5 class="font-weight-bold">Desglose de Respuestas</h5>

          <div class="item-indicator">
            <span class="label">
              <nz-badge nzStatus="processing" nzColor="#27ae60"></nz-badge>
              Respuestas Positivas (%):</span
            >
            <span class="value"
              >{{ summaryResult?.positiveResponsesPercentage }}%</span
            >
          </div>
          <div class="item-indicator">
            <span class="label">
              <nz-badge nzStatus="processing" nzColor="#f1c40f"></nz-badge>
              Respuestas Neutrales (%):</span
            >
            <span class="value"
              >{{ summaryResult?.neutralResponsesPercentage }}%</span
            >
          </div>
          <div class="item-indicator">
            <span class="label">
              <nz-badge nzStatus="processing" nzColor="#e74c3c"></nz-badge
              >Respuestas Negativas (%):</span
            >
            <span class="value"
              >{{ summaryResult?.negativeResponsesPercentage }}%</span
            >
          </div>
          <div class="item-trend">
            <h6>Tendencia Mensual</h6>
            <p>{{ summaryResult?.periodidTrend }}</p>
          </div>
          <!-- <img
            *ngIf="!isLoadingSummary"
            [src]="CSAT_SCALE_IMG"
            alt="NPS Scale"
            class="img-responsive w-100 animated-fade-in"
          /> -->
        </div>
      </div>
      <div class="col-12 mt-3 p-0">
        <app-comments-insight
          [summaryResult]="summaryResult"
          [isLoadingSummary]="isLoadingSummary"
        ></app-comments-insight>
      </div>
    </div>
  </div>

  <div *ngIf="!hasResults" class="card detail-question-result my-4">
    <div class="item-summary">
      <h5 class="font-weight-bold">
        Índice de Satisfacción del Cliente (CSAT)
      </h5>
      <span class="text-muted"
        >Evaluación Directa de la Satisfacción del Cliente</span
      >
      <div class="text-center">
        <nz-empty nzNotFoundContent="No hay resultados para mostrar"></nz-empty>
      </div>
    </div>
  </div>
</nz-spin>
