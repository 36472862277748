<div class="card-info-survey animated-fade-in">
  <nz-skeleton
    [nzLoading]="surveyFacade.isLoadingSurvey$ | async"
    [nzActive]="true"
    [nzAvatar]="true"
    [nzParagraph]="{ rows: 4 }"
    [nzRound]="true"
  >
    <img
      [src]="THINK_GRAPHIC_IMG"
      class="think-image d-none d-x2l-block"
      alt=""
    />

    <div
      class="row animated-fade-in"
      *ngIf="(surveyFacade.isLoadingSurvey$ | async) === false"
    >
      <!-- survey data -->
      <div class="col-xs-12 col-sm-6 col-xl-8">
        <div class="card-image-data">
          <div class="row m-0 h-100">
            <div class="col-xs-12 col-xl-6 d-none d-xl-block bg">
              <div class="header-info-survey">
                <h4>{{ (surveyFacade.survey$ | async)?.name }}</h4>
                <p>
                  {{
                    (surveyFacade.survey$ | async)?.description
                      | shortText: 0:85
                  }}
                </p>
                <button
                  type="submit"
                  class="btn btn-primary my-2"
                  (click)="showModal()"
                >
                  <span class="mr-2"> Editar encuesta </span>
                  <i nz-icon nzType="edit" nzTheme="fill"></i>
                </button>

                <nz-modal
                  [nzFooter]="null"
                  [(nzVisible)]="isVisibleModal"
                  nzTitle="Editar Encuesta"
                  (nzOnCancel)="handleCancelModal()"
                  [nzMaskClosable]="false"
                  style="width: 900px"
                >
                  <ng-container *nzModalContent>
                    <app-survey-form
                      [survey]="surveyFacade.survey$ | async"
                      [editSurvey]="true"
                      (handleCancel)="handleCancelModal()"
                    ></app-survey-form>
                  </ng-container>
                </nz-modal>
              </div>
            </div>
            <div class="col-xs-12 col-xl-6 info-survey">
              <nz-spin
                [nzSpinning]="surveyFacade.loadingAboutSurvey$ | async"
                [nzTip]="'Obteniendo información...'"
                style="height: 100%; width: 100%"
              >
                <div
                  class="info-bg h-100"
                  *ngIf="
                    (surveyFacade.aboutSurvey$ | async) !== null &&
                    (surveyFacade.aboutSurvey$ | async).length > 0
                  "
                >
                  <div class="text-center m-3">
                    <small class="badge">Durante el último mes</small>
                    <h4
                      class="text-dark text-center font-weight-semibold mt-2 mb-3"
                    >
                      <span class="text-primary">Sobre esta encuesta</span>
                    </h4>
                    <nz-carousel
                      nzEffect="scrollx"
                      [nzAutoPlay]="true"
                      [nzAutoPlaySpeed]="5000"
                    >
                      <div
                        nz-carousel-content
                        class="py-0 pt-1"
                        *ngFor="let item of surveyFacade.aboutSurvey$ | async"
                      >
                        <span
                          class="text-center d-block"
                          [innerHTML]="item.question | shortText: 0:85"
                        >
                        </span>
                        <span
                          class="font-weight-semibold tx-12 text-center d-block mt-2"
                          [innerHTML]="item.response"
                        >
                        </span>
                      </div>
                    </nz-carousel>
                  </div>
                </div>
                <div
                  class="info-bg-empty"
                  *ngIf="
                    ((surveyFacade.aboutSurvey$ | async) === null ||
                      (surveyFacade.aboutSurvey$ | async).length === 0) &&
                    (surveyFacade.loadingAboutSurvey$ | async) === false
                  "
                >
                  <div class="text-justified align-items-center">
                    <h4
                      class="text-dark text-center font-weight-semibold mb-3 mt-2"
                    >
                      <span class="text-primary">¡Hola! 🖖</span>
                    </h4>
                    <h5 class="text-dark text-center">
                      Al parecer aún no tienes respuestas
                    </h5>
                    <span class="text-dark text-center">
                      Para comenzar, crea tu primera pregunta, genera <br />
                      interacciones y luego podrás ver las estadísticas aquí.
                    </span>
                  </div>
                </div>
              </nz-spin>
            </div>
          </div>
        </div>
      </div>

      <!-- survey info description -->
      <div class="col-xs-12 col-sm-6 col-xl-4">
        <div class="row info-plan h-100 animated-fade-in">
          <div class="col-12 align-self-center">
            <!-- bloque que debes diseñar -->
            <div class="custom-block">
              <div class="custom-block-content d-none d-lg-block">
                <h5 class="block-title">>>>>></h5>
              </div>
              <div class="mx-auto ml-lg-auto">
                <div class="container">
                  <div class="row">
                    <!-- Opción de ver resultados -->
                    <div class="col mt-1">
                      <button
                        class="custom-btn"
                        nz-button
                        nzType="default"
                        nzShape="round"
                        nz-tooltip
                        (click)="viewResults()"
                        [nzTooltipTitle]="
                          (surveyFacade.survey$ | async)?.totalQuestions < 1
                            ? 'Para activar esta opción tu encuesta debe tener interacción'
                            : 'Revisa los resultados de tus encuestas'
                        "
                        nzTooltipPlacement="bottom"
                        [disabled]="
                          (surveyFacade.survey$ | async)?.totalQuestions < 1
                        "
                        [ngStyle]="{
                          color:
                            (surveyFacade.survey$ | async)?.totalQuestions < 1
                              ? 'gray'
                              : 'white'
                        }"
                      >
                        <i nz-icon nzType="bar-chart" nzTheme="outline"></i>
                      </button>
                    </div>

                    <!-- Opción de copiar link de la encuesta en portapapeles -->
                    <div class="col mt-1">
                      <button
                        class="custom-btn"
                        type="button"
                        nz-button
                        nzType="default"
                        nzShape="round"
                        nz-tooltip
                        (click)="copyUrlShared()"
                        [nzTooltipTitle]="
                          (surveyFacade.survey$ | async)?.totalQuestions < 1
                            ? 'Para activar esta opción tu encuesta debe tener al menos una pregunta'
                            : 'Facilita el acceso de tus encuestas a través de una URL'
                        "
                        nzTooltipPlacement="bottom"
                        [disabled]="
                          (surveyFacade.survey$ | async)?.totalQuestions < 1
                        "
                        [ngStyle]="{
                          color:
                            (surveyFacade.survey$ | async)?.totalQuestions < 1
                              ? 'gray'
                              : 'white'
                        }"
                      >
                        <i nz-icon nzType="copy" nzTheme="outline"></i>
                      </button>
                    </div>

                    <!-- Opción de generar QR -->
                    <div class="col mt-1">
                      <button
                        class="custom-btn"
                        nz-button
                        nzType="default"
                        nzShape="round"
                        nz-tooltip
                        (click)="showQrSurvey()"
                        [nzTooltipTitle]="
                          (surveyFacade.survey$ | async)?.totalQuestions < 1
                            ? 'Para activar esta opción tu encuesta debe tener al menos una pregunta'
                            : 'Facilita el acceso de tus encuestas a través de un QR'
                        "
                        nzTooltipPlacement="bottom"
                        [disabled]="
                          (surveyFacade.survey$ | async)?.totalQuestions < 1
                        "
                        [ngStyle]="{
                          color:
                            (surveyFacade.survey$ | async)?.totalQuestions < 1
                              ? 'gray'
                              : 'white'
                        }"
                      >
                        <i nz-icon nzType="qrcode" nzTheme="outline"></i>
                      </button>
                    </div>

                    <!-- Opción de compartir por Mail -->
                    <div class="col mt-1">
                      <button
                        class="custom-btn"
                        nz-button
                        nzType="default"
                        nzShape="round"
                        nz-tooltip
                        (click)="showMailSharedSurvey(true)"
                        [nzTooltipTitle]="
                          (surveyFacade.survey$ | async)?.totalQuestions < 1
                            ? 'Para activar esta opción tu encuesta debe tener al menos una pregunta'
                            : 'Envía tu encuesta por correo electrónico'
                        "
                        nzTooltipPlacement="bottom"
                        [disabled]="
                          (surveyFacade.survey$ | async)?.totalQuestions < 1
                        "
                        [ngStyle]="{
                          color:
                            (surveyFacade.survey$ | async)?.totalQuestions < 1
                              ? 'gray'
                              : 'white'
                        }"
                      >
                        <i nz-icon nzType="mail" nzTheme="outline"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 align-self-center">
            <!-- Wrapper for the stats -->
            <div class="stats-wrapper">
              <!-- surveys -->
              <div class="stat-item">
                <div class="stat-value">
                  <span class="font-weight-bold text-question">{{
                    (surveyFacade.survey$ | async)?.totalQuestions
                  }}</span>
                </div>
                <div class="stat-label">
                  <span class="questions">Preguntas Activas</span>
                </div>
              </div>
              <!-- responses -->
              <div class="stat-item">
                <div class="stat-value">
                  <span class="font-weight-bold text-answer">{{
                    (surveyFacade.survey$ | async)?.surveyAnswered
                  }}</span>
                </div>
                <div class="stat-label">
                  <span class="answer">Encuestas respondidas</span>
                </div>
              </div>
              <!-- responses -->
              <div class="stat-item">
                <div class="stat-value">
                  <span class="font-weight-bold text-answer">{{
                    (surveyFacade.survey$ | async)?.responsesUsed
                  }}</span>
                </div>
                <div class="stat-label">
                  <span class="answer">Respuestas totales</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nz-skeleton>
</div>

<nz-dropdown-menu #menu="nzDropdownMenu">
  <ul *ngIf="true" nz-menu>
    <li nz-menu-item>Ver Resultados</li>
    <li nz-submenu nzTitle="Compartir">
      <ul>
        <li nz-menu-item (click)="showQrSurvey()">Ver QR</li>
        =
        <li nz-menu-item (click)="copyUrlShared()">Copiar Link</li>
      </ul>
    </li>
  </ul>
</nz-dropdown-menu>

<nz-modal
  [nzFooter]="null"
  [(nzVisible)]="isVisibleForm"
  [nzTitle]="'QR: ' + (surveyFacade.survey$ | async)?.name"
  (nzOnCancel)="handleCancel()"
  (nzOnOk)="handleOk()"
>
  <ng-container *nzModalContent>
    <nz-spin
      [nzSpinning]="surveyFacade.isLoadingQR$ | async"
      [nzTip]="'Generando QR...'"
    >
      <div style="height: 450px">
        <div
          *ngIf="(surveyFacade.isLoadingQR$ | async) === false"
          class="animated-fade-in"
        >
          <img
            class="d-block mx-auto"
            width="400px"
            height="400px"
            [src]="surveyFacade.imageSurveyQR$ | async"
            alt="imagen-qr"
          />
          <button
            type="submit"
            class="btn btn-primary d-block ml-auto my-2"
            (click)="surveyFacade.downloadQRSurvey()"
            [disabled]="surveyFacade.isLoadingQR$ | async"
          >
            <span
              *ngIf="surveyFacade.isLoadingQR$ | async"
              class="d-flex align-items-center"
            >
              <span class="mr-2"> Descargando </span>
              <i nz-icon [nzType]="'loading'"></i>
            </span>
            <span
              *ngIf="(surveyFacade.isLoadingQR$ | async) === false"
              class="d-flex align-items-center"
            >
              <span class="mr-2"> Descargar QR </span>
              <i nz-icon nzType="down-circle" nzTheme="outline"></i
            ></span>
          </button>
        </div>
      </div>
    </nz-spin>
  </ng-container>
</nz-modal>

<nz-modal
  [nzFooter]="null"
  [(nzVisible)]="isVisibleMailSharedModal"
  [nzTitle]="
    'Comparti por correo encuesta: ' + (surveyFacade.survey$ | async)?.name
  "
  (nzOnCancel)="handleCancel()"
  (nzOnOk)="handleOk()"
>
  <ng-container *nzModalContent>
    <nz-spin
      [nzSpinning]="surveyFacade.isLoadingQR$ | async"
      [nzTip]="'Obteniendo información...'"
    >
      <div style="min-height: 450px">
        <app-shared-survey
          *ngIf="(surveyFacade.isLoadingQR$ | async) === false"
        ></app-shared-survey>
      </div>
    </nz-spin>
  </ng-container>
</nz-modal>
