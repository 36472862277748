import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss'],
})
export class MaintenanceComponent {
  public IMG_INFO = '../../../assets/images/graphics/THG_02.svg'
}
