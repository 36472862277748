import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { ISurvey } from 'src/app/models/response/ISurvey'
import { environment } from 'src/environments/environment'
import { IMassiveSurveyQuestionRequest } from './../../models/request/IMassiveSurveyQuestion'
import { IResponse } from './../../models/response/IResponse'

@Injectable({
  providedIn: 'root',
})
export class SurveyService {
  private api: string

  constructor(public http: HttpClient) {
    this.api = environment.api
  }

  public createSurvey(request: ISurvey): Observable<IResponse> {
    const uri = `${this.api}/survey`
    return this.http.post(uri, request).pipe(map((data: IResponse) => data))
  }

  public createMassiveSurvey(
    request: IMassiveSurveyQuestionRequest
  ): Observable<IResponse> {
    const uri = `${this.api}/survey/massive-survey`
    return this.http.post(uri, request).pipe(map((data: IResponse) => data))
  }
  u
  public updateSurveyFetch(request: ISurvey): Observable<IResponse> {
    const uri = `${this.api}/survey/${request.surveyId}`
    return this.http.put(uri, request).pipe(map((data: IResponse) => data))
  }

  public toggleFavoriteSurveyFetch(surveyId: string): Observable<IResponse> {
    const uri = `${this.api}/survey/toggle-favorite/${surveyId}`
    return this.http.put(uri, null).pipe(map((data: IResponse) => data))
  }

  public listSurveys(isArchived = false): Observable<IResponse> {
    const uri = `${this.api}/survey-list/${isArchived ? '1' : '0'}`
    return this.http.get(uri).pipe(map((data: IResponse) => data))
  }

  public getSurveyDetail(surveyId: string): Observable<IResponse> {
    const uri = `${this.api}/survey/${surveyId}`
    return this.http.get(uri).pipe(map((data: IResponse) => data))
  }

  // public getAboutSurveyDetail(surveyId: string): Observable<IResponse> {
  //   const uri = `${this.api}/survey/info/${surveyId}/monthly`
  //   return this.http.get(uri).pipe(map((data: IResponse) => data))
  // }

  public getAboutSurvey(surveyId: string): Observable<IResponse> {
    const uri = surveyId
      ? `${this.api}/survey/info/${surveyId}/monthly`
      : `${this.api}/survey/info/monthly`
    return this.http.get(uri).pipe(map((data: IResponse) => data))
  }

  public QRbySurveyId(surveyId: string): Observable<IResponse> {
    const uri = `${this.api}/v1/public/survey/qr/${surveyId}`
    return this.http.get(uri).pipe(map((data: IResponse) => data))
  }

  public archiveSurvey(
    surveyId: string,
    isArchived: number
  ): Observable<IResponse> {
    const uri = `${this.api}/archive-survey/${surveyId}/${isArchived}`
    return this.http.get(uri).pipe(map((data: IResponse) => data))
  }

  public toggleSurvey(
    surveyId: string,
    isActive: number
  ): Observable<IResponse> {
    const uri = `${this.api}/toggle-survey/${surveyId}/${isActive}`
    return this.http.get(uri).pipe(map((data: IResponse) => data))
  }

  public deleteSurvey(surveyId: string): Observable<IResponse> {
    const uri = `${this.api}/survey/destroy/${surveyId}`
    return this.http.delete(uri).pipe(map((data: IResponse) => data))
  }
}
