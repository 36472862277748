import { Component } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { filter, map } from 'rxjs/operators'
import { SessionFacade } from 'src/app/store/session/session.facade'
import { environment } from 'src/environments/environment'
import { UserFacade } from './store/user/user.facade'

const TITLE = 'title'
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  public isDevEnv: boolean
  public constructor(
    private router: Router,
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    public sessionFacade: SessionFacade,
    public userFacade: UserFacade
  ) {
    this.isDevEnv = environment.env === 'dev'

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let child = this.activatedRoute.firstChild
          while (child) {
            if (child.firstChild) {
              child = child.firstChild
            } else if (child.snapshot.data && child.snapshot.data[TITLE]) {
              return child.snapshot.data[TITLE]
            } else {
              return null
            }
          }
          return null
        })
      )
      .subscribe((data: string) => {
        if (data) {
          this.titleService.setTitle(data)
        }
      })
  }
}
