import { Injectable } from '@angular/core'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import {
  UpdateIdJiraTicketCreated,
  UpdateLoadingJiraTicketCreated,
  CleanIdJiraTicketCreated,
} from './jira.actions'

export interface IJiraModel {
  idTicketCreated: string
  isLoadingJiraTicketCreated: boolean
}

@State<IJiraModel>({
  name: 'jira',
  defaults: {
    idTicketCreated: null,
    isLoadingJiraTicketCreated: false,
  },
})
@Injectable()
export class JiraState {
  @Selector()
  public static getTicket(state: IJiraModel): string {
    return state.idTicketCreated
  }
  @Selector()
  public static getLoadingJiraTicketCreated(state: IJiraModel): boolean {
    return state.isLoadingJiraTicketCreated
  }

  @Action(UpdateIdJiraTicketCreated)
  updateIdJiraTicketCreated(
    { getState, setState }: StateContext<IJiraModel>,
    { payload }: UpdateIdJiraTicketCreated
  ): void {
    const state = getState()
    setState({
      ...state,
      idTicketCreated: payload,
    })
  }

  @Action(UpdateLoadingJiraTicketCreated)
  updateLoadingJiraTicketCreated(
    { getState, setState }: StateContext<IJiraModel>,
    { payload }: UpdateLoadingJiraTicketCreated
  ): void {
    const state = getState()
    setState({
      ...state,
      isLoadingJiraTicketCreated: payload,
    })
  }

  @Action(CleanIdJiraTicketCreated)
  cleanIdJiraTicketCreated({
    getState,
    setState,
  }: StateContext<IJiraModel>): void {
    const state = getState()
    setState({
      ...state,
      idTicketCreated: null,
      isLoadingJiraTicketCreated: false,
    })
  }
}
