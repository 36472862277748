import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core'
import { Store } from '@ngxs/store'
import { ShepherdService } from 'angular-shepherd'
import { NzModalService } from 'ng-zorro-antd/modal'
import { first } from 'rxjs/operators'
import { IUser } from 'src/app/models/response/IUser'
import { SurveyFacade } from 'src/app/store/store.index'
import { ConfirmCheckboxComponentComponent } from './../../components/confirm-checkbox-component/confirm-checkbox-component.component'
import { IUserTutorial } from './../../models/response/IUser'
import { UserFacade } from './../../store/user/user.facade'
import { TYPE_CARD } from './../../utils/constants/survey-messages'
import {
  defaultStepOptions,
  defaultSteps,
  enableElements,
} from './tutorial-data/tutorial.data'

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss'],
})
export class SurveyComponent implements OnInit, AfterViewInit {
  public isVisibleForm: boolean
  public isVisibleFormWithIA: boolean
  public surveyTypeFilter: string

  public typeList: string
  public typeStatusSurvey: number
  public isModeSurveyArchived: boolean = false

  public pageIndex: number
  public pageSize: number

  public typeViewSurvey = 'card'
  public IMG_NOT_FOUND = '../../../assets/images/icons/no-results.png'

  constructor(
    public surveyFacade: SurveyFacade,
    public userFacade: UserFacade,
    private shepherdService: ShepherdService,
    private modalService: NzModalService,
    private cdr: ChangeDetectorRef,
    private store: Store
  ) {
    this.isVisibleForm = false
    this.isVisibleFormWithIA = false
    this.surveyTypeFilter = 'ALL'
    this.pageIndex = 1
    this.pageSize = 4
    this.typeList = TYPE_CARD
    this.typeStatusSurvey = 1
  }

  ngOnInit(): void {
    this.surveyFacade.getSurveyList()
    this.surveyFacade.getSurveyCategories()
  }

  showModal(): void {
    this.isVisibleForm = true
  }

  handleOk(): void {
    this.isVisibleForm = false
  }

  handleCancel(): void {
    this.isVisibleForm = false
  }

  async ngAfterViewInit(): Promise<void> {
    const user: IUser = await this.userFacade.user$.pipe(first()).toPromise()
    this.shepherdService.defaultStepOptions = defaultStepOptions
    this.shepherdService.modal = true
    this.shepherdService.confirmCancel = false
    this.shepherdService.addSteps(defaultSteps)
    this.shepherdService.tourObject.on('cancel', () => {
      enableElements('#info-plan')
      enableElements('#survey-list')
      this.showCancelConfirmationModal(user.tutorial)
    })
    this.shepherdService.tourObject.on('complete', () => {
      enableElements('#info-plan')
      enableElements('#survey-list')

      this.userFacade.userTutorialFetch({
        ...user.tutorial,
        surveyTutorialCompleted: true,
      })
    })

    if (user && !user.tutorial.surveyTutorialCompleted)
      this.shepherdService.start()
  }

  showCancelConfirmationModal(payload: IUserTutorial): void {
    const modal = this.modalService.confirm({
      nzTitle: '¿Deseas salir del tutorial?',
      nzContent: ConfirmCheckboxComponentComponent,
      nzClosable: false,

      nzOnOk: () => {
        const componentInstance =
          modal.getContentComponent() as ConfirmCheckboxComponentComponent
        this.handleCancelConfirm(componentInstance.dontShowAgain, payload)
      },
      nzOnCancel: () => {
        this.handleCancelConfirm(false, payload)
      },
    })
  }

  handleModal(): void {
    this.isVisibleForm = false
    this.isVisibleFormWithIA = true
  }

  handleCancelConfirm(dontShowAgain: boolean, payload: IUserTutorial): void {
    enableElements('#info-plan')
    enableElements('#survey-list')

    if (dontShowAgain) {
      this.userFacade.userTutorialFetch({
        ...payload,
        surveyTutorialCompleted: true,
      })
    }
  }
}
