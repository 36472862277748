import { ILoadingReport } from './report.state'

export class UpdateQuestionResponsesDataReport {
  static readonly type = '[REPORT] update question responses report'
  constructor(public payload: number[]) {}
}

export class UpdateSurveyResponsesDataReport {
  static readonly type = '[REPORT] update survey responses report'
  constructor(public payload: any) {}
}
export class UpdateTrendTotalResponsesDataReport {
  static readonly type = '[REPORT] update trend total responses report'
  constructor(public payload: any) {}
}
export class UpdateIsLoadingQuestionResponses {
  static readonly type = '[REPORT] update loading question responses report'
  constructor(public payload: boolean) {}
}

export class UpdateIsLoadingTrendTotalResponses {
  static readonly type = '[REPORT] update loading trend total responses report'
  constructor(public payload: boolean) {}
}
