export interface ISurveyListInfo {
  totalSurveys: number
  activedSurveys: number
  disabledSurveys: number
  archivedSurveys: number
}

export const initialValuesSuryveListInfo: ISurveyListInfo = {
  totalSurveys: 0,
  activedSurveys: 0,
  disabledSurveys: 0,
  archivedSurveys: 0,
}
