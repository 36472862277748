import {
  Component,
  HostListener,
  OnInit,
  ViewEncapsulation,
} from '@angular/core'
import { SessionFacade } from 'src/app/store/session/session.facade'
import { environment } from 'src/environments/environment'
import { TokenService } from '../services/session/token.service'
import { PaymentFacade } from '../store/payment/payment.facade'
import { PlanFacade } from './../store/plan/plan.facade'
import { UserFacade } from './../store/user/user.facade'

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['../shared/shared.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PagesComponent implements OnInit {
  public showExpandSidebar: boolean
  public showSidebarMobile: boolean
  public env: string

  constructor(
    public sessionFacade: SessionFacade,
    public userFacade: UserFacade,
    public paymentFacade: PaymentFacade,
    private planFacade: PlanFacade,
    public _token: TokenService
  ) {
    this.env = environment.env
  }

  ngOnInit(): void {
    this.getInnerWith()
    this.planFacade.obtainPlansDetailFetch()
    this.planFacade.obtainCurrentPlanFetch()
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.getInnerWith()
  }

  private getInnerWith(): void {
    // this.showExpandSidebar = window.innerWidth < 768
    this.showSidebarMobile = window.innerWidth < 1200
  }

  public changeShowExpandSidebar(): void {
    this.showExpandSidebar = !this.showExpandSidebar
  }
}
