import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-loader-section',
  templateUrl: './loader-section.component.html',
  styleUrls: ['./loader-section.component.scss'],
})
export class LoaderSectionComponent {
  @Input() title = 'Obteniendo información'
}
