import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { IQuestion } from 'src/app/models/response/IQuestion'
import { FeedBackService } from 'src/app/services/shared/feedback.service'

@Component({
  selector: 'app-nps-mobile',
  templateUrl: './nps-mobile.component.html',
  styleUrls: ['./nps-mobile.component.scss'],
})
export class NpsMobileComponent implements OnInit {
  @Output() handleShow = new EventEmitter<void>()
  @Output() handleSubmit = new EventEmitter<void>()
  @Output() responseChange = new EventEmitter<number>()
  @Output() commentChange = new EventEmitter<string | null>()
  @Input() response: number | string | null = null
  @Input() comment: string | null = null
  @Input() question: IQuestion
  scaleNumbers = Array.from({ length: 11 }, (_, index) => index)

  constructor(public _feedBackService: FeedBackService) {
    this.response = +this.response || null
  }

  ngOnInit(): void {}

  getButtonClass(index: number): string {
    let buttonType = ''
    if (index <= 5) buttonType = 'detractor'
    else if (index <= 8) buttonType = 'passive'
    else buttonType = 'promoter'

    return `nps--widget__button ${buttonType} ${
      this.response && +this.response === +index ? 'active' : ''
    }`
  }

  getButtonStyle(index: number): any {
    switch (index) {
      case 0:
        return { backgroundColor: '#ff9eae' }
      case 1:
      case 2:
        return { backgroundColor: '#ffafbc' }
      case 3:
        return { backgroundColor: '#ffc0cb' }
      case 4:
        return { backgroundColor: '#ffd1d9' }
      case 5:
        return { backgroundColor: '#ffe2e7' }
      case 9:
        return { backgroundColor: '#adecba' }
      case 10:
        return { backgroundColor: '#5ad974' }
    }
  }

  handleChange(index: number): void {
    this.response = +index
    this.handleSubmit.emit()
    this.responseChange.emit(index)
  }

  handleCancel(): void {
    this.response = null
    this.responseChange.emit(null)
    this.handleShow.emit()
  }
}
